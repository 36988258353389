import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import PageHeader from "../components/PageHeader";
import ActionButton from "../components/ActionButton";

import ModalSummaryItem from "../components/ModalSummaryItem";

import { IoSwapVertical } from "react-icons/io5";
import { AiOutlineWarning } from "react-icons/ai";

import Modal from "@mui/material/Modal";

import * as apiCalls from "../redux/actions/apiCalls";
import * as binanceTickerActions from "../redux/actions/binanceTIckerWebSocketActions";

import IconedInput from "../components/IconedInput";

import { notification } from "antd";
import AlertModal from "../components/AlertModal";

const POPULAREXCHANGES = [
  {
    id: "1",
    text: "Binance",
    type: "BINANCE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
    createaccountlink: "https://accounts.binance.com/en/register",
    selected: false,
    availablemethods: {
      qrcode: true,
      manual: true,
      noaccount: true,
    },
  },
  {
    id: "3",
    text: "Huobi Global",
    type: "HUOBI",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
    createaccountlink: "https://www.huobi.com/en-us/register/",
    selected: false,
    availablemethods: {
      qrcode: false,
      manual: true,
      noaccount: true,
    },
  },
];

const openNotification = ({
  title = "Notification",
  description,
  duration,
  style,
}) => {
  const args = {
    message: title,
    description: description,
    duration: duration || 5,
    style: style || {},
  };
  notification.open(args);
};

const SignalAutomaticOperationScreen = ({
  auth,
  deviceconfigs,
  language,
  colorscheme,
  myexchanges,
  api,
  binancetickers,
  binancetickeractions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const mState = location.state;
  const signal = mState.signal;

  const symbolData = binancetickers && binancetickers[signal.name];
  const currentprice = symbolData?.currentprice;
  const percent = symbolData?.percent;
  const margin = symbolData?.margin;

  const [ordertobesent, setOrderToBeSent] = useState({
    quantity: undefined,
    targets: [...signal.targets],
    purchasingprice: signal.purchasingprice,
    stoploss: signal.stoploss,
    expense: undefined,
    minNotional: false,
    minPrice: false,
    maxPriceHit: false,
    stoplossvalid: false,
    percentprice: false,
    noquantity: false,
    noexpense: false,
  });
  const [confirmationvisible, setConfirmationVisible] = useState(false);
  const [sending, setSending] = useState(false);
  const [showpopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("");
  const [popupbody, setPopupBody] = useState("");

  useEffect(() => {
    binancetickeractions.subscribeToSymbol(signal.basecoin + signal.quotecoin);
    return () => {
      // Anything in here is fired on component unmount.
      binancetickeractions.unsubscribeToSymbol(
        signal.basecoin + signal.quotecoin
      );
    };
  }, []);

  const calculatePossibleProfit = () => {
    const { purchasingprice, targets } = signal;
    if (targets != null && targets != undefined && targets.length > 0) {
      let totalprofit = 0;
      for (var i = 0; i < targets.length; i++) {
        if (targets[i].value && targets[i].share && purchasingprice) {
          let yVal = parseFloat(purchasingprice);
          let xVal = parseFloat(targets[i].value);
          let roi = (xVal - yVal) / yVal;
          totalprofit += roi * targets[i].share;
        }
      }
      return totalprofit.toFixed(2);
    }
    return "N/A";
  };

  const calculatePossibleLoss = () => {
    const { purchasingprice, stoploss } = signal;
    if (purchasingprice != undefined && stoploss != undefined) {
      let yVal = parseFloat(purchasingprice);
      let xVal = parseFloat(stoploss);
      let roi = (yVal - xVal) / yVal;
      return (roi * 100).toFixed(2);
    }
    return "N/A";
  };

  const checkMinNotional = (filters, quantity) => {
    for (var i = 0; i < filters.length; i++) {
      if (filters[i].filterType == "MIN_NOTIONAL") {
        let minNotional = parseFloat(filters[i].minNotional);
        let y = parseFloat(quantity) * parseFloat(currentprice);
        if (y < minNotional) {
          return true;
        }
        for (var x = 0; x < ordertobesent.targets.length; x++) {
          const share = parseFloat(ordertobesent.targets[x].share) / 100.0;
          const shareQuantity = share * quantity * parseFloat(currentprice);
          if (shareQuantity < minNotional) {
            return true;
          }
        }
        return false;
      }
    }
    return false;
  };

  const makeOrder = async () => {
    let sender = {
      symbol: signal.name,
      quantity: ordertobesent.quantity,
      side: "BUY",
      stoploss: ordertobesent.stoploss,
      stoplosstype: "AUTOMATIC",
      type: "LIMIT",
      price: ordertobesent.purchasingprice,
      userid: auth.user.id,
      targets: ordertobesent.targets,
      signalid: signal.id,
    };
    setConfirmationVisible(false);
    setSending(true);
    const marketPromise =
      signal.exchange == "BINANCE"
        ? api.createBinanceMarketTrade(sender)
        : api.createHuobiMarketOrder(sender);
    marketPromise
      .then((res) => {
        setSending(false);
        setPopupTitle(language.success.order.header);
        setPopupBody(language.success.order.body);
        setShowPopup(true);
        // openNotification({
        //     title: language.success.order.header,
        //     description: (
        //         <div style={{
        //             display: "flex",
        //             flexDirection: "row",
        //             alignItems: "center",
        //         }}>
        //             <div>
        //                 <AiOutlineWarning
        //                     style={{
        //                         color: colorscheme.v2.warning,
        //                         fontSize: deviceconfigs.icon.largeicon,
        //                     }}
        //                 />
        //             </div>
        //             <div style={{
        //                 marginLeft: "12px",
        //             }}>{language.success.order.body}</div>
        //         </div>
        //     )
        // });

        setOrderToBeSent({
          quantity: undefined,
          targets: [],
          purchasingprice: signal.purchasingprice,
          stoploss: signal.stoploss,
          expense: undefined,
          minNotional: false,
          minPrice: false,
          maxPriceHit: false,
          stoplossvalid: false,
          percentprice: false,
          noquantity: false,
          noexpense: false,
        });
      })
      .catch((err) => {
        if (err.response.data.responsecode == "-1013") {
          openNotification({
            title: language.SignalsScreen.genericerror,
            description: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <AiOutlineWarning
                    style={{
                      color: colorscheme.v2.warning,
                      fontSize: deviceconfigs.icon.largeicon,
                    }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  {language.SignalsScreen.lotsizenotsatisfied}
                </div>
              </div>
            ),
          });
          return;
        } else if (err.response.data.responsecode == "-2010") {
          openNotification({
            title: language.SignalsScreen.genericerror,
            description: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <AiOutlineWarning
                    style={{
                      color: colorscheme.v2.warning,
                      fontSize: deviceconfigs.icon.largeicon,
                    }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  {language.SignalsScreen.insufficientbalance}
                </div>
              </div>
            ),
          });
          return;
        } else if (err.response.data.responsecode == "-2015") {
          openNotification({
            title: language.SignalsScreen.genericerror,
            description: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <AiOutlineWarning
                    style={{
                      color: colorscheme.v2.warning,
                      fontSize: deviceconfigs.icon.largeicon,
                    }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  {language.SignalsScreen.apikeyerror}
                </div>
              </div>
            ),
          });

          return;
        }
        openNotification({
          title: language.SignalsScreen.genericerror,
          description: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <AiOutlineWarning
                  style={{
                    color: colorscheme.v2.warning,
                    fontSize: deviceconfigs.icon.largeicon,
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "12px",
                }}
              >
                {err.response.data.responsecode}
              </div>
            </div>
          ),
        });
      });
    return;
  };

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    marketwatchcontainer: {
      padding: deviceconfigs.layout.largepadding,
    },
    currentpriceandpercentcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pointcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    iconcontainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: deviceconfigs.layout.mediumpadding,
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    icon: {
      width: deviceconfigs.button.xlargesize,
      height: deviceconfigs.button.xlargesize,
      borderRadius: 999,
      objectFit: "contain",
    },
    currentpricecontainer: {
      flex: 1,
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    percentcontainer: {
      width: "24vw",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor:
        margin < 0 ? colorscheme.v2.error : colorscheme.v2.success,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    currentprice: {
      fontSize: deviceconfigs.font.xxxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    percent: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      color: colorscheme.v2.primarytext,
    },
    margin: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    pointiconcontainer: {},
    chartanddetailscontainer: {},
    scrollcontainer: {
      flex: 1,
      paddingTop: "150px",
    },
    chartcontainer: {},
    detailscontainer: {
      flex: 1,
    },
    detailssubheadercontainer: {
      padding: deviceconfigs.layout.xlargepadding,
      marginLeft: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    infocontainer: {
      padding: deviceconfigs.layout.xxlargepadding,
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      borderTopColor: colorscheme.v2.bordercolor,
      borderTopWidth: 1,
    },
    infoleftitem: {
      flex: 1,
    },
    infoseperator: {
      width: 1,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.bordercolor,
    },
    inforightitem: {
      flex: 1,
    },
    signalsubheader: {
      flex: 1,
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "400",
      color: colorscheme.v2.heavytext,
    },
    manualsubheader: {
      alignSelf: "flex-end",
      color: colorscheme.v2.heavytext,
    },
    infoitemcontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    infoitemline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.mediumpadding}px 0px`,
    },
    infoitemtitle: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    infoitemvalue: {
      flex: 1,
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    infoitemvaluetwo: {
      alignSelf: "flex-end",
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.success,
    },
    actionbuttonscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    actionbutton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colorscheme.v2.primarybackground,
      padding: deviceconfigs.layout.xlargepadding,
      borderRadius: deviceconfigs.layout.xlargepadding,
      marginHorizontal: deviceconfigs.layout.xxlargepadding,
    },
    actionbuttontext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "500",
      color: colorscheme.v2.backgroundtext,
    },
    explanationcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    explanation: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "400",
      color: colorscheme.v2.unselected,
    },
    pricecontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    priceheader: {},
    price: {},
    minquantitycontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    minquantityleft: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.unselected,
    },
    minquantityright: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
  };
  const { info, basecoin, quotecoin } = signal;
  const { targets, stoploss, purchasingprice, quantity } = ordertobesent;
  const t = targets.map((e) => {
    return e.share;
  });
  var totalShares = -1;
  if (t.length > 0) {
    totalShares = (t || []).reduce((acc, cv) => {
      return acc + cv;
    });
  }
  const minNotionalArray =
    info &&
    (info.filters || []).filter((a) => {
      return a.filterType == "MIN_NOTIONAL";
    });
  const minNotional =
    minNotionalArray &&
    minNotionalArray.length == 1 &&
    parseFloat(minNotionalArray[0].minNotional);

  let minShare = 100.0;
  for (var g = 0; g < targets.length; g++) {
    if (parseFloat(targets[g].share) < minShare) {
      minShare = parseFloat(targets[g].share);
    }
  }

  const pMyExchanges = (myexchanges && myexchanges.payload) || {};
  const pMyExchangesData = pMyExchanges.data;
  const myExchange = pMyExchangesData.filter((a) => {
    return a.type == signal.exchange;
  })[0];
  const myBalances =
    myExchange.balances &&
    myExchange.balances.filter((a) => {
      return a.asset == signal.quotecoin;
    });
  const myBalance =
    myBalances && myBalances.length > 0 ? parseFloat(myBalances[0].free) : 0;
  const binanceMinQuantity =
    (parseFloat(minNotional) / parseFloat(currentprice)) *
    (100.0 / parseFloat(minShare));
  const huobiMinQuantity =
    (100.0 / parseFloat(minShare)) * signal.info["sell-market-min-order-amt"];
  const minQuantity =
    signal.exchange == "HUOBI" ? huobiMinQuantity : binanceMinQuantity;

  const isExpenseInvalid =
    ordertobesent.expense == undefined || ordertobesent.expense == null;
  const isQuantityInvalid =
    ordertobesent.quantity == undefined || ordertobesent.quantity == null;

  const minValue =
    signal.exchange == "HUOBI"
      ? signal.minordervalue
      : parseFloat(currentprice) * minQuantity;
  const isLessThanMinQuantity =
    parseFloat(quantity) < parseFloat(minQuantity) ||
    (minValue && parseFloat(minValue) > parseFloat(ordertobesent.expense));

  const signalExchange = POPULAREXCHANGES.filter((a) => {
    return a.type === signal.exchange;
  })[0];

  const SignalInfoItem = (props) => {
    const { title, value, valuetwo } = props;
    return (
      <div style={styles.infoitemcontainer}>
        <div style={styles.infoitemline}>
          <div style={styles.infoitemtitle}>{title}</div>
        </div>
        <div style={styles.infoitemline}>
          <div style={styles.infoitemvalue}>{value}</div>
          {valuetwo && <div style={styles.infoitemvaluetwo}>{valuetwo}</div>}
        </div>
      </div>
    );
  };
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          top: "0px",
          zIndex: 100,
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            onBackPressed={() => {
              window.history.back();
            }}
            lefticon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                }}
              >
                <div style={{}}>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: colorscheme.v2.backgroundtext,
                    }}
                  >
                    {signal.basecoin + signal.quotecoin}
                  </div>
                </div>
              </div>
            }
            righticon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                  backgroundColor: colorscheme.v2.cardbackground,
                  padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.largepadding}px`,
                }}
              >
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <img
                    src={signalExchange.icon}
                    style={{
                      width: "18px",
                      height: "18px",
                      objectFit: "contain",
                    }}
                    alt={"MyPerformers"}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: colorscheme.v2.backgroundtext,
                    }}
                  >
                    {signalExchange.text}
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div style={styles.marketwatchcontainer}>
          <div style={styles.currentpriceandpercentcontainer}>
            <div style={styles.currentpricecontainer}>
              <div style={styles.currentprice}>{"$ " + currentprice}</div>
            </div>
            <div style={styles.percentcontainer}>
              <div style={styles.percent}>{percent + "%"}</div>
            </div>
          </div>
          <div style={styles.pointcontainer}>
            <div style={styles.pointiconcontainer}>
              <IoSwapVertical
                style={{
                  fontSize: deviceconfigs.icon.mediumicon,
                  color: colorscheme.v2.unselected,
                }}
              />
            </div>
            <div style={styles.margin}>{margin}</div>
          </div>
        </div>
      </div>
      <div style={styles.scrollcontainer}>
        <div style={styles.detailscontainer}>
          <div style={styles.detailssubheadercontainer}>
            <div style={styles.signalsubheader}>{language.signaldetails}</div>
            <div style={styles.manualsubheader}>{language.automatic}</div>
          </div>
          <div style={styles.infocontainer}>
            <div style={styles.infoleftitem}>
              <SignalInfoItem
                title={language.purchasingprice}
                value={signal.purchasingprice}
              />
              <SignalInfoItem
                title={language.initialprice}
                value={parseFloat(signal.initialprice)}
              />
              <SignalInfoItem
                title={language.stoploss}
                value={signal.stoploss}
              />
            </div>
            <div style={styles.infoseperator}></div>
            <div style={styles.inforightitem}>
              {(signal.targets || []).map((target, index) => {
                return (
                  <div key={index}>
                    <SignalInfoItem
                      title={`${language.target} #${index + 1}`}
                      value={target.value}
                      valuetwo={target.share + "%"}
                    />
                  </div>
                );
              })}
              <SignalInfoItem
                title={language.targetedprofit}
                value={calculatePossibleProfit() + "%"}
              />
              <SignalInfoItem
                title={language.riskofinvestment}
                value={calculatePossibleLoss() + "%"}
              />
            </div>
          </div>
          <div style={styles.explanationcontainer}>
            <div style={styles.explanation}>
              {language.buyxinexchangefory(basecoin, quotecoin)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
            }}
          >
            <div
              style={{
                padding: deviceconfigs.layout.xxlargepadding,
                borderWidth: 1,
                borderColor:
                  ordertobesent.minNotional ||
                  isLessThanMinQuantity ||
                  ordertobesent.noquantity
                    ? colorscheme.v2.error
                    : colorscheme.v2.bordercolor,
                borderRadius: deviceconfigs.layout.xlargepadding,
                width: "82vw",
              }}
            >
              <IconedInput
                style={{
                  color: colorscheme.v2.backgroundtext,
                }}
                onFocus={() => {
                  // if (this.myScroll && this.quantityy) {
                  //     setTimeout(() => {
                  //         this.myScroll.scrollTo({ x: 0, y: this.quantityy + keyboardHeight });
                  //     }, 800);
                  // }
                }}
                value={quantity}
                onChangeText={(text) => {
                  ordertobesent.quantity = text
                    .replace(/\s/g, "")
                    .replace(/,/g, ".");
                  if (signal.exchange == "BINANCE") {
                    ordertobesent.minNotional = checkMinNotional(
                      signal.info.filters,
                      ordertobesent.quantity
                    );
                  }

                  if (
                    ordertobesent.quantity == undefined ||
                    ordertobesent.quantity == ""
                  ) {
                    ordertobesent.expense = "0";
                    ordertobesent.noquantity = true;
                  } else {
                    ordertobesent.expense = (
                      parseFloat(ordertobesent.quantity) *
                      parseFloat(currentprice)
                    ).toFixed(signal.valueprecision);
                    ordertobesent.noexpense = false;
                    ordertobesent.noquantity = false;
                  }
                  setOrderToBeSent(ordertobesent);
                }}
                placeholder={`${language.quantity}(${basecoin})`}
                placeholderTextColor={colorscheme.v2.heavytext}
              />
            </div>
            <div style={styles.minquantitycontainer}>
              <div style={styles.minquantityleft}>
                {language.minimumquantity} :{" "}
              </div>
              <div style={styles.minquantityright}>
                {minQuantity.toFixed(signal.amountprecision) + " " + basecoin}{" "}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
            }}
            onLayout={(e) => {
              //this.amounty = e.nativeEvent.layout.y;
            }}
          >
            <div
              style={{
                padding: deviceconfigs.layout.xxlargepadding,
                borderWidth: 1,
                borderColor:
                  ordertobesent.minNotional ||
                  isLessThanMinQuantity ||
                  ordertobesent.noexpense
                    ? colorscheme.v2.error
                    : colorscheme.v2.bordercolor,
                borderRadius: deviceconfigs.layout.xlargepadding,
                width: "82vw",
              }}
            >
              <IconedInput
                style={{
                  color: colorscheme.v2.backgroundtext,
                }}
                onFocus={() => {
                  // if (this.myScroll && this.amounty) {
                  //     setTimeout(() => {
                  //         this.myScroll.scrollTo({ y: this.amounty + deviceconfigs.layout.xxlargepadding });
                  //     }, 800);
                  // }
                }}
                value={ordertobesent.expense}
                onChangeText={(text) => {
                  ordertobesent.expense = text
                    .replace(/\s/g, "")
                    .replace(/,/g, ".");
                  if (
                    (ordertobesent.expense == undefined) |
                    (ordertobesent.expense == "")
                  ) {
                    ordertobesent.quantity = "0";
                    ordertobesent.noexpense = true;
                  } else {
                    ordertobesent.quantity = (
                      parseFloat(ordertobesent.expense) /
                      parseFloat(currentprice)
                    ).toFixed(signal.amountprecision);
                    ordertobesent.noexpense = false;
                    ordertobesent.noquantity = false;
                  }
                  if (signal.exchange == "BINANCE") {
                    ordertobesent.minNotional = checkMinNotional(
                      signal.info.filters,
                      ordertobesent.quantity
                    );
                  }
                  setOrderToBeSent({ ...ordertobesent });
                }}
                placeholder={`${language.amount}(${quotecoin})`}
                placeholderTextColor={colorscheme.v2.heavytext}
              />
            </div>
            <div style={styles.minquantitycontainer}>
              <div style={styles.minquantityleft}>{language.available} : </div>
              <div style={styles.minquantityright}>
                {myBalance + " " + quotecoin}
              </div>
            </div>
            <div style={styles.minquantitycontainer}>
              <div style={styles.minquantityleft}>
                {language.minimumamount} :{" "}
              </div>
              <div style={styles.minquantityright}>
                {minValue + " " + quotecoin}
              </div>
            </div>
          </div>
          <div style={styles.actionbuttonscontainer}>
            <ActionButton
              text={language.send}
              disabled={
                isExpenseInvalid ||
                isQuantityInvalid ||
                ordertobesent.noquantity ||
                ordertobesent.noexpense ||
                ordertobesent.maxPriceHit ||
                ordertobesent.minNotional ||
                ordertobesent.minPrice ||
                ordertobesent.percentprice ||
                ordertobesent.stoplossvalid ||
                isLessThanMinQuantity ||
                myBalance < parseFloat(ordertobesent.expense)
              }
              onClick={() => {
                if (
                  (ordertobesent.expense == undefined) |
                  (ordertobesent.expense == "")
                ) {
                  ordertobesent.noexpense = true;
                  if (
                    ordertobesent.quantity == undefined ||
                    ordertobesent.quantity == ""
                  ) {
                    ordertobesent.noquantity = true;
                    setOrderToBeSent(ordertobesent);
                    return;
                  }
                  setOrderToBeSent(ordertobesent);
                  return;
                }
                if (
                  ordertobesent.quantity == undefined ||
                  ordertobesent.quantity == ""
                ) {
                  ordertobesent.noquantity = true;
                  setOrderToBeSent(ordertobesent);
                  return;
                }
                setOrderToBeSent({ ...ordertobesent });
                setConfirmationVisible(true);
              }}
            />
          </div>
          <div style={{ height: "48vh" }}></div>
        </div>
      </div>
      <Modal
        open={confirmationvisible}
        onClose={() => setConfirmationVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            width: "94vw",
            height: "82vh",
            padding: deviceconfigs.layout.xxlargepadding,
            borderRadius: deviceconfigs.layout.xxlargepadding,
            margin: "auto",
          }}
        >
          <div style={{ flex: 1, backgroundColor: colorscheme.v2.primary }}>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: deviceconfigs.layout.xxlargepadding,
                  marginBottom: deviceconfigs.layout.xlargepadding,
                  padding: `${deviceconfigs.layout.xlargepadding}px 0px`,
                }}
              >
                <div style={styles.iconcontainer}>
                  <img
                    src={signal.icon}
                    style={styles.icon}
                    alt={basecoin + quotecoin}
                  />
                </div>
                <div
                  style={{
                    marginLeft: deviceconfigs.layout.xlargepadding,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: deviceconfigs.font.xlargetext,
                      fontWeight: "500",
                      color: colorscheme.v2.primarytext,
                    }}
                  >
                    {basecoin}
                  </div>
                  <div
                    style={{
                      fontSize: deviceconfigs.font.xlargetext,
                      fontWeight: "500",
                      color: colorscheme.v2.unselected,
                    }}
                  >
                    {"/" + quotecoin}
                  </div>
                </div>
              </div>
              <div style={styles.marketwatchcontainer}>
                <div style={styles.currentpriceandpercentcontainer}>
                  <div style={styles.currentpricecontainer}>
                    <div
                      style={{
                        ...styles.currentprice,
                        color: colorscheme.v2.primarytext,
                      }}
                    >
                      {"$ " + currentprice}
                    </div>
                  </div>
                  <div style={styles.percentcontainer}>
                    <div style={styles.percent}>{percent + "%"}</div>
                  </div>
                </div>
                <div style={styles.pointcontainer}>
                  <div style={styles.pointiconcontainer}>
                    <IoSwapVertical
                      style={{
                        fontSize: deviceconfigs.icon.mediumicon,
                        color: colorscheme.v2.unselected,
                      }}
                    />
                  </div>
                  <div style={styles.margin}>{margin}</div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: deviceconfigs.layout.xlargepadding,
                  padding: deviceconfigs.layout.xlargepadding,
                }}
              >
                <ModalSummaryItem
                  lefttext={language.exchange}
                  righttext={signal.exchange}
                />
                <ModalSummaryItem
                  lefttext={language.type}
                  righttext={language.automatic}
                />
                <ModalSummaryItem
                  lefttext={language.quantity}
                  righttext={ordertobesent.quantity + " " + signal.basecoin}
                />
                <ModalSummaryItem
                  lefttext={language.amount}
                  righttext={ordertobesent.expense + " " + signal.quotecoin}
                />
              </div>
            </div>
            <div style={{}}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: deviceconfigs.layout.xxlargepadding,
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                  (item) => {
                    return (
                      <div
                        key={item}
                        style={{
                          flex: 1,
                          backgroundColor: colorscheme.v2.primarytext,
                          height: deviceconfigs.layout.smallpadding,
                          marginHorizontal: deviceconfigs.layout.mediumpadding,
                        }}
                      ></div>
                    );
                  }
                )}
              </div>
            </div>
            <div style={{}}>
              <div
                style={{
                  marginLeft: deviceconfigs.layout.xlargepadding,
                  padding: deviceconfigs.layout.xlargepadding,
                }}
              >
                <ModalSummaryItem
                  lefttext={language.purchasingprice}
                  righttext={ordertobesent.purchasingprice}
                />
                <ModalSummaryItem
                  lefttext={language.stoploss}
                  righttext={ordertobesent.stoploss}
                />
                {(ordertobesent.targets || [])
                  .sort((a, b) => {
                    if (a.targetorder < b.targeorder) {
                      return -1;
                    } else if (a.targetorder > b.targetorder) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      <div key={index}>
                        <ModalSummaryItem
                          lefttext={language.target + "# " + (index + 1)}
                          righttext={item.value}
                          extradata={item.share + "%"}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div style={{}}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: deviceconfigs.layout.xxlargepadding,
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                  (item) => {
                    return (
                      <div
                        key={item}
                        style={{
                          flex: 1,
                          backgroundColor: colorscheme.v2.primarytext,
                          height: deviceconfigs.layout.smallpadding,
                          marginHorizontal: deviceconfigs.layout.mediumpadding,
                        }}
                      ></div>
                    );
                  }
                )}
              </div>
            </div>
            <div style={{}}>
              <div
                style={{
                  marginLeft: deviceconfigs.layout.xlargepadding,
                  padding: deviceconfigs.layout.xlargepadding,
                }}
              >
                <ModalSummaryItem
                  lefttext={language.targetedprofit}
                  righttext={calculatePossibleProfit() + "%"}
                />
                <ModalSummaryItem
                  lefttext={language.riskofinvestment}
                  righttext={calculatePossibleLoss() + "%"}
                />
              </div>
            </div>
            <div style={{ height: "4vh" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: `${deviceconfigs.layout.xxlargepadding}px 0px`,
            }}
          >
            <ActionButton
              text={language.cancel}
              buttonstyle={{
                marginHorizontal: deviceconfigs.layout.xxlargepaddding,
                width: "36vw",
                backgroundColor: colorscheme.v2.error,
              }}
              onClick={() => {
                setConfirmationVisible(false);
              }}
            />
            <ActionButton
              text={language.confirm}
              buttonstyle={{
                marginHorizontal: deviceconfigs.layout.xxlargepaddding,
                width: "36vw",
              }}
              onClick={() => {
                makeOrder();
              }}
            />
          </div>
        </div>
      </Modal>
      <AlertModal
        open={showpopup}
        title={popuptitle}
        body={popupbody}
        onBackdropClick={() => {
          setShowPopup(false);
          setPopupTitle("");
          setPopupBody("");
        }}
        buttons={[
          {
            text: "OK",
            onClick: () => {
              navigate("/signals");
            },
            cancel: false,
          },
          {
            text: "My Orders",
            onClick: () => {
              navigate("/orders");
            },
            cancel: false,
          },
        ]}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    binancetickeractions: bindActionCreators(binanceTickerActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    myexchanges: state.myexchanges,
    language: state.language,
    loading: state.loading,
    binancetickers: state.binancetickers,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignalAutomaticOperationScreen);
