import React from "react";

const R = {
  _name: "Deutsch",
  _language: "Sprache",
  _denom: "de",
  _type: "GERMAN",
  myperformers: "My Performers.",
  signinto: "Sich anmelden bei",
  forgotpassword: "Passwort vergessen?",
  rememberme: "Erinnere mich ",
  activate: "Aktivieren",
  faceid: "Face ID",
  fingerprint: "Fingerabdruck",
  signin: "Anmelden",
  completeregistration: "Registrierung abschließen",
  biometricnotfound: "Biometrischer Datensatz nicht gefunden",
  verifybiometric: "Bitte verifizieren Sie Ihre Identität mit Ihrem Passwort",
  ok: "OK",
  yes: "Ja",
  no: "Nein",
  donthaveanaccount: "Sie haben noch kein Konto?",
  register: "Registrieren",
  createanew: "Erstelle einen neuen",
  account: "Account",
  alreadyhaveanaccount: "Haben Sie bereits ein Konto?",
  continue: "Fortfahren",
  verystrong: "Sehr stark",
  strong: "Stark",
  medium: "Mittel",
  weak: "Schwach",
  atleast8digits: "Mindestens 8 Stellen",
  signals: "Signale",
  alerts: "Alarme",
  orders: "Meine Historie",
  watchlist: "Watchlist",
  addyouraccount: "Account hinzufügen",
  connectyourexchange: "Verbinden Sie Ihre Börse",
  connectyourbinance: "Verbinden Sie Ihre Binance",
  taptoseehow: "Tippen Sie, um zu sehen, wie",
  scanqrcode: "QR Code scannen",
  addyourkeys: "Fügen Sie Ihre Keys hinzu",
  selectintegrationmethod: "Integrationsmethode auswählen",
  selectmethod: "Methode auswählen",
  noaccount: "Kein Account",
  manualkeycopy: "API Schlüssel manuell kopieren und einfügen",
  apikey: "API Key",
  secretkey: "Secret Key",
  manualkeys: "Manual Keys",
  next: "Weiter",
  addyourkeyssummary: "Add Your Keys Zusammenfassung",
  createaccount: "Account erstellen",
  profit: "Profit",
  balanceinusdt: "Saldo (USDT)",
  balanceinbtc: "Saldo (BTC)",
  connectedexchanges: "Verbundene Börsen für Automatik - Binance",
  signals: "Signale",
  open: "Offen",
  accepted: "Akzeptiert",
  cancelled: "Storniert",
  signaldetails: "Signal-Details",
  manual: "Manuell",
  automatic: "Automatik",
  buyrange: "Kauf Bereich",
  buyrangefrom: "Von",
  buyrangeto: "Bis",
  initialprice: "Ursprünglicher Preis",
  stoploss: "Stop Loss",
  targets: "Ziele",
  target: "Ziel",
  addnewtarget: "Neues Ziel hinzufügen",
  orderdetails: "Order-Details",
  currentstatus: "Aktueller Status",
  currentresult: "Aktuelles Result",
  active: "Aktiv",
  waiting: "Wartend",
  closed: "Geschlossen",
  closedactive: "Geschlossen Aktive Bestellung",
  closedwaiting: "Geschlossener Warteauftrag",
  closedbystoploss: "Stop Loss",
  closedbysystem: "Geschlossen durch das System",
  completed: "Abgeschlossen",
  finished: "Beendet",
  exchange: "Börse",
  type: "Typ",
  quantity: "Menge",
  amount: "Höhe",
  purchasingprice: "Einkaufspreis",
  targetedprofit: "Angestrebter Gewinn",
  realizedprofit: "Realisierter Gewinn und Verlust",
  riskofinvestment: "Risiko des Investments",
  minimumquantity: "Mindest Menge",
  minimumvalue: "Mindest Betrag",
  minimumamount: "Minimum Amount",
  available: "Verfügbar",
  reached: "Erreicht",
  search: "Suche",
  cancel: "Abbrechen",
  confirm: "Bestätigen",
  marketwatch: "Markt Übersicht",
  name: "Name",
  price: "Preis",
  changeIn24h: "24%",
  changeIn7d: "7t%",
  marketcap: "Marktkapitalisierung",
  volume: "Voloumen",
  chart: "Chart",
  watchlist: "Watchlist",
  myfavoritecoins: "Meine lieblings Coins",
  marketprice: "Markt Preis",
  created: "Erstellt",
  hit: "Ziel treffen",
  add: "Hinzufügen",
  selectexchange: "Börse auswählen",
  selectexchangeandpairing: "Börse und Handelspaar auswählen",
  choose: "Wählen Sie",
  selectedexchange: "Ausgewählte Börse ",
  selectedcointoken: "Ausgewählter Coin/Token",
  targetvalue: "Zielwert",
  selectanexchange: "Wählen Sie eine Börse",
  selectcointoken: "Wählen sie ein Coin/Token Paar",
  settings: "Einstellungen",
  app: "App",
  abouttext: "Über",
  support: "Support",
  accountsettings: "Account Einstellungen",
  launchscreen: "Startbildschirm",
  switchto: (currentmode) => {
    return currentmode == "light"
      ? "Wechsle zum Nachtmodus"
      : "Wechsle zum Tagmodus";
  },
  language: "Sprache",
  termsandconditions: "Bedingungen und Konditionen",
  privacypolicy: "Datenschutzerklärung",
  newsletter: "Newsletter",
  sharemyperformersapp: "Teile die MyPerformers App",
  contact: "Kontakt",
  contactsupport: "Kontaktieren Sie den Support",
  sendfeedback: "Feedback senden",
  howtoguides: "Anleitungen",
  notificationsettings: "Benachrichtigungs Einstellungen",
  notifications: "Benachrichtigungen",
  securitysettings: "Sicherheits Einstellungen",
  version: "Version",
  logout: "Abmelden",
  news: "News",
  neworderfeedback: "Feedback zur neuen Orders",
  targethit: "Ziel-Treffer",
  targetthreepercent: "Ziel 3%",
  stoploss: "Stop Loss",
  stoplossthreepercent: "Stop Loss 3%",
  order: "Order",
  signal: "Signal",
  newsignal: "Neues Signal veröffentlicht",
  signalchange: "Signal hat sich verändert",
  signalexpiration: "Signal abgelaufen",
  newscreated: "News veröffentlicht",
  orderactivated: "Order Activated",
  ordercompleted: "Order Completed",
  resetsettings: "Einstellungen zurücksetzen",
  oneclickautomaticorder: "Automatische Order mit einem Klick",
  requirephoneverificationfororders: "SMS-Bestätigung für Order anfordern",
  assetslist: "Liste der Vermögenswerte",
  password: "Passwort",
  changepassword: "Passwort ändern",
  currentpassword: "Aktuelles Passwort",
  newpassword: "Neues Passwort",
  repeatnewpassword: "Neues Passwort wiederholen",
  security: "Sicherheit",
  myorders: "My Orders",
  closeorder: "Order schließen",
  noaccount: "Kein Account",
  changeavatar: "Avatar ändern",
  clicktochange: "Zum Ändern klicken",
  connectedat: "Verbunden bei",
  overview: "Übersicht ",
  markets: "Märkte",
  statistics: "Statistiken",
  lowhigh: "Niedrig/Hoch",
  volume24h: "Voloumen 24h",
  maxsupply: "Max Menge",
  alltimehigh: "Allzeithoch",
  alltimelow: "Allzeittief",
  tags: "Tags",
  topnews: "Top News",
  bitcoin: "Bitcoin",
  altcoins: "Altcoins",
  nft: "NFT",
  subscriptiontype: "Abonnement Typ",
  verifyyouremail: "Verifizieren Sie Ihre E-Mail",
  selectsubscriptiontype: "Wählen Sie Ihr Abonnement",
  arabic: "عربي",
  english: "English",
  french: "Français",
  german: "Deutsch",
  dutch: "Nederlands",
  turkish: "Türkçe",
  russian: "русский",
  spanish: "Español",
  italian: "Italiano",
  save: "Speichern",

  paymentsummary: "Zahlungsübersicht",
  mypayments: "Meine Zahlungen",
  upgradenow: "Upgrade",
  waitingforconfirmation: "Warte auf Bestätigung",
  created: "Anfrage erstellt",
  confirmed: "Bestätigt",
  confirmedpartially: "Teilweise bestätigt",
  rejected: "Abgelehnt",
  selectpaymentcoin: "Zahlungsart auswählen",
  paymentwarning:
    "Bitte achten Sie darauf, dass Sie das richtige Netzwerk für die Überweisung des Payment Coins auswählen",
  paymentwarning2:
    "Bitte stellen Sie sicher, dass Sie die Überweisungskosten bezahlen ansonsten erhalten wir nicht den kompletten Betrag und können die Version nicht freischalten.",
  requestedamount: "Angeforderter Betrag",
  copiedamounttoclipboard: "Betrag in Zwischenablage kopiert",
  copiedaddresstoclipboard: "Zahlungsadresse in Zwischenablage kopiert",
  paymentaddress: "Zahlungsadresse",
  paymentnetwork: "Zahlungsnetzwerk",
  addcoinpair: "Coin hinzufügen",
  submit: "Einreichen",
  targetsnotfull:
    "Sie haben nicht 100% der Zielanteile ausgewählt. Nicht ausgewählte Zielanteile werden durch den Automatic Stop-Loss reguliert.",
  free: "Frei",
  locked: "Gesperrt",
  myassets: "Mein Guthaben",
  totalinusdt: "Gesamt in USDT",
  totalfree: "Insgesamt Frei",
  totallocked: "Total Gesperrt",
  resetpassword: "Passwort zurücksetzen",
  verifylogin: "Anmeldung bestätigen",
  disclaimerp1: "Wir tun unser Bestes, um den Inhalt dieser App vorzubereiten.",
  disclaimerp2:
    "MyPerformers App kann jedoch keine Gewähr für die Formulierungen und Vorschläge der Inhalte sowie deren Richtigkeit übernehmen.",
  disclaimerp3:
    "Darüber hinaus haftet MyPerformers App im gesetzlich zulässigen Umfang nicht für Verluste und/oder Schäden aufgrund der Nutzung der Informationen in unserer App.",
  disclaimerp4:
    "Durch die Nutzung unserer App stimmen Sie hiermit unserem Haftungsausschluss zu und stimmen seinen Bedingungen zu.",
  acknowledge: "Anerkennen",
  markallasread: "Alles als gelesen markieren",
  markasread: "Als gelesen markieren",
  uptotop: "Up to Top",
  email: "E-Mail",
  appinitlineone:
    "Gewinnen im Kryptomarkt kann einfach und kontrollierbar sein.",
  appinitlinetwo: "Einfache und schnelle bedienung für Krypto-Liebhaber",
  appinitlinethree: "Vermögenswerte speichern ... Das dauert ein bisschen",
  appinitlinefour:
    "Starten Sie jetzt, um den Kryptomarkt effizient, effektiv und lukrativ zu nutzen.",
  disconnectwarningone: (exchange) => {
    return `Sie sind dabei, sich Ihr ${exchange} Verbinung zu trennen.`;
  },
  disconnectwarningtwo: "Daher werden alle offenen Orders sofort gestoppt.",
  disconnectwarningthree:
    "Die verbleibenden Vermögenswerte, die durch Akzeptieren von Marktinformationen gekauft wurden, werden sofort per Market Order verkauft.",
  disconnectwarningfour:
    "Ich bin mir dessen bewusst und stimme diesem Verfahren zu.",
  sureclose: "Sind Sie sicher das Sie die Order schließen wollen?",
  invoices: "Rechnungen",
  restarttheapp: "Neustart",
  selectpaymentmethod: "Zahlungsweise wählen",
  apikeyexpired:
    "API-Schlüssel abgelaufen. Versuchen Sie, diesen Austausch zu löschen, und stellen Sie die Verbindung erneut her.",
  activecurrencies: "Währungen",
  activeexchanges: "Börsen",
  activemarketpairs: "Marktpaare",
  btcdominance: "BTC Dom.",
  ethdominance: "ETH Dom.",
  totalmarketcap: "Market Cap.",
  totalmarketcap24h: "Market Cap. 24h",
  sellingprice: "Verkaufspreis",
  smartflow: "Automatik",
  smartflowheader:
    "Ist der Automatikmodus aktiviert, werden Marktinformationen automatisch angenommen und ausgeführt, solange Sie ausreichend USDT auf Binance haben. Welcher Wert pro Trade genommen wird ist von Ihnen anzugeben und kann jederzeit angepasst werden.",
  smartflowsexpl: "Dazu gibt es zwei Möglichkeiten",
  smartflowsubheader1: "1) Mit einem fixen USDT Betrag",
  smartflowsubheader2: "2) Mit einem fixen Prozentsatz",
  smartflowp1:
    "Geben Sie dazu auf der folgenden Seite den gewünschten Betrag an. Dieser Betrag wird dann für jede Marktinformation verwendet.",
  smartflowp2:
    "Wählen Sie dazu auf der folgenden Seite den gewünschten Prozentsatz aus.",
  smartflowlastp:
    "Dieser Prozentsatz wird dann vom vorhandenen USDT Guthaben auf Binance, für jede Marktinformation genommen. (Voraussetzung ist, dass der Mindestwert pro Trade (60 USDT) nicht unterschritten wird.)",
  smartflowreadandagreed:
    "Hiermit bestätige ich die Bedingungen gelesen und verstanden zu haben und stimme diesen zu.",
  enablesmartflow: "Automatik Modus aktivieren",
  amountofusdt: "USDT Betrag pro Trade",
  shareoffunds: "Anteil (%) der Mittel (USDT) pro Trade",
  enjoyfullmyperformersexperience: "Genieße das volle MyPerformers-Erlebnis.",
  install: "Installieren",
  donthaveanaccountyet: "Sie haben noch kein Konto?",
  gotomyexchanges: "Zu Meine Exchanges",
  gotodashboard: "Zur Startseite",
  retryapiandsecretkeys: "Wiederholen Sie die API und den geheimen Schlüssel",
  availableusdtfunds: "Verfügbare USDT für Automatik",
  automaticperformance: "Automatik Performance - Übersicht",
  automaticmode: "Automatik Modus",
  automaticmodepopupheader1:
    "Ist der Automatikmodus aktiviert, werden Marktinformationen automatisch angenommen und ausgeführt, solange Sie ausreichend USDT auf Binance haben. Welcher Wert pro Trade genommen wird ist von Ihnen anzugeben und kann jederzeit angepasst werden.",
  automaticmodepopupsubheader1: "Dazu gibt es zwei Möglichkeiten",
  automaticmodepopuptext1: "1.) Mit einem fixen USDT-Betrag",
  automaticmodepopuptext2: "2.) Mit einem fixen Prozentsatz",
  automaticmodepopupheader2: "Ertrag 10% pro Monat – Regelung",
  automaticmodepopuptext3:
    "Wir haben es uns zur Aufgabe gemacht, jedem die Möglichkeit zu geben am Kryptomarkt zu partizipieren, so einfach und transparent wie möglich. Daher haben wir uns dazu entschlossen, keine Nutzungsgebühr zu verrechnen, wenn wir weniger als 10% pro Monat erwirtschaften. Mit anderen Worten, wenn der Automatik Modus weniger als 10 % pro Monat erwirtschaftet ist im Folgemonat keine Gebühr für die Nutzung zu bezahlen. Als Berechnungsbasis dafür gelten die Werte, die Berechnung, wie hier in diesem Feld gezeigt. Die Entwicklung der letzten 7 Tage und eines Monats, sowie einen Trading Report finden sie unter dem Menüpunkt Diagramm.",
  automaticinlinedesc:
    "Hier müssen Sie festlegen, ob Sie die Marktinformationen mit einen fixen USDT Betrag pro Trade oder einem bestimmten Prozentsatz des USDT Guthabens auf Binance annehmen möchten. Es kann immer nur eine Auswahl getroffen werden. Die Werte können jederzeit angepasst werden.",
  minimumamount60USDT: "Mindestbetrag 60,00 USDT",
  minimumshare: (share) => `Min. anteil ${share}%`,
  maximumshare: (share) => `Max. anteil ${share}%`,
  balanceofyourexchanges: "Saldo Ihrer Börsen",
  information: "Information",
  charts: "Diagramme",
  h24: "24Std.",
  d7: "7T",
  d30: "30T",
  y1: "1 Jahr",
  automaticlast7days: "Automatik- Letzte 7 Tage",
  automaticlast30days: "Automatik - Letzte 30 Tage",
  startcapital: "(Startkapital 100 USDT)",

  addingaccountsuccessful:
    "Konto konnte nicht hinzugefügt werden. Grund: Ungültiger API-Sicherheitsschlüssel",
  youhavealreadyaccountintegrated: (text) =>
    `You have already ${text} account integrated`,
  checkyouremail:
    "Überprüfen Sie Ihre E-Mail und melden Sie sich dem neuem Passwort an",
  resetpasswordsuccess: "Erfolgreich zurückgesetzt",
  attention: "Achtung",
  basic: "Basic",
  premium: "Premium",
  upgradetopremium: "Upgrade zu Premium",
  viewdetails: "Detailansicht",
  automaticwarning: "Automatic Mode Warning",
  noexchangeconnected: "You must have Binance Exchange connected in order to use Automatic functionality.",
  membershipexpired: "In order to use Automatic Mode, you have to become a Premium User.",
  gopremium: "Go Premium",
  howtojointelegram: "Signal Kanal",
  start: "Start",

  readandagree: (deviceconfigs, colorscheme) => {
    return (
      <span
        style={{
          fontSize: deviceconfigs.font.mediumtext,
          fontWeight: "700",
          color: colorscheme.v2.backgroundtext,
        }}
      >
        Ich habe die{" "}
        <span
          style={{
            fontWeight: "700",
            fontSize: deviceconfigs.font.mediumtext,
            color: colorscheme.v2.primary,
          }}
        >
          Allgemeinen Geschäftsbedingungen
        </span>
        sorgfältig gelesen und stimme ihnen zu.
      </span>
    );
  },
  noaccountfirstpar: (exchange) => {
    return `Wir sind derzeit dabei, die ${exchange} via API zu verbinden.Dazu benötigen Sie ein aktives und verifiziertes Konto auf ${exchange}.`;
  },
  noaccountsecondpar: (exchange) => {
    return `Wenn Sie bereits ein ${exchange} Account haben, wählen Sie eine der beiden anderen Optionen.`;
  },
  noaccountthirdpar: (exchange) => {
    return `Wenn Sie noch keinen ${exchange} Account haben, können Sie jetzt ganz einfach einen erstellen, indem Sie auf die Schaltfläche unten klicken.`;
  },
  createanaccount: "Account erstellen",
  buyxinexchangefory: (x, y) => {
    return `Kaufen ${x} auf der Börse für ${y}`;
  },
  membership: (type) => {
    switch (type) {
      case "TRIAL":
        return "Basic";
      case "PREMIUM":
        return "Premium";
      case "PROMOTION30DAYS":
        return "30-Tage Premium"
      default:
        return "N/A";
    }
  },
  membershiptext: "Membership",
  expiresat: "Läuft aus am: ",
  expires: "Läuft aus am: ",
  paycurrency: "Währung",
  totalamountinusd: "Gesamtbetrag in USD",
  clicktoupgrade: "Klicken Sie für ein Upgrade",
  send: "Senden",
  step: (x) => {
    return "Schritt " + x;
  },

  youareabouttoleave: "Möchten Sie MyPerformers wirklich verlassen?",
  on: "On",
  off: "Off",

  about: {
    line1: "Willkommen bei MPA!",
    line2: "MPA ist ein Anwendungstool mit Fokus auf den Kryptomarkt und bietet Ihnen folgende Möglichkeiten.",
    line3: "-aktuell fundierte Informationen",
    line4: "-ein Marktüberblick, um immer auf dem neuesten Stand zu sein",
    line5: "Überblick über Ihr Vermögen, einfach und übersichtlich",
    line6: "Vermögensverwaltung auf höchstem Niveau",
    line7: "Viele Bereiche sind kostenlos nutzbar. Die Portfolioverwaltung ist ein kostenpflichtiger Bereich, sie wird unter der Funktion Automatik angeboten. Sie können die Automatik 30 Tage kostenlos nutzen, wenn Sie es innerhalb von 7 Tagen nach dem Herunterladen der App aktivieren.",
    line8: "3 einfache Schritte sind wichtig, um mit der Automatik zu starten und am Kryptomarkt teilzunehmen :",
    line9: "(!) Registrieren Sie sich bei Binance und erstellen Sie eine API",
    line10: "(!) Verbinden Sie die APP über die API-Schnittstelle mit Binance",
    line11: "(!) Aktivieren Sie den automatischen Modus",
    line12: "Was bietet der Automatikmodus?",
    line13: "Der Automatikmodus ist die automatische Ausführung von Marktinformationen direkt auf Ihrem Börsenkonto (derzeit über Binance). Solange Sie USDT auf Ihrem Börsenkonto verfügbar haben und der Automatikmodus aktiv ist, werden Trades vollautomatisch ausgeführt. Der für einen Handel verwendete Betrag wird von Ihnen bestimmt. Der Mindesteinsatz pro Trade beträgt 60,00 USDT",
  },

  videotutorial: "Video Tutorial",
  automaticmode: "Der Automatikmodus",
  automaticmodedesc: {
    p1: "Automatischer Modus ist die automatische Ausführung von Marktinformationen direkt auf Ihrem Börsenkonto. Solange Sie USDT auf Ihrem Börsenkonto verfügbar haben und der Automatikmodus aktiv ist, werden Trades voll automatisiert umgesetzt. Es besteht immer die Möglichkeit, dass sie selbst in einen laufenden Trade eingreifen. Der für einen Trade verwendete Betrag wird von Ihnen bestimmt. Der Minimum Betrag pro Trade beträgt aktuell 60,00 USDT.",
    p2: "10 % pro Monat Leistungsregel*",
    p3: "Wir haben es uns zur Aufgabe gemacht, jedem die Möglichkeit zu geben, möglichst einfach und transparent am Kryptomarkt teilzunehmen, mit dieser Funktion.Als Zeichen wie ernst wir unsere uns selbst auferlegte Aufgabe nehmen, haben wir uns dazu entschieden, keine Gebühren für die Nutzung der App zu verrechnen, wenn wir weniger als 10 % in 30 Tagen Nutzung erwirtschaften.",
    p4: "Was bedeutet das für Sie, wie funktioniert das?",
    p5: "Wenn sie die APP laden, innerhalb von 7 Tagen Binance via API verknüpfen und den Automatikmodus aktivieren können sie Die APP, mit dieser Funktion, 30 Tage kostenlos nutzen.",
    p6: "Lassen sie den Automatik Modus 30 Tage durchgehend laufen, ohne in einen Trade händisch einzugreifen, und es werden nicht 10% erwirtschaftet haben sie weitere 30 Tage die Nutzung gratis.",
    p7: "Haben Sie für 30 Tage bezahlt und in diesem Zeitraum werden keinen 10% erwirtschaftet, vorausgesetzt Sie lassen den Automatik Modus 30 Tage durchgehend laufen, ohne in einen Trade händisch einzugreifen, haben sie die nächsten 30 Tage die Nutzung Gratis.",
    p8: "Werden die 10%, unter denselben Voraussetzungen wie zuvor erwirtschaftet ist für die Nutzung der APP die Nutzungsgebühr zu bezahlen.",
    p9: "",
    p10: "* gilt bis auf Wiederruf",
  },

  firstregistration: {
    line1: "Jetzt Automatikmodus 30 Tage testen - KOSTENLOS!",
    line2: "Wenn Sie Ihre Börse (derzeit Binance) innerhalb der nächsten 7 Tage anbinden und den Automatikmodus aktivieren, können Sie den Automatikmodus 30 Tage kostenlos testen. Wenn Sie den automatischen Modus nach 7 Tagen nicht aktiviert haben, ist dieses Angebot nicht mehr gültig.",
    line3: "Eine detaillierte Beschreibung, wie Sie Ihr Börsenkonto verbinden und den automatischen Modus starten, finden Sie in der APP unter dem weißen ? oben rechts.",
    line4: "Nutzen Sie diese einmalige Gelegenheit",
    line5: "Ihr MPA-TEAM",
  },
  binanceapikeypassive: "Der Binance-API-Schlüssel ist abgelaufen, Spot & Margin sind deaktiviert. Dieser Punkt ist unbedingt erforderlich, um die Automatik zu nutzen. Wenn Sie den automatischen Modus verwenden möchten, Gehen Sie zu Ihrem Binance-Konto und aktivieren Sie Ihre API-Optionen erneut oder erstellen Sie eine neue",

  disablingautomaticwarning: {
    title: "Automatische Warnung deaktivieren",
    body: {
      line1: "Lieber MPA-Nutzer!",
      line2: "Wie Sie wahrscheinlich wissen, gilt derzeit die 10%-Regelung. Voraussetzung ist, dass der Automatikmodus 30 Tage lang eingeschaltet ist und nicht manuell in einen Trade eingegriffen wird. Durch das Abschalten des Automatikmodus verliert die 10%-Regelung in diesem Zeitraum ihre Gültigkeit."
    }
  },
  proceed: "Fortfahren",

  success: {
    addexchangesuccess: {
      header: "Erfolgreich",
      body: "Exchange erfolgreich hinzufügen",
    },
    scansuccess: {
      header: "Scann war erfolgreich",
      body: "QR Scan erfolgreich",
    },
    order: {
      header: "Order erfolgreich",
      body: "Order erfolgreich erhalten.",
    },
    passwordchange: {
      header: "Erfolgreich",
      body: "Passwort wurde erfolgreich geändert.",
    },
    verificationsuccess: {
      header: "Erfolgreich",
      body: "E-Mail Verifizierung war erfolgreich",
    },
    paymentrequestreceived: {
      header: "Zahlungsaufforderung erhalten",
      body: "Ihre Zahlungsanfrage ist eingegangen. Überprüfen Sie den Fortschritt in etwa 5 Minuten.",
    },
    purchasesuccess: {
      header: "Abonnement erfolgreich",
      body: "Sie können jetzt alle Funktionen von MyPerformers nutzen.",
    },
    displaynamechangedsuccess: {
      header: "Success",
      body: "Display Name changed Successfully.",
    },
  },
  warning: {
    newversionavailable: {
      header: "New Version Available",
      body: "Please restart the app to get the latest version.",
    },
    alreadysubscribed: {
      header: "Subscription Warning",
      body: "Already Subscribed",
    },
    nobinanceforautomatic: {
      header: "Account-Fehler",
      body: "Bitte integrieren Sie Ihr Binance-Konto für den Automatik Modus.",
    },
  },
  error: {
    generic: {
      header: "Fehler",
      body: "Etwas lief schief.",
    },
    loginnotsuccessful: {
      header: "Anmelde-Fehler",
      body: "Zugangsdaten nicht korrekt.",
    },
    permission: {
      header: "Berechtigungsfehler",
      body: "Vergewissern Sie sich, dass Sie der Kamera die Erlaubnis geben.",
    },
    noexchangeaccount: {
      header: "Fehler",
      body: "Sie haben keine Börsenkonten.",
    },
    exchangeaccountspecifiednotconnected: (exchange) => {
      return {
        header: "Fehler",
        body: `Sie haben keine mit ${exchange} verbundenen Börsenkonten.`,
      };
    },
    passwordchange: {
      header: "Fehler",
      body: "Bei der Passwortänderung ist ein Fehler aufgetreten.",
    },
    faceidnotverified: {
      header: "Fehler",
      body: "FaceID/Fingerabdruck nicht verifiziert",
    },
    verificationerror: {
      header: "Fehler",
      body: "Anmelde Verifizierung fehlgeschlagen",
    },
    faceid: {
      header: "Error",
      body: "Biometrics Not Supported",
    },
    biometricsnotenrolled: {
      header: "Biometrics Error",
      body: "Biometrics Not Enrolled.",
    },
    nobiometriclevel: {
      header: "Biometrics Error",
      body: "Level of Enrollment is not high.",
    },
    biometricsnotcompatible: {
      header: "Biometrics Error",
      body: "Biometrics Not Compatible.",
    },
    signuperror: {
      header: "Sign Up Error",
      body: "Username/Email already exists.",
    },
    expirederror: {
      header: "Membership Expired",
      body: "Please upgrade your membership for unlimited access.",
    },
    insufficientbalance: {
      header: "Insufficient Balance",
      body: "You don't have enough amount in your balance.",
    },
    purchasefailed: {
      header: "Subscription Failed",
      body: "Unknown error occurred during subscription",
    },
  },
};

export default R;
