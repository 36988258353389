import PropTypes from "prop-types";
import React, { useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import AddExchangeStepCounter from "../components/AddExchangeStepCounter";
import IconicBadge from "../components/IconicBadge";
import ActionButton from "../components/ActionButton";

import * as apiCalls from "../redux/actions/apiCalls";
import * as myExchangesActions from "../redux/actions/myExchangesActions";

import { useLocation, useNavigate } from "react-router-dom";

import { HiChevronLeft, HiOutlineQuestionMarkCircle } from "react-icons/hi";
import AlertModal from "../components/AlertModal";
import LoadingModal from "../components/LoadingModal";

const AddExchangeAccountSummaryScreen = ({
  deviceconfigs,
  auth,
  colorscheme,
  language,
  api,
  myexchangesactions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const selectedexchange = state.selectedexchange;
  const apikey = state.apikey;
  const secretkey = state.secretkey;
  const [loading, setLoading] = useState(false);
  const [showpopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("");
  const [popupbody, setPopupBody] = useState("");
  const [popupbuttons, setPopupButtons] = useState([]);

  const SUCCESS_BUTTONS = [
    {
      text: language.gotomyexchanges,
      onClick: () => {
        navigate("/my-exchanges");
      },
      cancel: false,
    },
    {
      text: language.gotodashboard,
      onClick: () => {
        navigate("/dashboard");
      },
      cancel: false,
    },
  ];

  const ERROR_BUTTONS = [
    {
      text: language.gotodashboard,
      onClick: () => {
        navigate("/dashboard");
      },
      cancel: false,
    },
    {
      text: language.retryapiandsecretkeys,
      onClick: () => {
        setShowPopup(false);
      },
      cancel: false,
    },
  ];

  const _createExchangeAccount = async () => {
    setLoading(true);
    api
      .createExchangeAccount({ apikey, secretkey, type: selectedexchange.type })
      .then((res) => {
        if (res?.data.code === 400) {
          // INVALID API KEY
          setPopupTitle(language.error.addexchangefail.header);
          setPopupBody(language.error.addexchangefail.body);
          setPopupButtons(ERROR_BUTTONS);
          setShowPopup(true);
          setLoading(false);
          return;
        }
        setTimeout(async () => {
          await myexchangesactions.checkExchanges();
          setLoading(false);
          setPopupTitle(language.success.addexchangesuccess.header);
          setPopupBody(language.success.addexchangesuccess.body);
          setPopupButtons(SUCCESS_BUTTONS);
          setShowPopup(true);
          console.log({ auth });
          if (!auth.user?.progress?.firstexchangeconnectedts) {
            await api.updateFirstExchangeConnected();
          }

        });
      })
      .catch(async (err) => {
        if (err.response.data.code === 400) {
          // INVALID API KEY
          setPopupTitle(language.error.addexchangefail.header);
          setPopupBody(language.error.addexchangefail.body);
          setPopupButtons(ERROR_BUTTONS);
          setShowPopup(true);
        }
        setLoading(false);
      });
  };

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    topsummarycardcontainer: {
      backgroundColor: colorscheme.v2.primary,
      borderBottomLeftRadius: "12vw",
      borderBottomRightRadius: "12vw",
      padding: `0px ${deviceconfigs.layout.xlargepadding}px 32vw ${deviceconfigs.layout.xlargepadding}px`,
    },
    titleandquestioncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titlecontainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    backbuttoncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.primaryalternative,
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    titletextcontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    titlename: {
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
    },
    questioncontainer: {
      marginRight: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.primaryalternative,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: deviceconfigs.layout.xlargepadding,
    },
    stepscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      marginTop: deviceconfigs.layout.xxxxlargepadding,
    },
    stepstextcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginVertical: deviceconfigs.layout.xlargepadding,
    },
    stepstext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "600",
      color: colorscheme.v2.lighttext,
    },
    majorstepoff: {
      width: deviceconfigs.layout.xlargepadding,
      height: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.unselected,
      borderRadius: 999,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    majorstepon: {
      width: deviceconfigs.layout.xxlargepadding,
      height: deviceconfigs.layout.xxlargepadding,
      backgroundColor: colorscheme.v2.selected,
      borderRadius: 999,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    minorstep: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      width: deviceconfigs.layout.largepadding,
      height: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.unselected,
      borderRadius: 999,
    },
    selectorcontainer: {
      borderRadius: deviceconfigs.layout.xxxxlargepadding,
      width: "84vw",
      height: "62vh",
      position: "absolute",
      top: "28vh",
      left: "8vw",
      backgroundColor: colorscheme.v2.background,
    },
    selectexchangetextcontainer: {
      padding: deviceconfigs.layout.xxxxlargepadding,
      justifyContent: "center",
      alignItems: "center",
    },
    selectexchangetext: {
      fontSize: deviceconfigs.font.xxxlargetext,
      fontWeight: "500",
      color: colorscheme.v2.heavytext,
    },
    selectedexchangeiconcontainer: {
      display: "flex",
      justifyContent: "center",
    },
    titleandinputscontainer: {},
    titleandinputcontainer: {},
    inputtitlecontainer: {
      marginVertical: deviceconfigs.layout.xxlargepadding,
      marginHorizontal: deviceconfigs.layout.xxlargepadding,
    },
    inputcontainer: {
      display: "flex",
    },
    inputtitle: {
      marginLeft: deviceconfigs.layout.xxlargepadding,
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "500",
      color: colorscheme.v2.heavytext,
    },
    questionandbuttoncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      alignSelf: "center",
      marginHorizontal: deviceconfigs.layout.xxlargepadding,
      marginVertical: deviceconfigs.layout.xlargepadding,
      position: "absolute",
      bottom: deviceconfigs.layout.xxxxlargepadding,
      left: "8vw",
      width: "100vw",
      justifyContent: "center",
      zIndex: 1000,
    },
    questioncontainer: {
      backgroundColor: colorscheme.v2.primary,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: deviceconfigs.layout.xlargepadding,
    },
    buttoncontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    text: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "400",
      color: colorscheme.v2.unselected,
      width: "72vw",
      height: "12vh",
      alignSelf: "center",
      marginLeft: deviceconfigs.layout.xxlargepadding,
      wordBreak: "break-all"
    },
  };
  return (
    <PageView style={styles.mainframe}>
      <div style={{ flex: 1 }}>
        <div style={styles.topsummarycardcontainer}>
          <div style={styles.titleandquestioncontainer}>
            <div style={styles.titlecontainer}>
              <div
                style={styles.backbuttoncontainer}
                onClick={() => {
                  window.history.back();
                }}
              >
                <HiChevronLeft
                  style={{
                    fontSize: "18px",
                    color: colorscheme.v2.primarytext,
                  }}
                />
              </div>
              <div style={styles.titletextcontainer}>
                <div style={styles.titlename}>{language.addyourkeys}</div>
              </div>
            </div>
          </div>
          <div style={{}}>
            <AddExchangeStepCounter step={3} />
          </div>
        </div>
        <div style={styles.selectorcontainer}>
          <div style={styles.selectexchangetextcontainer}>
            <div style={styles.selectexchangetext}>{language.manualkeys}</div>
          </div>
          <div style={styles.selectedexchangeiconcontainer}>
            <IconicBadge {...selectedexchange} />
          </div>
          <div style={styles.titleandinputscontainer}>
            <div style={styles.titleandinputcontainer}>
              <div style={styles.inputtitlecontainer}>
                <div style={styles.inputtitle}>{language.apikey}</div>
              </div>
              <div style={styles.inputcontainer}>
                <div style={styles.text}>{apikey}</div>
              </div>
            </div>
            <div style={styles.titleandinputcontainer}>
              <div style={styles.inputtitlecontainer}>
                <div style={styles.inputtitle}>{language.secretkey}</div>
              </div>
              <div style={styles.inputcontainer}>
                <div style={styles.text}>{secretkey}</div>
              </div>
            </div>
          </div>
          <div style={{ height: "42vh" }}></div>
        </div>
      </div>
      <div style={styles.questionandbuttoncontainer}>
        <div style={styles.buttoncontainer}>
          <ActionButton
            text={language.connectyourbinance}
            onClick={() => {
              _createExchangeAccount();
            }}
            buttonstyle={{
              width: "64vw",
            }}
          />
        </div>
      </div>
      <AlertModal
        open={showpopup}
        title={popuptitle}
        body={popupbody}
        onBackdropClick={() => {
          setShowPopup(false);
          setPopupTitle("");
          setPopupBody("");
        }}
        buttons={popupbuttons}
      />
      <LoadingModal loading={loading} />
    </PageView>
  );
};

AddExchangeAccountSummaryScreen.propTypes = {
  api: PropTypes.shape({
    login: PropTypes.func,
  }),
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    myexchangesactions: bindActionCreators(myExchangesActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    loading: state.loading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExchangeAccountSummaryScreen);
