import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const HomePageActionButton = ({
  key,
  deviceconfigs,
  language,
  colorscheme,
  buttoncontainer,
  badge,
  icon,
  text,
  onClick,
}) => {
  const styles = {
    maincontainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
    },
    buttoncontainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colorscheme.v2.cardbackground,
      width: "84px",
      height: "84px",
      borderRadius: deviceconfigs.layout.xxlargepadding,
    },
    textcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    text: {
      color: colorscheme.v2.backgroundtext,
      fontSize: deviceconfigs.font.mediumtext,
      textAlign: "center",
      whiteSpace: "nowrap"
    },
  };
  return (
    <div key={key} style={styles.maincontainer}>
      <div
        style={{
          ...styles.buttoncontainer,
          ...buttoncontainer,
        }}
        onClick={onClick}
      >
        {icon}
        <div
          style={{
            position: "relative",
          }}
        >
          {badge}
        </div>
      </div>
      <div style={styles.textcontainer}>
        <div style={styles.text}>{text}</div>
      </div>
    </div>
  );
};

HomePageActionButton.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageActionButton);