import { SHOW_ERROR_PROGRESS } from '../../constants/ReduxStates';


let showErrors = {
    dashboard: {
        apiexpired: true,
    }
}

const updateObjProp = (obj, value, propPath) => {
    const [head, ...rest] = propPath.split('.');

    !rest.length
        ? obj[head] = value
        : updateObjProp(obj[head], value, rest.join('.'));
}

export function showErrorProgressSuccess(payload) {
    return {
        type: SHOW_ERROR_PROGRESS.SHOW_ERROR_PROGRESS_SUCCESS,
        payload,
    };
}

export function loadShowErrorsProgress() {
    return function (dispatch) {
        dispatch(showErrorProgressSuccess(showErrors));
    };
}


export function updateShowErrorProgress(value, path) {
    return function (dispatch) {
        updateObjProp(showErrors, value, path);
        dispatch(showErrorProgressSuccess(showErrors));
    };
}
