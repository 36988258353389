import { BINANCETICKERS } from "../../constants/ReduxStates";

let ws = undefined;
let list = {};

export function binanceWSUpdatedSuccess(payload) {
  return {
    type: BINANCETICKERS.BINANCETICKERS_SUCCESS,
    payload,
  };
}

export function binanceWSUpdatedFail() {
  return {
    type: BINANCETICKERS.BINANCETICKERS_FAIL,
  };
}

export function closeBinanceWebSocket() {
  return function (dispatch) {
    try {
      ws.close();
    } catch (e) {
      console.log(e);
    }
  };
}

export function initiateBinanceWebSocket() {
  return function (dispatch) {
    try {
      if (ws !== undefined && ws.status === WebSocket.OPEN) {
        return;
      }
      ws = new WebSocket(`wss://stream.binance.com:9443/ws/btcusdt@ticker`);
      ws.onmessage = (ev) => {
        const data = JSON.parse(ev.data);

        list[data["s"]] = {
          currentprice: parseFloat(data["c"]),
          percent: parseFloat(data["P"]),
          margin: parseFloat(data["p"]),
        };
        //console.log("Received WS Message", list);
        dispatch(binanceWSUpdatedSuccess(list));
      };

      ws.onopen = (e) => {
        console.log("Binance WS Connection Success");
        list["BTCUSDT"] = {
          currentprice: "-",
          percent: "-",
          margin: "-",
        };
        subscribeToSymbol("ETHUSDT")(dispatch);
        subscribeToSymbol("BNBUSDT")(dispatch);
        subscribeToSymbol("SOLUSDT")(dispatch);
        subscribeToSymbol("ADAUSDT")(dispatch);
        dispatch(binanceWSUpdatedSuccess(list));
      };

      ws.onclose = (e) => {
        dispatch(binanceWSUpdatedFail());
        ws = new WebSocket(`wss://stream.binance.com:9443/ws/btcusdt@ticker`);
      };
    } catch (e) {
      dispatch(binanceWSUpdatedFail());
    }
  };
}

export function subscribeToSymbol(symbol) {
  return function (dispatch) {
    if (symbol === undefined) {
      return;
    }
    try {
      const item = symbol.toLowerCase() + "@ticker";
      const param = [item];
      const subscription = {
        method: "SUBSCRIBE",
        params: param,
        id: 1,
      };
      ws.send(JSON.stringify(subscription));
    } catch (e) {
      console.error(e);
    }
  };
}

export function unsubscribeToSymbol(symbol) {
  return function (dispatch) {
    if (symbol === undefined) {
      return;
    }
    try {
      const item = symbol.toLowerCase() + "@ticker";
      const param = [item];
      const subscription = {
        method: "UNSUBSCRIBE",
        params: param,
        id: 1,
      };
      ws.send(JSON.stringify(subscription));
    } catch (e) {
      console.error(e);
    }
  };
}
