import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";

import { Switch } from "antd";

import * as apiCalls from "../redux/actions/apiCalls";
import { useEffect, useState } from "react";
import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router-dom";

const SmartFlowDescriptionScreen = ({
  api,
  deviceconfigs,
  language,
  colorscheme,
}) => {
  const [read, setRead] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchSmartFlowSettings = async () => {
    return api.getMySmartFlows();
  };

  useEffect(() => { }, []);

  const styles = {
    headercontainer: {
      backgroundColor: colorscheme.v2.background,
    },
    textscontainer: {
      paddingTop: "72px",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    smartflowheader: {
      margin: `${deviceconfigs.layout.mediumpadding}px ${deviceconfigs.layout.largepadding}px`,
      fontSize: "19px",
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
      padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
      backgroundColor: colorscheme.v2.cardbackground,
      borderRadius: "12px",
    },
    smartflowsubheader: {
      margin: `${deviceconfigs.layout.mediumpadding}px ${deviceconfigs.layout.xlargepadding}px`,
      fontSize: "17px",
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
      padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
      backgroundColor: colorscheme.v2.cardbackground,
      borderRadius: "12px",
    },
    smartflowexpl: {
      margin: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.largepadding}px`,
      fontSize: "19px",
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
      padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
      backgroundColor: colorscheme.v2.cardbackground,
      borderRadius: "12px",
    },
    smartflowparagraph: {
      margin: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
      fontSize: "14px",
      fontWeight: "400",
      color: colorscheme.v2.backgroundtext,
    },
    optioncheckboxitemcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
      display: "flex",
      flexDirection: "row",
    },
  };
  return (
    <div style={{
      paddingBottom: "32px",
    }}>
      <div
        style={{
          position: "fixed",
          zIndex: 100,
          width: "100vw",
          top: -56,
          left: 0,
          right: 0,
          backgroundColor: colorscheme.v2.background,
          paddingTop: "56px",
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.smartflow}
            onBackPressed={() => {
              window.history.back();
            }}
            headerstyle={{
              fontSize: deviceconfigs.font.xxlargetext,
              fontWeight: "600",
              color: colorscheme.v2.backgroundtext,
              padding: `${12}px 0px`,
            }}
          />
        </div>
      </div>

      <div style={styles.textscontainer}>
        <div>
          <div>
            <span style={{
              fontSize: 15,
              fontWeight: "700",
            }}>The Automatic mode </span> is the automatic execution of market information, directly on your exchange account.As long as you have USDT available on your exchange account and the automatic mode is active, trades are executed fully automatically. The amount used for a trade is determined by you. The minimum bet per trade is 60.00 USDT.
          </div>
          <div style={{
            fontSize: 15,
            fontWeight: "700",
            marginTop: "18px",
          }}>There are two ways to set the amount : </div>
          <div>
            1) With a fixed USDT amount
          </div>
          <div>
            2) With a percentage
          </div>
          <div>
            To do this, select the desired percentage under Share. This proportion of the existing balance is then always used for each trade, as long as the balance is sufficient.
          </div>
          <div style={{
            marginTop: "18px",
          }}>
            <span style={{
              fontSize: 15,
              fontWeight: "700",
            }}>MPA is an application tool.</span> Market information and the automatic implementation of this by activating the automatic mode are offered. There is no guarantee that every trade will be completed positively. Each user acts on their own responsibility and confirms by activating the automatic mode that they are aware of the risk and that MPA is not responsible for any losses and expressly walves any legal claim should losess arise.
          </div>
        </div>
        <div style={styles.optioncheckboxitemcontainer}>
          <Switch
            checked={read}
            onChange={(read) => {
              setRead(read);
            }}
          />
          <div
            style={{
              marginLeft: "12px",
              fontSize: "13px",
              fontWeight: "500",
              color: colorscheme.v2.backgroundtext,
            }}
          >
            {language.smartflowreadandagreed}
          </div>
        </div>
        <div style={{}}>
          <ActionButton
            text={language.next}
            disabled={!read}
            onClick={() => {
              navigate("/settings/smart-flows");
            }}
            buttonstyle={{
              width: "64vw",
              margin: "auto",
              marginTop: "24px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    balancedisplay: state.balancedisplay,
    myexchanges: state.myexchanges,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    unreads: state.unreads,
    cmcidmap: state.cmcidmap,
    installation: state.installation,
    globalmetrics: state.globalmetrics,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartFlowDescriptionScreen);
