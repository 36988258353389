import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";

import SignalInfoItem from "../components/SignalInfoItem";
import PageHeader from "../components/PageHeader";

import { abbreviateNumber } from "../utils/stringutils";

import * as apiCalls from "../redux/actions/apiCalls";
import * as binanceTickerActions from "../redux/actions/binanceTIckerWebSocketActions";

import { IoSwapVertical } from "react-icons/io5";

import TradingViewWidget from "react-tradingview-widget";

const MarketWatchDetailsScreen = ({
  language,
  colorscheme,
  deviceconfigs,
  binancetickers,
  binancetickeractions,
}) => {
  const location = useLocation();
  const [sPeriod, setSPeriod] = useState("24h");

  const [index, setIndex] = useState(0);

  const myState = location.state;
  const selectedcoin = myState?.selectedcoin;
  const performance = myState?.performance;

  const symbolData =
    binancetickers &&
    binancetickers[selectedcoin.symbol.toUpperCase() + "USDT"];
  const currentprice = symbolData?.currentprice;
  const percent = symbolData?.percent;
  const margin = symbolData?.margin;

  useEffect(() => {
    binancetickeractions.subscribeToSymbol(
      selectedcoin.symbol.toLowerCase() + "usdt"
    );
    return () => {
      binancetickeractions.unsubscribeToSymbol(
        selectedcoin.symbol.toLowerCase() + "usdt"
      );
    };
  }, []);

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {},
    contentcontainer: {
      flex: 1,
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      paddingTop: "172px",
    },
    scrollcontainer: {
      flex: 1,
    },
    uppermenucontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    coinsummarycontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    chartcontainer: {
      backgroundColor: colorscheme.v2.background,
    },
    statscontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    statsheadercontainer: {},
    statsoptionscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    timeperiodselectorcontainer: {
      backgroundColor: colorscheme.v2.primaryinactive,
      padding: deviceconfigs.layout.xlargepadding,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    timeperiodselectorselected: {
      padding: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.background,
      borderRadius: deviceconfigs.layout.xlargepadding,
      alignItems: "center",
    },
    timeperiodselectorunselected: {
      padding: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.primaryinactive,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    periodtextselected: {
      width: "8vw",
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
      textAlign: "center",
    },
    periodtextunselected: {
      width: "8vw",
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
      textAlign: "center",
    },
    statsdetailscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    aboutcontainer: {},
    tagscontainer: {},
    tagitemscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    relatednewscontainer: {},
    statsdetailsleftcontainer: {
      flex: 1,
    },
    statsdetailsrightcontainer: {
      flex: 1,
    },
    statstext: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    pricebarcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    pricebar: {
      backgroundColor: colorscheme.v2.primaryinactive,
      borderRadius: deviceconfigs.layout.xlargepadding,
      height: deviceconfigs.layout.largepadding,
    },
    pricevalues: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    itembutton: {
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      backgroundColor: colorscheme.v2.primary,
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    itemtext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      color: colorscheme.v2.primarytext,
    },
    pricetext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    currentpriceandpercentcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    currentpricecontainer: {
      flex: 1,
    },
    percentcontainer: {
      width: "24vw",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor:
        margin < 0 ? colorscheme.v2.error : colorscheme.v2.success,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    currentprice: {
      fontSize: deviceconfigs.font.xxxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    percent: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      color: colorscheme.v2.background,
    },
    margin: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    nametext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    pointcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pointiconcontainer: {},
    lowhightext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
  };

  const { periods } = performance;
  const h24 = periods["24h"];
  const y1 = periods["365d"];
  const d30 = periods["30d"];
  const { all_time } = periods;
  const period = sPeriod === "24h" ? h24 : sPeriod === "30d" ? d30 : y1;
  const periodLowValue = parseFloat(period.quote.USD.low);
  const periodHighValue = parseFloat(period.quote.USD.high);
  const periodLowStr = periodLowValue.toFixed(2);
  const periodHighStr = periodHighValue.toFixed(2);
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          zIndex: 100,
          width: "100vw",
          top: -56,
          left: 0,
          right: 0,
          backgroundColor: colorscheme.v2.background,
          paddingTop: "56px",
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.marketwatch}
            onBackPressed={() => {
              window.history.back();
            }}
            headerstyle={{
              fontSize: deviceconfigs.font.xxlargetext,
              fontWeight: "600",
              color: colorscheme.v2.backgroundtext,
              padding: `${12}px 0px`,
            }}
          />
        </div>
        <div style={styles.coinsummarycontainer}>
          <div>
            <div style={styles.nametext}>{selectedcoin.name}</div>
          </div>
          <div style={styles.currentpriceandpercentcontainer}>
            <div style={styles.currentpricecontainer}>
              <div style={styles.currentprice}>
                {"$ " + (currentprice ? currentprice : "-")}
              </div>
            </div>
            <div style={styles.percentcontainer}>
              <div style={styles.percent}>
                {(percent ? percent : "-") + "%"}
              </div>
            </div>
          </div>
          <div style={styles.pointcontainer}>
            <div style={styles.pointiconcontainer}>
              <IoSwapVertical
                style={{
                  fontSize: "18px",
                  color: colorscheme.v2.primary,
                }}
              />
            </div>
            <div style={styles.margin}>{margin}</div>
          </div>
        </div>
      </div>

      <div style={styles.contentcontainer}>
        {index === 0 && (
          <div>
            <div style={styles.chartcontainer}>
              <TradingViewWidget
                symbol={selectedcoin.symbol + "USDT"}
                width={"92vw"}
                height={"300px"}
                theme={colorscheme.v2.name}
                allow_symbol_change={false}
              />
            </div>
            <div style={styles.statscontainer}>
              <div style={styles.statsheadercontainer}>
                <div style={styles.statstext}>{language.statistics}</div>
              </div>
              <div
                style={{
                  backgroundColor: colorscheme.v2.bordercolor,
                  height: 1,
                  margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
                }}
              ></div>
              <div style={styles.statsoptionscontainer}>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <div style={styles.lowhightext}>{language.lowhigh}</div>
                </div>
                <div style={styles.timeperiodselectorcontainer}>
                  <div
                    style={
                      sPeriod === "24h"
                        ? styles.timeperiodselectorselected
                        : styles.timeperiodselectorunselected
                    }
                    onClick={() => {
                      setSPeriod("24h");
                    }}
                  >
                    <div
                      style={
                        sPeriod === "24h"
                          ? styles.periodtextselected
                          : styles.periodtextunselected
                      }
                    >
                      {language.h24}
                    </div>
                  </div>
                  <div
                    style={
                      sPeriod === "30d"
                        ? styles.timeperiodselectorselected
                        : styles.timeperiodselectorunselected
                    }
                    onClick={() => {
                      setSPeriod("30d");
                    }}
                  >
                    <div
                      style={
                        sPeriod === "30d"
                          ? styles.periodtextselected
                          : styles.periodtextunselected
                      }
                    >
                      {language.d30}
                    </div>
                  </div>
                  <div
                    style={
                      sPeriod === "1y"
                        ? styles.timeperiodselectorselected
                        : styles.timeperiodselectorunselected
                    }
                    onClick={() => {
                      setSPeriod("1y");
                    }}
                  >
                    <div
                      style={
                        sPeriod === "1y"
                          ? styles.periodtextselected
                          : styles.periodtextunselected
                      }
                    >
                      {language.y1}
                    </div>
                  </div>
                </div>
              </div>
              <div style={styles.pricebarcontainer}>
                <div
                  style={{
                    ...styles.pricebar,
                  }}
                ></div>
                <div style={styles.pricevalues}>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <div style={styles.pricetext}>{"$ " + periodLowStr}</div>
                  </div>
                  <div style={{}}>
                    <div style={styles.pricetext}>{"$ " + periodHighStr}</div>
                  </div>
                </div>
              </div>
              <div style={styles.statsdetailscontainer}>
                <div style={styles.statsdetailsleftcontainer}>
                  <SignalInfoItem
                    title={language.marketcap}
                    value={
                      "$ " + abbreviateNumber(selectedcoin.quote.USD.market_cap)
                    }
                  />
                  <SignalInfoItem
                    title={language.volume24h}
                    value={
                      "$ " + abbreviateNumber(selectedcoin.quote.USD.volume_24h)
                    }
                  />
                  <SignalInfoItem
                    title={language.maxsupply}
                    value={abbreviateNumber(selectedcoin.max_supply)}
                  />
                  <SignalInfoItem
                    title={language.alltimehigh}
                    value={"$ " + all_time.quote.USD.high}
                  />
                  <SignalInfoItem
                    title={language.alltimelow}
                    value={"$ " + all_time.quote.USD.low}
                  />
                </div>
                <div style={styles.statsdetailsrightcontainer}>
                  <SignalInfoItem
                    title={language.dilutedmarketcap}
                    value={
                      "$ " +
                      abbreviateNumber(
                        selectedcoin.quote.USD.fully_diluted_market_cap
                      )
                    }
                  />
                  <SignalInfoItem
                    title={language.circulatingsupply}
                    value={abbreviateNumber(selectedcoin.circulating_supply)}
                  />
                  <SignalInfoItem
                    title={language.totalsupply}
                    value={abbreviateNumber(selectedcoin.total_supply)}
                  />
                  <SignalInfoItem
                    title={language.rank}
                    value={"#" + selectedcoin.cmc_rank}
                  />
                  <SignalInfoItem
                    title={language.marketcapdominance}
                    value={selectedcoin.quote.USD.market_cap_dominance + "%"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {index === 1 && <div></div>}
        <div
          style={{
            height: "12vh",
          }}
        ></div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    binancetickeractions: bindActionCreators(binanceTickerActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    myexchanges: state.myexchanges,
    cmcidmap: state.cmcidmap,
    membership: state.membership,
    binancetickers: state.binancetickers,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketWatchDetailsScreen);
