import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";
import MyOrderSummary from "../components/MyOrderSummary";
import FilterButton from "../components/FilterButton";

import * as FirebaseService from "../services/firebase";
import * as apiCalls from "../redux/actions/apiCalls";
import * as filtersActions from "../redux/actions/filtersActions";

import { List } from "antd";
import { useNavigate } from "react-router-dom";

const OrdersScreen = ({
  deviceconfigs,
  colorscheme,
  language,
  xactionssignals,
  navigation,
  filters,
  filtersactions,
  membership,
  myexchanges,
  huobisymbols,
  signals,
  cmcidmap,
  api,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ordersdata, setOrdersdata] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const signalsdata = signals.payload || [];

  const oFilters = filters.payload.order;

  useEffect(() => {
    console.log("User Effect Coming");
    fetchCPOrders();
  }, [signals]);

  const fetchCPOrders = useCallback(async () => {
    if (refreshing) {
      return;
    }
    setRefreshing(true);
    let cmcidmapM = cmcidmap;
    if (cmcidmap["BTC"] == undefined) {
      const cmcidmapRes = await FirebaseService.getIDMapDBOnceAsync();
      cmcidmapM = cmcidmapRes.val().data;
      cmcidmapM = cmcidmapM.reduce((map, curr) => {
        map[curr.symbol] = curr;
        return map;
      }, {});
    }
    api
      .getCPOrders()
      .then(async (res) => {
        var results = res.data.object.content || [];
        for (const order of results) {
          try {
            order.icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/${cmcidmapM[order.basecoin.toUpperCase()].id
              }.png`;
          } catch (e) {
            console.error(e);
          }
        }
        setRefreshing(false);
        setOrdersdata(results);
      })
      .catch((err) => {
        setRefreshing(false);
        console.log("Error", err);
      });
  }, []);

  const calculateRealizedProfit = (myorder) => {
    const { purchaseprice, quantity, targets, closedprice } = myorder;
    const investment = parseFloat(purchaseprice) * parseFloat(quantity);
    const realizedTargets = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status === "REALIZED"
          ? (parseFloat(curr.share) / 100.0) *
          parseFloat(quantity) *
          parseFloat(curr.value)
          : 0);
    }, 0.0);
    let unrealizedQuantity = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status !== "REALIZED"
          ? (parseFloat(curr.share) / 100.0) * quantity
          : 0);
    }, 0.0);
    if (targets == undefined || targets.length == 0) {
      unrealizedQuantity = parseFloat(quantity);
    }

    const unrealizedSell = closedprice
      ? parseFloat(closedprice) * unrealizedQuantity
      : 0;
    console.log({ realizedTargets, unrealizedQuantity, unrealizedSell });
    let result =
      ((realizedTargets + unrealizedSell - investment) / investment) * 100.0;
    console.log("My Result", result);
    return result.toFixed(5);
  };

  const calculateCurrentProfit = (myorder) => {
    const { purchaseprice, quantity, targets, closedprice } = myorder;
    const investment = parseFloat(purchaseprice) * parseFloat(quantity);
    const realizedTargets = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status === "REALIZED"
          ? (parseFloat(curr.share) / 100.0) *
          parseFloat(quantity) *
          parseFloat(curr.value)
          : 0);
    }, 0.0);
    let unrealizedQuantity = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status !== "REALIZED"
          ? (parseFloat(curr.share) / 100.0) * quantity
          : 0);
    }, 0.0);

    if (targets == undefined || targets.length == 0) {
      unrealizedQuantity = parseFloat(quantity);
    }

    const unrealizedSell = closedprice
      ? parseFloat(closedprice) * unrealizedQuantity
      : 0;

    let result =
      ((realizedTargets + unrealizedSell - investment) / investment) * 100.0;
    return result.toFixed(3);
  };

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px`,
    },
    filterscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: `0px 8px`,
      padding: `0px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    listcontainer: {
      paddingBottom: deviceconfigs.layout.xxlargepadding,
      paddingTop: "108px",
      flex: 1,
    },
  };
  const renderItem = (item) => {
    const realizedProfit = calculateRealizedProfit(item);
    const orderstatus = orderStatusConverter(
      item.status,
      item.timestamps,
      language,
      colorscheme,
      realizedProfit
    );

    const result = (signalsdata || []).filter((a) => {
      return a.id === item.parentsignalid;
    });
    const resResult = result && result.length > 0 && result[0];
    return (
      <MyOrderSummary
        icon={item.icon}
        basecoin={item.basecoin}
        quotecoin={item.quotecoin}
        exchange={item.exchange}
        creationdate={item.creationdate}
        timestamps={item.timestamps}
        realizedprofit={realizedProfit}
        closedprice={item.closedprice}
        status={orderstatus}
        signal={resResult}
        smartflow={item.smartflow === "ENABLED"}
        onClick={async () => {
          if (item.exchange === "BINANCE") {
            let symbolResult = await api.getExchangeInfoForSymbol(
              item.basecoin + item.quotecoin
            );
            navigate("/orders/order-details", {
              state: {
                myorder: item,
                filters: symbolResult.data.object.symbols[0].filters,
              },
            });
          } else if (item.exchange == "HUOBI") {
            const hSymbols = huobisymbols.payload;
            if (hSymbols) {
              for (var i = 0; i < hSymbols.length; i++) {
                if (hSymbols[i].symbol.toUpperCase() == item.symbol) {
                  item.priceprecision = hSymbols[i]["price-precision"];
                  item.valueprecision = hSymbols[i]["value-precision"];
                  item.amountprecision = hSymbols[i]["amount-precision"];
                  item.info = hSymbols[i];
                  navigate("/orders/order-details", {
                    state: { myorder: item, filters: hSymbols[i] },
                  });
                  return;
                }
              }
            }
          }
        }}
      />
    );
  };
  const filteredorders = ordersdata.filter((a) => {
    return statusSellableStatusResolver(a.status, oFilters);
  });
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          zIndex: 100,
          top: "0px",
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.orders}
            onBackPressed={() => {
              navigate("/dashboard");
            }}
          />
        </div>
        <div style={styles.filterscontainer}>
          <FilterButton
            selected={oFilters.accepted}
            onClick={() => {
              filtersactions.updateOrderFilters({
                ...oFilters,
                accepted: !oFilters.accepted,
              });
            }}
            text={language.accepted}
          />
          <FilterButton
            selected={oFilters.finished}
            onClick={() => {
              filtersactions.updateOrderFilters({
                ...oFilters,
                finished: !oFilters.finished,
              });
            }}
            text={language.finished}
          />
          <FilterButton
            selected={oFilters.cancelled}
            onClick={() => {
              filtersactions.updateOrderFilters({
                ...oFilters,
                cancelled: !oFilters.cancelled,
              });
            }}
            text={language.cancelled}
          />
          <FilterButton
            selected={oFilters.stoploss}
            onClick={() => {
              filtersactions.updateOrderFilters({
                ...oFilters,
                stoploss: !oFilters.stoploss,
              });
            }}
            text={language.stoploss}
          />
        </div>
      </div>
      <div style={styles.listcontainer}>
        <List
          style={{
            flex: 1,
            marginHorizontal: deviceconfigs.layout.xlargepadding,
            paddingVertical: deviceconfigs.layout.mediumpadding,
          }}
          loading={refreshing}
          dataSource={filteredorders}
          renderItem={renderItem}
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
};

function orderStatusConverter(status, timestamps, language, colorscheme, realizedProfit) {
  switch (status) {
    case "WAITING":
      return {
        color: colorscheme.v2.primary,
        bgcolor: colorscheme.v2.primarybackground,
        text: language.waiting,
        summarytext: language.accepted,
        ts: timestamps.creationdate,
        pressable: true,
        status,
      };
    case "ACTIVE":
      return {
        color: colorscheme.v2.primary,
        bgcolor: colorscheme.v2.primarybackground,
        text: language.active,
        summarytext: language.accepted,
        ts: timestamps.activedate,
        pressable: true,
        status,
      };
    case "CLOSEDWAITING":
      return {
        color: colorscheme.v2.attention,
        bgcolor: colorscheme.v2.attentionbackground,
        text: language.closedwaiting,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "CLOSEDACTIVE":
      return {
        color: colorscheme.v2.attention,
        bgcolor: colorscheme.v2.attentionbackground,
        text: language.closedactive,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "CLOSEDBYSYSTEM":
      return {
        color: colorscheme.v2.error,
        bgcolor: colorscheme.v2.errorbackground,
        text: language.closedbysystem,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "CLOSEDBYSTOPLOSS":
      return {
        color: colorscheme.v2.error,
        bgcolor: colorscheme.v2.errorbackground,
        text: language.closedbystoploss,
        summarytext: language.closedbystoploss,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "COMPLETED":
      return {
        color: realizedProfit < 0 ? colorscheme.v2.error : colorscheme.v2.success,
        bgcolor: realizedProfit < 0 ? colorscheme.v2.errorbackground : colorscheme.v2.successbackground,
        text: language.completed,
        summarytext: language.finished,
        ts: timestamps.completedate || timestamps.closedate,
        pressable: false,
        status,
      };
    default:
      return;
  }
}

function statusSellableStatusResolver(status, filters) {
  switch (status) {
    case "WAITING":
    case "ACTIVE":
      return filters.accepted;
    case "CLOSEDWAITING":
    case "CLOSEDACTIVE":
    case "CLOSEDBYSYSTEM":
      return filters.cancelled;
    case "CLOSEDBYSTOPLOSS":
      return filters.stoploss;
    case "COMPLETED":
      return filters.finished;
    default:
      return false;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    filtersactions: bindActionCreators(filtersActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
    signals: state.signals,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersScreen);
