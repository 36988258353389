import * as Constants from '../../constants/ReduxStates';

const initialAuthState = {
};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.INSTALLATION.INSTALLATION_AVAILABLE:
            return action.payload;
        case Constants.INSTALLATION.INSTALLATION_NOT_AVAILABLE:
            return { ...action.payload };
        default:
            return state;
    }
}
