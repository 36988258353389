import React from "react";

const R = {
  _name: "Türkçe",
  _language: "Dil",
  _denom: "tr",
  _type: "TURKISH",
  myperformers: "My Performers.",
  signinto: "Giriş Yap",
  forgotpassword: "Şifremi Unuttum",
  rememberme: "Beni Hatırla",
  activate: "Etkinleştir",
  faceid: "Yüz Tanıma",
  fingerprint: "Parmak İzi",
  signin: "Üye Girişi",
  completeregistration: "Kayıt İşlemini Tamamla",
  biometricnotfound: "Biyometrik Kayıt Bulunamadı",
  verifybiometric: "Lütfen şifreniz ile kimlik doğrulama yapınız",
  ok: "Tamam",
  yes: "Evet",
  no: "Hayır",
  donthaveanaccount: "Hesabınız yok mu?",
  register: "Kayıt Ol",
  createanew: "Oluştur",
  account: "Hesap",
  alreadyhaveanaccount: "Hesabınız var mı?",
  continue: "İlerle",
  verystrong: "Çok Güçlü",
  strong: "Güçlü",
  medium: "Orta",
  weak: "Zayıf ",
  atleast8digits: "En az 8 karakter,harf veya rakam",
  signals: "Sinyaller",
  alerts: "Alarmlar ",
  orders: "Emirler",
  watchlist: "İzleme Listesi",
  addyouraccount: "Hesap ekle",
  connectyourexchange: "Borsa Hesaplarınıza Bağlanın",
  connectyourbinance: "Binance Hesabınızı bağlayın.",
  upgradetopremium: "Premium'a Yükselt",
  taptoseehow: "Nasıl olduğunu öğren",
  scanqrcode: "QR Kod Tarat ",
  addyourkeys: "Anahtarları ekleyin",
  selectintegrationmethod: "Entegrasyon Yöntemini Seçin",
  selectmethod: "Yöntem Seç",
  noaccount: "Hesap Bulunamadı",
  manualkeycopy: "Manuel Anahtar Kopyalama/Yapıştırma",
  apikey: "API Anahtarı",
  secretkey: "Gizli Anahtar",
  manualkeys: "Manuel Anahtar",
  next: "Sıradaki",
  addyourkeyssummary: "Anahtar Özetinizi Ekleyin",
  createaccount: "Hesap Oluştur",
  profit: "Kar",
  balanceinusdt: "Bakiye (USDT)",
  balanceinbtc: "Bakiye (BTC)",
  connectedexchanges: "Bağlı Borsalar",
  signals: "Sinyaller",
  open: "Açık",
  accepted: "Onaylı",
  cancelled: "İptal",
  signaldetails: "Sinyal Detayları",
  manual: "Manuel",
  automatic: "Otomatik ",
  buyrange: "Alım Noktası",
  buyrangefrom: "Başlangıç",
  buyrangeto: "Bitiş",
  initialprice: "İlk Fiyat",
  stoploss: "Stop Limit",
  targets: "Hedefler",
  target: "Hedef",
  addnewtarget: "Hedef Ekle",
  orderdetails: "Emir Detayları",
  currentstatus: "Mevcut Durum ",
  currentresult: "Mevcut Sonuç",
  active: "Aktif",
  waiting: "Bekliyor",
  closed: "Kapandı",
  closedactive: "Kapanan Aktif Emir",
  closedwaiting: "Bekleyen Aktif Emir",
  closedbystoploss: "Stop Loss",
  closedbysystem: "Sistem tarafından Kapatıldı",
  completed: "Tamamlandı",
  finished: "Bitti",
  exchange: "Borsa",
  type: "Yaz",
  quantity: "Miktar",
  amount: "Tutar",
  purchasingprice: "Alış Fiyatı",
  targetedprofit: "Hedeflenen Kazanç",
  realizedprofit: "Gerçek Kazanç & Zarar",
  riskofinvestment: "Yatırım Riski",
  minimumquantity: "Mindest Menge",
  minimumvalue: "Mindest Betrag",
  minimumamount: "Minimum Amount",
  available: "Kullanıma Uygun",
  reached: "Ulaşıldı",
  search: "Ara",
  cancel: "İptal Et",
  confirm: "Onayla",
  marketwatch: "Piyasa Takip",
  markallasread: "Hepsini Okundu İşaretle",
  markasread: "Okundu İşaretle",
  name: "İsim",
  price: "Fiyat",
  changeIn24h: "24%",
  changeIn7d: "7d%",
  marketcap: "Piyasa Değeri",
  volume: "Hacim",
  chart: "Grafik",
  myfavoritecoins: "Favori Coinlerim",
  marketprice: "Piyasa Fiyatı",
  created: "Oluşturuldu",
  hit: "Hedefe Ulaşmak",
  add: "Ekle",
  selectexchange: "Borsa Seç",
  selectexchangeandpairing: "Borsa Seç ve Eşleştir",
  choose: "Seç",
  selectedexchange: "Seçilen Borsa",
  selectedcointoken: "Seçilen Coin/Token",
  targetvalue: "Hedef Hacim",
  selectanexchange: "Borsa Seç",
  selectcointoken: "Coin-Token Seçimi",
  settings: "Ayarlar",
  app: "Uygulama",
  abouttext: "Hakkında",
  support: "Destek",
  accountsettings: "Hesap Ayarları",
  launchscreen: "Başlangıç Ekranı",
  switchto: (currentmode) => {
    return currentmode == "light" ? "Gece Mod'una Geç" : "Gündüz Mod'una Geç";
  },
  language: "Dil",
  termsandconditions: "Şartlar ve Koşullar",
  privacypolicy: "Gizlilik Politikası",
  newsletter: "Bülten",
  sharemyperformersapp: "MyPerformers App'ı Paylaş",
  contact: "İletişim",
  contactsupport: "İletişim Desteği",
  sendfeedback: "Geri Bildirim Gönder",
  howtoguides: "Nasıl Yapılır?",
  notificationsettings: "Bildirim Ayarları",
  notifications: "Bildirimler",
  securitysettings: "Güvenlik Ayarları",
  version: "Sürüm",
  logout: "Çıkış Yap",
  news: "Haberler",
  neworderfeedback: "Yeni Emir Bildirimi",
  targethit: "Hedefi Tuttur",
  targetthreepercent: "Hedef %3",
  stoploss: "Stop Limit",
  stoplossthreepercent: "Stop Limit %3",
  order: "Emir",
  signal: "Sinyal",
  newsignal: "Yeni Yayınlanan Sinyaller",
  signalchange: "Sinyal Değişti",
  signalexpiration: "Sinyal Süresi Doldu",
  newscreated: "Yayınlanan Haberler",
  orderactivated: "Emir Aktif",
  ordercompleted: "Emir Tamamlandı",
  resetsettings: "Ayarları Sıfırla",
  oneclickautomaticorder: "Otomatik Emir Oluşturma",
  requirephoneverificationfororders: "Emir vermek için Sms Doğrulaması Gerekir",
  assetslist: "Varlık Listesi",
  password: "Şifre",
  changepassword: "Şifre Değiştir",
  currentpassword: "Mevcut Şifre",
  newpassword: "Yeni Şifre",
  repeatnewpassword: "Yeni Şifre Tekrar",
  security: "Güvenlik",
  myorders: "Emirlerim",
  closeorder: "Emir Kapat",
  noaccount: "Hesap Bulunamadı",
  changeavatar: "Avatar'ı Değiştir",
  clicktochange: "Değiştirmek için Tıklayın",
  connectedat: "'e Bağlandı",
  overview: "Genel Bakış",
  markets: "Piyasalar",
  statistics: "İstatistikler",
  lowhigh: "Düşük/Yüksek",
  volume24h: "Hacim24s",
  maxsupply: "Maximum Arz",
  alltimehigh: "Tüm zamanların en yükseği",
  alltimelow: "Tüm zamanların en düşüğü",
  tags: "Etiketler",
  topnews: "Manşet",
  bitcoin: "Bitcoin",
  altcoins: "Altcoin",
  nft: "NFT",
  subscriptiontype: "Abonelik Türü",
  verifyyouremail: "E-Mail adresinizi doğrulayın",
  selectsubscriptiontype: "Abonelik Türünü Seçin",
  arabic: "عربي",
  english: "English",
  french: "Français",
  german: "Deutsch",
  dutch: "Nederlands",
  turkish: "Türkçe",
  russian: "русский",
  spanish: "Español",
  italian: "Italiano",
  save: "Kaydet",

  paymentsummary: "Ödeme Özeti",
  mypayments: "Ödemelerim",
  upgradenow: "Güncelle",
  waitingforconfirmation: "Onay Bekleniyor",
  created: "Ödeme Kaydı Oluşturuldu",
  confirmed: "Ödeme Onaylandı",
  confirmedpartially: "Ödeme Kısmen Onaylandı",
  rejected: "Ödeme Reddedildi",
  selectpaymentcoin: "Ödeme Yöntemi Seç",
  paymentwarning:
    "Lütfen ödemenizi yaparken doğru ödeme ağında olduğundan emin olun.",
  paymentwarning2:
    "Lütfen ödeme tutarının tam olduğundan emin olun. Ödemenin eksik yapılması durumunda üyelik aktivasyonu gerçekleşmeyecektir.",
  requestedamount: "Ödenmesi Gereken Tutar",
  copiedamounttoclipboard: "Tutar Kopyalandı",
  copiedaddresstoclipboard: "Ödeme Adresi Kopyalandı",
  paymentaddress: "Ödeme Adresi",
  paymentnetwork: "Ödeme Ağı",
  addcoinpair: "Yeni Coin çifti Ekle",
  submit: "Gönder",
  targetsnotfull:
    "Emirinize ait hedefler %100'ün altında. Geri kalan hedefler, Otomatik Stop-Loss yöntemine göre işlem görecektir.",
  free: "Serbest Miktar",
  locked: "Kilitli Miktar",
  myassets: "Varlıklarım",
  totalinusdt: "Toplam (USDT)",
  totalfree: "Toplam Serbest Varlıklar",
  totallocked: "Toplam Kilitli Varlıklar",
  verifylogin: "Giriş Onayı",
  disclaimerp1:
    "Bu uygulamadaki içeriklerin geliştirilmesi için çok çalışıyoruz.",
  disclaimerp2:
    "Ancak, MyPerformers App içeriklerde geçen ifade ve önerilerin ve bunların doğruluğunu garanti etmez.",
  disclaimerp3:
    "Bu bağlamda ve yasalar gereğince, MyPerformers App meydana gelebilecek zarardan hiçbir şekilde sorumlu tutulamaz.",
  disclaimerp4:
    "Uygulamayı kullanarak, bu feragatnameye ve onun maddelerine uyacağınızı taahhüt etmiş olursunuz.",
  acknowledge: "Kabul Et",
  uptotop: "En Üste Çık",
  email: "E-Posta",
  password: "Şifre",
  resetpassword: "Şifreyi Sıfırla",
  appinitlineone:
    "Kripto dünyasında kazanmanın kolay ve kontrol edilebilir yolu.",
  appinitlinetwo: "Kripto severler için kolay, hızlı ve basit arayüz.",
  appinitlinethree: "Kaynakları kaydediyoruz...Biraz zaman alabilir.",
  appinitlinefour:
    "Kripto pazarını verimli, aktif ve kazançlı bir şekilde kullanmak için Şimdi Başlayın.",
  disconnectwarningone: (exchange) => {
    return `Şimdi ${exchange} borsasından çıkış yapıyorsunuz.`;
  },
  disconnectwarningtwo: "Bu sebeple bütün Açık emirleriniz kapatılacaktır.",
  disconnectwarningthree:
    "Var olan bütün diğer varlıklarınız Piyasa değerinden otomatik olarak satılacaktır.",
  disconnectwarningfour: "Bu bilgilendirmeyi okudum ve onaylıyorum.",
  sureclose: "Emri kapatmak istediğinize emin misiniz?",
  invoices: "Faturalar",
  restarttheapp: "Yeniden Başlat",
  selectpaymentmethod: "Ödeme Yöntemi Seç",
  apikeyexpired:
    "Api anahtarı geçerli değil. Yeni bir Binance API anahtarı bağlamanız gerekli..",
  activecurrencies: "Kripto Birimler",
  activeexchanges: "Borsalar",
  activemarketpairs: "Pariteler",
  btcdominance: "BTC Dom.",
  ethdominance: "ETH Dom.",
  totalmarketcap: "Market Kap.",
  totalmarketcap24h: "Market Kap. 24s",
  sellingprice: "Satış fiyatı",
  smartflow: "Otomatik",
  smartflowheader:
    "Otomatik mod, piyasa bilgilerinin otomatik olarak yürütülmesidir. Binance alım satımlarında USDT'niz olduğu sürece tam otomatik olarak gerçekleştirilecektir.",
  smartflowsexpl: "Bunu yapmanın iki yolu vardır.",
  smartflowsubheader1: "1) Sabit bir USDT tutarı ile",
  smartflowsubheader2: "2) Sabit bir yüzde ile",
  smartflowp1:
    "Bu seçeneği kullanmak için, aşağıdaki sayfada kullanmayı planladığınız para miktarını belirtin. Bu miktar daha sonra her bir piyasa bilgisi için kullanılacaktır.",
  smartflowp2:
    "Bu seçeneği kullanmak için, aşağıdaki sayfada istediğiniz kullanmayı planladığınız para yüzdesini seçin. Binance'de bulunan bakiyenin bu yüzdesi daha sonra piyasa bilgilerine göre kullanılır.",
  smartflowlastp:
    "Binance'deki USDT bakiyesi seçilen miktarın üzerinde olduğu sürece piyasa bilgileri kabul edilecektir. Kredinin yetersiz olması durumunda mevcut piyasa bilgileri kabul edilemez. Yeterli USDT tekrar mevcut olduğunda, piyasa bilgileri tekrar kabul edilecektir.",
  smartflowreadandagreed:
    "Otomatik Sorumluluk Reddi'ni okudum ve kabul ediyorum",
  enablesmartflow: "Otomatik Etkinleştir",
  amountofusdt: "İşlem başına USDT tutarı",
  shareoffunds: "İşlem başına fonların (USDT) payı (%)",
  enjoyfullmyperformersexperience: "Enjoy full MyPerformers Experience.",
  install: "Yükle",
  donthaveanaccountyet: "Henüz bir hesabın yok mu?",
  gotomyexchanges: "Hesaplarım",
  gotodashboard: "Ana Sayfa",
  retryapiandsecretkeys: "API ve Secret Anahtarları Tekrar Dene",
  availableusdtfunds: "Otomatik için mevcut USDT",
  automaticperformance: "Otomatik Performansı - Sistem Çapında",
  automaticmode: "Otomatik Mod",
  automaticmodepopupheader1:
    "Otomatik mod, piyasa bilgilerinin otomatik olarak yürütülmesidir. Binance'de USDT'niz olduğu sürece, işlemler tam otomatik olarak gerçekleştirilecektir.",
  automaticmodepopupsubheader1:
    "Bunun için kullanılan miktar sizin tarafınızdan belirlenir. Bunu yapmanın iki yolu vardır.",
  automaticmodepopuptext1: "1.) Sabit bir USDT tutarı ile",
  automaticmodepopuptext2: "2.) Sabit bir yüzde ile",
  automaticmodepopupheader2: "Aylık %10 Performans Kuralı",
  automaticmodepopuptext3:
    "Herkese kripto piyasasına mümkün olduğunca kolay ve şeffaf bir şekilde katılma fırsatı vermeyi misyon edindik. Bu nedenle, ayda %10'dan daha az kar sağlamanı sağlarsak herhangi bir ücret talep etmemeye karar verdik. Yani otomatik mod %10'dan daha az kar sağlatıyorsa bir sonraki ay uygulamayı ücretsiz olarak kullanabilirsiniz. Genel otomatik modun değerleri, burada bu alanda gösterildiği gibi hesaplama için geçerlidir. Belgeler için bir ticaret geçmişi, Grafik menü öğesinin altında bulunabilir.",
  automaticinlinedesc:
    "Burada, işlem başına sabit bir USDT tutarı mı yoksa tüccar başına Binance Exchange'inizde mevcut USDT bakiyenizin belirli bir yüzdesini mi kullanmak istediğinize dair bir seçim yapmanız gerekir. Bir seferde yalnızca bir seçim yapılabilir.",
  minimumamount60USDT: "Minimum Tutar 60 USDT",
  minimumshare: (share) => `Asgari pay %${share}`,
  maximumshare: (share) => `Azami pay ${share}%`,
  balanceofyourexchanges: "Borsa Hesaplarınızdaki Varlıkların Toplamı",
  information: "Bilgi",
  charts: "Grafikler",
  h24: "24s",
  d7: "7g",
  d30: "30g",
  y1: "1Y",
  automaticlast7days: "Otomatik - Son 7 Gün",
  automaticlast30days: "Otomatik - Son 30 Gün",
  startcapital: "(Başlangıç Sermayesi 100 USDT)",
  automaticwarning: "Automatic Mode Warning",
  noexchangeconnected: "You must have Binance Exchange connected in order to use Automatic functionality.",
  membershipexpired: "In order to use Automatic Mode, you have to become a Premium User.",
  gopremium: "Go Premium",
  howtojointelegram: "Sinyal Kanalı",
  start: "Başla",

  addingaccountsuccessful:
    "Hesap ekleme başarısız. Sebep : Geçersiz API-Secret Anahtarı",
  youhavealreadyaccountintegrated: (text) =>
    `Zaten bağlı bir ${text} hesabınız bulunuyor.`,
  checkyouremail: "Yeni şifreniz için E-Mailinizi kontrol edin.",
  resetpasswordsuccess: "Şifre sıfırlama başarılı.",
  attention: "Dikkat",
  basic: "Basic",
  premium: "Premium",
  viewdetails: "İncele",
  youareabouttoleave: "MyPerformers'tan çıkmak istediğinize emin misiniz?",

  readandagree: (deviceconfigs, colorscheme) => {
    return (
      <span
        style={{
          fontSize: deviceconfigs.font.mediumtext,
          fontWeight: "700",
          color: colorscheme.v2.backgroundtext,
        }}
      >
        <span
          style={{
            fontWeight: "700",
            fontSize: deviceconfigs.font.mediumtext,
            color: colorscheme.v2.primary,
          }}
        >
          {" "}
          Kullanıcı Sözleşmesini{" "}
        </span>{" "}
        okuduğumu ve anladığımı beyan ederim.
      </span>
    );
  },
  noaccountfirstpar: (exchange) => {
    return `${exchange} ile API bağlantısı kuruyoruz.Kurulumun tamamlanması için etkin ve doğrulanmış ${exchange} hesabı gerekmektedir .`;
  },
  noaccountsecondpar: (exchange) => {
    return `Eğer bir ${exchange} hesabınız var ise, diğer iki seçenekten birini seçiniz.`;
  },
  noaccountthirdpar: (exchange) => {
    return `Eğer ${exchange} hesabınız yok ise, aşağıdaki butona tıklayarak kolay bir şekilde şimdi oluşturabilirsiniz.`;
  },
  createanaccount: "Hesap Oluştur",
  buyxinexchangefory: (x, y) => {
    return `${x}'den ${y} satın al`;
  },
  membership: (type) => {
    switch (type) {
      case "TRIAL":
        return "Basic";
      case "PREMIUM":
        return "Premium";
      case "PROMOTION30DAYS":
        return "30 Günlük Premium"
      default:
        return "Cevapsız";
    }
  },
  membershiptext: "Üyelik",
  expiresat: "Bitiş Tarihi",
  expires: "Bitiş Tarihi",
  paycurrency: "Ödeme Coin Birimi",
  totalamountinusd: "Toplam USD Karşılığı",
  clicktoupgrade: "Güncellemek için Tıklayınız",
  upgradenow: "Güncelle",
  send: "Gönder",
  step: (x) => {
    return "Aşama " + x;
  },
  myperformersapp: "MyPerformers",

  on: "Açık",
  off: "Kapalı",

  about: {
    line1: "MPA'ya Hoşgeldiniz!",
    line2: "MPA, size aşağıdaki olanakları sunan kripto marketlere odaklanan bir uygulama aracıdır",
    line3: "-güvenilir ve hızlı bilgi akışı",
    line4: "-her an güncel piyasalar genel bakışı",
    line5: "Kripto varlıklarınızın basit ve anlaşılır özetleri",
    line6: "En yüksek seviyeden portfolyö yönetimi",
    line7: "Uygulamadaki pek çok alanın kullanımı ücretsizdir. Portfolyö yönetimi,Otomatik Mod adı altında, ücretli bir hizmettir. Uygulama kayıt olup borsa hesabınızı 7 gün içinde bağlarsanız 30 gün boyunca PREMİUM özelliklerden yararlanabilirsiniz.",
    line8: "Bunu yapmak için 3 basit aşama var :",
    line9: "(!) Binance uygulamasına kendi kullanıcı adınızla girin",
    line10: "(!) MPA, API anahtarları vasıtasıyla Binance API'ye bağlayın",
    line11: "(!) Otomatik modu aktif hale getirin",
    line12: "Otomatik mod bana ne sunuyor?",
    line13: "Otomatik mod, market bilgilerinin otomatik yönetimini ifade eder. Bağlı bulunan borsa hesabınızda aktif USDT olduğu sürece, sizin belirleyeceğiniz şekilde paylaşılan piyasa verilerinin otomatik işlenmesi sağlanır. Asgari giriş bedeli 60 USDTdir",
  },

  videotutorial: "Video Dökümanı",
  automaticmode: "Otomatik Mod",
  automaticmodedesc: {
    p1: "Otomatik mod, piyasa işlemlerinindoğrudan borsa hesabınızda otomatik olarak yürütülmesidir. Borsa hesabınızda USDT'niz olduğu ve otomatik mod aktif olduğu sürece, işlemler tam otomatik olarak uygulanacaktır. Devam eden bir ticarete sizin müdahale etme şansınız her zaman olacaktır. Bir ticaret için kullanılan miktar sizin tarafınızdan belirlenir. İşlembaşına minimum yatırımşartışu anda 60,00 USDT'dir.",
    p2: "Aylık %10 fayda Kuralı*",
    p3: "Bu kural ile herkese kripto piyasasına mümkün olduğunca kolay ve şeffaf bir şekildekatılmafırsatı vermeyi misyon edindik.Kendimize kullanıcılara fayda kazandırma görevini empoze ettiğimiz ve ne kadar ciddiye aldığımızın bir işareti olarak, 30 gün içinde %10'dan daha az otomatik mod işlemi üretirsek uygulamayı kullanmak için ücret almamayı seçtik.",
    p4: "Bu size neden fayda sağlar,nasılçalışıyor?",
    p5: "MyPerformers APP'i yükler, Binance'i 7 gün içinde API seçeneği ile bağlayıp otomatik modu etkinleştirirseniz, APP'i otomatik mod işlevi ile birlikte bu işlevle 30 gün boyunca ücretsiz kullanabilirsiniz.",
    p6: "Let the automatic mode run continuosly for 30 days without intervening in a trade manually, and it will not generate 10% if you have another 30 days of use for free.",
    p7: "30 gün boyunca ödeme yaptıysanız ve bu süre içinde %10 kazanmadıysanız, otomatik modu 30 gün boyunca manuel olarak herhangi bir işleme müdahale etmeden sürekli çalıştırırarak ücretsiz kullanabilirsiniz.",
    p8: "%10 yukarıdaaçıklanankoşullaraltında hizmet sağlandı ise, APP kullanımı için abonelik ücreti ödenmelidir.",
    p9: "",
    p10: "* bir sonraki duyuruya kadar geçerlidir",
  },

  firstregistration: {
    line1: "Otomatik modu 30 gün boyunca deneyin - ÜCRETSİZ",
    line2: "Kayıt olduktan sonra 7 gün içinde borsa hesabınızı bağlarsanız 30 gün boyunca PREMİUM özelliklerden yararlanabilirsiniz.",
    line3: "Borsa hesaplarınızı bağlamak için daha fazla bilgiye Ana Sayfadaki ? butonuna tıklayarak erişebilirsiniz.",
    line4: "Bu eşsiz fırsatı kaçırmayın.",
    line5: "Sevgilerle, MPA Takımı",
  },

  binanceapikeypassive: "Binance API Key has Expired. Please go to your Binance account and re-activate your API options or create a new one",

  disablingautomaticwarning: {
    title: "Otomatik Mod Kapatma Uyarısı",
    body: {
      line1: "Sevgili MPA kullanıcısı!",
      line2: "Bildiğin üzere, Otomatik Mod'da. The prerequisite is that the automatic mode is ON for 30 days and that a trade is not intervened manually. By switching off the automatic mode, the 10% rule loses its validity in this period."
    }
  },
  proceed: "Proceed",

  success: {
    addexchangesuccess: {
      header: "Başarılı",
      body: "Hesap ekleme işlemi başarılı",
    },
    scansuccess: {
      header: "Tarama Başarılı",
      body: "QR Taraması Başarılı",
    },
    order: {
      header: "Emir Başarılı",
      body: "Emir Başarıyla Alındı.",
    },
    passwordchange: {
      header: "Başarılı",
      body: "Şifre Başarıyla Değiştirildi.",
    },
    verificationsuccess: {
      header: "Başarılı",
      body: "E-Mail Doğrulaması Başarılı",
    },
    paymentrequestreceived: {
      header: "Ödeme Talebi Alındı",
      body: "Ödeme isteği başarıyla alındı. Takriben 5 dakika içinde işleme konacaktır.",
    },
    purchasesuccess: {
      header: "Üyelik İşlemi Başarılı",
      body: "Artık bütün MyPerformers özelliklerini kullanmaya başlayabilirsiniz.",
    },
    displaynamechangedsuccess: {
      header: "Success",
      body: "Display Name changed Successfully.",
    },
  },
  warning: {
    newversionavailable: {
      header: "New Version Available",
      body: "Please restart the app to get the latest version.",
    },
    alreadysubscribed: {
      header: "Subscription Warning",
      body: "Already Subscribed",
    },
  },
  error: {
    generic: {
      header: "Hata",
      body: "Bir Şeyler Ters Gitti.",
    },
    loginnotsuccessful: {
      header: "Giriş hatası",
      body: "Kimlik Bilgileri Doğru Değil.",
    },
    permission: {
      header: "izin Hatası",
      body: "Kameraya izin verdiğinizden emin olun.",
    },
    noexchangeaccount: {
      header: "Hata",
      body: "Borsa hesabınız bulunmamaktadır.",
    },
    exchangeaccountspecifiednotconnected: (exchange) => {
      return {
        header: "Hesap Hatası",
        body: `${exchange} borsasına bağlı hiçbir hesabınız bulunmuyor.`,
      };
    },
    passwordchange: {
      header: "Hata",
      body: "Şifre değiştirilirken bir hata oluştu.",
    },
    faceidnotverified: {
      header: "Hata",
      body: "Yüz Tanıma/Parmak İzi eşleştirilmedi",
    },
    verificationerror: {
      header: "Hata",
      body: "Giriş Doğrulama Hatası",
    },
    faceid: {
      header: "Error",
      body: "Biometrics Not Supported",
    },
    biometricsnotenrolled: {
      header: "Biometrics Error",
      body: "Biometrics Not Enrolled.",
    },
    nobiometriclevel: {
      header: "Biometrics Error",
      body: "Level of Enrollment is not high.",
    },
    biometricsnotcompatible: {
      header: "Biometrics Error",
      body: "Biometrics Not Compatible.",
    },
    signuperror: {
      header: "Kayıt Hatası",
      body: "E-Posta zaten kayıtlı.",
    },
    expirederror: {
      header: "Membership Expired",
      body: "Please upgrade your membership for unlimited access.",
    },
    insufficientbalance: {
      header: "Insufficient Balance",
      body: "You don't have enough amount in your balance.",
    },
    purchasefailed: {
      header: "Subscription Failed",
      body: "Unknown error occurred during subscription",
    },
  },
};

export default R;
