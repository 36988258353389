import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref, onValue, get, set } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";

console.log(process.env.REACT_APP_FIREBASE_URL);

const firebaseConfig = {
  apiKey: "AIzaSyBWrIKhvomMwR1Eitf6si29DVAO8kzpbSM",
  authDomain: "performers-app.firebaseapp.com",
  databaseURL: `${process.env.REACT_APP_FIREBASE_URL}`,
  projectId: "performers-app",
  storageBucket: "performers-app.appspot.com",
  messagingSenderId: "51484744562",
  appId: "1:51484744562:web:e4b3d760f59c29a2f7dbeb",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

signInAnonymously(auth)
  .then(() => { })
  .catch((error) => {
    console.error("Some Erorr during sign in anonymous", error);
  });

const signalsDB = ref(getDatabase(firebaseApp), "signals");
const coinMarketCapListingsDB = ref(
  getDatabase(firebaseApp),
  "coinmarketcap/listings"
);
const coinMarketCapIDMapDB = ref(
  getDatabase(firebaseApp),
  "coinmarketcap/idmap"
);

const binancePricePairsDB = ref(
  getDatabase(firebaseApp),
  "excahanges/binance/prices"
);

const newsDB = ref(getDatabase(firebaseApp), "news");

const messaging = getMessaging(firebaseApp);

export function getMessagingObj() {
  return messaging;
}

export function initializeFirebase() { }

export function getSignalsDBOnce(callback) {
  get(signalsDB)
    .then((snapshot) => {
      callback(snapshot);
    })
    .catch((err) => { });
}

export function getSignalsDBOn(callback) {
  onValue(signalsDB, callback);
}

export function getListingsDBOnce(callback) {
  get(coinMarketCapListingsDB)
    .then((snapshot) => {
      callback(snapshot);
    })
    .catch((err) => { });
}

export function getListingsDBOn(callback) {
  onValue(coinMarketCapListingsDB, callback);
}

export function getNewsOnce(callback) {
  get(newsDB)
    .then((snapshot) => {
      callback(snapshot);
    })
    .catch((err) => { });
}

export function getNewsOn(callback) {
  onValue(newsDB, callback);
}

export function getIDMapDBOnce(callback) {
  get(coinMarketCapIDMapDB)
    .then((snapshot) => {
      callback(snapshot);
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function getIDMapDBOnceAsync() {
  return get(coinMarketCapIDMapDB);
}

export function getIDMapDBOn(callback) {
  onValue(coinMarketCapIDMapDB, callback);
}

export function getBinancePairPricesOnce(callback) {
  get(binancePricePairsDB)
    .then((snapshot) => {
      callback(snapshot);
    })
    .catch((err) => {
      console.error(err);
    });
}

export function getBinancePairPricesOn(callback) {
  onValue(binancePricePairsDB, callback);
}

export async function getBinancePairPricesOnceAsync() {
  return get(binancePricePairsDB)
}
