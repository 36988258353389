import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SignUpScreen from "../..//screens/SignUpScreen";
import SignInScreen from "../../screens/SignInScreen";
import EmailVerificationScreen from "../../screens/EmailVerificationScreen";
import DashboardScreen from "../../screens/DashboardScreen";
import SettingsScreen from "../../screens/SettingsScreen";
import MyExchangesScreen from "../../screens/MyExchangesScreen";
import RegistrationVerificationScreen from "../../screens/RegistrationVerificationScreen";
import NewsScreen from "../../screens/NewsScreen";
import AccountSettingsScreen from "../../screens/AccountSettingsScreen";
import AddExchangeAccountSelectExchangeScreen from "../../screens/AddExchangeAccountSelectExchangeScreen";
import AddExchangeAccountSelectMethodScreen from "../../screens/AddExchangeAccountSelectMethodScreen";
import AddExchangeAccountManualCodeEntryScreen from "../../screens/AddExchangeAccountManualCodeEntryScreen";
import AddExchangeAccountSummaryScreen from "../../screens/AddExchangeAccountSummaryScreen";
import SignalsScreen from "../../screens/SignalsScreen";
import NotificationSettingsScreen from "../../screens/NotificationSettingsScreen";
import OrdersScreen from "../../screens/OrdersScreen";
import OrderDetailsScreen from "../../screens/OrderDetailsScreen";
import SignalDetailsScreen from "../../screens/SignalDetailsScreen";
import LanguageSettingsScreen from "../../screens/LanguageSettingsScreen";
import SignalAutomaticOperationScreen from "../../screens/SignalAutomaticOperationScreen";
import NotificationsScreen from "../../screens/NotificationsScreen";
import MarketWatchScreen from "../../screens/MarketWatchScreen";
import AssetListScreen from "../../screens/AssetListScreen";
import MarketWatchDetailsScreen from "../../screens/MarketWatchDetailsScreen";
import TermsAndConditionsScreen from "../../screens/TermsAndConditionsScreen";
import NewsDetailsScreen from "../../screens/NewsDetailsScreen";
import AddExchangeAccountNoAccountScreen from "../../screens/AddExchangeAccountNoAccountScreen";
import SelectMembershipScreen from "../../screens/SelectMembershipScreen";
import SelectPaymentCoinScreen from "../../screens/SelectPaymentCoinScreen";
import PaymentSummaryScreen from "../../screens/PaymentSummaryScreen";
import MyPaymentsScreen from "../../screens/MyPaymentsScreen";
import PaymentDetailsScreen from "../../screens/PaymentDetailsScreen";

import * as myExchangesActions from "../../redux/actions/myExchangesActions";
import * as installationActions from "../../redux/actions/installationEventActions";
import * as binanceTickerActions from "../../redux/actions/binanceTIckerWebSocketActions";
import * as unreadNotificationsActions from "../../redux/actions/unreadNotificationsActions";

import * as globalMetricsActions from "../../redux/actions/globalMetricsActions";
import MarketCapScreen from "../../screens/MarketCapScreen";
import SmartFlowDescriptionScreen from "../../screens/SmartFlowDescriptionScreen";
import SmartFlowSettingsScreen from "../../screens/SmartFlowSettingsScreen";
import ForgotPasswordScreen from "../../screens/ForgotPasswordScreen";
import SmartFlowPerformanceChartsScreen from "screens/SmartFlowPerformanceChartsScreen";
import AboutMyPerformersAppScreen from "screens/AboutMyPerformersAppScreen";
import PersonalAutomaticPerformanceScreen from "screens/PersonalAutomaticPerformanceScreen";
import AutomaticDescriptionScreen from "screens/AutomaticDescriptionScreen";

const AppContainer = ({
  auth,
  myexchangesactions,
  colorscheme,
  installationactions,
  globalmetricsactions,
  unreadnotificationsactions,
  binancetickeractions,
}) => {
  const isLoggedIn = auth !== undefined && auth.token !== undefined;

  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    //e.prompt();
    //setButton(e);
    installationactions.saveInstallationEvent(e);
    // Update UI notify the user they can install the PWA
    //   showInstallPromotion();
    // Optionally, send analytics event that PWA install promo was shown.
  });
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: colorscheme.v2.background,
      }}
      className="main-container"
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={!isLoggedIn ? <SignInScreen /> : <DashboardScreen />}
          />
          <Route path="/sign-up" element={<SignUpScreen />} />
          <Route path="/sign-in" element={<SignInScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route
            path="/email-verification"
            element={<EmailVerificationScreen />}
          />
          <Route
            path="/registration-verification"
            element={<RegistrationVerificationScreen />}
          />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/signals" element={<SignalsScreen />} />
          <Route
            path="/signals/signal-details"
            element={<SignalDetailsScreen />}
          />
          <Route
            path="/signals/signal-details/automatic"
            element={<SignalAutomaticOperationScreen />}
          />
          <Route path="/notifications" element={<NotificationsScreen />} />
          <Route path="/market-watch" element={<MarketWatchScreen />} />
          <Route
            path="/market-watch/details"
            element={<MarketWatchDetailsScreen />}
          />
          <Route path="/orders" element={<OrdersScreen />} />
          <Route
            path="/orders/order-details"
            element={<OrderDetailsScreen />}
          />
          <Route path="/my-exchanges" element={<MyExchangesScreen />} />
          <Route path="/my-exchanges/assets" element={<AssetListScreen />} />
          <Route
            path="/add-exchange-select-exchange"
            element={<AddExchangeAccountSelectExchangeScreen />}
          />
          <Route
            path="/add-exchange-select-method"
            element={<AddExchangeAccountSelectMethodScreen />}
          />
          <Route
            path="/add-exchange-manual-key"
            element={<AddExchangeAccountManualCodeEntryScreen />}
          />
          <Route
            path="/add-exchange-summary"
            element={<AddExchangeAccountSummaryScreen />}
          />
          <Route
            path="/add-exchange-no-account"
            element={<AddExchangeAccountNoAccountScreen />}
          />
          <Route path="/news" element={<NewsScreen />} />
          <Route path="/news/details" element={<NewsDetailsScreen />} />
          <Route path="/marketwatch-global" element={<MarketCapScreen />} />
          <Route path="/watchlist" element={<DashboardScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
          <Route
            path="/settings/terms-and-conditions"
            element={<TermsAndConditionsScreen />}
          />
          <Route
            path="/settings/privacy-policy"
            element={<TermsAndConditionsScreen />}
          />
          <Route
            path="/settings/account-settings"
            element={<AccountSettingsScreen />}
          />
          <Route
            path="/settings/notification-settings"
            element={<NotificationSettingsScreen />}
          />
          <Route
            path="/settings/language-settings"
            element={<LanguageSettingsScreen />}
          />
          <Route
            path="/settings/smart-flows/description"
            element={<SmartFlowDescriptionScreen />}
          />
          <Route
            path="/settings/automatic/description"
            element={<AutomaticDescriptionScreen />}
          />
          <Route
            path="/settings/smart-flows"
            element={<SmartFlowSettingsScreen />}
          />
          <Route
            path="/memberships/select-membership"
            element={<SelectMembershipScreen />}
          />
          <Route
            path="/memberships/select-payment-coin"
            element={<SelectPaymentCoinScreen />}
          />
          <Route
            path="/memberships/payment-summary"
            element={<PaymentSummaryScreen />}
          />
          <Route path="/my-payments" element={<MyPaymentsScreen />} />
          <Route
            path="/my-payments/payment-details"
            element={<PaymentDetailsScreen />}
          />
          <Route
            path="/automatic-performance-charts"
            element={<SmartFlowPerformanceChartsScreen />}
          />
          <Route
            path="/about-myperformersapp"
            element={<AboutMyPerformersAppScreen />}
          />
          <Route
            path="/personal-automatic-performance"
            element={<PersonalAutomaticPerformanceScreen />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    myexchangesactions: bindActionCreators(myExchangesActions, dispatch),
    installationactions: bindActionCreators(installationActions, dispatch),
    globalmetricsactions: bindActionCreators(globalMetricsActions, dispatch),
    unreadnotificationsactions: bindActionCreators(
      unreadNotificationsActions,
      dispatch
    ),
    binancetickeractions: bindActionCreators(binanceTickerActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    colorscheme: state.colorscheme,
    installation: state.installation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
