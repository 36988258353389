import { connect } from "react-redux";

const GenericErrorPopupHeader = ({ language }) => {

    return (
        <div style={{
            padding: "12px 8px",
            display : "flex",
            justifyContent :"center",
            alignItems : "center"
        }}>
            <img
                src={require("../../assets/images/v2/error.png")}
                style={{
                    width: "42px",
                    height: "42px",
                    objectFit: "contain",
                }}
            />
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        balancedisplay: state.balancedisplay,
        myexchanges: state.myexchanges,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        unreads: state.unreads,
        cmcidmap: state.cmcidmap,
        installation: state.installation,
        globalmetrics: state.globalmetrics,
        smartflows: state.smartflows,
        cmclisting: state.cmclisting,
        binancetickers: state.binancetickers,
        signals: state.signals,
        usercommchannel: state.usercommchannel,
        automaticperformances: state.automaticperformances,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericErrorPopupHeader);