
import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.PRICETICKERS.PRICETICKERS_LOADED:
            return action.payload;
        case Constants.PRICETICKERS.PRICETICKERS_FAILED:
            return { ...action };
        default:
            return state;
    }
}
