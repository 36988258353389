const R = {
  images: {
    darklogo: require("assets/images/v2/logo-dark.png"),
    lightlogo: require("assets/images/v2/logo-light.png"),
    danger: require("assets/images/v2/danger.png"),
    home: require("assets/images/v2/home-icon-light-v2.png"),
    smartflowdark: require("assets/images/v2/smart-flow-dark.png"),
    smartflowlight: require("assets/images/v2/smart-flow-light.png"),
  },
};

export default R;
