import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const ActionButton = ({
  deviceconfigs,
  colorscheme,
  language,
  icon,
  text,
  onClick,
  size = "large",
  disabled,
  buttonstyle,
  textstyle,
}) => {
  const styles = {
    maincontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical:
        size === "small"
          ? deviceconfigs.layout.xlargepadding
          : deviceconfigs.layout.xxlargepadding,
      paddingHorizontal:
        size === "small"
          ? deviceconfigs.layout.largepadding
          : deviceconfigs.layout.xxlargepadding,
      padding:
        size === "small"
          ? `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.largepadding}px`
          : `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
      backgroundColor: disabled
        ? colorscheme.v2.unselected
        : colorscheme.v2.primary,
      width: size === "small" ? "32vw" : "48vw",
      alignSelf: "center",
      justifyContent: "center",
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    textcontainer: {
      marginLeft: deviceconfigs.layout.largepadding,
    },
    text: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
      color: colorscheme.v2.primarytext,
    },
  };
  return (
    <div
      style={{
        ...styles.maincontainer,
        ...buttonstyle,
      }}
      role={"button"}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      data-test="button-container"
    >
      {icon && <div data-testid="icon">{icon}</div>}
      <div style={styles.textcontainer}>
        <div
          style={{
            ...styles.text,
            ...textstyle,
          }}
          data-test="text"
        >
          {text}
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
