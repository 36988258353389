import { connect } from "react-redux";

const WelcomeToMyPerformersAppPopupBodyComponent = ({ colorscheme, language }) => {
    const styles = {

    };
    return (
        <div style={{
        }}>
            <div style={{ color: colorscheme.v2.backgroundtext, fontSize: "15px", fontWeight: "700", margin: "8px 0px" }}>{language.firstregistration.line1}</div>
            <div style={{ color: colorscheme.v2.backgroundtext, fontSize: "13px", fontWeight: "400", }}>{language.firstregistration.line2}</div>
            <div style={{ color: colorscheme.v2.backgroundtext, fontSize: "13px", fontWeight: "400", marginTop: "12px", }}>{language.firstregistration.line3}</div>
            <div style={{ color: colorscheme.v2.backgroundtext, fontSize: "15px", fontWeight: "700", marginTop: "12px", }}>{language.firstregistration.line4}</div>
            <div style={{ color: colorscheme.v2.backgroundtext, fontSize: "15px", fontWeight: "700", marginTop: "12px", }}>{language.firstregistration.line5}</div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WelcomeToMyPerformersAppPopupBodyComponent);