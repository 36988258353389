import LottieView from 'react-lottie';
import * as animationData from '../../assets/raw/myperformers_loading_01.json';

import Modal from '@mui/material/Modal';


const LoadingModal = ({ loading }) => {

    if (!loading) {
        return <div></div>;
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Modal
            open={loading}
        >
            <div
                style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "32vh",
                        justifyContent: "flex-start",
                    }}
                >
                    <LottieView
                        width={"56vw"}
                        height={"48vw"}
                        speed={1}
                        options={defaultOptions}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default LoadingModal;
