import { PRICETICKERS } from "../../constants/ReduxStates";
import axios from 'axios';

let timer = undefined;

export function loadPricesSuccess(payload) {
    return {
        type: PRICETICKERS.PRICETICKERS_LOADED,
        payload,
    };
}

export function loadBalancesFailed() {
    return {
        type: PRICETICKERS.PRICETICKERS_FAILED,
    };
}

export function loadPriceTickers() {
    return async function (dispatch) {
        timer = setInterval(async () => {
            try {
                const prices = await axios.get(
                    `https://api.binance.com/api/v3/ticker/price`, {
                    headers: [],
                }
                );
                var priceMap = prices.data.reduce(function (map, obj) {
                    map[obj.symbol] = obj.price;
                    return map;
                }, {});
                dispatch(loadPricesSuccess(priceMap));
            } catch (e) {
                console.log("Error during price ticker in priceTickerActions");
            }
        }, 3000);
    }
}

export function clearPriceTickers() {
    return async function (dispatch) {
        clearInterval(timer);
    }
}