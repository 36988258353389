import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { toHumanReadableDateTime } from '../../utils/timeservice';

const InfoItem = ({ deviceconfigs, colorscheme, language, title, value, valuetwo, targethit }) => {
    const styles = {
        infoitemcontainer: {
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
        },
        infoitemline: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            margin: `${deviceconfigs.layout.mediumpadding}px 0px`,
        },
        infoitemtitle: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.unselected,
        },
        infoitemvalue: {
            flex: 1,
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        infoitemvaluetwo: {
            marginLeft: "12px",
            alignSelf: "flex-end",
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        targethit: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.success,
        },
        targethitdate: {
            fontSize: deviceconfigs.font.smalltext,
            fontWeight: "300",
            color: colorscheme.v2.backgroundtext,
            marginLeft: deviceconfigs.layout.mediumpadding
        }
    };
    return (
        <div style={styles.infoitemcontainer}>
            <div style={styles.infoitemline}>
                <div style={styles.infoitemtitle}>{title}{valuetwo ? <span style={styles.infoitemvaluetwo}>{`(${valuetwo})`}</span> : null}</div>
            </div>
            <div style={styles.infoitemline}>
                <div style={styles.infoitemvalue}>{value}</div>
            </div>
            {targethit && <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <div style={styles.targethit}>
                    {language.reached}
                </div>
                <div style={styles.targethitdate}>
                    {toHumanReadableDateTime(targethit)}
                </div>
            </div>}
        </div>
    );
};

InfoItem.propTypes = {
    text: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoItem);