import { INSTALLATION } from '../../constants/ReduxStates';

export function installationAvailable(payload) {
    return {
        type: INSTALLATION.INSTALLATION_AVAILABLE,
        payload,
    };
}

export function installationNotAvailable() {
    return {
        type: INSTALLATION.INSTALLATION_NOT_AVAILABLE,
    };
}

export function getInstallationEvent() {
    return function (dispatch) {

    };
}

export function saveInstallationEvent(payload) {
    return function (dispatch) {
        if (payload === undefined) {
            return;
        }
        dispatch(installationAvailable(payload));
    };
}
