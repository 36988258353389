import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useCallback, useEffect, useState } from "react";

import {
  AiOutlineGlobal,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from "react-icons/ai";

import { FaTelegramPlane } from "react-icons/fa";

import { FiChevronRight } from "react-icons/fi";

import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";

import Colors from "../constants/Colors";

import { Link, useNavigate } from "react-router-dom";

import * as authActions from "../redux/actions/authActions";
import * as colorActions from "../redux/actions/colorsActions";
import * as apiCalls from "../redux/actions/apiCalls";

import PageView from "../components/Page";
import SettingsButton from "../components/SettingsButton";

import { toHumanReadableDateTime } from "../utils/timeservice";
import BottomNavBar from "../components/BottomNavBar";
import { releaseCandidateVersion } from "../constants/ReduxStates";
import AlertModal from "../components/AlertModal";

const isLoggedIn = (auth) => auth && auth.user && auth.user.role == "STANDARD";

const SettingsScreen = ({
  auth,
  language,
  deviceconfigs,
  colorscheme,
  authactions,
  coloractions,
  api,
}) => {
  const navigate = useNavigate();
  const [showpopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("");
  const [popupbody, setPopupBody] = useState("");
  const [popupbuttons, setPopupButtons] = useState([]);
  const styles = {
    mainframe: {
      backgroundColor: colorscheme.v2.background,
    },
    topheadercontainer: {
      padding: `${12}px ${deviceconfigs.layout.xxxxlargepadding}px`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: colorscheme.v2.background,
    },
    topheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    socialcontainer: {
      backgroundColor: colorscheme.v2.background,
      alignSelf: "center",
      margin: "4x 12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "8px",
      margin: "12px 2%",
    },
    socialitem: {
      marginHorizontal: "18px",
    },
    socialbutton: {
      backgroundColor: colorscheme.v2.background,
      borderRadius: 999,
      width: "10vw",
      height: "10vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    socialicon: {
      fontSize: "24px",
      color: colorscheme.v2.backgroundtext,
    },
    chevronrighticon: {
      fontSize: "18px",
      color: colorscheme.v2.backgroundtext,
    },
    menucontainer: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    menubuttoncontainertopradiusborder: {
      borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
      borderTopRightRadius: deviceconfigs.layout.xlargepadding,
      borderBottomStyle: "solid",
    },
    menubuttoncontainerbottomradiusborder: {
      borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
      borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
      borderBottomStyle: "solid",
    },
    menubuttoncontainerbottomborder: {
      borderBottomColor: colorscheme.v2.bordercolor,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
    },
    subheadercontainer: {
      margin: `${0}px ${deviceconfigs.layout.xxxxlargepadding}px`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    subheader: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    menuitemscontainer: {
      margin: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xxxlargepadding}px`,
    },
  };
  const { membership } = auth.user;
  const membershipType = membership
    ? language.membership(membership.type)
    : "N/A";
  const expirationdate =
    membership && toHumanReadableDateTime(membership.expirationdate);
  const isBasic = membership?.type == "TRIAL";
  const showErrorPopup = useCallback((title, body, buttons) => {
    setPopupTitle(title);
    setPopupBody(body);
    setPopupButtons(buttons);
    setShowPopup(true);
  }, []);
  return (
    <div style={styles.mainframe}>
      <div style={styles.topheadercontainer}>
        <div style={styles.topheader}>{language.settings}</div>
      </div>
      <div style={styles.socialcontainer}>
        <div style={styles.socialitem}>
          <div
            style={styles.socialbutton}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    setShowPopup(false);
                    window.location.href = "https://myperformers.app";
                  },
                },
              ]);
            }}
          >
            <AiOutlineGlobal style={styles.socialicon} />
          </div>
        </div>
        <div style={styles.socialitem}>
          <div
            style={styles.socialbutton}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    setShowPopup(false);
                    window.location.href =
                      "https://www.instagram.com/myperformers/";
                  },
                },
              ]);
            }}
          >
            <AiOutlineInstagram style={styles.socialicon} />
          </div>
        </div>
        <div style={styles.socialitem}>
          <div
            style={styles.socialbutton}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    setShowPopup(false);
                    window.location.href =
                      "https://www.youtube.com/channel/UCVY0Np3rkGgIS-pItU4xz2A";
                  },
                },
              ]);
            }}
          >
            <AiOutlineYoutube style={styles.socialicon} />
          </div>
        </div>
        <div style={styles.socialitem}>
          <div
            style={styles.socialbutton}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    setShowPopup(false);
                    window.location.href =
                      "https://twitter.com/myperformersapp";
                  },
                },
              ]);
            }}
          >
            <AiOutlineTwitter style={styles.socialicon} />
          </div>
        </div>
        <div style={styles.socialitem}>
          <div
            style={styles.socialbutton}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    setShowPopup(false);
                    window.location.href = "https://t.me/myperformers";
                    // window.location.href = "https://myperformers.app";
                  },
                },
              ]);
            }}
          >
            <FaTelegramPlane style={styles.socialicon} />
          </div>
        </div>
      </div>
      <div style={styles.menucontainer}>
        <div style={styles.subheadercontainer}>
          <div style={styles.subheader}>{language.subscriptiontype}</div>
        </div>
        <div style={styles.menuitemscontainer}>
          <div
            style={{
              backgroundColor: colorscheme.v2.background,
              borderRadius: deviceconfigs.layout.xlargepadding,
              padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px 0px ${deviceconfigs.layout.xlargepadding}px`,
              margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: deviceconfigs.font.xxlargetext,
                fontWeight: "600",
                color: colorscheme.v2.backgroundtext,
              }}
            >
              {membershipType}
            </div>
            {!isBasic ? <div
              style={{
                marginTop: deviceconfigs.layout.largepadding,
                fontSize: deviceconfigs.font.smalltext,
                fontWeight: "300",
                color: colorscheme.v2.backgroundtext,
              }}
            >
              {language.expiresat + " " + expirationdate}
            </div> : undefined}
            <div
              style={{
                display: "flex",
                backgroundColor: colorscheme.v2.primary,
                margin: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
                justifyContent: "center",
                alignItems: "center",
                padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.largepadding}px`,
                borderRadius: deviceconfigs.layout.xlargepadding,
                width: "64vw",
              }}
              onClick={() => {
                navigate("/memberships/select-membership");
              }}
            >
              <div
                style={{
                  fontSize: deviceconfigs.font.largetext,
                  fontWeight: "600",
                  color: colorscheme.v2.lighttext,
                }}
              >
                {language.clicktoupgrade}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.subheadercontainer}>
          <div style={styles.subheader}>{language.app}</div>
          <div
            style={{
              fontSize: deviceconfigs.font.mediumtext,
              fontWeight: "400",
              color: colorscheme.v2.backgroundtext,
              flex: 1,
              textAlign: "right",
            }}
          >
            {language.version + " " + releaseCandidateVersion}
          </div>
        </div>
        <div style={styles.menuitemscontainer}>
          <SettingsButton
            framestyle={{
              ...styles.menubuttoncontainerbottomborder,
            }}
            lefttext={language.mypayments}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              navigate("/my-payments");
            }}
          />
          <SettingsButton
            framestyle={{
              ...styles.menubuttoncontainerbottomborder,
              ...styles.menubuttoncontainertopradiusborder,
            }}
            lefttext={language.accountsettings}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              navigate("/settings/account-settings");
            }}
          />
          {membership.type === "EXCLUSIVE" ? (
            <SettingsButton
              framestyle={{
                ...styles.menubuttoncontainerbottomborder,
              }}
              lefttext={language.notificationsettings}
              righticon={<FiChevronRight style={styles.chevronrighticon} />}
              onClick={() => {
                navigate("/settings/notification-settings");
              }}
            />
          ) : null}
          <SettingsButton
            framestyle={{
              ...styles.menubuttoncontainerbottomborder,
            }}
            lefttext={language.switchto(colorscheme.v2.name)}
            righticon={
              colorscheme.v2.name == "light" ? (
                <BsFillMoonFill style={styles.chevronrighticon} />
              ) : (
                <BsFillSunFill style={styles.chevronrighticon} />
              )
            }
            onClick={async () => {
              var colormode = colorscheme.v2.name;
              if (colormode == "dark") {
                await coloractions.saveColorMode("light");
                let updateResults = await api.updateUserSettings({
                  viewmode: "LIGHT",
                });
              } else {
                await coloractions.saveColorMode("dark");
                let updateResults = await api.updateUserSettings({
                  viewmode: "DARK",
                });
              }
            }}
          />
          <SettingsButton
            framestyle={
              isLoggedIn(auth)
                ? {
                  ...styles.menubuttoncontainerbottomborder,
                }
                : {
                  ...styles.menubuttoncontainerbottomborder,
                  ...styles.menubuttoncontainertopradiusborder,
                }
            }
            lefttext={language.language}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              navigate("/settings/language-settings");
            }}
          />
          {isLoggedIn(auth) && (
            <SettingsButton
              framestyle={{
                borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
                borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
              }}
              lefttext={language.logout}
              righticon={<FiChevronRight style={styles.chevronrighticon} />}
              onClick={() => {
                authactions.logout();
                navigate("/");
              }}
            />
          )}
        </div>
        <div style={styles.subheadercontainer}>
          <div style={styles.subheader}>{language.abouttext}</div>
        </div>
        <div style={styles.menuitemscontainer}>
          <SettingsButton
            framestyle={{
              borderBottomColor: colorscheme.v2.bordercolor,
              borderBottomWidth: 1,
              borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
              borderTopRightRadius: deviceconfigs.layout.xlargepadding,
              borderBottomStyle: "solid",
            }}
            lefttext={language.termsandconditions}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              navigate("/settings/terms-and-conditions", {
                state: { link: "https://myperformers.app/terms-conditions" },
              });
            }}
          />
          <SettingsButton
            framestyle={{
              borderBottomColor: colorscheme.v2.bordercolor,
              borderBottomWidth: 1,
              borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
              borderTopRightRadius: deviceconfigs.layout.xlargepadding,
              borderBottomStyle: "solid",
            }}
            lefttext={language.privacypolicy}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              navigate("/settings/privacy-policy", {
                state: { link: "https://myperformers.app/privacy-policy" },
              });
            }}
          />
          <SettingsButton
            framestyle={{
              borderBottomColor: colorscheme.v2.bordercolor,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
            lefttext={language.howtoguides}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              navigate("/settings/privacy-policy", {
                state: {
                  link: `https://support.myperformers.app/${language._denom}/support/home`,
                },
              });
            }}
          />
          <SettingsButton
            framestyle={{
              borderBottomColor: colorscheme.v2.bordercolor,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
            lefttext={language.sharemyperformersapp}
            lefttextstyle={{
              color: colorscheme.v2.backgroundtext,
            }}
            onClick={() => {
              if (navigator.share) {
                navigator.share({
                  text: "My Performers App | Crypto Signals",
                  url: "https://myperformers.app",
                });
              } else {
                console.log("Cannot Share");
              }
            }}
          />
        </div>
        <div style={styles.subheadercontainer}>
          <div style={styles.subheader}>{language.support}</div>
        </div>
        <div style={{ ...styles.menuitemscontainer, marginBottom: 0 }}>
          <SettingsButton
            framestyle={{
              borderBottomColor: colorscheme.v2.bordercolor,
              borderBottomWidth: 1,
              borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
              borderTopRightRadius: deviceconfigs.layout.xlargepadding,
              borderBottomStyle: "solid",
            }}
            lefttext={language.contactsupport}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    window.location.href = "mailto:support@myperformers.app";
                  },
                },
              ]);
            }}
          />
          <SettingsButton
            framestyle={{
              borderBottomColor: colorscheme.v2.bordercolor,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
            lefttext={language.sendfeedback}
            righticon={<FiChevronRight style={styles.chevronrighticon} />}
            onClick={() => {
              showErrorPopup("Warning", "You are leaving the app", [
                {
                  text: "Cancel",
                  cancel: true,
                  onClick: () => {
                    setShowPopup(false);
                  },
                },
                {
                  text: "Go to site",
                  cancel: false,
                  onClick: () => {
                    window.location.href = "mailto:support@myperformers.app";
                  },
                },
              ]);
            }}
          />
        </div>
      </div>
      <div
        style={{ height: "84px", backgroundColor: colorscheme.v2.background }}
      ></div>
      <AlertModal
        open={showpopup}
        title={popuptitle}
        body={popupbody}
        onBackdropClick={() => {
          setShowPopup(false);
          setPopupTitle("");
          setPopupBody("");
        }}
        buttons={popupbuttons}
      />
      <BottomNavBar />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    authactions: bindActionCreators(authActions, dispatch),
    coloractions: bindActionCreators(colorActions, dispatch),
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
