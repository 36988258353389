import { connect } from "react-redux";

const Checkbox = ({
  deviceconfigs,
  colorscheme,
  checked,
  onChange,
  maincontainer,
  innercontainer,
  disabled,
}) => {
  const styles = {
    maincontainer: {
      border: `1px solid ${colorscheme.v2.primary}`,
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "30px",
      height: "30px",
    },
    innercontainer: {
      width: "24px",
      height: "24px",
      borderRadius: "4px",
      backgroundColor: checked
        ? colorscheme.v2.primary
        : colorscheme.v2.background,
    },
    innercontainerdisabled: {
      width: "24px",
      height: "24px",
      borderRadius: "4px",
      backgroundColor: checked
        ? colorscheme.v2.unselected
        : colorscheme.v2.background,
    },
  };
  return (
    <div
      style={{
        ...styles.maincontainer,
        ...maincontainer,
      }}
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
    >
      <div
        style={
          disabled
            ? {
                ...styles.innercontainerdisabled,
                ...innercontainer,
              }
            : {
                ...styles.innercontainer,
                ...innercontainer,
              }
        }
      ></div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
