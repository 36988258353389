import { connect } from "react-redux";

const SizeableButton = ({
  colorscheme,
  size,
  text,
  textstyle = {},
  disabled,
  onClick,
  icon,
  iconContainerStyle,
  mainContainerStyle,
}) => {
  const styles = {
    maincontainer: {
      padding: "8px 18px",
      borderRadius: "12px",
      backgroundColor: disabled
        ? colorscheme.v2.heavytext
        : colorscheme.v2.primary,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px auto",
      width: "62vw",
    },
    textstyle: {
      color: colorscheme.v2.lighttext,
      fontSize: "18px",
      fontWeight: "500",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <button
      disabled={disabled}
      style={{
        ...styles.maincontainer,
        ...mainContainerStyle,
      }}
      onClick={() => {
        onClick();
      }}
    >
      {icon ? (
        <div
          style={{
            ...styles.iconContainer,
            ...iconContainerStyle,
          }}
        >
          {icon}
        </div>
      ) : null}
      <div
        style={{
          ...styles.textstyle,
          ...textstyle,
        }}
      >
        {text}
      </div>
    </button>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    colorscheme: state.colorscheme,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SizeableButton);
