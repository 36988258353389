import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

const FilterButton = ({ deviceconfigs, colorscheme, language, text, selected, onClick }) => {
    const styles = {
        mainframe: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: selected ? colorscheme.v2.primary : colorscheme.v2.inactiveactionbutton,
            width: "30vw",
            margin: `0px ${deviceconfigs.layout.mediumpadding}px`,
            padding: deviceconfigs.layout.largepadding,
            borderRadius: deviceconfigs.layout.xlargepadding
        },
        text: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "400",
            color: selected ? colorscheme.v2.primarydarktext : colorscheme.v2.primary,
        }
    };

    return (
        <div style={styles.mainframe} onClick={onClick}>
            <div style={styles.text}>{text}</div>
        </div>
    );
}

FilterButton.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);