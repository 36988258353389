import React, { useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from '../components/Page';
import PageHeader from '../components/PageHeader';
import SettingsButton from '../components/SettingsButton';
import ActionButton from '../components/ActionButton';

import { Switch } from 'antd';

import * as apiCalls from '../redux/actions/apiCalls';

const NotificationSettingsScreen = ({ auth, deviceconfigs, language, colorscheme, api, settingsactions }) => {
    const [settingstobechanged, setSettingsToBeChanged] = useState({
        ...auth.user.settings.appsettings,
        ...auth.user.settings.notificationsettings,
        ...auth.user.settings.preferences,
        ...auth.user.settings.securitysettings,
    });
    const [loading, setLoading] = useState(false);

    const updateSettings = () => {
        this.setState({
            loading: true
        }, async () => {
            try {
                let t = await this._sleep(1800);
                let result = await api.updateUserSettings(settingstobechanged);
                let x = await api.getMirror();
                console.log(settingstobechanged);
                this.setState({
                    loading: false,
                })
            } catch (e) {
                console.error(e);
                this.setState({
                    loading: false,
                })
            }
        })

    }
    const styles = {
        mainframe: {
            flex: 1,
        },
        headercontainer: {
            marginBottom: deviceconfigs.layout.largepadding,
        },
        scrollcontainer: {
            flex: 1,
        },
        scroll: {

        },
        menuitemscontainer: {
            margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxxlargepadding}px`,
        },
        menubuttoncontainertopradiusborder: {
            borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
            borderTopRightRadius: deviceconfigs.layout.xlargepadding,
        },
        menubuttoncontainerbottomradiusborder: {
            borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
            borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
        },
        menubuttoncontainerbottomborder: {
            borderBottomColor: colorscheme.v2.bordercolor,
            borderBottomWidth: 1,
        },
        menucontainer: {
            flex: 1,
        },
        subheadercontainer: {
            margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxxxlargepadding}px`,
            display: "flex",
            flexDirection: "column",
            flexDirection: "row",
            alignItems: "center",
        },
        subheader: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "600",
            color: colorscheme.v2.backgroundtext,
        },
    };
    console.log(settingstobechanged);
    return (
        <PageView style={styles.mainframe} loading={loading}>
            <div style={styles.headercontainer}>
                <PageHeader
                    header={language.notificationsettings}
                    onBackPressed={() => {
                        window.history.back();
                    }}
                />
            </div>
            <div style={styles.scrollcontainer}>
                <div style={styles.menucontainer}>
                    <div style={styles.subheadercontainer}>
                        <div style={styles.subheader}>{language.order}</div>
                    </div>
                    <div style={styles.menuitemscontainer}>
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder,
                                ...styles.menubuttoncontainertopradiusborder
                            }}
                            lefttext={language.orderactivated}
                            righticon={
                                <Switch
                                    checked={settingstobechanged.orderactivated}
                                    onChange={(change) => {
                                        setSettingsToBeChanged({
                                            ...settingstobechanged,
                                            orderactivated: change
                                        })
                                    }}

                                />
                            }
                            onClick={() => {

                            }}
                        />
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder,
                                ...styles.menubuttoncontainertopradiusborder
                            }}
                            lefttext={language.ordercompleted}
                            righticon={
                                <Switch
                                    checked={settingstobechanged.ordercompleted}
                                    onChange={(change) => {
                                        setSettingsToBeChanged({
                                            ...settingstobechanged,
                                            ordercompleted: change
                                        })
                                    }}

                                />
                            }
                            onClick={() => {

                            }}
                        />
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder,
                            }}
                            lefttext={language.targethit}
                            righticon={
                                <Switch
                                    checked={settingstobechanged.targethit}
                                    onChange={(change) => {
                                        setSettingsToBeChanged({
                                            ...settingstobechanged,
                                            targethit: change
                                        })
                                    }}

                                />
                            }
                            onClick={() => {

                            }}
                        />
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder,
                            }}
                            lefttext={language.stoploss}
                            righticon={
                                <Switch
                                    checked={settingstobechanged.stoploss}
                                    onChange={(change) => {
                                        setSettingsToBeChanged({
                                            ...settingstobechanged,
                                            stoploss: change
                                        });
                                    }}

                                />
                            }
                            onClick={() => {

                            }}
                        />
                    </div>
                    <div style={styles.subheadercontainer}>
                        <div style={styles.subheader}>{language.signal}</div>
                    </div>
                    <div style={styles.menuitemscontainer}>
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder,
                                ...styles.menubuttoncontainertopradiusborder
                            }}
                            lefttext={language.newsignal}
                            righticon={
                                <Switch
                                    checked={settingstobechanged.newsignal}
                                    onChange={(change) => {
                                        setSettingsToBeChanged({
                                            ...settingstobechanged,
                                            newsignal: change
                                        });
                                    }}

                                />
                            }
                            onClick={() => {

                            }}
                        />
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder,
                            }}
                            lefttext={language.signalexpiration}
                            righticon={
                                <Switch
                                    checked={settingstobechanged.signalexpiration}
                                    onChange={(change) => {
                                        setSettingsToBeChanged({
                                            ...settingstobechanged,
                                            signalexpiration: change
                                        });
                                    }}

                                />
                            }
                            onClick={() => {

                            }}
                        />
                    </div>
                    <div style={styles.menuitemscontainer}>
                        <ActionButton
                            text={language.save}
                            onClick={() => {
                                this.updateSettings();
                            }}
                            buttonstyle={{
                                margin: "auto",
                            }}
                        />
                    </div>
                </div>
            </div>
        </PageView>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        iconcache: state.iconcache,
        colorscheme: state.colorscheme,
        balances: state.balances,
        xactionssignals: state.xactionssignals,
        language: state.language,
        loading: state.loading,
        huobisymbols: state.huobisymbols,
        filters: state.filters,
        myexchanges: state.myexchanges,
        membership: state.membership,
        cmcidmap: state.cmcidmap,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsScreen);