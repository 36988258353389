import { NEWS } from "../../constants/ReduxStates";
import * as api from "./apiCalls";
import { getNewsOn } from "../../services/firebase";

let timer = undefined;

export function newsSuccess(payload) {
  return {
    type: NEWS.NEWS_LOAD_SUCCEESS,
    payload,
  };
}

export function newsFail() {
  return {
    type: NEWS.NEWS_LOAD_FAIL,
  };
}

export function startNewsUpdateService() {
  return async function (dispatch) {
    getNewsOn((snapshot) => {
      const data = snapshot.val();
      const newsdata = [
        data["topNews"],
        data["bitcoinnews"],
        data["altcoinnews"],
        data["nftnews"],
      ];
      dispatch(newsSuccess(newsdata));
    });
  };
}
