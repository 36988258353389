import { EXCHANGES } from "../../constants/ReduxStates";
import * as api from "../actions/apiCalls";
import axios from 'axios';

let processing = false;

export function loadMyExchangesSuccess(payload) {
  return {
    type: EXCHANGES.EXCHANGES_LOAD_SUCCESS,
    payload,
    timestamp: Date.now(),
  };
}

export function loadMyExchangesFailed() {
  return {
    type: EXCHANGES.EXCHANGES_LOAD_FAILED,
    timestamp: Date.now(),
  };
}

export function checkExchanges() {
  return async function (dispatch, getState) {
    if (processing === true) {
      return;
    }
    processing = true;
    try {
      let exchangeDataResult = await api.getMyExchangeAccounts()(
        dispatch,
        getState
      );
      const myexchangesdata = exchangeDataResult.data.object.content || [];
      const prices = await axios.get(
        `https://api.binance.com/api/v3/ticker/price`, {
        headers: [],
      }
      );
      var binancePriceList = prices.data.reduce(function (map, obj) {
        map[obj.symbol] = obj.price;
        return map;
      }, {});
      let sum = 0;
      let btcSum = 0;
      let btcPriceINUSDT = 0;
      if (
        binancePriceList === undefined ||
        binancePriceList === null ||
        binancePriceList.length === 0
      ) {
        return;
      }
      console.log({ binancePriceList });
      btcPriceINUSDT = parseFloat(binancePriceList["BTCUSDT"]);
      try {
        for (var i = 0; i < myexchangesdata.length; i++) {
          myexchangesdata[i].esum = 0;
          if (myexchangesdata[i].type == "BINANCE") {
            try {
              var res = await api.getBinanceBalances()(dispatch, getState);
              let eSum = 0;
              const uniqueassets = res.data.object.filter(
                (asset, index, self) =>
                  index === self.findIndex((t) => t.asset === asset.asset)
              );


              for (var l = 0; l < uniqueassets.length; l++) {

                let priceInUSDT;
                let priceInBTC;
                let priceInBUSD;
                let priceInUSDTStr = binancePriceList[uniqueassets[l].asset + "USDT"];
                let priceInBTCStr, priceInBUSDStr;
                if (!priceInUSDTStr) {
                  priceInBTCStr = binancePriceList[uniqueassets[l].asset + "BTC"];
                  if (!priceInBTCStr) {
                    priceInBUSDStr = binancePriceList[uniqueassets[l].asset + "BUSDT"];
                    if (!priceInBTCStr) {

                    } else {
                      priceInBUSD = parseFloat(priceInBUSDStr);
                    }
                  } else {
                    priceInBTC = parseFloat(priceInBTCStr);
                  }
                } else {
                  priceInUSDT = parseFloat(priceInUSDTStr);
                }
                if (priceInUSDT) {
                  uniqueassets[l].inusdt =
                    parseFloat(priceInUSDT) *
                    (parseFloat(uniqueassets[l].free) +
                      parseFloat(uniqueassets[l].locked));
                } else {
                  if (
                    uniqueassets[l].asset === "USDT" ||
                    uniqueassets[l].asset === "BUSD"
                  ) {

                    uniqueassets[l].inusdt =
                      parseFloat(uniqueassets[l].free) +
                      parseFloat(uniqueassets[l].locked);
                  } else if (uniqueassets[l].asset == "BTC") {
                    uniqueassets[l].inusdt =
                      (parseFloat(uniqueassets[l].free) +
                        parseFloat(uniqueassets[l].locked)) /
                      parseFloat(btcPriceINUSDT);
                  } else if (priceInBTC > 0) {
                    uniqueassets[l].inusdt = priceInBTC / btcPriceINUSDT;
                  } else if (priceInBUSD > 0) {
                    uniqueassets[l].inusdt =
                      priceInBUSD *
                      (parseFloat(uniqueassets[l].free) +
                        parseFloat(uniqueassets[l].locked));
                  }
                }
              }
              console.log("Prea", uniqueassets);
              for (var t = 0; t < uniqueassets.length; t++) {
                if (
                  uniqueassets[t].inusdt != null &&
                  uniqueassets[t].inusdt != undefined
                ) {
                  sum += parseFloat(uniqueassets[t].inusdt);
                  eSum += parseFloat(uniqueassets[t].inusdt);
                }
              }
              const balances = uniqueassets;
              myexchangesdata[i].balances = balances || [];

              myexchangesdata[i].inusdt = eSum;
              myexchangesdata[i].inbtc = eSum / parseFloat(btcPriceINUSDT);
              btcSum += myexchangesdata[i].inbtc;
            } catch (e) {
              console.log("Cannot connec to API for Binance Balances", e);
            }
          } else if (myexchangesdata[i].type == "HUOBI") {
            // try {
            //   var res = await api.getHuobiBalances()(dispatch, getState);
            //   let eSum = 0;
            //   const uniqueassets = res.data.object.filter(
            //     (asset, index, self) =>
            //       index === self.findIndex((t) => t.asset === asset.asset)
            //   );
            //   for (var l = 0; l < uniqueassets.length; l++) {
            //     let priceInUSDT = 0;
            //     let priceInBTC = 0;
            //     for (var b = 0; b < huobiPriceList.length; b++) {
            //       if (
            //         huobiPriceList[b].symbol.toUpperCase() ==
            //         uniqueassets[l].asset.toUpperCase() + "USDT"
            //       ) {
            //         priceInUSDT += huobiPriceList[b].bid;
            //       } else if (
            //         huobiPriceList[b].symbol.toUpperCase() ==
            //         uniqueassets[l].asset.toUpperCase() + "BTC"
            //       ) {
            //         priceInBTC += huobiPriceList[b].bid;
            //       }
            //     }
            //     if (priceInUSDT) {
            //       uniqueassets[l].inusdt =
            //         parseFloat(priceInUSDT) *
            //         (parseFloat(uniqueassets[l].free) +
            //           parseFloat(uniqueassets[l].locked));
            //     } else {
            //       if (uniqueassets[l].asset.toUpperCase() === "USDT") {
            //         uniqueassets[l].inusdt =
            //           parseFloat(uniqueassets[l].free) +
            //           parseFloat(uniqueassets[l].locked);
            //       } else if (uniqueassets[l].asset.toUpperCase() == "BTC") {
            //         uniqueassets[l].inusdt =
            //           (parseFloat(uniqueassets[l].free) +
            //             parseFloat(uniqueassets[l].locked)) /
            //           parseFloat(btcPriceINUSDT);
            //       } else if (priceInBTC > 0) {
            //         uniqueassets[l].inusdt = priceInBTC / btcPriceINUSDT;
            //       }
            //     }
            //   }
            //   for (var t = 0; t < uniqueassets.length; t++) {
            //     if (
            //       uniqueassets[t].inusdt != null &&
            //       uniqueassets[t].inusdt != undefined
            //     ) {
            //       sum += parseFloat(uniqueassets[t].inusdt);
            //       eSum += parseFloat(uniqueassets[t].inusdt);
            //     }
            //   }
            //   const balances = uniqueassets;
            //   myexchangesdata[i].balances = balances || [];
            //   // for (var b = 0; b < myexchangesdata[i].balances.length; b++) {
            //   //     try {
            //   //         myexchangesdata[i].balances[b].logo = await checkCacheIcon(myexchangesdata[i].balances[b].asset.toUpperCase());
            //   //     } catch (e) {
            //   //         console.log("Cannot get cache icon");
            //   //     }
            //   // }
            //   myexchangesdata[i].inusdt = eSum;
            //   myexchangesdata[i].inbtc = eSum / parseFloat(btcPriceINUSDT);
            //   btcSum += myexchangesdata[i].inbtc;
            // } catch (e) {
            //   console.log("Cannot connec to API for Huobi Balances");
            // }
          }
        }
        const myExchangesData = {
          data: myexchangesdata,
          inusdt: sum,
          inbtc: btcSum,
        };
        processing = false;
        // console.log("MyExchangesBu", myExchangesData);
        dispatch(loadMyExchangesSuccess(myExchangesData));
      } catch (e) {
        console.log("Some error during exchange", e);
      }
    } catch (e) {
      console.error(e);
    }
  };
}
