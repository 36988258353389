import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Helmet } from "react-helmet";

import PageHeader from "../components/PageHeader";
import ActionButton from "../components/ActionButton";

import { IoSwapVertical } from "react-icons/io5";

import TradingViewWidget from "react-tradingview-widget";

import { useLocation, useNavigate } from "react-router-dom";

import * as apiCalls from "../redux/actions/apiCalls";
import * as binanceTickerActions from "../redux/actions/binanceTIckerWebSocketActions";

const POPULAREXCHANGES = [
  {
    id: "1",
    text: "Binance",
    type: "BINANCE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
    createaccountlink: "https://accounts.binance.com/en/register",
    selected: false,
    availablemethods: {
      qrcode: true,
      manual: true,
      noaccount: true,
    },
  },
  {
    id: "3",
    text: "Huobi Global",
    type: "HUOBI",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
    createaccountlink: "https://www.huobi.com/en-us/register/",
    selected: false,
    availablemethods: {
      qrcode: false,
      manual: true,
      noaccount: true,
    },
  },
];

const SignalDetailsScreen = ({
  deviceconfigs,
  language,
  colorscheme,
  api,
  binancetickers,
  binancetickeractions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const mState = location.state;
  const signal = mState.signal;

  const symbolData = binancetickers && binancetickers[signal.name];
  const currentprice = symbolData?.currentprice;
  const percent = symbolData?.percent;
  const margin = symbolData?.margin;

  useEffect(() => {
    binancetickeractions.subscribeToSymbol(signal.basecoin + signal.quotecoin);
    return () => {
      // Anything in here is fired on component unmount.
      binancetickeractions.unsubscribeToSymbol(
        signal.basecoin + signal.quotecoin
      );
    };
  }, []);

  const calculatePossibleProfit = () => {
    const { purchasingprice, targets } = signal;
    if (targets != null && targets != undefined && targets.length > 0) {
      let totalprofit = 0;
      for (var i = 0; i < targets.length; i++) {
        if (targets[i].value && targets[i].share && purchasingprice) {
          let yVal = parseFloat(purchasingprice);
          let xVal = parseFloat(targets[i].value);
          let roi = (xVal - yVal) / yVal;
          totalprofit += roi * targets[i].share;
        }
      }
      return totalprofit.toFixed(2);
    }
    return "N/A";
  };

  const calculatePossibleLoss = () => {
    const { purchasingprice, stoploss } = signal;
    if (purchasingprice != undefined && stoploss != undefined) {
      let yVal = parseFloat(purchasingprice);
      let xVal = parseFloat(stoploss);
      let roi = (yVal - xVal) / yVal;
      return (roi * 100).toFixed(2);
    }
    return "N/A";
  };
  if (!signal) {
    window.history.back();
    return <div></div>;
  }
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    marketwatchcontainer: {
      padding: deviceconfigs.layout.largepadding,
    },
    currentpriceandpercentcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pointcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    currentpricecontainer: {
      flex: 1,
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    percentcontainer: {
      width: "24vw",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor:
        margin < 0 ? colorscheme.v2.error : colorscheme.v2.success,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    currentprice: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    percent: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      color: colorscheme.v2.primarytext,
    },
    margin: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    pointiconcontainer: {},
    chartanddetailscontainer: {},
    scrollcontainer: {
      flex: 1,
      paddingTop: "150px",
    },
    chartcontainer: {
      backgroundColor: colorscheme.v2.background,
    },
    detailscontainer: {
      flex: 1,
    },
    detailssubheadercontainer: {
      display: "flex",
      flexDirection: "row",
      padding: deviceconfigs.layout.xlargepadding,
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    infocontainer: {
      padding: deviceconfigs.layout.xxlargepadding,
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      borderTopColor: colorscheme.v2.bordercolor,
      borderTopWidth: 1,
    },
    infoleftitem: {
      flex: 1,
    },
    infoseperator: {
      width: 1,
      margin: `${0}px ${deviceconfigs.layout.xlargepadding}px`,
      backgroundColor: colorscheme.v2.bordercolor,
    },
    inforightitem: {
      flex: 1,
    },
    signalsubheader: {
      flex: 1,
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "400",
      color: colorscheme.v2.unselected,
    },
    infoitemcontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    infoitemline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.mediumpadding}px 0px`,
    },
    infoitemtitle: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    infoitemvalue: {
      flex: 1,
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    infoitemvaluetwo: {
      marginLeft: "12px",
      alignSelf: "flex-end",
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    actionbuttonscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    actionbutton: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colorscheme.v2.primarybackground,
      padding: deviceconfigs.layout.xxlargepadding,
      borderRadius: deviceconfigs.layout.xlargepadding,
      margin: `${0}px ${deviceconfigs.layout.xxlargepadding}px`,
      width: "42vw",
    },
    actionbuttontext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "300",
      color: colorscheme.v2.primary,
    },
  };
  const signalExchange = (POPULAREXCHANGES || []).filter((a) => {
    return a.type === signal.exchange;
  })[0];
  const signalTargets = signal.targets || [];

  const SignalInfoItem = (props) => {
    const { title, value, valuetwo } = props;
    return (
      <div style={styles.infoitemcontainer}>
        <div style={styles.infoitemline}>
          <div style={styles.infoitemtitle}>
            {title}
            {valuetwo ? (
              <span style={styles.infoitemvaluetwo}>{`(${valuetwo})`}</span>
            ) : null}
          </div>
        </div>
        <div style={styles.infoitemline}>
          <div style={styles.infoitemvalue}>{value}</div>
        </div>
      </div>
    );
  };

  const { state } = signal;
  const isExpired = state === "EXPIRED";
  const signalTargetsMap = signalTargets
    .sort((a, b) => {
      return a.targetorder - b.targetorder;
    })
    .map((target, index) => {
      return (
        <div key={index}>
          <SignalInfoItem
            title={language.target + " # " + (index + 1)}
            value={target.value}
            valuetwo={target.share + "%"}
          />
        </div>
      );
    });
  return (
    <div style={styles.mainframe}>
      <Helmet>
        <meta
          name="description"
          content={`${signal.basecoin + signal.quotecoin}`}
        />
        <meta name="og:title" content={signal.basecoin + signal.quotecoin} />
        <meta
          name="og:description"
          content={signal.basecoin + signal.quotecoin}
        />
        <meta property="og:url" content="https://app.dev.myperformers.app" />
        <meta
          name="og:image"
          content="https://storage.googleapis.com/cryptoperformer-myperformersapp/logo-05.png"
        />
      </Helmet>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          top: "0px",
          zIndex: 100,
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            onBackPressed={() => {
              window.history.back();
            }}
            lefticon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                }}
              >
                <div style={{}}>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: colorscheme.v2.backgroundtext,
                    }}
                  >
                    {signal.basecoin + signal.quotecoin}
                  </div>
                </div>
              </div>
            }
            righticon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                  backgroundColor: colorscheme.v2.cardbackground,
                  padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.largepadding}px`,
                }}
              >
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <img
                    src={signalExchange.icon}
                    style={{
                      width: "18px",
                      height: "18px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: colorscheme.v2.backgroundtext,
                    }}
                  >
                    {signalExchange.text}
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div style={styles.marketwatchcontainer}>
          <div style={styles.currentpriceandpercentcontainer}>
            <div style={styles.currentpricecontainer}>
              <div style={styles.currentprice}>{"$ " + currentprice}</div>
            </div>
            <div style={styles.percentcontainer}>
              <div style={styles.percent}>{percent + "%"}</div>
            </div>
          </div>
          <div style={styles.pointcontainer}>
            <div style={styles.pointiconcontainer}>
              <IoSwapVertical
                style={{
                  fontSize: deviceconfigs.icon.mediumicon,
                  color: colorscheme.v2.unselected,
                }}
              />
            </div>
            <div style={styles.margin}>{margin}</div>
          </div>
        </div>
      </div>
      <div style={styles.scrollcontainer}>
        <div style={styles.chartcontainer}>
          <TradingViewWidget
            symbol={signal.basecoin + signal.quotecoin}
            width={"92vw"}
            height={"300px"}
            theme={colorscheme.v2.name}
            allow_symbol_change={false}
          />
        </div>
        <div style={styles.detailscontainer}>
          <div style={styles.detailssubheadercontainer}>
            <div style={styles.signalsubheader}>{language.signaldetails}</div>
          </div>
          <div style={styles.infocontainer}>
            <div style={styles.infoleftitem}>
              <SignalInfoItem
                title={language.purchasingprice}
                value={signal.purchasingprice}
              />
              <SignalInfoItem
                title={language.stoploss}
                value={signal.stoploss}
              />
              <SignalInfoItem
                title={language.targetedprofit}
                value={calculatePossibleProfit() + "%"}
              />
              <SignalInfoItem
                title={language.riskofinvestment}
                value={calculatePossibleLoss() + "%"}
              />
            </div>
            <div style={styles.infoseperator}></div>
            <div style={styles.inforightitem}>{signalTargetsMap}</div>
          </div>
        </div>
        {!isExpired && (
          <div style={styles.actionbuttonscontainer}>
            <ActionButton
              text={language.automatic}
              onClick={() => {
                navigate("/signals/signal-details/automatic", {
                  state: { signal },
                });
              }}
            />
          </div>
        )}
        <div style={{ height: "12vh" }}></div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    binancetickeractions: bindActionCreators(binanceTickerActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    binancetickers: state.binancetickers,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignalDetailsScreen);
