import * as Constants from '../../constants/ReduxStates';

const initialAuthState = {
    login: undefined,
    password: undefined,
    isLoggedIn: false,
};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.AUTH.CHECK_LOGIN:
            return { ...action.payload };
        case Constants.AUTH.LOGIN_SUCCESS:
            return { ...action.payload };
        case Constants.AUTH.LOGOUT_SUCCESS:
            return { ...initialAuthState };
        case Constants.AUTH.REQUIRE_REGISTER:
            return { ...initialAuthState , je : "erdem"};
        default:
            return state;
    }
}
