import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  toHumanReadableDate,
  toHumanReadableTime,
} from "../../utils/timeservice";
import { addCommas } from "../../utils/stringutils";

import { AiOutlineClose } from "react-icons/ai";
import { ImEqualizer2 } from "react-icons/im";

import SmartFlowIcon from "../SmartFlowIcon";

const MyExchangeCard = ({
  deviceconfigs,
  colorscheme,
  language,
  icon,
  balancedisplay,
  balanceatstartinusdt,
  balanceInUSDT,
  balanceInBTC,
  creationdate,
  onClick,
  onDeletePressed,
  invalid,
  type,
  smartflows,
  supported,
}) => {
  const totalbalancehidden = balancedisplay.type == "BALANCEHIDE";
  const profitDiff =
    ((parseFloat(balanceInUSDT) - parseFloat(balanceatstartinusdt)) /
      parseFloat(balanceInUSDT)) *
    100;
  const profDifference = profitDiff.toFixed(4);
  const styles = {
    mainframe: {
      position: "relative",
      width: "92vw",
      backgroundColor: colorscheme.v2.cardbackground,
      borderRadius: deviceconfigs.layout.xxlargepadding,
      display: "flex",
      flexDirection: "row",
      padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    iconcontainer: {
      margin: `12px ${deviceconfigs.layout.xlargepadding}px`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    summarycontainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      flex: 1,
    },
    chevroncontainer: {},
    icon: {
      width: deviceconfigs.button.xlargesize,
      height: deviceconfigs.button.xlargesize,
      objectFit: "contain",
    },
    summaryitemcontainer: {
      margin: `${deviceconfigs.layout.mediumpadding}px 0px`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    summaryitemlefttext: {
      width: "32vw",
      fontSize: deviceconfigs.font.mediumtext,
      color: colorscheme.v2.backgroundtext,
    },
    summaryitemrighttext: {
      flex: 1,
      fontSize: deviceconfigs.font.mediumtext,
      color: colorscheme.v2.backgroundtext,
    },
    summaryitemrighttextup: {
      flex: 1,
      fontSize: deviceconfigs.font.mediumtext,
      color: totalbalancehidden
        ? colorscheme.v2.backgroundtext
        : colorscheme.v2.success,
    },
    summaryitemrighttextdown: {
      flex: 1,
      fontSize: deviceconfigs.font.mediumtext,
      color: totalbalancehidden
        ? colorscheme.v2.backgroundtext
        : colorscheme.v2.error,
    },
    deletecontainer: {
      position: "absolute",
      top: deviceconfigs.layout.largepadding,
      right: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.errorbackground,
      padding: deviceconfigs.layout.mediumpadding,
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
      borderRadius: 12,
      display: "flex",
      alignItems: "center",
    },
    detailviewcontainer: {
      backgroundColor: colorscheme.v2.primary,
      padding: deviceconfigs.layout.mediumpadding,
      marginLeft: "auto",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
    },
  };
  const smartFlowSupported =
    supported.filter((a) => {
      return a === "smartflow";
    }).length > 0;
  const signalsSupported =
    supported.filter((a) => {
      return a === "signals";
    }).length > 0;
  const smartFlowsExist = smartflows && smartflows.length > 0;
  return (
    <div style={styles.mainframe} onClick={onClick}>
      <div style={styles.iconcontainer}>
        <img src={icon} style={styles.icon} alt={"MyPerformers"} />
        {smartFlowSupported ? (
          <div
            style={{
              position: "relative",
              padding: deviceconfigs.layout.mediumpadding,
              borderRadius: "8px",
              border: "1px solid #929292",
              marginTop: deviceconfigs.layout.largepadding,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SmartFlowIcon
              style={{
                width: "18px",
                height: "18px",
              }}
            />
            {smartFlowsExist ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 2,
                  right: 2,
                  width: "4px",
                  height: "4px",
                  borderRadius: 999,
                  backgroundColor: colorscheme.v2.error,
                }}
              ></div>
            ) : null}
          </div>
        ) : null}
        {signalsSupported ? (
          <div
            style={{
              position: "relative",
              padding: deviceconfigs.layout.mediumpadding,
              borderRadius: "8px",
              border: "1px solid #929292",
              marginTop: deviceconfigs.layout.largepadding,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImEqualizer2
              style={{
                fontSize: "18px",
                color: colorscheme.v2.backgroundtext,
              }}
            />
          </div>
        ) : null}
      </div>
      <div style={styles.summarycontainer}>
        <div style={styles.summaryitemcontainer}>
          <div
            style={{
              ...styles.summaryitemlefttext,
              fontWeight: "600",
              color: colorscheme.v2.success,
            }}
          >
            {language.connectedat}
          </div>
          <div
            style={{
              ...styles.summaryitemrighttextup,
              fontWeight: "600",
              color: colorscheme.v2.success,
            }}
          >
            <div>{toHumanReadableDate(creationdate)}</div>
            <div>{toHumanReadableTime(creationdate)}</div>
          </div>
        </div>
        <div style={styles.summaryitemcontainer}>
          <div style={styles.summaryitemlefttext}>{language.profit}</div>
          <div
            style={
              profitDiff > 0
                ? styles.summaryitemrighttextup
                : styles.summaryitemrighttextdown
            }
          >
            {totalbalancehidden ? "********" : profDifference + " %"}
          </div>
        </div>
        <div style={styles.summaryitemcontainer}>
          <div style={styles.summaryitemlefttext}>{language.balanceinusdt}</div>
          <div style={styles.summaryitemrighttext}>
            {totalbalancehidden
              ? "********"
              : addCommas(parseFloat(balanceInUSDT).toFixed(2))}
          </div>
        </div>
        <div style={styles.summaryitemcontainer}>
          <div style={styles.summaryitemlefttext}>{language.balanceinbtc}</div>
          <div style={styles.summaryitemrighttext}>
            {totalbalancehidden
              ? "********"
              : addCommas(parseFloat(balanceInBTC).toFixed(8))}
          </div>
        </div>
        <div>
          {invalid ? (
            <div
              style={{
                fontSize: deviceconfigs.font.smalltext,
                color: colorscheme.v2.error,
              }}
            >
              {language.apikeyexpired}
            </div>
          ) : null}
        </div>
        <div style={styles.detailviewcontainer}>
          <div
            style={{
              fontSize: "11px",
              fontWeight: "500",
              padding: "2px 4px",
              color: colorscheme.v2.primarytext,
            }}
          >
            {language.viewdetails}
          </div>
        </div>
      </div>
      <div style={styles.deletecontainer} onClick={onDeletePressed}>
        <AiOutlineClose
          style={{
            color: colorscheme.v2.error,
            fontSize: "24px",
          }}
        />
      </div>
    </div>
  );
};

MyExchangeCard.propTypes = {
  icon: PropTypes.string,
  profit: PropTypes.number,
  balanceInBTC: PropTypes.number,
  balanceInUSDT: PropTypes.number,
  onDeletePressed: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
    balancedisplay: state.balancedisplay,
    smartflows: state.smartflows,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyExchangeCard);
