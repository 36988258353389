import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";

import LoadingModal from "../components/LoadingModal";
import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";

import * as apiCalls from "../redux/actions/apiCalls";

const NewsDetailsScreen = ({ colorscheme, deviceconfigs }) => {
  const location = useLocation();
  const myState = location.state;
  const link = myState.link;
  const sourcename = myState.sourcename;
  const [loading, setLoading] = useState(true);
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      display: "flex",
      flexDirection: "column",
    },
    headercontainer: {
      marginTop: deviceconfigs.layout.xlargepadding,
    },
    flatlistcontainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    flatlist: {},
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <PageView style={styles.mainframe}>
      <div style={styles.headercontainer}>
        <PageHeader
          header={sourcename}
          headerstyle={{ fontWeight: "600" }}
          onBackPressed={() => {
            window.history.back();
          }}
        />
      </div>
      <div style={styles.flatlistcontainer}>
        <iframe
          src={link}
          style={{
            outline: "none",
            border: "none",
            width: "100vw",
            flex: 1,
          }}
          title={"MyPerformers"}
          onLoad={() => {
            setLoading(false);
          }}
          onAbort={() => {
            setLoading(false);
          }}
        />
      </div>
      <LoadingModal loading={loading} />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    colorscheme: state.colorscheme,
    deviceconfigs: state.deviceconfigs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailsScreen);
