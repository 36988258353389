import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { toHumanReadableDateTime } from '../../utils/timeservice';

const NotificationItem = ({ deviceconfigs, colorscheme, language, title, image, readstate, content, creationdate, onClick, onLayout, id }) => {
    const styles = {
        mainContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colorscheme.v2.cardbackground,
            padding: deviceconfigs.layout.xxlargepadding,
            borderRadius: deviceconfigs.layout.xlargepadding,
            margin: `${deviceconfigs.layout.mediumpadding}px ${deviceconfigs.layout.xlargepadding}px`,
            position: "relative",
        },
        iconcontainer: {
        },
        titleandcontentcontainer: {
            flex: 5,
            margin: `0px ${deviceconfigs.layout.xxlargepadding}px`,

        },
        datecontainer: {
            flex: 2,
        },
        signalinfocontainer: {
            flex: 1,
        },
        pairscontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        signalstatuscontainer: {

        },
        signalstatusaccepted: {
            backgroundColor: colorscheme.v2.primarybackground,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: deviceconfigs.layout.xlargepadding,
            padding: `${deviceconfigs.layout.largepadding}px 0px`,
        },
        signalstatusopen: {

        },
        signalstatuscancelled: {

        },
        titletext: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        contenttext: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "300",
            color: colorscheme.v2.backgroundtext,
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
        },
        statusdatetext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "500",
            color: colorscheme.v2.backgroundtext,
        }
    };
    return (
        <div
            style={styles.mainContainer}
            onLayout={onLayout}
            onClick={onClick}>
            <div style={styles.iconcontainer}>
                <img
                    src={image}
                    style={{
                        width: deviceconfigs.button.largesize,
                        height: deviceconfigs.button.largesize,
                        borderRadius: deviceconfigs.layout.xlargepadding,
                        objectFit: "contain",
                    }}
                />
            </div>
            <div style={styles.titleandcontentcontainer}>
                <div style={styles.titletext}>{title}</div>
                <div style={styles.contenttext}>{content}</div>
                <div style={styles.statusdatetext}>{toHumanReadableDateTime(creationdate)}</div>
            </div>
            {readstate === "UNREAD" ? <div style={{
                position: "absolute",
                left: deviceconfigs.layout.xlargepadding,
                top: deviceconfigs.layout.mediumpadding,
                width: deviceconfigs.layout.xlargepadding,
                height: deviceconfigs.layout.xlargepadding,
                borderRadius: 999,
                backgroundColor: "red",
            }}></div> : null}
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);

