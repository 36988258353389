import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PropTypes from 'prop-types';

import {
    AiOutlineArrowLeft
} from 'react-icons/ai';

const PageHeader = ({ deviceconfigs, colorscheme, language, header, headerstyle, onBackPressed, righticon, righticonstyle, righticonOnPress, lefticon, lefticonStyle, lefticonOnClick }) => {
    const styles = {
        mainframe: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            backgroundColor: colorscheme.v2.background,
        },
        iconcontainer: {
            margin: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px`
        },
        icon: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            justifyContent: "center",
        },
        headertitlecontainer: {
            display: "flex",
            flexDirection: 'row',
            flex: 1,
        },
        header: {
            textAlign: "center",
            fontWeight: "700",
            fontSize: deviceconfigs.font.largetext,
            color: colorscheme.v2.backgroundtext,
        },
        lefticon: {
            marginLeft: deviceconfigs.layout.xlargepadding,
        },
        righticon: {
            marginRight: deviceconfigs.layout.largepadding,
        }
    };

    return (
        <div style={styles.mainframe}>
            <div style={styles.iconcontainer}>
                {onBackPressed && <div style={styles.icon} onClick={onBackPressed}>
                    <AiOutlineArrowLeft
                        style={{
                            fontSize: "24px",
                            color: colorscheme.v2.backgroundtext,
                        }}
                    />
                </div>}
            </div>
            {lefticon &&
                <div
                    style={{
                        ...styles.lefticon,
                        ...lefticonStyle
                    }}
                    onClick={lefticonOnClick}
                >
                    {lefticon}
                </div>
            }
            <div style={styles.headertitlecontainer}>
                <div style={{
                    ...styles.header,
                    ...headerstyle
                }}>
                    {header}
                </div>
            </div>
            {righticon &&
                <div
                    style={{
                        ...styles.righticon,
                        ...righticonstyle
                    }}
                    onClick={righticonOnPress}
                >
                    {righticon}
                </div>
            }
        </div>
    );
}

PageHeader.propTypes = {
    header: PropTypes.string,
    headerstyle: PropTypes.object,
    onBackPressed: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);