import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { toHumanReadableDate, toHumanReadableTime, toHumanReadableDateTime } from "../../utils/timeservice";

const POPULAREXCHANGES = [
    {
        id: "1",
        text: "Binance",
        type: "BINANCE",
        icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
        selected: false,
    },
    {
        id: "2",
        text: "Coinbase 2.0",
        type: "COINBASE",
        icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/89.png",
        selected: false,
    },
    {
        id: "3",
        text: "Huobi Global",
        type: "HUOBI",
        icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
        selected: false,
    },
    {
        id: "4",
        text: "KuCoin",
        type: "KUCOIN",
        icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png",
        selected: false,
    },
    {
        id: "5",
        text: "Bittrex",
        type: "BITTREX",
        icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/22.png",
        selected: false,
    },
];

const SignalSummary = ({ deviceconfigs, colorscheme, language, id, icon, basecoin, quotecoin, exchange, timestamp, date, status, invested, onClick }) => {
    const styles = {
        mainframe: {
            display: "flex",
            flexDirection: "row",
            padding: deviceconfigs.layout.xlargepadding,
            borderRadius: deviceconfigs.layout.xlargepadding,
            backgroundColor: colorscheme.v2.cardbackground,
            margin: `auto`,
            width: "92vw",
        },
        iconcontainer: {
            marginHorizontal: deviceconfigs.layout.xlargepadding,
        },
        signalinfocontainer: {
            flex: 1,
            margin : "0px 12px",
        },
        pairscontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        signalstatuscontainer: {

        },
        signalstatusaccepted: {
            width: "24vw",
            paddingVertical: deviceconfigs.layout.xlargepadding,
            backgroundColor: status.color,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: deviceconfigs.layout.xlargepadding,
        },
        signalstatusopen: {

        },
        signalstatuscancelled: {

        },
        basecointext: {
            fontSize: deviceconfigs.font.xxlargetext,
            fontWeight: "300",
            color: colorscheme.v2.backgroundtext,
        },
        quotecointext: {
            fontSize: deviceconfigs.font.xxlargetext,
            fontWeight: "300",
            color: colorscheme.v2.unselected,
        },
        icon: {
            width: deviceconfigs.button.xxlargesize,
            height: deviceconfigs.button.xxlargesize,
            borderRadius: deviceconfigs.layout.xlargepadding,
            objectFit: "contain",
        },
        exchangecontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginVertical: deviceconfigs.layout.largepadding,
        },
        exchangetext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
            marginLeft: deviceconfigs.layout.largepadding,
        },
        timestampcontainer: {
            marginVertical: deviceconfigs.layout.largepadding,
        },
        timestamptext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.heavytext,

        },
        statustext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: status.textcolor,
        }
    };
    const tExchange = POPULAREXCHANGES.filter((a) => {
        return a.type == exchange;
    })[0];
    return (
        <div style={styles.mainframe} onClick={onClick}>
            <div style={styles.iconcontainer}>
                <img
                    src={icon}
                    style={styles.icon}
                />
            </div>
            <div style={styles.signalinfocontainer}>
                <div style={styles.pairscontainer}>
                    <div style={styles.basecointext}>{basecoin}</div>
                    <div style={styles.quotecointext}>{"/" + quotecoin}</div>
                </div>
                <div style={styles.exchangecontainer}>
                    <img
                        src={tExchange.icon}
                        style={{
                            width: deviceconfigs.button.mediumsize,
                            height: deviceconfigs.button.mediumsize,
                            objectFit: "contain",
                        }}
                    />
                    <div style={styles.exchangetext}>{tExchange.text}</div>
                    {invested && <div style={{
                        fontSize: deviceconfigs.font.mediumtext,
                        fontWeight: "500",
                        color: colorscheme.v2.success,
                        marginLeft: deviceconfigs.layout.largepadding,
                    }}>{language.accepted}</div>}
                </div>
                <div style={styles.timestampcontainer}>
                    <div style={styles.timestamptext}>{toHumanReadableDateTime(timestamp)}</div>
                </div>
            </div>
            <div style={styles.signalstatuscontainer}>
                <div style={styles.signalstatusaccepted}>
                    <div style={styles.statustext}>{status.text}</div>
                    <div style={{
                        fontSize: deviceconfigs.font.smalltext,
                        fontWeight: "500",
                        color: colorscheme.v2.successtext,
                        textAlign: "center",
                        marginTop: deviceconfigs.layout.mediumpadding,
                    }}>
                        {toHumanReadableDate(date)}
                    </div>
                    <div style={{
                        fontSize: deviceconfigs.font.smalltext,
                        fontWeight: "500",
                        color: colorscheme.v2.successtext,
                        textAlign: "center"
                    }}>
                        {toHumanReadableTime(date)}
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignalSummary);