const POPULAREXCHANGES = [
  {
    id: "1",
    text: "Binance",
    type: "BINANCE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
    createaccountlink: "https://accounts.binance.com/en/register",
    supported: ["smartflow"],
    availablemethods: {
      qrcode: true,
      manual: true,
      noaccount: true,
    },
    selectable: true,
  },
  {
    id: "2",
    text: "Coinbase 2.0",
    type: "COINBASE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/89.png",
    supported: [],
    availablemethods: {
      qrcode: false,
      manual: true,
      noaccount: true,
    },
    selectable: false,
  },
  {
    id: "3",
    text: "Huobi Global",
    type: "HUOBI",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
    createaccountlink: "https://www.huobi.com/en-us/register/",
    supported: [],
    availablemethods: {
      qrcode: false,
      manual: true,
      noaccount: true,
    },
    selectable: false,
  },
  {
    id: "4",
    text: "KuCoin",
    type: "KUCOIN",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png",
    supported: [],
    availablemethods: {
      qrcode: false,
      manual: false,
      noaccount: false,
    },
    selectable: false,
  },
  {
    id: "5",
    text: "Bittrex",
    type: "BITTREX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/22.png",
    supported: [],
    availablemethods: {
      qrcode: false,
      manual: false,
      noaccount: false,
    },
    selectable: false,
  },
];

export default POPULAREXCHANGES;
