import * as Constants from "../../constants/ReduxStates";

const initialAuthState = [];

export default function reducer(state = initialAuthState, action) {
  switch (action.type) {
    case Constants.NEWS.NEWS_LOAD_SUCCEESS:
      return action.payload;
    case Constants.NEWS.NEWS_LOAD_FAIL:
      return undefined;
    default:
      return state;
  }
}
