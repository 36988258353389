import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PageHeader from "components/PageHeader";

const AutomaticDescriptionScreen = ({
    api,
    deviceconfigs,
    language,
    colorscheme,
}) => {
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
            padding: "8px",
            borderRadius: "18px",
        },
        headercontainer: {
            position: "fixed",
            top: "0px",
            width: "100vw",
            zIndex: 100,
            padding: `${deviceconfigs.layout.xxlargepadding}px 0px`,
            backgroundColor: colorscheme.v2.background,
        },
        header: {
            marginTop: "18px",
            fontSize: "17px",
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
            display: "flex",
            flexDirection: "row",
        },
        paragraph: {
            marginTop: "18px",
            fontSize: "13px",
            fontWeight: "400",
            color: colorscheme.v2.backgroundtext,
        }
    };
    return (
        <div style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <PageHeader
                    header={language.smartflow}
                    onBackPressed={() => {
                        window.history.back();
                    }}
                />
            </div>
            <div style={{
                flex: 1,
                padding: "64px 24px",
            }}>
                <div style={styles.textscontainer}>
                    <div style={styles.header}>
                        {language.automaticmode}
                    </div>
                    <div style={styles.paragraph}>
                        {language.automaticmodedesc.p1}
                    </div>
                    <div style={styles.header}>
                        {language.automaticmodedesc.p2}
                    </div>
                    <div style={styles.paragraph}>
                        {language.automaticmodedesc.p3}
                    </div>
                    <div style={styles.header}>
                        {language.automaticmodedesc.p4}
                    </div>
                    <div style={styles.paragraph}>
                        <ul>
                            <li>{language.automaticmodedesc.p5}</li>
                            <li>{language.automaticmodedesc.p6}</li>
                            <li>{language.automaticmodedesc.p7}</li>
                            <li>{language.automaticmodedesc.p8}</li>
                            <li>{language.automaticmodedesc.p9}</li>
                        </ul>
                    </div>
                    <div>{language.automaticmodedesc.p10}</div>
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        balancedisplay: state.balancedisplay,
        myexchanges: state.myexchanges,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        unreads: state.unreads,
        cmcidmap: state.cmcidmap,
        installation: state.installation,
        globalmetrics: state.globalmetrics,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutomaticDescriptionScreen);