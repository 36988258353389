import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import PageView from '../components/Page';
import PageHeader from "../components/PageHeader";

import {
    HiOutlineChevronRight
} from 'react-icons/hi';

const COINS = [
    {
        id: "1",
        name: "Tether",
        symbol: "USDT",
        external: "usdttrc20",
        icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    },
    {
        id: "2",
        name: "Tron",
        symbol: "TRX",
        external: "trx",
        icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
    },
];

const SelectPaymentCoinScreen = ({ deviceconfigs, language, colorscheme }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        headercontainer: {
        },
    }
    const myState = location.state;
    const membership = myState.membership;

    const coinsMap = (COINS || []).map((item) => {
        return (
            <div
                key={item.id}
                style={{
                    backgroundColor: colorscheme.v2.cardbackground,
                    margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
                    padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                    borderRadius: deviceconfigs.layout.xlargepadding,
                    borderColor: colorscheme.v2.primary,
                }}
                onClick={() => {
                    navigate("/memberships/payment-summary", { state: { membership, coin: item } });
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: deviceconfigs.layout.largepadding,
                    }}>
                        <img
                            src={item.icon}
                            style={{
                                width: deviceconfigs.button.xlargesize,
                                height: deviceconfigs.button.xlargesize,
                                borderRadius: 999,
                                objectFit: "contain",
                            }}
                        />
                    </div>
                    <div style={{
                        flex: 4,
                        marginLeft: deviceconfigs.layout.xlargepadding,
                    }}>
                        <div style={{
                            fontSize: deviceconfigs.font.xlargetext,
                            fontWeight: "700",
                            color: colorscheme.v2.backgroundtext,
                        }}>{item.name}</div>
                    </div>
                    <div style={{
                        padding: deviceconfigs.layout.largepadding,
                        borderRadius: deviceconfigs.layout.xlargepadding,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colorscheme.v2.background,
                    }}>
                        <div style={styles.chevroncontainer}>
                            <HiOutlineChevronRight
                                style={{
                                    fontSize: "28px",
                                    color: colorscheme.v2.backgroundtext,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <PageView style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <PageHeader
                    onBackPressed={() => {
                        window.history.back();
                    }}
                    header={language.selectpaymentcoin}
                />
            </div>
            <div>
                <div style={{
                }}>
                    {coinsMap}
                </div>
            </div>
        </PageView>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        myexchanges: state.myexchanges,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentCoinScreen);