import React from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";

const minorCircles = [1, 2, 3, 4, 5];

const AddExchangeStepCounter = ({ language, colorscheme, deviceconfigs, step }) => {

    const styles = {
        stepscontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: `${deviceconfigs.layout.xxxxlargepadding}px ${deviceconfigs.layout.xxlargepadding}px 0px ${deviceconfigs.layout.xxlargepadding}px`,
        },
        stepstextcontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
        },
        stepstext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "600",
            color: colorscheme.v2.lighttext,
        },
        majorstepoff: {
            width: deviceconfigs.layout.xlargepadding,
            height: deviceconfigs.layout.xlargepadding,
            backgroundColor: colorscheme.v2.unselected,
            borderRadius: 999,
            marginHorizontal: deviceconfigs.layout.largepadding,
        },
        majorstepon: {
            width: deviceconfigs.layout.xxlargepadding,
            height: deviceconfigs.layout.xxlargepadding,
            backgroundColor: colorscheme.v2.success,
            borderRadius: 999,
            marginHorizontal: deviceconfigs.layout.largepadding,
        },
        selectedminorstep: {
            marginHorizontal: deviceconfigs.layout.largepadding,
            width: deviceconfigs.layout.largepadding,
            height: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.success,
            borderRadius: 999,
        },
        unselectedminorstep: {
            marginHorizontal: deviceconfigs.layout.largepadding,
            width: deviceconfigs.layout.largepadding,
            height: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.unselected,
            borderRadius: 999,
        },
    };
    return (
        <div>
            <div style={styles.stepscontainer}>
                <div style={step >= 1 ? styles.majorstepon : styles.majorstepoff}></div>
                {(minorCircles || []).map((item, index) => {
                    return (
                        <div key={index} style={step > 1 ? styles.selectedminorstep : styles.unselectedminorstep}></div>
                    );
                })}
                <div style={step >= 2 ? styles.majorstepon : styles.majorstepoff}></div>
                {(minorCircles || []).map((item, index) => {
                    return (
                        <div key={index} style={step > 2 ? styles.selectedminorstep : styles.unselectedminorstep}></div>
                    );
                })}
                <div style={step == 3 ? styles.majorstepon : styles.majorstepoff}></div>
            </div>
            <div style={styles.stepstextcontainer}>
                <div style={styles.stepstext}>{language.step(1)}</div>
                <div style={styles.stepstext}>{language.step(2)}</div>
                <div style={styles.stepstext}>{language.step(3)}</div>
            </div>
        </div>
    );
}

AddExchangeStepCounter.propTypes = {
    step: PropTypes.number,
};

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeStepCounter);