import { connect } from "react-redux";

const PageView = ({ children, style, colorscheme }) => {
    const styles = {
        header: {
            height: "32px",
            backgroundColor: colorscheme.v2.primary,
            width: "100%",
        }
    }
    return (
        <div style={{
            backgroundColor : colorscheme.v2.background,
            minHeight : "100vh",
            display : "flex",
            flexDirection : "column",
        }}>
            <div style={styles.header}></div>
            <div style={style}>
                {children}
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageView);