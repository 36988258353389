import { AUTH } from '../../constants/ReduxStates';

export function loginSuccess(payload) {
    return {
        type: AUTH.LOGIN_SUCCESS,
        payload,
    };
}

export function logoutSuccess() {
    return {
        type: AUTH.LOGOUT_SUCCESS,
    };
}

export function requireRegister() {
    return {
        type: AUTH.REQUIRE_REGISTER,
    };
}

export function loginPersist(payload) {
    return function (dispatch) {
        if (payload == undefined) {
            return;
        }
        localStorage.setItem("user", JSON.stringify(payload));
        dispatch(loginSuccess(payload));
    };
}

export function logout() {
    return function (dispatch) {
        try {
            localStorage.clear();
            dispatch(logoutSuccess());
        } catch (error) {
            throw error;
        }
    };
}

export function checkLoginState() {
    return function (dispatch) {
        console.log("Checking Login State");
        try {
            let result = localStorage.getItem('user');
            if (result) {
                let user = JSON.parse(result);
                dispatch(loginSuccess(user));
            } else {
                dispatch(requireRegister());
            }
        } catch (error) {
            dispatch(requireRegister());
        }
    };
}

