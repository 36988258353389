import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useLocation, useNavigate } from "react-router-dom";

import PageView from "../components/Page";
import AddExchangeStepCounter from '../components/AddExchangeStepCounter';
import IconicBadge from "../components/IconicBadge";
import IconedInput from '../components/IconedInput';
import ActionButton from "../components/ActionButton";

import {
    HiChevronLeft,
    HiOutlineQuestionMarkCircle
} from 'react-icons/hi';

const AddExchangeAccountManualCodeEntryScreen = ({ deviceconfigs, auth, colorscheme, language }) => {
    const [apikey, setApikey] = useState("");
    const [secretkey, setSecretkey] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const selectedexchange = state.selectedexchange;
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        topsummarycardcontainer: {
            backgroundColor: colorscheme.v2.primary,
            borderBottomLeftRadius: "12vw",
            borderBottomRightRadius: "12vw",
            padding: `0px ${deviceconfigs.layout.xlargepadding}px 32vw ${deviceconfigs.layout.xlargepadding}px`,
        },
        titleandquestioncontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        titlecontainer: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: deviceconfigs.layout.xlargepadding,
        },
        backbuttoncontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.primaryalternative,
            borderRadius: deviceconfigs.layout.xlargepadding,
        },
        titletextcontainer: {
            marginLeft: deviceconfigs.layout.xlargepadding,
        },
        titlename: {
            color: colorscheme.v2.primarytext,
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "600",
        },
        questioncontainer: {
            marginRight: deviceconfigs.layout.xlargepadding,
            backgroundColor: colorscheme.v2.primaryalternative,
            borderRadius: deviceconfigs.layout.xlargepadding,
            justifyContent: 'center',
            alignItems: "center",
            padding: deviceconfigs.layout.xlargepadding
        },
        stepscontainer: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: 'center',
            marginHorizontal: deviceconfigs.layout.xlargepadding,
            marginTop: deviceconfigs.layout.xxxxlargepadding,
        },
        stepstextcontainer: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginVertical: deviceconfigs.layout.xlargepadding,
        },
        stepstext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "600",
            color: colorscheme.v2.lighttext,
        },
        majorstepoff: {
            width: deviceconfigs.layout.xlargepadding,
            height: deviceconfigs.layout.xlargepadding,
            backgroundColor: colorscheme.v2.unselected,
            borderRadius: 999,
            marginHorizontal: deviceconfigs.layout.xlargepadding,
        },
        majorstepon: {
            width: deviceconfigs.layout.xxlargepadding,
            height: deviceconfigs.layout.xxlargepadding,
            backgroundColor: colorscheme.v2.selected,
            borderRadius: 999,
            marginHorizontal: deviceconfigs.layout.xlargepadding,
        },
        minorstep: {
            marginHorizontal: deviceconfigs.layout.xlargepadding,
            width: deviceconfigs.layout.largepadding,
            height: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.unselected,
            borderRadius: 999,
        },
        selectorcontainer: {
            borderRadius: deviceconfigs.layout.xxxxlargepadding,
            width: "84vw",
            height: "62vh",
            position: "absolute",
            top: "28vh",
            left: "8vw",
            backgroundColor: colorscheme.v2.background,
        },
        selectexchangetextcontainer: {
            padding: deviceconfigs.layout.xxlargepadding,
            justifyContent: "center",
            alignItems: "center",
        },
        selectexchangetext: {
            fontSize: deviceconfigs.font.xxlargetext,
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
        },
        selectedexchangeiconcontainer: {
            display: "flex",
            justifyContent: "center",
        },
        titleandinputscontainer: {

        },
        titleandinputcontainer: {

        },
        inputtitlecontainer: {
            marginVertical: deviceconfigs.layout.xxlargepadding,
            marginHorizontal: deviceconfigs.layout.xxlargepadding,
        },
        inputcontainer: {
            marginLeft: deviceconfigs.layout.xxlargepadding,
        },
        inputtitle: {
            marginLeft: deviceconfigs.layout.xxlargepadding,
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
        },
        input: {
            width: "72vw",
            height: "12vh",
            alignSelf: "center",
            borderRadius: deviceconfigs.layout.xxlargepadding,
            backgroundColor: colorscheme.v2.cardbackground,
            padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
            color: colorscheme.v2.backgroundtext,
            border: "none",
            outline: "none",
            resize: "none",
        },
        scrollviewcontainer: {
            flexGrow: 1,
            height: "72vh",
        },
        questionandbuttoncontainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "stretch",
            alignSelf: 'center',
            marginHorizontal: deviceconfigs.layout.xxlargepadding,
            marginVertical: deviceconfigs.layout.xlargepadding,
            position: "absolute",
            bottom: deviceconfigs.layout.xxxxlargepadding,
            left: "8vw",
            width: "84vw",
            zIndex: 1000,

        },
        questioncontainer: {
            backgroundColor: colorscheme.v2.primary,
            borderRadius: deviceconfigs.layout.xlargepadding,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            padding: deviceconfigs.layout.xlargepadding
        },
        buttoncontainer: {
            flex: 1,
            marginLeft: deviceconfigs.layout.xlargepadding,
        }
    };
    const minorCircles = [1, 2, 3, 4, 5];
    return (
        <PageView style={styles.mainframe}>
            <div style={{ flex: 1 }}>
                <div style={styles.topsummarycardcontainer}>
                    <div style={styles.titleandquestioncontainer}>
                        <div style={styles.titlecontainer}>
                            <div style={styles.backbuttoncontainer}
                                onClick={() => {
                                    window.history.back();
                                }}>
                                <HiChevronLeft
                                    style={{
                                        fontSize: "28px",
                                        color: colorscheme.v2.primarytext,
                                    }}
                                />
                            </div>
                            <div style={styles.titletextcontainer}>
                                <div style={styles.titlename}>
                                    {language.addyourkeys}
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddExchangeStepCounter
                        step={3}
                    />
                </div>
                <div style={styles.selectorcontainer}>
                    <div style={styles.selectexchangetextcontainer}>
                        <div style={styles.selectexchangetext}>{language.manualkeys}</div>
                    </div>
                    <div style={styles.selectedexchangeiconcontainer}>
                        <IconicBadge
                            {...selectedexchange}
                        />
                    </div>
                    <div style={styles.titleandinputscontainer}>
                        <div style={styles.titleandinputcontainer}>
                            <div style={styles.inputtitlecontainer}>
                                <div style={styles.inputtitle}>{language.apikey}</div>
                            </div>
                            <div style={styles.inputcontainer}>
                                <textarea
                                    style={styles.input}
                                    value={apikey}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        if (/\n/.test(text)) {
                                            //call submit function here
                                            return;
                                        } else {
                                            setApikey(text);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div style={styles.titleandinputcontainer}>
                            <div style={styles.inputtitlecontainer}>
                                <div style={styles.inputtitle}>{language.secretkey}</div>
                            </div>
                            <div style={styles.inputcontainer}>
                                <textarea
                                    style={styles.input}
                                    value={secretkey}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        if (/\n/.test(text)) {
                                            //call submit function here
                                            return;
                                        } else {
                                            setSecretkey(text);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div style={styles.questionandbuttoncontainer}>
                <div style={styles.buttoncontainer}>
                    <ActionButton
                        text={language.next}
                        onClick={() => {
                            navigate("/add-exchange-summary", { state: { selectedexchange, apikey, secretkey } });
                        }}
                        buttonstyle={{
                            width: "72vw"
                        }}
                    />
                </div>
            </div>
        </PageView>
    );

}
AddExchangeAccountManualCodeEntryScreen.propTypes = {
};

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        iconcache: state.iconcache,
        colorscheme: state.colorscheme,
        language: state.language,
        loading: state.loading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeAccountManualCodeEntryScreen);
