import React from "react";
import { connect } from "react-redux";

import SmartFlowIcon from "../../components/SmartFlowIcon";

import { toHumanReadableDateTime } from "../../utils/timeservice";

import { HiChevronRight } from "react-icons/hi";
import { ImEqualizer2 } from "react-icons/im";

const AddExchangeButton = (props) => {
  const {
    deviceconfigs,
    colorscheme,
    language,
    icon,
    text,
    selected,
    onClick,
    creationdate,
    smartflows,
  } = props;
  const supported = props.supported;
  console.log("Received Supported", props, props.supported);
  const styles = {
    unselectedmaincontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      backgroundColor: colorscheme.v2.cardbackground,
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.largepadding}px`,
      padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    selectedmaincontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      backgroundColor: colorscheme.v2.primary,
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.largepadding}px`,
      padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    iconcontainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: deviceconfigs.layout.largepadding,
    },
    icon: {
      width: deviceconfigs.button.xlargesize,
      height: deviceconfigs.button.xlargesize,
      objectFit: "contain",
    },
    textcontainer: {
      flex: 1,
      marginLeft: deviceconfigs.layout.largepadding,
    },
    unselectedtext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    selectedtext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "600",
      color: colorscheme.v2.success,
    },
    chevroncontainer: {
      padding: deviceconfigs.layout.xlargepadding,
    },
  };
  const smartFlowSupported =
    (supported || []).filter((a) => {
      return a === "smartflow";
    }).length > 0;
  const signalsSupported =
    (supported || []).filter((a) => {
      return a === "signals";
    }).length > 0;
  const smartFlowsExist = smartflows && smartflows.length > 0;
  return (
    <div
      style={
        selected ? styles.selectedmaincontainer : styles.unselectedmaincontainer
      }
      onClick={onClick}
    >
      {icon ? (
        <div style={styles.iconcontainer}>
          <img src={icon} style={styles.icon} alt={"MyPerformers"} />
        </div>
      ) : null}

      <div style={styles.textcontainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={selected ? styles.selectedtext : styles.unselectedtext}>
            {text}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {smartFlowSupported ? (
              <div
                style={{
                  position: "relative",
                  padding: deviceconfigs.layout.mediumpadding,
                  borderRadius: "8px",
                  border: "1px solid #929292",
                  marginLeft: deviceconfigs.layout.mediumpadding,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SmartFlowIcon
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                />
                {smartFlowsExist ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 2,
                      right: 2,
                      width: "4px",
                      height: "4px",
                      borderRadius: 999,
                      backgroundColor: colorscheme.v2.error,
                    }}
                  ></div>
                ) : null}
              </div>
            ) : null}
            {signalsSupported ? (
              <div
                style={{
                  position: "relative",
                  padding: deviceconfigs.layout.mediumpadding,
                  borderRadius: "8px",
                  border: "1px solid #929292",
                  marginLeft: deviceconfigs.layout.mediumpadding,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImEqualizer2
                  style={{
                    fontSize: "18px",
                    color: colorscheme.v2.backgroundtext,
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>

        {creationdate ? (
          <div>
            <div style={{}}>
              <div
                style={{
                  color: colorscheme.v2.success,
                  fontSize: deviceconfigs.font.smalltext,
                  fontWeight: "600",
                }}
              >
                {language.connectedat +
                  " " +
                  toHumanReadableDateTime(creationdate)}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div style={styles.chevroncontainer}>
        <HiChevronRight
          style={{
            fontSize: "28px",
            color: colorscheme.v2.backgroundtext,
          }}
        />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
    smartflows: state.smartflows,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeButton);
