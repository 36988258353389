import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PropTypes from 'prop-types';

import * as apiCalls from "../redux/actions/apiCalls";

import PageView from "../components/Page";
import IconicBadge from "../components/IconicBadge";
import AddExchangeStepCounter from "../components/AddExchangeStepCounter";

import { useLocation, useNavigate } from "react-router-dom";

import {
    AiOutlineArrowLeft
} from 'react-icons/ai';

import {
    HiOutlineQuestionMarkCircle
} from 'react-icons/hi';

import {
    HiChevronLeft,
} from 'react-icons/hi';
import ActionButton from "../components/ActionButton";

const AddExchangeAccountNoAccountScreen = ({ deviceconfigs, auth, colorscheme, language, navigation, route }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const myState = location.state;
    const selectedexchange = myState.selectedexchange;

    const { createaccountlink } = selectedexchange;
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        topsummarycardcontainer: {
            backgroundColor: colorscheme.v2.primary,
            borderBottomLeftRadius: "12vw",
            borderBottomRightRadius: "12vw",
            padding: `0px ${deviceconfigs.layout.xlargepadding}px 32vw ${deviceconfigs.layout.xlargepadding}px`,
        },
        titleandquestioncontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        titlecontainer: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: deviceconfigs.layout.xlargepadding,
        },
        backbuttoncontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.primary,
            borderRadius: deviceconfigs.layout.xlargepadding,
        },
        titletextcontainer: {
            marginLeft: deviceconfigs.layout.xlargepadding,
        },
        profilename: {
            color: colorscheme.v2.primarytext,
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "600",
        },
        questioncontainer: {
            marginRight: deviceconfigs.layout.xlargepadding,
            backgroundColor: colorscheme.v2.primaryalternative,
            borderRadius: deviceconfigs.layout.xlargepadding,
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: "center",
            padding: deviceconfigs.layout.xlargepadding
        },
        stepscontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: 'center',
            marginHorizontal: deviceconfigs.layout.xlargepadding,
            marginTop: deviceconfigs.layout.xxxxlargepadding,
        },
        stepstextcontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginVertical: deviceconfigs.layout.xlargepadding,
        },
        stepstext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "600",
            color: colorscheme.v2.lighttext,
        },
        majorstepoff: {
            width: deviceconfigs.layout.xlargepadding,
            height: deviceconfigs.layout.xlargepadding,
            backgroundColor: colorscheme.v2.unselected,
            borderRadius: 999,
            marginHorizontal: deviceconfigs.layout.xlargepadding,
        },
        majorstepon: {
            width: deviceconfigs.layout.xxlargepadding,
            height: deviceconfigs.layout.xxlargepadding,
            backgroundColor: colorscheme.v2.selected,
            borderRadius: 999,
            marginHorizontal: deviceconfigs.layout.xlargepadding,
        },
        minorstep: {
            marginHorizontal: deviceconfigs.layout.xlargepadding,
            width: deviceconfigs.layout.largepadding,
            height: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.unselected,
            borderRadius: 999,
        },
        selectorcontainer: {
            borderRadius: deviceconfigs.layout.xxxxlargepadding,
            width: "84vw",
            height: "62vh",
            position: "absolute",
            top: "28vh",
            left: "8vw",
            backgroundColor: colorscheme.v2.background,
        },
        selectexchangetextcontainer: {
            padding: deviceconfigs.layout.xxlargepadding,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        selectexchangetext: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
        },
        selectedexchangeiconcontainer: {
            display: "flex",
            justifyContent: "center",
        },
        noaccountpar: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "400",
            color: colorscheme.v2.backgroundtext,
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
            lineHeight: "22px",
        }
    };
    const minorCircles = [1, 2, 3, 4, 5];
    return (
        <PageView style={styles.mainframe}>
            <div style={styles.topsummarycardcontainer}>
                <div style={styles.titleandquestioncontainer}>
                    <div style={styles.titlecontainer}>
                        <div style={styles.backbuttoncontainer}
                            onClick={() => {
                                window.history.back();
                            }}>
                            <HiChevronLeft
                                style={{
                                    fontSize: "28px",
                                    color: colorscheme.v2.primarytext,
                                }}
                            />
                        </div>
                        <div style={styles.titletextcontainer}>
                            <div style={styles.profilename}>
                                {language.selectintegrationmethod}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                }}>
                    <AddExchangeStepCounter
                        step={3}
                    />
                </div>
            </div>
            <div style={styles.selectorcontainer}>
                <div style={styles.selectexchangetextcontainer}>
                    <div style={styles.selectexchangetext}>{language.noaccount}</div>
                </div>
                <div style={styles.selectedexchangeiconcontainer}>
                    <IconicBadge
                        {...selectedexchange}
                    />
                </div>
                <div style={{
                    margin: "0px 10vw",
                    padding: "12px 4px",
                }}>
                    <div style={styles.noaccountpar}>{language.noaccountfirstpar(selectedexchange.text)}</div>
                    <div style={styles.noaccountpar}>{language.noaccountsecondpar(selectedexchange.text)}</div>
                    <div style={styles.noaccountpar}>{language.noaccountthirdpar(selectedexchange.text)}</div>
                </div>
                <div style={{
                    margin: `${deviceconfigs.layout.xlargepadding} 0px`,
                }}>
                    <ActionButton
                        text={language.createanaccount}
                        buttonstyle={{
                            margin: "auto",
                            marginTop: "32px",
                        }}
                        onClick={() => {
                            window.location.href = createaccountlink
                        }}
                    />
                </div>
                <div style={{
                    height: "20vh"
                }}></div>
            </div>
        </PageView>
    );
}

AddExchangeAccountNoAccountScreen.propTypes = {
};

function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        iconcache: state.iconcache,
        colorscheme: state.colorscheme,
        language: state.language,
        loading: state.loading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeAccountNoAccountScreen);
