
import { connect } from "react-redux";


const SettingsButton = ({ colorscheme, framestyle, lefttext, lefttextstyle, righticon, righttext, righttextstyle, onClick }) => {
    const styles = {
        mainframe: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colorscheme.v2.background,
            padding: "18px",
        },
        lefttextcontainer: {
            flex: 4,
            padding: "0px 12px",
        },
        rightcontainer: {
            flex: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end"
        },
        lefttext: {
            fontSize: "13px",
            fontWeight: "600",
            color: colorscheme.v2.backgroundtext,
        },
        righttext: {
            fontSize: "13px",
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
        },
    };
    return (
        <div
            style={{
                ...styles.mainframe,
                ...framestyle
            }}
            onClick={onClick}>
            <div style={styles.lefttextcontainer}>
                <div style={{
                    ...styles.lefttext,
                    ...lefttextstyle,
                }}>{lefttext}</div>
            </div>
            <div style={styles.rightcontainer}>
                <div>
                    <div style={{
                        ...styles.righttext,
                        ...righttextstyle,
                    }}>{righttext}</div>
                </div>
                <div style={{ marginLeft: "12px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {righticon}
                </div>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        colorscheme: state.colorscheme
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsButton);