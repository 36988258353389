import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";

const ModalSummaryItem = ({ deviceconfigs, colorscheme, lefttext, righttext, extradata }) => {
    const styles = {
        maincontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginVertical: deviceconfigs.layout.largepadding,
        },
        leftsidecontainer: {
            width: "45vw",
        },
        rightsidecontainer: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end"
        },
        lefttext: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "500",
            color: colorscheme.v2.primarytext,
        },
        righttext: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "500",
            color: colorscheme.v2.primarytext,
        },
        extradata: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "500",
            color: colorscheme.v2.success,
        }
    };
    return (
        <div style={styles.maincontainer}>
            <div style={styles.leftsidecontainer}>
                <div style={styles.lefttext}>{lefttext}</div>
            </div>
            <div style={styles.rightsidecontainer}>
                <div style={styles.righttext}>{righttext}</div>
                {extradata && <div style={styles.righttext}>{" - "}</div>}
                {extradata && <div style={styles.extradata}>{extradata}</div>}
            </div>
        </div>
    );
}

ModalSummaryItem.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    extradata: PropTypes.string
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSummaryItem);