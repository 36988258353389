const R = {
  light: {
    v2: {
      name: "light",
      primary: "rgb(23, 53, 135)",
      /*primary: "rgb(41,90,223)",*/
      primarytext: "rgb(255,255,255)",
      primarydarktext: "rgb(220,220,220)",
      primarybackground: "rgb(206, 216, 245)",
      success: "rgb(108,212,75)",
      successbackground: "rgb(207, 242, 196)",
      successtext: "rgb(144,144,144)",
      warning: "rgb(236,219,83)",
      warningbackground: "rgb()",
      warningtext: "rgb()",
      attention: "rgb(227, 118, 34)",
      attentionbackground: "rgb(252, 215, 151)",
      info: "rgb(108,160,220)",
      infobackground: "rgb()",
      infotext: "rgb()",
      debug: "rgb(92,111,31)",
      debugbackground: "rgb()",
      debugtext: "rgb()",
      error: "rgb(251,111,94)",
      errorbackground: "rgb(245, 196, 191)",
      errortext: "rgb()",
      background: "rgb(255,255,255)",
      cardbackground: "rgb(230, 230, 230)",
      backgroundtext: "rgb(0,0,0)",
      lighttext: "rgb(255,255,255)",
      heavytext: "rgb(100,100,100)",
      inputbackground: "rgb(242,242,242)",
      header: "rgb(0,0,0)",
      bordercolor: "rgb(222,222,222)",
      darkbordercolor: "rgb(222,222,222)",
      secondbackground: "rgb(244 , 244 , 244)",
      thirdbackground: "rgb(234,236,242)",
      lighttext: "rgb(250,250,250)",
      inactivetext: "rgb(200,200,250)",
      inactiveactionbutton: "rgb(241,245,253)",
      inactiveactionbuttontext: "rgb(23,23,23)",
      primaryinactive: "rgb(242,245,255)",
      unselected: "rgb(200,200,200)",
      selected: "rgb(200,200,200)",
      warningpopuptext: "#163587",
      shadow: {
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 6,
      },
      shallowshadow: {
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
      },
      chart: {
        linechart: {
          line: (opacity) => {
            return `rgba(98,98,254,${opacity})`;
          },
          label: (opacity) => {
            return `rgba(32,32,32,${opacity})`;
          },
        },
      },
    },
  },
  dark: {
    v2: {
      name: "dark",
      primary: "rgb(23, 53, 135)",
      primarytext: "rgb(255,255,255)",
      primarydarktext: "rgb(180,180,180)",
      primarybackground: "rgb(206, 216, 245)",
      success: "rgb(108,212,75)",
      successbackground: "rgb(207, 242, 196)",
      successtext: "rgb(23,23,23)",
      warning: "rgb(236,219,83)",
      warningbackground: "rgb()",
      warningtext: "rgb()",
      attention: "rgb(227, 118, 34)",
      attentionbackground: "rgb(252, 215, 151)",
      info: "rgb(108,160,220)",
      infobackground: "rgb()",
      infotext: "rgb()",
      debug: "rgb(92,111,31)",
      debugbackground: "rgb()",
      debugtext: "rgb()",
      error: "rgb(251,111,94)",
      errorbackground: "rgb(245, 196, 191)",
      errortext: "rgb()",
      background: "rgb(17,26,48)",
      cardbackground: "rgb(37,53,88)",
      backgroundtext: "rgb(255,255,255)",
      lighttext: "rgb(255,255,255)",
      heavytext: "rgb(180,180,180)",
      unselected: "rgb(200,200,200)",
      inputbackground: "rgb(37,36,71)",
      header: "rgb(0,0,0)",
      bordercolor: "rgb(188,188,188)",
      darkbordercolor: "rgb(155,155,155)",
      secondbackground: "rgb(37,36,71)",
      thirdbackground: "rgb(21,21,46)",
      lighttext: "rgb(250,250,250)",
      inactivetext: "rgb(200,200,250)",
      inactiveactionbutton: "rgb(241,245,253)",
      inactiveactionbuttontext: "rgb(23,23,23)",
      primaryinactive: "rgb(242,245,255)",
      selected: "rgb(242,245,255)",
      warningpopuptext: "#163587",
      shadow: {
        shadowColor: "#fff",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
      },
      shallowshadow: {
        shadowColor: "#fff",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
      },
      chart: {
        linechart: {
          line: (opacity) => {
            return `rgba(108,212,75,${opacity})`;
          },
          label: (opacity) => {
            return `rgba(200,200,200,${opacity})`;
          },
        },
      },
    },
  },
};

export default R;
