import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import LoadingModal from "../components/LoadingModal";

import PageView from "../components/Page"
import PageHeader from "../components/PageHeader";

const TermsAndConditionsScreen = ({ deviceconfigs, colorscheme, language }) => {

    const location = useLocation();
    const myState = location.state;
    const { link } = myState;
    const [loading, setLoading] = useState(true);
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        headercontainer: {

        },
        contentcontainer: {
            flex: 1,
            margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
        },
    }
    return (
        <PageView>
            <div style={styles.headercontainer}>
                <PageHeader
                    header={language.termsandcondition}
                    onBackPressed={() => {
                        window.history.back();
                    }}
                    headerstyle={{
                        fontSize: deviceconfigs.font.xlargetext,
                        fontWeight: "700",
                    }}
                />
            </div>
            <div>
                <iframe src={link}
                    style={{
                        outline: "none",
                        border: "none",
                        width: "100vw",
                        height: "84vh",
                    }}
                    onLoad={() => {
                        setLoading(false);
                    }}
                />
            </div>
            <LoadingModal 
                loading={loading}
            />
        </PageView>
    )
}
function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsScreen);