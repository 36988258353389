import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";
import IconedInput from "../components/IconedInput";
import MarketDataSummary from "../components/MarketDataSummary";
import BottomNavBar from "../components/BottomNavBar";

import * as apiCalls from "../redux/actions/apiCalls";

import { List } from "antd";

import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const MarketWatchScreen = ({
  api,
  auth,
  language,
  colorscheme,
  deviceconfigs,
  cmcidmap,
  cmclisting,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const marketdata = cmclisting || [];
  const [query, setQuery] = useState(undefined);

  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  const result = query
    ? (marketdata || []).filter((a) => {
        return (
          a.name.indexOf(query) != -1 ||
          a.symbol.indexOf(query) != -1 ||
          a.slug.indexOf(query) != -1
        );
      })
    : marketdata;

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      margin: `2px ${deviceconfigs.layout.largepadding}px`,
    },
    scrollcontainer: {
      flex: 1,
      paddingTop: "118px",
      zIndex: -100,
    },
    scroll: {
      flex: 1,
    },
    headercontainer: {
      backgroundColor: colorscheme.v2.background,
    },
    itemstyle: {
      padding: deviceconfigs.layout.xlargepadding,
      alignItems: "flex-start",
    },
    headeritem: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    iconcontainer: {},
    namecontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    name: {},
    scrollview: {
      flex: 1,
    },
    icon: {
      width: deviceconfigs.button.xlargesize,
      height: deviceconfigs.button.xlargesize,
      borderRadius: 999,
    },
  };

  return (
    <div
      style={{
        backgroundColor: colorscheme.v2.background,
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 100,
          width: "100vw",
          top: -56,
          left: 0,
          right: 0,
          backgroundColor: colorscheme.v2.background,
          paddingTop : "56px",
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.marketwatch}
            headerstyle={{
              fontSize: deviceconfigs.font.xxlargetext,
              fontWeight: "600",
              color: colorscheme.v2.backgroundtext,
              padding: `${12}px 0px`,
            }}
            righticon={
              <div
                style={{
                  color: colorscheme.v2.backgroundtext,
                }}
              >
                {language.uptotop}
              </div>
            }
            righticonOnPress={() => {
              executeScroll();
            }}
          />
        </div>
        <div>
          <IconedInput
            containerstyle={{
              width: "92vw",
              alignSelf: "center",
            }}
            lefticon={
              <BsSearch
                style={{
                  fontSize: "18px",
                  color: colorscheme.v2.backgroundtext,
                }}
              />
            }
            leftseperator
            placeholder={language.search + "..."}
            onChangeText={(query) => {
              setQuery(query);
            }}
          />
        </div>
      </div>
      <div style={styles.scrollcontainer} ref={myRef}>
        <List
          style={{
            flex: 1,
          }}
          dataSource={result}
          renderItem={(item) => {
            return (
              <div key={item.cmc_rank}>
                <MarketDataSummary
                  price={item.quote.USD.price}
                  changeIn24h={item.quote.USD.percent_change_24h}
                  changeIn7d={item.quote.USD.percent_change_7d}
                  marketcap={item.quote.USD.market_cap}
                  volume={item.quote.USD.market_cap}
                  name={item.name}
                  symbol={item.symbol}
                  icon={item.icon}
                  index={item.cmc_rank}
                  pricestyle={{
                    fontSize: deviceconfigs.font.xlargetext,
                    fontWeight: "600",
                    color: colorscheme.v2.backgroundtext,
                  }}
                  chart={`https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/${item.id}.svg`}
                  onClick={async () => {
                    let performance = await api.getPerformance(item.symbol);
                    navigate("/market-watch/details", {
                      state: {
                        selectedcoin: item,
                        performance: performance.data.object.data[item.symbol],
                      },
                    });
                  }}
                />
              </div>
            );
          }}
          rowKey={(item) => item.cmc_rank}
        />
      </div>
      <BottomNavBar />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    myexchanges: state.myexchanges,
    cmcidmap: state.cmcidmap,
    membership: state.membership,
    cmclisting: state.cmclisting,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketWatchScreen);
