import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import swDev from "./swDev";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swDev();

console.log("DEKEKFKAF");
navigator.serviceWorker
  .getRegistration()
  .then((reg) => {
    reg.pushManager
      .subscribe({
        userVisibleOnly: true,
      })
      .then((sub) => {
        console.log("This IS Sum", sub);
      })
      .catch((err) => {
        console.error("Meac ulpe", err);
      });
  })
  .catch((err) => {
    console.error("Cannot get SW Registration", err);
  });
