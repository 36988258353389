import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const MembershipStatusBadge = ({
  auth,
  colorscheme,
  language,
  badgeContainerStyle,
  textContainerStyle,
  textStyle,
}) => {
  const membershipResolver = useCallback((type) => {
    switch (type) {
      case "PREMIUM":
      case "PROMOTION30DAYS":
        return language.premium;
      case "TRIAL":
      default:
        return language.basic;
    }
  }, []);
  const membershipText = membershipResolver(auth?.user?.membership?.type);
  const isExpired = auth?.user?.membership?.status === "EXPIRED";
  const styles = {
    badgeContainer: {
      width: "96px",
      backgroundColor: isExpired ? colorscheme.v2.attention : colorscheme.v2.success,
      padding: 2,
      borderRadius: 8,
    },
    textContainer: {
      textAlign: "center",
      display: "flex",
      flexDirection: "columns",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      color: colorscheme.v2.primarytext,
    },
  };
  return (
    <div
      style={{
        ...styles.badgeContainer,
        ...badgeContainerStyle,
      }}
    >
      <div
        style={{
          ...styles.textContainer,
          ...textContainerStyle,
        }}
      >
        <span
          style={{
            ...styles.text,
            ...textStyle,
          }}
        >
          {membershipText}
        </span>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
    membership: state.membership,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipStatusBadge);
