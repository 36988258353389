import { connect } from "react-redux";
import { useState } from "react";
import { bindActionCreators } from "redux";
import SmartFlowPerformanceItem from "components/SmartFlowPerformanceItem";

const AutomaticPerformanceItemContainer = ({
    colorscheme,
    deviceconfigs,
    language,
    automaticperformances,
}) => {
    const styles = {
        mainContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "12px",
        },
    };
    return (
        <div
            style={styles.mainContainer}
        >
            <SmartFlowPerformanceItem
                title={language.h24}
                value={automaticperformances?.h24.toFixed(3)}
            />
            <SmartFlowPerformanceItem
                title={language.d7}
                value={automaticperformances?.d7.toFixed(3)}
            />
            <SmartFlowPerformanceItem
                title={language.d30}
                value={automaticperformances?.d30.toFixed(3)}
            />
            <SmartFlowPerformanceItem
                title={language.y1}
                value={automaticperformances?.y1.toFixed(3)}
            />
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {};
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        automaticperformances: state.automaticperformances,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutomaticPerformanceItemContainer);