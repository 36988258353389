import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { MEMBERSHIP } from "../constants/ReduxStates";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";
import NotificationItem from "../components/NotificationItem";

import * as apiCalls from "../redux/actions/apiCalls";
import * as unreadNotificationsActions from "../redux/actions/unreadNotificationsActions";

import { List } from "antd";

const NotificationsScreen = ({
  deviceconfigs,
  language,
  colorscheme,
  api,
  navigation,
  membership,
  huobisymbols,
  cmcidmap,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState({
    signal: true,
    order: false,
    watchlist: false,
  });
  const [notificationsdata, setNotificationsData] = useState([]);
  const [unreads, setUnreads] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchExchange = (symbol) => {
    return api.getExchangeInfoForSymbol(symbol);
  };

  const getPrecision = (item) => {
    const { info } = item;
    if (info) {
      for (var i = 0; i < info.filters.length; i++) {
        if (info.filters[i].filterType == "LOT_SIZE") {
          let ss = info.filters[i].stepSize;
          let iindex = ss.indexOf(".");
          let oindex = ss.indexOf("1");
          let diff = oindex - iindex;
          if (diff < 1) {
            diff = 0;
          }
          return diff;
        }
      }
    }
    return 8;
  };

  const getDecimals = (item) => {
    const { info } = item;
    if (info) {
      for (var i = 0; i < info.filters.length; i++) {
        if (info.filters[i].filterType == "PRICE_FILTER") {
          let ss = info.filters[i].tickSize;
          let iindex = ss.indexOf(".");
          let oindex = ss.indexOf("1");
          let diff = oindex - iindex;
          if (diff < 1) {
            diff = 0;
          }
          return diff;
        }
      }
    }
    return 8;
  };

  const fetchUserNotifications = async () => {
    let unreads = {
      signal: 0,
      order: 0,
      watchlist: 0,
    };
    try {
      setLoading(true);
      const result = await api.getUserNotifications();
      const results = result.data.object.content || [];
      for (var i = 0; i < results.length; i++) {
        try {
          if (results[i].icon) {
            results[
              i
            ].image = `https://s2.coinmarketcap.com/static/img/coins/64x64/${
              cmcidmap[results[i].icon.toUpperCase()].id
            }.png`;
          }
        } catch (e) {
          console.error(e);
        }

        if (results[i].readstate == "UNREAD") {
          if (results[i].domain == "SIGNAL") {
            unreads.signal++;
          } else if (results[i].domain == "ORDER") {
            unreads.order++;
          } else if (results[i].domain == "WATCHLIST") {
            unreads.watchlist++;
          }
        }
      }
      setNotificationsData(results);
      setUnreads(unreads);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserNotifications();
  }, []);

  const membershipExpired =
    membership && membership.type === MEMBERSHIP.MEMBERSHIP_EXPIRED;
  const styles = {
    mainframe: {
      flex: 1,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px`,
    },
    menucontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "center",
      paddingBottom: deviceconfigs.layout.largepadding,
    },
    menubuttonselected: {
      flex: 1,
      padding: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.primary,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: deviceconfigs.layout.xlargepadding,
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      position: "relative",
    },
    menubuttonunselected: {
      flex: 1,
      padding: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.background,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: deviceconfigs.layout.xlargepadding,
      border: `1px solid ${colorscheme.v2.primary}`,
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      position: "relative",
    },
    buttontextselected: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.primarytext,
    },
    buttontextunselected: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.backgroundtext,
    },
    listcontainer: {
      flex: 1,
      paddingBottom: deviceconfigs.layout.xxlargepadding,
      paddingTop: "110px",
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
    },
    list: {
      flex: 1,
    },
  };
  const notificationsFiltered = (notificationsdata || []).filter((a) => {
    if (a.domain == "SIGNAL") {
      return selected.signal;
    } else if (a.domain == "ORDER") {
      return selected.order;
    } else if (a.domain == "WATCHLIST") {
      return selected.watchlist;
    }
  });
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          zIndex: 100,
          top: "0px",
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            onBackPressed={() => {
              window.history.back();
            }}
            righticon={
              <div
                style={{
                  fontSize: deviceconfigs.font.largetext,
                  color: colorscheme.v2.backgroundtext,
                  fontWeight: "400",
                }}
              >
                {language.markallasread}
              </div>
            }
            righticonstyle={{
              marginRight: deviceconfigs.layout.largepadding,
            }}
            righticonOnPress={async () => {
              if (selected.signal) {
                await api.markAllSignalNotificationsRead();
              }
              if (selected.order) {
                await api.markAllOrderNotificationsRead();
              }
              if (selected.watchlist) {
                await api.markAllWatchlistNotificationsRead();
              }
              fetchUserNotifications();
            }}
          />
        </div>
        <div style={styles.menucontainer}>
          <div
            style={
              selected.signal
                ? styles.menubuttonselected
                : styles.menubuttonunselected
            }
            onClick={() => {
              setSelected({ signal: true, order: false, watchlist: false });
            }}
          >
            {unreads.signal > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: -Math.floor(deviceconfigs.button.largesize / 3),
                  left: -Math.floor(deviceconfigs.button.largesize / 3),
                  width: deviceconfigs.button.largesize,
                  height: deviceconfigs.button.largesize,
                  borderRadius: 999,
                  backgroundColor: "red",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: deviceconfigs.font.smalltext,
                  }}
                >
                  {unreads.signal}
                </div>
              </div>
            )}
            <div
              style={
                selected.signal
                  ? styles.buttontextselected
                  : styles.buttontextunselected
              }
            >
              {language.signals}
            </div>
          </div>
          <div
            style={
              selected.order
                ? styles.menubuttonselected
                : styles.menubuttonunselected
            }
            onClick={() => {
              setSelected({ signal: false, order: true, watchlist: false });
            }}
          >
            {unreads.order > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: -Math.floor(deviceconfigs.button.largesize / 3),
                  left: -Math.floor(deviceconfigs.button.largesize / 3),
                  width: deviceconfigs.button.largesize,
                  height: deviceconfigs.button.largesize,
                  borderRadius: 999,
                  backgroundColor: "red",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: deviceconfigs.font.smalltext,
                  }}
                >
                  {unreads.order}
                </div>
              </div>
            )}
            <div
              style={
                selected.order
                  ? styles.buttontextselected
                  : styles.buttontextunselected
              }
            >
              {language.orders}
            </div>
          </div>
          <div
            style={{
              ...styles.menubuttonunselected,
              backgroundColor: "#e2e2e2",
            }}
            onClick={() => {
            }}
          >
            {unreads.watchlist > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: -Math.floor(deviceconfigs.button.largesize / 3),
                  left: -Math.floor(deviceconfigs.button.largesize / 3),
                  width: deviceconfigs.button.largesize,
                  height: deviceconfigs.button.largesize,
                  borderRadius: 999,
                  backgroundColor: "red",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: deviceconfigs.font.smalltext,
                  }}
                >
                  {unreads.watchlist}
                </div>
              </div>
            )}
            <div
              style={{
                ...styles.buttontextunselected,
                color: "#a2a2a2",
              }}
            >
              {language.watchlist}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <div style={styles.listcontainer}>
          <List
            dataSource={notificationsFiltered}
            style={styles.list}
            renderItem={(item) => {
              return (
                <div key={item.id}>
                  <NotificationItem
                    icon={item.icon}
                    image={item.image}
                    title={item.title}
                    content={item.content}
                    creationdate={item.creationdate}
                    readstate={item.readstate}
                    onClick={async () => {
                      if (item.readstate != "READ") {
                        try {
                          console.log("Marking Read", item.readstate, item.id);
                          await api.markUserNotificationsRead(item.id);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                      if (membershipExpired) {
                        // promptMessage(language.error.expirederror.header, language.error.expirederror.body, [
                        //     {
                        //         text: "OK"
                        //     },
                        //     {
                        //         text: "Membership",
                        //         onPress: () => {
                        //             navigation.navigate("SelectMembership");
                        //         }
                        //     }
                        // ]);
                        return;
                      }
                      if (item.domain == "SIGNAL") {
                        try {
                          let signalResult = await api.getCPSignal(item.dataid);
                          let signal = signalResult.data.object;
                          signal.icon = item.image;
                          if (signal.exchange === "BINANCE") {
                            signal.info = (
                              await fetchExchange(signal.name)
                            ).data.object.symbols[0];
                            signal.precision = getPrecision(item);
                            signal.decimals = getDecimals(item);
                          } else if (signal.exchange === "HUOBI") {
                            const hSymbols = huobisymbols.payload;
                            if (hSymbols) {
                              for (var i = 0; i < hSymbols.length; i++) {
                                if (
                                  hSymbols[i].symbol.toUpperCase() ==
                                  signal.name
                                ) {
                                  signal.precision =
                                    hSymbols[i]["price-precision"];
                                  signal.valueprecision =
                                    hSymbols[i]["value-precision"];
                                  signal.amountprecision =
                                    hSymbols[i]["amount-precision"];
                                  signal.minordervalue =
                                    hSymbols[i]["min-order-value"];
                                  signal.info = hSymbols[i];
                                }
                              }
                            }
                          }

                          navigate("/signals/signal-details", {
                            state: { signal },
                          });
                        } catch (ex) {
                          console.error(ex);
                        }
                      } else if (item.domain == "ORDER") {
                        try {
                          let myOrderResult = await api.getCPOrderDetails(
                            item.dataid
                          );
                          const myOrder = myOrderResult.data.object;
                          let symbolResult = await api.getExchangeInfoForSymbol(
                            myOrder.basecoin + myOrder.quotecoin
                          );
                          const symbol = symbolResult.data.object.symbols[0];
                          navigate("/orders/order-details", {
                            state: {
                              myorder: myOrder,
                              filters: symbol.filters,
                            },
                          });
                        } catch (ex) {
                          console.error(ex);
                        }
                      } else if (item.domain == "WATCHLIST") {
                        try {
                          navigate("WatchlistHome");
                        } catch (ex) {
                          console.error(ex);
                        }
                      }
                    }}
                  />
                </div>
              );
            }}
            keyExtractor={(item) => item.id}
          />
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    unreadnotificationsactions: bindActionCreators(
      unreadNotificationsActions,
      dispatch
    ),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsScreen);
