import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { notification } from "antd";

import IconedInput from "../components/IconedInput";
import PageView from "../components/Page";
import SizeableButton from "../components/SizeableButton";

import { AiOutlineWarning, AiFillCheckCircle } from "react-icons/ai";

import * as apiCalls from "../redux/actions/apiCalls";
import LoadingModal from "components/LoadingModal";

const openNotification = ({
  title = "Notification",
  description,
  duration,
  style,
}) => {
  const args = {
    message: title,
    description: description,
    duration: duration || 5,
    style: style || {},
  };
  notification.open(args);
};

const ForgotPasswordScreen = ({
  deviceconfigs,
  colorscheme,
  language,
  api,
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    secondframe: {
      marginLeft: deviceconfigs.layout.xlargepadding,
      marginRight: deviceconfigs.layout.xlargepadding,
    },
    imagecontainer: {
      paddingLeft: "32px",
      paddingTop: "32px",
    },
    signintotext: {
      paddingLeft: "32px",
      fontSize: deviceconfigs.font.xxxxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    myperformerstextcontainer: {
      paddingLeft: "32px",
    },
    myperformerstext: {
      color: colorscheme.v2.heavytext,
      fontWeight: "700",
    },
    inputscontainer: {
      margin: `${deviceconfigs.layout.xxxlargepadding}px ${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.largepadding}px`,
      padding: deviceconfigs.layout.xlargepadding,
    },
    inputcontainer: {
      marginTop: deviceconfigs.layout.xlargepadding,
      marginBottom: deviceconfigs.layout.xlargepadding,
    },
    line: {
      backgroundColor: colorscheme.v2.primary,
      height: deviceconfigs.layout.largepadding,
      width: "12vw",
      marginLeft: "32px",
      marginRight: deviceconfigs.layout.xlargepadding,
      borderRadius: deviceconfigs.layout.largepadding,
    },
    optionscontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
      marginRight: deviceconfigs.layout.xlargepadding,
      padding: deviceconfigs.layout.xlargepadding,
    },
    optionitemcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginVertical: deviceconfigs.layout.xlargepadding,
    },
    optiontextitemcontainer: {
      flex: 1,
    },
    optioncheckboxitemcontainer: {},
    optiontext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: "#727272",
    },
    signinbuttoncontainer: {
      marginTop: deviceconfigs.layout.xlargepadding,
    },
    bottomtextcontainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: deviceconfigs.layout.xxxxlargepadding,
      justifyContent: "center",
      alignItems: "center",
    },
    donthaveaccounttext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: "#727272",
    },
  };
  return (
    <PageView style={styles.mainframe}>
      <div style={styles.imagecontainer}>
        <img
          width={96}
          height={96}
          src={
            colorscheme.v2.name === "dark"
              ? require("../assets/images/v2/logo-dark.png")
              : require("../assets/images/v2/logo-light.png")
          }
          alt={"MyPerformers"}
        />
      </div>
      <div
        style={{
          marginHorizontal: deviceconfigs.layout.xlargepadding,
        }}
      >
        <div style={styles.signintotext}>{language.forgotpassword}</div>
      </div>
      <div style={styles.myperformerstextcontainer}>
        <div style={styles.myperformerstext}>{language.myperformers}</div>
      </div>
      <div style={styles.line}></div>
      <div style={styles.inputscontainer}>
        <div style={styles.inputcontainer}>
          <IconedInput
            placeholder={language.email + "..."}
            onChangeText={(email) => {
              setEmail(email);
            }}
            value={email}
          />
        </div>
      </div>
      <div style={styles.signinbuttoncontainer}>
        <SizeableButton
          text={language.resetpassword}
          onClick={async () => {
            try {
              setLoading(true);
              let forgotresult = await api.forgotPassword(email);
              setLoading(false);
              openNotification({
                title: language.resetpasswordsuccess,
                description: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AiFillCheckCircle
                        style={{
                          color: colorscheme.v2.success,
                          fontSize: deviceconfigs.icon.largeicon,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "12px",
                      }}
                    >
                      {language.checkyouremail}
                    </div>
                  </div>
                ),
              });
              setTimeout(() => {
                navigate("/sign-in");
              }, 3000);
            } catch (e) {
              console.error(e);
              openNotification({
                title: language.error.forgotpassworderror.header,
                description: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AiOutlineWarning
                        style={{
                          color: colorscheme.v2.error,
                          fontSize: deviceconfigs.icon.largeicon,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "12px",
                      }}
                    >
                      {language.error.forgotpassworderror.body}
                    </div>
                  </div>
                ),
                duration: 7,
              });
              setLoading(false);
            }
          }}
        />
      </div>
      <div style={styles.bottomtextcontainer}>
        <div style={styles.donthaveaccounttext}>
          {language.alreadyhaveanaccount}
        </div>
        <div
          style={{
            marginLeft: deviceconfigs.layout.largepadding,
            color: colorscheme.v2.primary,
            fontWeight: "700",
          }}
          onClick={() => {
            navigate("/sign-in");
          }}
        >
          {language.signin}
        </div>
      </div>
      <div
        style={{
          height: "12vh",
        }}
      ></div>
      <LoadingModal loading={loading} />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordScreen);
