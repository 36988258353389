import React from 'react';

const R = {
    name: "Español",
    language: "Idioma",
    denom: "es",
    WelcomeScreen: {
        monitor: "Controle sus inversiones en un solo lugar.",
        portfolio: "Gestión de la cartera de los principales bolsas de criptomonedas en una sola aplicación",
        login: "Iniciar Sesión",
    },
    LoginScreen: {
        countrycode: "Código de país",
        phonenumber: "Número de teléfono",
        submit: "Enviar",
        connectionerror: "Error de conexión",
        cannotconnect: "No se puede conectar al servidor. Inténtelo de nuevo más tarde.",
        enternumber: "Ingresa número telefónico",
    },
    HomeScreen: {
        welcome: "Bienvenido",
        totalbalance: "Balance total",
        marketdata: "Datos del mercado",
        search: "Buscar...",
        myexchangeaccounts: "Mis cuentas de intercambio",
        balance: "Balance",
        change24h: "Cambio en 24h %",
        change7d: "Cambio en 24h %",
        save: "Salvar",
        noexchangeaccount: "Aún no tiene cuentas de Exchange.",
        addexchangeaccount: "Agregar cuenta de Exchange",
        marketcap: "Tapa del mercado",
        source: "Fuente",
        realtime: "Tiempo real",
        deletewarning: "Estás a punto de eliminar tu cuenta de intercambio",
        areyousure: "Estas seguro?",
        cancel: "Cancelar",
        yes: "Si",
        changeIn7d: "Cambio en 24h %",
        changeIn24h: "Cambio en 24h %",
        free: "Libre",
        locked: "Bloqueado",
        permissionerror: "Error de permiso",
        camerarollpermissionerror: "Debes otorgar permiso de fotos para cambiar tu foto de perfil."
    },
    SMSVerificationScreen: {
        entersms: "Ingrese el código de verificación por SMS",
    },
    MyAssetsScreen: {
        myassets: "Mis activos",
        totalinusdt: "Total en USDT",
        totalfree: "Total libre",
        totallocked: "Total bloqueado",
    },
    SignalsScreen: {
        info: "Información",
        exchange: "Intercambio",
        signals: "Señales",
        buyrange: "Rango de compra",
        buyrangefrom: "De",
        buyrangeto: "Hasta",
        stoploss: "Stop Loss",
        target: "Objetivo",
        purchasingprice: "Precio de Compra",
        purprice: "Prec. Compra",
        manual: "Manual",
        automatic: "Automático",
        expired: "Cerrado",
        xactioninexchange: (base, quote, deviceconfigs, colorscheme) => {
            return (
                <span style={{
                    fontWeight: "700",
                    fontSize: deviceconfigs.font.mediumtext,
                    color: colorscheme.text,
                }}>Compra<span style={{
                    fontWeight: "500",
                    fontSize: deviceconfigs.font.mediumtext,
                    color: colorscheme.primary
                }}>{" " + base}</span>  a cambio de  <span style={{
                    fontWeight: "700",
                    fontSize: deviceconfigs.font.mediumtext,
                    color: colorscheme.primary
                }}>{quote}</span>
                </span>
            )
        },
        sharewarning: (colorscheme,) => {
            return (
                <span style={{
                    color: colorscheme.heavytext
                }}>Click on <span style={{ color: colorscheme.error, fontWeight: "700" }}>Back</span> if you want to change the shares. Click on <span style={{ color: colorscheme.primary, fontWeight: "700" }}>Confirm</span> to proceed.
                </span>
            )
        },
        market: "Bolsa",
        limit: "Límite",
        price: "Precio",
        quantity: "Cantidad",
        sendorder: "Enviar orden",
        noexchangewarning: "¡Para utilizar las señales, se debe conectar una centralita!",
        addexchangeaccount: "Agregar cuenta de Exchange",
        success: "Éxito",
        realtime: "Tiempo real",
        ordersuccessful: "Pedido exitoso",
        lotsizenotsatisfied: "Tamaño del lote no satisfecho",
        insufficientbalance: "La cuenta tiene saldo insuficiente para la acción solicitada.",
        apikeyerror: "Clave de API incorrecta o permiso denegado",
        genericerror: "Se produjo algún error durante la transacción",
        orderconfirmation: "Confirmación del pedido",
        targetsnotfull: "No ha seleccionado el 100% de las acciones objetivo. Las acciones objetivo no seleccionadas estarán reguladas por el Stop Loss automático.",
        available: "Disponible",
        amount: "Monto",
        confirm: "Confirmar",
        error: "Error",
        stoplosserror: "Stop Loss no puede exceder el precio de compra",
        totalshareerror: "La participación total no puede exceder el 100%",
        pricezeroerror: "El precio no puede ser 0",
        minnotionalerror: "Filtro nocional mínimo no satisfecho",
        quantityzeroerror: "La cantidad no puede estar vacía",
        maxpriceerror: "No puedes poner precios por encima del precio máximo.",
        walletsizeerror: "No puede enviar pedidos que superen el tamaño de su billetera.",
        back: "Regresa",
        closed: "Cerrado",
        open: "Abierto",
        share: "Compartir",
        accepted: "Aceptado",
        minquant: "Min. Cantidad"
    },
    MyOrdersScreen: {
        myorders: "Mis pedidos",
        purprice: "Precio de compra",
        quantity: "Cantidad",
        type: "Escribe",
        currentprice: "Precio actual",
        stoploss: "Stop Loss",
        stoplosstype: "Tipo de Stop Loss",
        currentpnl: "Pérdidas actuales",
        approvaldate: "Fecha de aprobación",
        originsignalid: "ID de señal de origen",
        realtime: "Tiempo real",
        state: "Estado",
        automatic: "Automático",
        manual: "Manual",
        target: "Objetive",
        pnl: "PnL",
        curstatus: "Estado actual",
        accepted: "Aceptado",
        finished: "Finalizado",
        cancelled: "Cancelado",
        closed: "Cerrado",
        open: "Abierto",
        incompletestatus: "Estado incompleto",
        active: "Activo",
        waiting: "Esperando",
        closedactive: "Orden activa cerrada",
        closedwaiting: "Orden de espera cerrada",
        closedbystoploss: "Cerrado por Stop Loss",
        closeconfirm: "Confirmación de cierre",
        sureclose: "¿Estás seguro de cerrar el pedido?",
        no: "No",
        yes: "Si",
        closeorder: "Cerrar la Orden",
        ordersuccessful: "Pedido exitoso",
        closedbysystem : "Cerrado por sistema"
    },
    FeedbackScreen: {
        whatdoyouthink: "Por favor comparta sus pensamientos.",
        sendfeedback: "Enviar comentarios",
        wehavereceived: "Tus pensamientos son importantes. Hemos recibido sus comentarios. Gracias por compartir.",
        success: "Éxito de retroalimentación",
        failed: "Comentarios fallidos",
    },
    SettingsScreen: {
        settings: "Ajustes",
        howtoaddexchange: "Preguntas más frecuentes",
        version: "Versión",
        logout: "Cerrar sesión",
        feedback: "Realimentación",
        aboutmyperformers: "Acerca de la aplicación MyPerformers",
        viewmode: "Modo de vista",
        darkmode: "Modo oscuro",
        lightmode: "Modo de luz",
    },
    AddExchangeScreen: {
        selectexchange: "Seleccione Exchange para agregar",
        volume24h: "Volumen 24h",
        exchangeerror: "Error de intercambio",
        notavailable: "No disponible por el momento",
        apion: "Api On",
        apioff: "Api Off",
        binance: {
            apikey: "Clave API",
            secretkey: "Llave secreta",
            showmykeys: "Mostrar mis llaves",
            showwebview: "Mostrar web",
            createexchange: "Crear cuenta de Exchange",
            warning: "Advertencia",
            optionswarning: "Asegúrese de marcar 'Habilitar el comercio al contado y de margen' en Restricciones de API",
            createwarning: "Para integrarse, cree una nueva clave de API",
            stepone: "Paso 1",
            steponevalue: "Iniciar sesión en Binance",
            steptwo: "Paso 2",
            steptwovalue: "Ir a la página de administración de API",
            stepthree: "Paso 3",
            stepthreevalue: "Crear nueva clave de API",
            stepfour: "Paso 4",
            stepfourvalue: "Habilite las opciones de negociación al contado y de margen",

        },
        back: "Regresa",
        camerapermissionerror: "Debe otorgar permiso a la cámara para escanear el código QR"
    },
    FAQScreen: {
        generaloverview: `1. General Overview`,
        dashboardscreen: `Dashboard Screen`,
        dashboardscreentext: `In the dashboard screen you will find your entire account overview under "My Exchange Accounts". Furthermore, you will find the total balance of your exchanges at the top of the screen, as well as a representation of the total market generated by CoinMarketCap in the middle of the screen.`,
        transactionhistory: `Transaction History`,
        transactionhistorytext: `The transaction history shows all orders that have been made so far to provide a general overview`,
        darklightmode: `Dark & Light Mode`,
        darklightmodetext: `In the settings you have the possibility to activate the Dark- or Light Mode and to set up MYPERFORMERS as individual as you like.`,
        usingcryptoperformer: "2. Using MYPERFORMERS",
        howtoconnectapi: `How to Connect a API?`,
        howtoconnectapitext: `If no account is connected yet, an Exchange account must be added here. Here you will be automatically redirected to the selection of exchanges that you can connect. Then you have to log in with your exchange data and, if necessary, complete the various verifications. You will then be taken to API Management, where you may need to create a new API and name it as you wish in the "key label" field. Consequently, your verifications will be requested again (if you have activated them). After successfully creating the API you have to copy and paste your API key and your secret key. You can find these keys at the top of the screen under "Show my keys". Now you are ready to go and you can find your connected exchange in your dashboard.`,
        whathappens: `What happens with automatic signals?`,
        whathappenstext: `Here signals from MYPERFORMERS verified traders are taken automatically. Stop loss, buy price as well as various sell targets are filled automatically as soon as they are reached.`,
        whathappensmanual: `What happens with manual signals?`,
        whathappensmanualtext: `Here signals from MYPERFORMERS verified traders are taken automatically. However, there is a possibility to manually configure the stop loss, the buy price, as well as all sell targets and the percentage distribution and adjust them to your own needs.`,
        openorder: `Open Order`,
        openordertext: `The order is open until the purchase price defined in the signals (automatic or manual) has been realized.`,
        cancelsignal: `Cancel Signal`,
        cancelsignaltext: `A signal can be canceled directly in the orders before the purchase price has been paid.`
    }
};

export default R;
