import { SMARTFLOWS } from "../../constants/ReduxStates";
import * as apiCalls from "../actions/apiCalls";

export function smartFlowsSuccess(payload) {
  return {
    type: SMARTFLOWS.SMARTFLOWS_SUCCESS,
    payload,
  };
}

export function checkSmartFlows() {
  return async function (dispatch, getState) {
    try {
      let result = await apiCalls.getMySmartFlows()(dispatch, getState);
      // await Notifications.setBadgeCountAsync(result.data.object);
      dispatch(smartFlowsSuccess(result.data.object));
    } catch (e) {
      console.error(e);
    }
  };
}
