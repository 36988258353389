import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageHeader from "../components/PageHeader";
import SignalSummary from "../components/SignalSummary";
import FilterButton from "../components/FilterButton";

import * as apiCalls from "../redux/actions/apiCalls";
import * as authActions from "../redux/actions/authActions";
import * as myXActionsActions from "../redux/actions/myXactionsActions";
import * as userCommChannelActions from "../redux/actions/userCommChannelActions";
import * as signalsActions from "../redux/actions/signalsActions";
import * as filtersActions from "../redux/actions/filtersActions";
import * as myExchangesActions from "../redux/actions/myExchangesActions";

import { EXCHANGES, MEMBERSHIP, SIGNALS } from "../constants/ReduxStates";

import {
  getBinanceQuantityPrecision,
  getBinancePricePrecision,
} from "../utils/binance-utils";

import { List } from "antd";
import { useNavigate } from "react-router-dom";

import { notification } from "antd";

import { AiOutlineWarning } from "react-icons/ai";

const isLoggedIn = (auth) => auth?.token !== undefined;

const openNotification = ({
  title = "Notification",
  description,
  duration,
  style,
}) => {
  const args = {
    message: title,
    description: description,
    duration: duration || 5,
    style: style || {},
  };
  notification.open(args);
};

const SignalsScreen = ({
  auth,
  myexchanges,
  deviceconfigs,
  colorscheme,
  language,
  navigation,
  filtersactions,
  filters,
  xactionssignals,
  xactionssignalsactions,
  membership,
  api,
  huobisymbols,
  cmcidmap,
  signalsactions,
  myexchangesactions,
  signals,
}) => {
  const navigate = useNavigate();

  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn(auth)) {
      navigate("/sign-in");
      return;
    }
    if (signals?.type !== SIGNALS.SIGNALS_LOAD_SUCCESS) {
      signalsactions.getSignals();
    }
    if (myexchanges?.type !== EXCHANGES.EXCHANGES_LOAD_SUCCESS) {
      myexchangesactions.checkExchanges();
    }
    if (myexchanges) {
      const pMyExchanges = myexchanges.payload;
      if (pMyExchanges) {
        loadMyXActionSignals();
      }
    }
  }, []);

  const signalsdata = signals.payload || [];
  signalsdata.forEach((item) => {
    try {
      item.icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/${
        cmcidmap[item.basecoin.toUpperCase()].id
      }.png`;
    } catch (e) {
      console.error(e);
    }
  });

  const fetchExchange = (symbol) => {
    return api.getExchangeInfoForSymbol(symbol);
  };

  const loadMyXActionSignals = useCallback(() => {
    console.log("Loading Accepted Signals");
    api
      .getCPSignalIdsOfMyOrders()
      .then((res) => {
        console.log("Foind Accepted Signals", res.data.object);
        if (res.data.object) {
          xactionssignalsactions.updateMyXActionsOnSignal(res.data.object);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const isInvestedIn = (signalid) => {
    console.log("checking is invested", signalid);
    const signals = xactionssignals?.payload;
    console.log("My Signals", xactionssignals);
    if (signals) {
      for (var i = 0; i < signals.length; i++) {
        if (signals[i].id === signalid) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const membershipExpired =
    membership && membership.type === MEMBERSHIP.MEMBERSHIP_EXPIRED;
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px`,
    },
    filterscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: `0px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    listcontainer: {
      flex: 1,
      paddingBottom: deviceconfigs.layout.xxlargepadding,
      paddingTop: "108px",
    },
  };
  const renderItem = (item) => {
    const pMyExchanges = myexchanges.payload;
    const status =
      item.state === "EXPIRED"
        ? {
            text: language.closed,
            color: colorscheme.v2.errorbackground,
            textcolor: colorscheme.v2.error,
            date: item.timestamps.closedate,
          }
        : isInvestedIn(item.id)
        ? {
            text: language.accepted,
            color: colorscheme.v2.successbackground,
            textcolor: colorscheme.v2.success,
            date: item.accepteddate,
          }
        : {
            text: language.open,
            color: colorscheme.v2.primarybackground,
            textcolor: colorscheme.v2.primary,
            date: item.creationdate,
          };
    const pMyExchangesData = pMyExchanges?.data;
    let xResult = (pMyExchangesData || []).filter((a) => {
      return a.type === item.exchange;
    });
    const hasIntegratedExchange = xResult && xResult.length > 0;
    return (
      <List.Item key={item.id} style={{ border: "none" }}>
        <SignalSummary
          id={item.id}
          icon={item.icon}
          basecoin={item.basecoin}
          quotecoin={item.quotecoin}
          exchange={item.exchange}
          timestamp={item.creationdate}
          invested={isInvestedIn(item.id)}
          date={status.date}
          status={status}
          onClick={async () => {
            if (membershipExpired) {
              openNotification({
                title: language.error.expirederror.header,
                description: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AiOutlineWarning
                        style={{
                          color: colorscheme.v2.warning,
                          fontSize: deviceconfigs.icon.largeicon,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "12px",
                      }}
                    >
                      {language.error.expirederror.body}
                    </div>
                  </div>
                ),
              });
              return;
            }
            if (!hasIntegratedExchange) {
              openNotification({
                title: language.error.exchangeaccountspecifiednotconnected(
                  item.exchange
                ).header,
                description: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AiOutlineWarning
                        style={{
                          color: colorscheme.v2.warning,
                          fontSize: deviceconfigs.icon.largeicon,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "12px",
                      }}
                    >
                      {
                        language.error.exchangeaccountspecifiednotconnected(
                          item.exchange
                        ).body
                      }
                    </div>
                  </div>
                ),
              });
              return;
            }
            let signal = (await api.getCPSignal(item.id)).data.object;
            if (signal.exchange === "BINANCE") {
              signal.info = (
                await fetchExchange(signal.name)
              ).data.object.symbols[0];
              signal.precision = getBinancePricePrecision(signal);
              signal.valueprecision = getBinancePricePrecision(signal);
              signal.amountprecision = getBinanceQuantityPrecision(signal);
            } else if (signal.exchange === "HUOBI") {
              const hSymbols = huobisymbols.payload;
              if (hSymbols) {
                for (var i = 0; i < hSymbols.length; i++) {
                  if (hSymbols[i].symbol.toUpperCase() === signal.name) {
                    signal.precision = hSymbols[i]["price-precision"];
                    signal.valueprecision = hSymbols[i]["value-precision"];
                    signal.amountprecision = hSymbols[i]["amount-precision"];
                    signal.minordervalue = hSymbols[i]["min-order-value"];
                    signal.info = hSymbols[i];
                  }
                }
              }
            }
            signal.icon = item.icon;
            navigate("/signals/signal-details", { state: { signal } });
          }}
        />
      </List.Item>
    );
  };
  const filteredsignals = signalsdata.filter((a) => {
    for (var y = 0; y < (xactionssignals?.payload || []).length; y++) {
      if (xactionssignals.payload[y].id === a.id) {
        a.signalstatus = "ACCEPTED";
        a.accepteddate = xactionssignals.payload[y].creationdate;
        break;
      }
    }
    return statusSellableStatusResolver(a, filters.payload.signal);
  });
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          zIndex: 100,
          top: "0px",
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.signals}
            onBackPressed={() => {
              navigate("/");
            }}
          />
        </div>
        <div style={styles.filterscontainer}>
          <FilterButton
            selected={filters.payload.signal.open}
            onClick={() => {
              filtersactions.updateSignalFilters({
                ...filters.payload.signal,
                open: !filters.payload.signal.open,
              });
            }}
            text={language.open}
          />
          <FilterButton
            selected={filters.payload.signal.accepted}
            onClick={() => {
              filtersactions.updateSignalFilters({
                ...filters.payload.signal,
                accepted: !filters.payload.signal.accepted,
              });
            }}
            text={language.accepted}
          />
          <FilterButton
            selected={filters.payload.signal.cancelled}
            onClick={() => {
              filtersactions.updateSignalFilters({
                ...filters.payload.signal,
                cancelled: !filters.payload.signal.cancelled,
              });
            }}
            text={language.closed}
          />
        </div>
      </div>
      <div style={styles.listcontainer}>
        <List
          style={{
            flex: 1,
            marginHorizontal: deviceconfigs.layout.xlargepadding,
            paddingVertical: deviceconfigs.layout.mediumpadding,
          }}
          loading={refreshing}
          onRefresh={() => {}}
          dataSource={filteredsignals}
          renderItem={renderItem}
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
};

function statusSellableStatusResolver(item, filters) {
  const { state, signalstatus } = item;
  if (state === "EXPIRED") {
    return filters.cancelled;
  }

  switch (signalstatus) {
    case "ACCEPTED":
      return filters.accepted;
    default:
      return filters.open;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(authActions, dispatch),
    api: bindActionCreators(apiCalls, dispatch),
    usercommchannelactions: bindActionCreators(
      userCommChannelActions,
      dispatch
    ),
    xactionssignalsactions: bindActionCreators(myXActionsActions, dispatch),
    filtersactions: bindActionCreators(filtersActions, dispatch),
    signalsactions: bindActionCreators(signalsActions, dispatch),
    myexchangesactions: bindActionCreators(myExchangesActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
    signals: state.signals,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignalsScreen);
