import * as Constants from '../../constants/ReduxStates';

const initialAuthState = {
};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.BALANCESDISPLAY.BALANCESHOW:
            return { ...action };
        case Constants.BALANCESDISPLAY.BALANCEHIDE:
            return { ...action };
        default:
            return state;
    }
}
