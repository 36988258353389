import { CMCIDMAP } from "../../constants/ReduxStates";
import { getIDMapDBOnce } from "../../services/firebase";

export function idMapLoadSuccess(payload) {
  return {
    type: CMCIDMAP.CMCIDMAPSUCCESS,
    payload,
  };
}

export function idMapLoadFail() {
  return {
    type: CMCIDMAP.CMCIDMAPFAIL,
  };
}

export function loadIDMap() {
  return function (dispatch) {
    try {
      getIDMapDBOnce((snapshot) => {
        const data = snapshot.val();

        if (data.data) {
          var result = data.data.reduce((map, curr) => {
            map[curr.symbol] = curr;
            return map;
          }, {});
          dispatch(idMapLoadSuccess(result));
        } else {
          dispatch(idMapLoadFail());
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
}
