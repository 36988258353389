import { PERSONAL_PERFORMANCES } from "../../constants/ReduxStates";
import * as apiCalls from "./apiCalls";

export function performanceLoadSuccess(payload) {
    return {
        type: PERSONAL_PERFORMANCES.PERSONAL_PERFORMANCES_LOAD_SUCCESS,
        payload,
    };
}

export function cratePersonalPerformance() {
    return async function (dispatch, getState) {
        try {
            console.log("fkalfjajfslafljal");
            let result = await apiCalls.createPersonalAutomaticPerformances()(dispatch, getState);
            console.log("Result", result.data.object)
            dispatch(performanceLoadSuccess(result.data.object));
        } catch (e) {
            console.error(e);
        }
    };
}
