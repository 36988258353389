import { PERFORMANCES } from "../../constants/ReduxStates";
import * as apiCalls from "./apiCalls";

export function performanceLoadSuccess(payload) {
  return {
    type: PERFORMANCES.AUTOMATIC_LOAD_SUCCESS,
    payload,
  };
}

export function getAutomaticPerformances() {
  return async function (dispatch, getState) {
    try {
      let result = await apiCalls.getAutomaticPerformance()(dispatch, getState);
      console.log({ result });
      dispatch(performanceLoadSuccess(result.data.object));
    } catch (e) {
      console.error(e);
    }
  };
}
