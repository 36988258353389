import { LANGUAGE } from '../../constants/ReduxStates';
import { EN, DE, TR, ES } from "../../services/language";

export function languageSelected(language) {
    return {
        type: LANGUAGE.LANGUAGE_SELECTED,
        payload: language,
    };
}

export function languageNotSelected() {
    return {
        type: LANGUAGE.LANGUAGE_NOT_SELECTED,
    };
}

export function selectLanguage(language) {
    return function (dispatch) {
        switch (language) {
            case "en":
                dispatch(languageSelected(EN));
                return;
            case "de":
                dispatch(languageSelected(DE));
                return;
            case "es":
                dispatch(languageSelected(ES));
                return;
            case "fr":
                dispatch(languageSelected(EN));
                return;
            case "tr":
                dispatch(languageSelected(TR));
                return;
            case "it":
                dispatch(languageSelected(EN));
                return;
            case "nl":
                dispatch(languageSelected(EN));
                return;
            case "rus":
                dispatch(languageSelected(EN));
                return;
            case "ar":
                dispatch(languageSelected(EN));
                return;
            default:
                dispatch(languageSelected(EN));
                return;
        }
    }
}

export function updateLanguage(language) {
    return function (dispatch) {
        switch (language) {
            case "en":
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
            case "de":
                localStorage.setItem('language', language);
                dispatch(languageSelected(DE));
                return;
            case "es":
                localStorage.setItem('language', language);
                dispatch(languageSelected(ES));
                return;
            case "fr":
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
            case "tr":
                localStorage.setItem('language', language);
                dispatch(languageSelected(TR));
                return;
            case "it":
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
            case "nl":
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
            case "rus":
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
            case "ar":
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
            default:
                localStorage.setItem('language', language);
                dispatch(languageSelected(EN));
                return;
        }
    }
}

export function getSelectedLanguage() {
    return function (dispatch) {
        let language = localStorage.getItem('language');
        switch (language) {
            case "en":
                dispatch(languageSelected(EN));
                return;
            case "de":
                dispatch(languageSelected(DE));
                return;
            case "es":
                dispatch(languageSelected(ES));
                return;
            case "tr":
                dispatch(languageSelected(TR));
                return;
            default:
                dispatch(languageSelected(EN));
        }
    }
}