import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const CoinSummaryCard = ({
  key,
  deviceconfigs,
  language,
  colorscheme,
  icon,
  name,
  trend,
  value,
  changeIn24h,
  changeIn7d,
  chart,
  onClick,
}) => {
  const styles = {
    maincontainer: {
      padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      borderRadius: deviceconfigs.layout.xxlargepadding,
      backgroundColor: colorscheme.v2.cardbackground,
      width: "36vw",
      height: "100%",
      margin: `0px ${deviceconfigs.layout.largepadding}px`,
    },
    nameandiconcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    iconcontainer: {
      marginRight: deviceconfigs.layout.xlargepadding,
    },
    icon: {
      width: deviceconfigs.layout.xxxxlargepadding,
      height: deviceconfigs.layout.xxxxlargepadding,
      borderRadius: 999,
      objectFit: "center",
    },
    namecontainer: {
      flex: 1,
      width: "42%",
    },
    name: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.backgroundtext,
      height: "24px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    valuecontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
      marginLeft: deviceconfigs.layout.largepadding,
    },
    valueup: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.success,
    },
    valuedown: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.error,
    },
    itemscontainer: {
      flexDirection: "row",
      marginLeft: deviceconfigs.layout.largepadding,
      flex: 1,
    },
    itemcontainer: {},
    itemwrapper: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    itemheader: {
      fontSize: deviceconfigs.font.mediumtext,
      color: colorscheme.v2.heavytext,
    },
    itemvalueup: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "500",
      color: colorscheme.v2.success,
    },
    itemvaluedown: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "500",
      color: colorscheme.v2.error,
    },
    chartcontainer: {
      marginTop: deviceconfigs.layout.largepadding,
    },
    chart: {},
  };
  return (
    <div key={key} style={styles.maincontainer} onClick={onClick}>
      <div style={styles.nameandiconcontainer}>
        <div style={styles.iconcontainer}>
          <img src={icon} style={styles.icon} alt={name} />
        </div>
        <div style={styles.namecontainer}>
          <div style={styles.name}>{name}</div>
        </div>
      </div>
      <div style={styles.valuecontainer}>
        <div style={trend === "up" ? styles.valueup : styles.valuedown}>
          {value}
        </div>
      </div>
      <div style={styles.itemscontainer}>
        <div
          style={{
            flex: 1,
          }}
        >
          <div style={styles.itemwrapper}>
            <div style={styles.itemheader}>
              24h %{" "}
              <div
                style={
                  changeIn24h > 0 ? styles.itemvalueup : styles.itemvaluedown
                }
              >
                {changeIn24h}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.chartcontainer}></div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
    balancedisplay: state.balancedisplay,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinSummaryCard);
