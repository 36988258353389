import { useState } from "react";

import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import PageView from "../components/Page";
import PageHeader from '../components/PageHeader';

const SUBSCRIPTIONTYPES = [
    {
        id: "1",
        name: {
            en: "1 Month Subscription",
            tr: "1 Aylık Üyelik",
            de: "1 Monat Abonnement",
        },
        type: "MONTHLY",
        price: "$25.00",
    },
    {
        id: "6",
        name: {
            en: "6 Months Subscription",
            tr: "6 Aylık Üyelik",
            de: "6 Monate Abonnement",
        },
        type: "SIXMONTHS",
        price: "$150.00",
    },
    {
        id: "12",
        name: {
            en: "12 Months Subscription",
            tr: "12 Aylık Üyelik",
            de: "12 Monate Abonnement",
        },
        type: "YEARLY",
        price: "$300.00",
    }
];

const SelectMembershipScreen = ({ deviceconfigs, language, colorscheme }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        headercontainer: {
        },
    };

    const membershipsMap = (SUBSCRIPTIONTYPES || []).map((item) => {
        return (
            <div
                key={item.id}
                style={{
                    backgroundColor: colorscheme.v2.primary,
                    margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
                    padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                    borderRadius: deviceconfigs.layout.xlargepadding,
                    borderColor: colorscheme.v2.primary,
                }}
                onClick={() => {
                    navigate("/memberships/select-payment-coin", { state: { membership: item } })
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <div style={{
                        flex: 4,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",

                    }}>
                        <div style={{
                            textAlign: "center",
                            fontSize: deviceconfigs.font.xlargetext,
                            fontWeight: "700",
                            color: colorscheme.v2.primarytext,
                        }}>{item.name[language._denom]}</div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: 'center',
                    alignItems: "center",
                    marginTop: deviceconfigs.layout.xxlargepadding,
                }}>
                    <div style={{
                        width: "22vw",
                        borderRadius: deviceconfigs.layout.xlargepadding,
                        backgroundColor: colorscheme.v2.background,
                        padding: `${deviceconfigs.layout.xlargepadding}px 0px`,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div style={{
                            fontSize: deviceconfigs.font.largetext,
                            fontWeight: "700",
                            color: colorscheme.v2.backgroundtext,
                        }}>{item.price}</div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <PageView style={styles.mainframe} loading={loading}>
            <div style={styles.headercontainer}>
                <PageHeader
                    onBackPressed={() => {
                        window.history.back();
                    }}
                    header={language.selectsubscriptiontype}
                />
            </div>
            <div>
                <div style={{
                }}>
                    {membershipsMap}
                </div>
            </div>
        </PageView>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        myexchanges: state.myexchanges,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectMembershipScreen);