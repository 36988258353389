import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as apiCalls from "../redux/actions/apiCalls";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import NewsSummary from "../components/NewsSummary";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavBar from "../components/BottomNavBar";

const NewsScreen = ({
  api,
  auth,
  language,
  colorscheme,
  deviceconfigs,
  newsdata,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      display: "flex",
      flexDirection: "column",
    },
    headercontainer: {
      marginTop: deviceconfigs.layout.xlargepadding,
      marginBottom: deviceconfigs.layout.largepadding,
    },
    coinheadercontainer: {
      marginLeft: deviceconfigs.layout.xxxlargepadding,
    },
    coinheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "500",
      color: colorscheme.v2.primaryalternative,
    },
    subheadercontainer: {
      display: "flex",
      padding: `${12}px ${deviceconfigs.layout.xxxxlargepadding}px`,
      flexDirection: "row",
      alignItems: "center",
    },
    subheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    flatlistcontainer: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      paddingTop: "112px",
    },
  };

  useEffect(() => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dataMap = (newsdata || []).map((data) => {
    return data.map((item) => {
      return (
        <div>
          <NewsSummary
            title={item.title}
            imagelink={item.image_url}
            link={item.news_url}
            creationdate={Date.parse(item.date)}
            source={item.source_name}
            tickers={item.tickers}
            onClick={() => {
              navigate("/news/details", {
                state: { link: item.news_url, sourcename: item.source_name },
              });
            }}
          />
        </div>
      );
    });
  });
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          top: "0px",
          zIndex: 100,
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.subheadercontainer}>
          <div style={styles.subheader}>{language.news}</div>
        </div>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="News Tabs"
            textColorPrimary={colorscheme.v2.primary}
            textColor={"primary"}
            indicatorColor={"primary"}
          >
            <Tab
              label={language.topnews}
              style={{
                color: colorscheme.v2.backgroundtext,
                fontWeight: "600",
                fontSize: "11px",
              }}
            />
            <Tab
              label={language.bitcoin}
              style={{
                color: colorscheme.v2.backgroundtext,
                fontWeight: "600",
                fontSize: "11px",
              }}
            />
            <Tab
              label={language.altcoins}
              style={{
                color: colorscheme.v2.backgroundtext,
                fontWeight: "600",
                fontSize: "11px",
              }}
            />
            <Tab
              label={language.nft}
              style={{
                color: colorscheme.v2.backgroundtext,
                fontWeight: "600",
                fontSize: "11px",
              }}
            />
          </Tabs>
        </div>
      </div>
      <div style={styles.flatlistcontainer}>{dataMap[value]}</div>
      <BottomNavBar />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    colorscheme: state.colorscheme,
    deviceconfigs: state.deviceconfigs,
    newsdata: state.newsdata,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsScreen);
