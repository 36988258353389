import { FILTERS } from "../../constants/ReduxStates";

export function loadFiltersSuccess(payload) {
    return {
        type: FILTERS.FILTERS_LOAD_SUCCESS,
        payload,
    };
}

export function loadFiltersFailed() {
    return {
        type: FILTERS.FILTERS_LOAD_FAILED,
    };
}

export function updateSignalFilters(filter) {
    return function (dispatch) {
        try {
            let filters = JSON.parse(localStorage.getItem("filters"));
            if (filters) {
                filters = {
                    ...filters,
                    signal: {
                        ...filter
                    }
                }
                localStorage.setItem("filters", JSON.stringify(filters));
                dispatch(loadFiltersSuccess(filters));
            } else {
                const fFilters = {
                    signal: {
                        ...filter
                    },
                    order: {
                        accepted: true,
                        finished: true,
                        cancelled: true,
                        stoploss: true,
                    }
                };
                localStorage.setItem("filters", JSON.stringify(fFilters));
                dispatch(loadFiltersSuccess(fFilters));
            }
        } catch (e) {
            console.error(e);
            console.log("Error filters");
        }
    }
}

export function updateOrderFilters(filter) {
    return function (dispatch) {
        try {
            let filters = JSON.parse(localStorage.getItem("filters"));
            if (filters) {
                filters = {
                    ...filters,
                    order: {
                        ...filter
                    }
                }
                localStorage.setItem("filters", JSON.stringify(filters));
                dispatch(loadFiltersSuccess(filters));
            } else {
                const fFilters = {
                    signal: {
                        open: true,
                        accepted: true,
                        cancelled: true,
                        stoploss: true,
                    },
                    order: {
                        ...filter
                    }
                };
                localStorage.setItem("filters", JSON.stringify(fFilters));
                dispatch(loadFiltersSuccess(fFilters));
            }
        } catch (e) {
            console.error(e);
            console.log("Error filters");
        }
    }
}

export function loadFilters() {
    return function (dispatch) {

        try {
            const filters = JSON.parse(localStorage.getItem("filters"));
            if (filters) {
                dispatch(loadFiltersSuccess(filters));
            } else {
                const fFilters = {
                    signal: {
                        open: true,
                        accepted: true,
                        cancelled: true,
                        stoploss: true,
                    },
                    order: {
                        accepted: true,
                        finished: true,
                        cancelled: true,
                        stoploss: true,
                    }
                };
                localStorage.setItem("filters", JSON.stringify(fFilters));
                dispatch(loadFiltersSuccess(fFilters));
            }

        } catch (e) {
            console.error(e);
            console.log("Error filters");
        }

    }
}