import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
    AiFillCaretDown,
    AiFillCaretUp,
    AiOutlineCaretDown
} from 'react-icons/ai';

const MarketDataSummary = ({ deviceconfigs, language, colorscheme, icon, symbol, name, index, price, changeIn24h, marketcap, volume, pricestyle, chart, onClick }) => {
    const styles = {
        maincontainer: {
            display: "flex",
            flexDirection: "row",
            borderBottomColor: colorscheme.v2.bordercolor,
            borderBottomWidth: 1,
            padding: deviceconfigs.layout.largepadding,
        },
        iconcontainer: {
            padding: deviceconfigs.layout.smallpadding,
        },
        icon: {
            width: deviceconfigs.button.largesize,
            height: deviceconfigs.button.largesize,
            objectFit: "contain",
            borderRadius: 999,
        },
        nameandsummarycontainer: {
            flex: 2,
        },
        namecontainer: {
            marginBottom: deviceconfigs.layout.largepadding,
        },
        name: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        summarycontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        indexcontainer: {
            padding: `${deviceconfigs.layout.mediumpadding}px ${deviceconfigs.layout.largepadding}px`,
            borderRadius: deviceconfigs.layout.largepadding,
            backgroundColor: colorscheme.v2.bordercolor,
        },
        index: {

        },
        symbolcontainer: {
            margin: `0px ${deviceconfigs.layout.largepadding}px`,
            width: "12vw",
        },
        symbol: {
            color: colorscheme.v2.backgroundtext
        },
        changecontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        changepositive: {
            marginLeft: deviceconfigs.layout.mediumpadding,
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "500",
            color: colorscheme.v2.success,
        },
        changenegative: {
            marginLeft: deviceconfigs.layout.mediumpadding,
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "500",
            color: colorscheme.v2.error,
        },
        chartcontainer: {
            flex: 1,
        },
        valuescontainer: {
            flex: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
        },
        valuecontainer: {

        },
        value: {
            fontSize: "11px",
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        marketcapcontainer: {

        },
        marketcap: {
            fontSize: "11px",
            fontWeight: "700",
            color: colorscheme.v2.unselected,
        }
    };
    const changeIn24hVal = parseFloat(changeIn24h);
    const positive = changeIn24hVal > 0;
    const changeStr = changeIn24hVal.toFixed(2);
    const priceStr = parseFloat(price).toFixed(8);
    return (
        <div style={styles.maincontainer} onClick={onClick}>
            {icon ? <div style={styles.iconcontainer}>
                <img
                    src={icon}
                    style={styles.icon}
                />
            </div> : null}
            <div style={styles.nameandsummarycontainer}>
                <div style={styles.namecontainer}>
                    <div style={styles.name}>{name}</div>
                </div>
                <div style={styles.summarycontainer}>
                    <div style={styles.indexcontainer}>
                        <div style={styles.index}>{index}</div>
                    </div>
                    <div style={styles.symbolcontainer}>
                        <div style={styles.symbol}>{symbol}</div>
                    </div>
                    <div style={styles.changecontainer}>
                        {positive ?
                            <AiFillCaretUp
                                style={{
                                    fontSize: deviceconfigs.button.mediumsize,
                                    color: colorscheme.v2.success,
                                }}
                            />
                            : <AiFillCaretDown
                                style={{
                                    fontSize: deviceconfigs.button.mediumsize,
                                    color: colorscheme.v2.error,
                                }}
                            />
                        }
                        <div style={positive ? styles.changepositive : styles.changenegative}>
                            {changeStr}
                        </div>
                    </div>
                </div>
            </div>
            <div style={styles.chartcontainer}>
                {/* <SvgUri
                    width={width * 0.134}
                    height={width * 0.096}
                    uri={chart}
                /> */}
                <img
                    src={chart}
                    style={{
                        width: "13vw",
                        height: "10vw",
                    }}
                />
            </div>
            <div style={styles.valuescontainer}>
                <div style={styles.valuecontainer}>
                    <div style={styles.value}>
                        {"$ " + priceStr}
                    </div>
                </div>
                <div style={styles.marketcapcontainer}>
                    <div style={styles.marketcap}>
                        {abbreviateNumber(marketcap)}
                    </div>
                </div>
            </div>
        </div>
    );
}

var SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];

function abbreviateNumber(number) {

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketDataSummary);