import React from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as apiCalls from '../redux/actions/apiCalls';
import * as languageActions from '../redux/actions/languageActions';

import PageView from '../components/Page';
import PageHeader from '../components/PageHeader';
import SettingsButton from '../components/SettingsButton';

import {
    HiOutlineChevronRight
} from 'react-icons/hi';

const LanguageSettingsScreen = ({ deviceconfigs, language, colorscheme, settings, auth, api, languageactions, navigation }) => {
    const styles = {
        mainframe: {
            flex: 1,
        },
        headercontainer: {
            marginBottom: deviceconfigs.layout.largepadding,
        },
        scrollcontainer: {
            flex: 1,
        },
        scroll: {

        },
        menuitemscontainer: {
            margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxxlargepadding}px`,
        },
        menubuttoncontainertopradiusborder: {
            borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
            borderTopRightRadius: deviceconfigs.layout.xlargepadding,
            borderBottomStyle: "solid",
        },
        menubuttoncontainerbottomradiusborder: {
            borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
            borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
            borderBottomStyle: "solid",
        },
        menubuttoncontainerbottomborder: {
            borderBottomColor: colorscheme.v2.bordercolor,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
        },
        menucontainer: {
            flex: 1,
        },
        subheadercontainer: {
            margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxxxlargepadding}px`,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        subheader: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "600",
            color: colorscheme.v2.backgroundtext,
        },
    };
    return (
        <PageView style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <PageHeader
                    header={language.language}
                    onBackPressed={() => {
                        window.history.back();
                    }}
                />
            </div>
            <div style={styles.scrollcontainer}>
                <div style={styles.menucontainer}>
                    <div style={styles.menuitemscontainer}>
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder
                            }}
                            lefttext={language.english}
                            righticon={
                                <HiOutlineChevronRight
                                    style={{
                                        fontSize: "24px",
                                        color: colorscheme.v2.backgroundtext,
                                    }}
                                />
                            }
                            onClick={async () => {
                                languageactions.updateLanguage("en");
                                let updateResults = await api.updateUserSettings({ language: "ENGLISH" });
                                window.history.back();
                            }}
                        />
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder
                            }}
                            lefttext={language.german}
                            righticon={
                                <HiOutlineChevronRight
                                    style={{
                                        fontSize: "24px",
                                        color: colorscheme.v2.backgroundtext,
                                    }}
                                />
                            }
                            onClick={async () => {
                                languageactions.updateLanguage("de");
                                let updateResults = await api.updateUserSettings({ language: "GERMAN" });
                                window.history.back();
                            }}
                        />
                        <SettingsButton
                            framestyle={{
                                ...styles.menubuttoncontainerbottomborder
                            }}
                            lefttext={language.turkish}
                            righticon={
                                <HiOutlineChevronRight
                                    style={{
                                        fontSize: "24px",
                                        color: colorscheme.v2.backgroundtext,
                                    }}
                                />
                            }
                            onClick={async () => {
                                languageactions.updateLanguage("tr");
                                let updateResults = await api.updateUserSettings({ language: "TURKISH" });
                                window.history.back();
                            }}
                        />
                    </div>
                </div>
            </div>
        </PageView>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
        languageactions: bindActionCreators(languageActions, dispatch)
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettingsScreen);