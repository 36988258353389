import { COMMCHANNEL } from '../../constants/ReduxStates';

export function loadMyCommChannelSuccess(payload) {
    return {
        type: COMMCHANNEL.COMM_CHANNEL_LOAD_SUCCESS,
        payload,
    };
}

export function loadMyCommChannelFail() {
    return {
        type: COMMCHANNEL.COMM_CHANNEL_LOAD_FAIL
    };
}

export function getMyCommChannel() {
    return function (dispatch) {
        let commchannel = JSON.parse(localStorage.getItem("commchannel"));
        if (commchannel) {
            dispatch(loadMyCommChannelSuccess(commchannel));
        } else {
            dispatch(loadMyCommChannelFail());
        }

    };
}

export function saveCommChannel(commchannel) {
    return function (dispatch) {
        if (commchannel === undefined) {
            return;
        }
        localStorage.setItem("commchannel", JSON.stringify(commchannel));
        dispatch(loadMyCommChannelSuccess(commchannel));
    };
}

export function deleteCommChannel() {
    return function (dispatch) {
        localStorage.removeItem("commchannel");
        dispatch(loadMyCommChannelFail());
    };
}
