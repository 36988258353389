import ReactECharts from 'echarts-for-react';
import { connect } from "react-redux";

const SmartFlowPerformanceChart = ({
  deviceconfigs,
  language,
  colorscheme,
  options,
  name,
  containerStyle,
}) => {
  const styles = {
    containerStyle: {},
  };
  return (
    <div
      style={{
        ...styles.containerStyle,
        ...containerStyle,
      }}
    >
      <ReactECharts option={options} />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    myexchanges: state.myexchanges,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    balancedisplay: state.balancedisplay,
    membership: state.membership,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartFlowPerformanceChart);
