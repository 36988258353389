import { XACTIONS } from '../../constants/ReduxStates';

export function xActionsLoadSuccess(payload) {
    return {
        type: XACTIONS.XACTIONS_LOADED,
        payload
    };
}

export function xActionsLoadFailed() {
    return {
        type: XACTIONS.XACTIONS_FAILED,
    };
}

export function updateMyXActionsOnSignal(signals) {
    return async function (dispatch) {
        localStorage.setItem('xactionsonsignalids', JSON.stringify(signals));
        dispatch(xActionsLoadSuccess(signals));
    }
}