import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { notification } from "antd";

import { useNavigate } from "react-router-dom";

import PageView from "../components/Page";
import AddExchangeButton from "../components/AddExchangeButton";
import AddExchangeStepCounter from "../components/AddExchangeStepCounter";

import { HiChevronLeft } from "react-icons/hi";

import { AiOutlineWarning } from "react-icons/ai";

import PopularExchanges from "../constants/Exchanges";

const POPULAREXCHANGES = PopularExchanges.filter((a) => {
  return a.selectable;
});

const AddExchangeAccountSelectExchangeScreen = ({
  deviceconfigs,
  auth,
  colorscheme,
  language,
  myexchanges,
}) => {
  const navigate = useNavigate();
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    topsummarycardcontainer: {
      backgroundColor: colorscheme.v2.primary,
      borderBottomLeftRadius: "12vw",
      borderBottomRightRadius: "12vw",
      padding: `0px ${deviceconfigs.layout.xlargepadding}px 32vw ${deviceconfigs.layout.xlargepadding}px`,
    },
    titleandquestioncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titlecontainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    backbuttoncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.primary,
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    titletextcontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    profilename: {
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
    },
    questioncontainer: {
      marginRight: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.primaryalternative,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: deviceconfigs.layout.xlargepadding,
    },
    stepscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      marginTop: deviceconfigs.layout.xxxxlargepadding,
    },
    stepstextcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginVertical: deviceconfigs.layout.xlargepadding,
    },
    stepstext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "600",
      color: colorscheme.v2.lighttext,
    },
    majorstepoff: {
      width: deviceconfigs.layout.xlargepadding,
      height: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.unselected,
      borderRadius: 999,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    majorstepon: {
      width: deviceconfigs.layout.xxlargepadding,
      height: deviceconfigs.layout.xxlargepadding,
      backgroundColor: colorscheme.v2.selected,
      borderRadius: 999,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    minorstep: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      width: deviceconfigs.layout.largepadding,
      height: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.unselected,
      borderRadius: 999,
    },
    selectorcontainer: {
      borderRadius: deviceconfigs.layout.xxxxlargepadding,
      width: "84vw",
      height: "62vh",
      position: "absolute",
      top: "28vh",
      left: "8vw",
      backgroundColor: colorscheme.v2.background,
    },
    selectexchangetextcontainer: {
      padding: deviceconfigs.layout.xxlargepadding,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    selectexchangetext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "500",
      color: colorscheme.v2.heavytext,
    },
  };
  const openNotification = useCallback(
    ({ title = language.notification, description, duration, style }) => {
      const args = {
        message: title,
        description: description,
        duration: duration || 5,
        style: style || {},
      };
      notification.open(args);
    },
    [language.notification]
  );
  const exchangeMaps = (POPULAREXCHANGES || []).map((item, index) => {
    if (myexchanges) {
      const pMyExchanges = myexchanges.payload;
      if (pMyExchanges) {
        if (pMyExchanges.data) {
          const pMyExchangesData = pMyExchanges.data;
          for (var i = 0; i < pMyExchangesData.length; i++) {
            if (pMyExchangesData[i].type === item.type) {
              item.creationdate = pMyExchangesData[i].creationdate;
              break;
            }
          }
        }
      }
    }
    return (
      <div key={item.id}>
        <AddExchangeButton
          icon={item.icon}
          text={item.text}
          selected={item.selected}
          creationdate={item.creationdate}
          supported={item.supported}
          onClick={() => {
            if (myexchanges) {
              const pMyExchanges = myexchanges.payload;
              if (pMyExchanges) {
                if (pMyExchanges.data) {
                  const pMyExchangesData = pMyExchanges.data;
                  for (var i = 0; i < pMyExchangesData.length; i++) {
                    if (pMyExchangesData[i].type === item.type) {
                      openNotification({
                        title: language.warning,
                        description: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <AiOutlineWarning
                                style={{
                                  color: colorscheme.v2.warning,
                                  fontSize: deviceconfigs.icon.largeicon,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: "12px",
                              }}
                            >
                              {language.youhavealreadyaccountintegrated(
                                item.text
                              )}
                            </div>
                          </div>
                        ),
                      });
                      return;
                    }
                  }
                }
              }
            }
            navigate("/add-exchange-select-method", {
              state: { selectedexchange: item },
            });
          }}
        />
      </div>
    );
  });
  return (
    <PageView style={styles.mainframe}>
      <div style={styles.topsummarycardcontainer}>
        <div style={styles.titleandquestioncontainer}>
          <div style={styles.titlecontainer}>
            <div
              style={styles.backbuttoncontainer}
              onClick={() => {
                window.history.back();
              }}
            >
              <HiChevronLeft
                style={{
                  fontSize: "28px",
                  color: colorscheme.v2.primarytext,
                }}
              />
            </div>
            <div style={styles.titletextcontainer}>
              <div style={styles.profilename}>
                {language.connectyourexchange}
              </div>
            </div>
          </div>
        </div>
        <div style={{}}>
          <AddExchangeStepCounter step={1} />
        </div>
      </div>
      <div style={styles.selectorcontainer}>
        <div style={styles.selectexchangetextcontainer}>
          <div style={styles.selectexchangetext}>{language.selectexchange}</div>
        </div>
        <div style={styles.scrollcontainer}>
          {exchangeMaps}
          <div
            style={{
              height: "20vh",
            }}
          ></div>
        </div>
      </div>
    </PageView>
  );
};

AddExchangeAccountSelectExchangeScreen.propTypes = {};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    loading: state.loading,
    balances: state.balances,
    myexchanges: state.myexchanges,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExchangeAccountSelectExchangeScreen);
