import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { BsDashLg } from 'react-icons/bs';

const AutomaticPersonalProfitTag = ({
    deviceconfigs,
    language,
    colorscheme,
    value,
}) => {
    const styles = {
        maincontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        caretContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
        textcontainer: {
            marginLeft: "4px",
        }
    };
    const caretStyle = value > 0 ? { color: colorscheme.v2.success, fontSize: "24px" } : { color: colorscheme.v2.error, fontSize: "24px" };
    const valueStyle = value > 0 ? { color: colorscheme.v2.success, fontSize: "14px" } : { color: colorscheme.v2.error, fontSize: "14px" };
    const caret = value == 0 ? <BsDashLg /> : value > 0 ? <AiFillCaretUp style={caretStyle} /> : <AiFillCaretDown style={caretStyle} />
    return (
        <div style={styles.maincontainer}>
            <div style={styles.caretContainer}>
                {caret}
            </div>
            <div style={styles.textcontainer}>
                <div style={valueStyle}>{"%" + value.toFixed(5)}</div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {};
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutomaticPersonalProfitTag);