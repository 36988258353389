import * as Constants from "../../constants/ReduxStates";

const initialAuthState = [];

export default function reducer(state = initialAuthState, action) {
  switch (action.type) {
    case Constants.CMCLISTING.CMCLISTING_SUCCESS:
      return action.payload;
    case Constants.CMCLISTING.CMCLISTING_FAIL:
      return { ...action };
    default:
      return state;
  }
}
