import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
  switch (action.type) {
    case Constants.DEVICECONFIGS.LOAD_DEVICE_CONFIGS_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}
