import Colors from "../../constants/Colors";
import { COLORS } from "../../constants/ReduxStates";

export function loadColorsSuccess(payload) {
  return {
    type: COLORS.COLORS_LOADED,
    payload,
  };
}

export function loadColorFail() {
  return {
    type: COLORS.COLORS_LOAD_FAILED,
  };
}

export function getColorMode() {
  return function (dispatch) {
    const colormode = localStorage.getItem("colormode");
    if (colormode == undefined) {
      dispatch(saveColorMode("light"));
      return colormode;
    }
    return colormode;
  };
}

export function loadColorMode() {
  return function (dispatch) {
    const colormode = localStorage.getItem("colormode");
    if (colormode) {
      if (colormode === "light") {
        dispatch(loadColorsSuccess(Colors.light));
        window.document.body.style.backgroundColor = Colors.light.v2.background;
        return;
      }
      Colors.selected = Colors.dark;
      window.document.body.style.backgroundColor = Colors.dark.v2.background;
      dispatch(loadColorsSuccess(Colors.dark));
      return;
    }
    dispatch(loadColorsSuccess(Colors.light));
    return;
  };
}

export function saveColorMode(colormode) {
  return function (dispatch) {
    if (colormode === "light") {
      localStorage.setItem("colormode", colormode);
      dispatch(loadColorsSuccess(Colors.light));
      window.document.body.style.backgroundColor = Colors.light.v2.background;
      return;
    } else if (colormode === "dark") {
      localStorage.setItem("colormode", colormode);
      window.document.body.style.backgroundColor = Colors.dark.v2.background;
      dispatch(loadColorsSuccess(Colors.dark));
      return;
    }
    dispatch(loadColorFail());
  };
}
