import { SIGNALS } from "../../constants/ReduxStates";
import { getSignalsDBOn } from "../../services/firebase";

export function signalsLoadSuccess(payload) {
  return {
    type: SIGNALS.SIGNALS_LOAD_SUCCESS,
    payload,
  };
}

export function signalsLoadFail() {
  return {
    type: SIGNALS.SIGNALS_LOAD_FAIL,
  };
}

export function getSignals() {
  return function (dispatch) {
    try {
      getSignalsDBOn((snapshot) => {
        const data = snapshot.val();
        if (data) {
          const signals = data;
          if (signals) {
            let keys = Object.keys(signals);
            if (keys && keys.length > 0) {
              let validsignals = [];
              for (var i = 0; i < keys.length; i++) {
                if (signals[keys[i]]) {
                  validsignals.push(signals[keys[i]]);
                }
              }
              validsignals = validsignals.sort((a, b) => {
                if (a.creationdate > b.creationdate) {
                  return -1;
                } else if (a.creationdate < b.creationdate) {
                  return 1;
                }
                return 0;
              });
              dispatch(signalsLoadSuccess(validsignals));
            }
          }
        }
      });
    } catch (e) {
      dispatch(signalsLoadFail());
    }
  };
}
