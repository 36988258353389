import React from "react";
import { connect } from "react-redux";

const IconicBadge = ({ deviceconfigs, colorscheme, language, icon, text }) => {
    const styles = {
        maincontainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
            backgroundColor: colorscheme.v2.primaryinactive,
            width: "42vw",
            alignSelf: "center",
            borderRadius: deviceconfigs.layout.xlargepadding,
        },
        iconcontainer: {
            padding: deviceconfigs.layout.mediumpadding,
        },
        icon: {
            width: deviceconfigs.button.mediumsize,
            height: deviceconfigs.button.mediumsize,
            objectFit: "contain",
        },
        textcontainer: {
            marginLeft: deviceconfigs.layout.largepadding,
        },
        text: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "600",
            color: colorscheme.v2.primary,
        }
    };
    return (
        <div
            style={styles.maincontainer}
        >
            {icon && <div style={styles.iconcontainer}>
                <img
                    src={icon}
                    style={styles.icon}
                />
            </div>}
            <div style={styles.textcontainer}>
                <div style={styles.text}>{text}</div>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconicBadge);