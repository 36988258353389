import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.GLOBALMETRICS.GLOBALMETRICS_LOADED:
            return { ...action.payload };
        default:
            return state;
    }
}
