import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";
import MyAssetListItem from "../components/MyAssetListItem";

import * as apiCalls from "../redux/actions/apiCalls";

import { List } from "antd";

const POPULAREXCHANGES = [
  {
    id: "1",
    text: "Binance",
    type: "BINANCE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
    selected: false,
  },
  {
    id: "2",
    text: "Coinbase 2.0",
    type: "COINBASE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/89.png",
    selected: false,
  },
  {
    id: "3",
    text: "Huobi Global",
    type: "HUOBI",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
    selected: false,
  },
  {
    id: "4",
    text: "KuCoin",
    type: "KUCOIN",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png",
    selected: false,
  },
  {
    id: "5",
    text: "Bittrex",
    type: "BITTREX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/22.png",
    selected: false,
  },
];

const AssetListScreen = ({ language, colorscheme, deviceconfigs }) => {
  const location = useLocation();
  const myParams = location.state;
  const exchange = myParams.exchange;

  const { balances } = exchange;
  const tExchange = POPULAREXCHANGES.filter((a) => {
    return a.type === exchange.type;
  })[0];
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {
      position: "fixed",
      top: "0px",
      width: "100vw",
      zIndex: 100,
      padding: `${deviceconfigs.layout.xxlargepadding}px 0px`,
      backgroundColor: colorscheme.v2.background,
    },
    coinheadercontainer: {
      marginLeft: deviceconfigs.layout.xxxlargepadding,
    },
    coinheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "500",
      color: colorscheme.v2.primaryalternative,
    },
    scrollviewcontainer: {
      flex: 1,
      marginLeft: deviceconfigs.layout.xlargepadding,
      paddingTop: "82px",
    },
    scrollview: {},
    actionbuttoncontainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginVertical: deviceconfigs.layout.xxlargepadding,
    },
    exchangetext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "500",
      color: colorscheme.v2.heavytext,
      marginLeft: deviceconfigs.layout.largepadding,
    },
  };
  const sortedBalances = balances.sort((a, b) => {
    if (a.inusdt > b.inusdt) {
      return -1;
    } else if (a.inusdt < b.inusdt) {
      return 1;
    }
    return 0;
  });
  return (
    <div style={styles.mainframe}>
      <div style={styles.headercontainer}>
        <PageHeader
          header={language.assetslist}
          onBackPressed={() => {
            window.history.back();
          }}
          righticon={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={tExchange.icon}
                style={{
                  width: deviceconfigs.button.largesize,
                  height: deviceconfigs.button.largesize,
                  objectFit: "contain",
                }}
                alt=""
              />
              <div style={styles.exchangetext}>{tExchange.text}</div>
            </div>
          }
        />
      </div>
      <div style={styles.scrollviewcontainer}>
        <List
          style={{
            flex: 1,
          }}
          dataSource={sortedBalances}
          renderItem={(item) => {
            return (
              <div key={item.asset}>
                <MyAssetListItem
                  logo={item.logo}
                  name={item.asset}
                  total={item.inusdt}
                  free={item.free}
                  locked={item.locked}
                />
              </div>
            );
          }}
          rowKey={(item) => item.asset}
        />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    myexchanges: state.myexchanges,
    cmcidmap: state.cmcidmap,
    membership: state.membership,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetListScreen);
