import * as Constants from '../../constants/ReduxStates';

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.HUOBISYMBOLS.HUOBISYMBOLS_LOADED:
            return { ...action };
        case Constants.HUOBISYMBOLS.HUOBISYMBOLS_FAILED:
            return { ...action };
        default:
            return state;
    }
}
