import * as Constants from '../../constants/ReduxStates';

const initialAuthState = {
};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.CMCIDMAP.CMCIDMAPSUCCESS:
            return { ...action.payload };
        case Constants.CMCIDMAP.CMCIDMAPFAIL:
            return { ...action };
        default:
            return state;
    }
}
