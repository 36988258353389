import * as Constants from '../../constants/ReduxStates';

const initialDetailsState = {};

export default function reducer(state = initialDetailsState, action) {
    switch (action.type) {
        case Constants.UNREADNOTIFICATIONS.UNREADNOTIFICATIONS_SUCCESS:
            return { ...action };
        case Constants.UNREADNOTIFICATIONS.UNREADNOTIFICATIONS_FAIL:
            return { ...action };
        default:
            return state;
    }
}
