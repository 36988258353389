import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  toHumanReadableDate,
  toHumanReadableDateTime,
  toHumanReadableTime,
} from "../../utils/timeservice";

import { ImEqualizer2 } from "react-icons/im";
import SmartFlowIcon from "../SmartFlowIcon";

const POPULAREXCHANGES = [
  {
    id: "1",
    text: "Binance",
    type: "BINANCE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
    selected: false,
  },
  {
    id: "2",
    text: "Coinbase 2.0",
    type: "COINBASE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/89.png",
    selected: false,
  },
  {
    id: "3",
    text: "Huobi Global",
    type: "HUOBI",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
    selected: false,
  },
  {
    id: "4",
    text: "KuCoin",
    type: "KUCOIN",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png",
    selected: false,
  },
  {
    id: "5",
    text: "Bittrex",
    type: "BITTREX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/22.png",
    selected: false,
  },
];

const MyOrderSummary = ({
  deviceconfigs,
  colorscheme,
  language,
  icon,
  basecoin,
  quotecoin,
  exchange = "N/A",
  creationdate,
  timestamps,
  smartflow,
  status,
  onClick,
  realizedprofit,
  closedprice,
  signal,
}) => {
  const styles = {
    mainframe: {
      display: "flex",
      flexDirection: "row",
      padding: deviceconfigs.layout.xlargepadding,
      borderRadius: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.cardbackground,
      margin: `${deviceconfigs.layout.xlargepadding}px 12px`,
    },
    iconcontainer: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    signalinfocontainer: {
      flex: 1,
      margin: `0px 12px`,
    },
    pairscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    signalstatuscontainer: {},
    signalstatusaccepted: {
      position: "relative",
      width: "24vw",
      paddingVertical: deviceconfigs.layout.largepadding,
      backgroundColor: status.bgcolor,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    signalstatusopen: {},
    signalstatuscancelled: {},
    basecointext: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "300",
      color: colorscheme.v2.backgroundtext,
    },
    quotecointext: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "300",
      color: colorscheme.v2.unselected,
    },
    icon: {
      width: deviceconfigs.button.xlargesize,
      height: deviceconfigs.button.xlargesize,
      borderRadius: deviceconfigs.layout.xlargepadding,
      objectFit: "contain",
    },
    exchangecontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    exchangetext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "500",
      color: colorscheme.v2.heavytext,
      marginLeft: deviceconfigs.layout.largepadding,
    },
    timestampcontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    timestamptext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.heavytext,
    },
    statustext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: status.color,
      textAlign: "center",
    },
    statusdatetext: {
      fontSize: deviceconfigs.font.smalltext,
      fontWeight: "500",
      color: colorscheme.v2.successtext,
    },
    currentresultcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    currentresultheader: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.heavytext,
    },
    currentresult: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color:
        parseFloat(realizedprofit) > 0
          ? colorscheme.v2.success
          : colorscheme.v2.error,
      margin: `${deviceconfigs.layout.largepadding}px 0px ${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
  };
  const tExchange = POPULAREXCHANGES.filter((a) => {
    return a.type === exchange;
  })[0];
  const isWaiting = status.status === "WAITING";
  const isActive = status.status === "ACTIVE";
  const isClosedWaiting = status.status === "CLOSEDWAITING";
  return (
    <div style={styles.mainframe} onClick={onClick}>
      <div style={styles.iconcontainer}>
        <img src={icon} style={styles.icon} alt={"MyPerformers"} />
      </div>
      <div style={styles.signalinfocontainer}>
        <div style={styles.pairscontainer}>
          <div style={styles.basecointext}>{basecoin}</div>
          <div style={styles.quotecointext}>{"/" + quotecoin}</div>
        </div>
        <div style={styles.exchangecontainer}>
          <img
            src={tExchange.icon}
            style={{
              width: deviceconfigs.button.mediumsize,
              height: deviceconfigs.button.mediumsize,
              objectFit: "contain",
            }}
            alt={"MyPerformers"}
          />
          <div style={styles.exchangetext}>{tExchange.text}</div>
        </div>
        <div style={styles.timestampcontainer}>
          <div style={styles.timestamptext}>
            {toHumanReadableDateTime(signal.creationdate || status.ts)}
          </div>
        </div>
        {!isWaiting && !isActive && !isClosedWaiting && (
          <div style={styles.currentresultcontainer}>
            <div style={styles.currentresultheader}>
              {language.realizedprofit}
            </div>
            <div style={styles.currentresult}>{realizedprofit}%</div>
          </div>
        )}
      </div>
      <div style={styles.signalstatuscontainer}>
        <div style={styles.signalstatusaccepted}>
          <div style={styles.statustext}>{status.summarytext}</div>
          <div style={styles.statusdatetext}>
            {toHumanReadableDate(status.ts)}
          </div>
          <div style={styles.statusdatetext}>
            {toHumanReadableTime(status.ts)}
          </div>
          <div
            style={{
              marginTop: deviceconfigs.layout.xlargepadding,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: -8,
              left: -28,
            }}
          >
            {smartflow ? (
              <div>
                <SmartFlowIcon
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </div>
            ) : (
              <div>
                <ImEqualizer2
                  style={{
                    fontSize: "18px",
                    color: colorscheme.v2.backgroundtext,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {(status.status === "WAITING" || status.status === "ACTIVE") && (
          <div
            style={{
              marginTop: deviceconfigs.layout.xlargepadding,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: deviceconfigs.font.mediumtext,
                fontWeight: "600",
                color: colorscheme.v2.backgroundtext,
              }}
            >
              {status.text}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrderSummary);
