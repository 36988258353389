import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
  switch (action.type) {
    case Constants.PERFORMANCES.AUTOMATIC_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
