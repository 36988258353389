import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useLocation } from 'react-router-dom'
import {
    HomeOutlined
} from '@ant-design/icons';

import {
    BiWallet
} from 'react-icons/bi';

import {
    AiOutlineCompass,
    AiOutlineSetting
} from 'react-icons/ai';

import {
    BsArrowDownUp
} from 'react-icons/bs';

import NavBarLink from '../NavBarLink';

const BottomNavBar = ({ routes, defaultRoute, colorscheme }) => {
    const location = useLocation();
    const styles = {
        container: {
            position: "fixed",
            bottom: "0px",
            backgroundColor: "white",
            padding: "8px 0px 18px 0px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTop: "1px solid #c2c2c2",
            backgroundColor : colorscheme.v2.background,
        },
        selectedstyle: {
            fontSize: "28px",
            color: colorscheme.v2.primary
        },
        unselectedstyle: {
            fontSize: "22px",
            color: "#c2c2c2"
        }
    }
    return (
        <div style={styles.container}>
            <div style={{
                flex: 1,
            }}>
                <NavBarLink
                    icon={
                        <HomeOutlined
                            style={(location.pathname.includes("/dashboard") || location.pathname == "/") ? styles.selectedstyle : styles.unselectedstyle}
                        />
                    }
                    to={"/dashboard"}
                />
            </div>
            <div style={{
                flex: 1,
            }}>
                <NavBarLink
                    icon={
                        <BiWallet
                            style={location.pathname.includes("/my-exchanges") ? styles.selectedstyle : styles.unselectedstyle}
                        />
                    }
                    to={"/my-exchanges"}
                />
            </div>
            <div style={{
                flex: 1,
            }}>
                <NavBarLink
                    icon={
                        <AiOutlineCompass
                            style={location.pathname.includes("/news") ? styles.selectedstyle : styles.unselectedstyle}
                        />
                    }
                    to={"/news"}
                />
            </div>
            <div style={{
                flex: 1,
            }}>
                <NavBarLink
                    icon={
                        <BsArrowDownUp
                            style={location.pathname.includes("/market-watch") ? styles.selectedstyle : styles.unselectedstyle}
                        />
                    }
                    to={"/market-watch"}
                />
            </div>
            <div style={{
                flex: 1,
            }}>
                <NavBarLink
                    icon={
                        <AiOutlineSetting
                            style={location.pathname.includes("/settings") ? styles.selectedstyle : styles.unselectedstyle}
                        />
                    }
                    to={"/settings"}
                />
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavBar);