import { connect } from "react-redux";

const SmartFlowPerformanceItem = ({ colorscheme, title, value }) => {
  const styles = {
    mainContainer: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      borderRadius: "8px",
      margin: `${0}px ${2}px`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      border: "1px solid " + colorscheme.v2.primary,
    },
    title: {
      fontSize: "14px",
      fontWeight: "600",
      color: colorscheme.v2.primarytext,
      width: "100%",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      textAlign: "center",
      backgroundColor: colorscheme.v2.primary,
      padding: `${4}px ${0}px`,
    },
    valuepositive: {
      fontSize: "13px",
      fontWeight: "700",
      color: colorscheme.v2.success,
      padding: `${4}px ${0}px`,
      textAlign: "center",

    },
    valuenegative: {
      fontSize: "13px",
      fontWeight: "700",
      padding: `${4}px ${0}px`,
      color: colorscheme.v2.error,
      textAlign: "center",
    },
  };

  const isPositive = value > 0;
  const isZero = value == "-" || value == 0;
  const valueStyle = isPositive ? styles.valuepositive : styles.valuenegative;
  return (
    <div style={styles.mainContainer}>
      <div style={styles.title}>{title}</div>
      {isZero ? (
        <div
          style={{
            fontSize: "13px",
            fontWeight: "700",
            padding: `${4}px ${0}px`,
            color: colorscheme.v2.backgroundtext,
            textAlign: "center",
          }}
        >
          {parseFloat(0).toFixed(2)}
        </div>
      ) : (
        <div style={valueStyle}>
          {isPositive ? "+" + value + "%" : value + "%"}
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartFlowPerformanceItem);