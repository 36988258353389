import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Modal from "@mui/material/Modal";

const AlertModal = ({
  deviceconfigs,
  colorscheme,
  open,
  onClose,
  containerStyle,
  titleContainerStyle,
  bodyContainerStyle,
  title,
  body,
  onBackdropClick,
  buttons,
  header,
  headerStyle,
  divider,
  footer,
  footerStyle
}) => {
  const styles = {
    container: {
      width: "92vw",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "30vh",
      left: "4vw",
      backgroundColor: "white",
      borderRadius: "12px",
    },
    titlecontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: colorscheme.v2.warningpopuptext,
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "600",
      padding : "12px 8px",
    },
    bodycontainer: {
      textAlign: "center",
      padding: "12px 2%",
      backgroundColor: colorscheme.v2.background,
      minHeight: "8vh",
      color: colorscheme.v2.backgroundtext,
      overflowY: "auto"
    },
    buttonscontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "8px 12px",
    },
    standardbuttonstyle: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      backgroundColor: colorscheme.v2.primary,
      textAlign: "center",
      padding: "8px 12px",
    },
    cancelbuttonstyle: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      backgroundColor: colorscheme.v2.error,
      textAlign: "center",
      padding: "8px 12px",
    },
    buttonStyle: {
      display: "flex",
      flexDirection: "row",
      borderRadius: "24px",
      borderRight: "1px solid #929292",
      margin: "0px 4px",
    },
    header: {
    },
    divider: {
      height: 1,
      backgroundColor: colorscheme.v2.backgroundtext,
      padding: "0px 12px",
      margin: "8px 0px",
    }
  };
  const buttonsMap = (buttons || []).map((button, index) => {
    let mStyle = styles.buttonStyle;
    return (
      <div
        key={index}
        onClick={button.onClick}
        style={
          button.cancel
            ? {
              ...mStyle,
              ...styles.cancelbuttonstyle,
              ...button.style,
            }
            : {
              ...mStyle,
              ...styles.standardbuttonstyle,
              ...button.style,
            }
        }
      >
        {button.text}
      </div>
    );
  });
  return (
    <Modal open={open} onClose={onClose} onBackdropClick={onBackdropClick}>
      <div
        style={{
          ...styles.container,
          ...containerStyle,
        }}
      >
        <div style={{
          ...styles.header,
          ...headerStyle,
        }}>
          {header}
        </div>
        {title ? <div
          style={{
            ...styles.titlecontainer,
            ...titleContainerStyle,
          }}
        >
          {title}
        </div> : undefined}
        {body ? <div
          style={{
            ...styles.bodycontainer,
            ...bodyContainerStyle,
          }}
        >
          {body}
        </div> : undefined}
        {divider ? <div style={styles.divider}></div> : undefined}
        {footer ? <div style={{
          ...styles.footer,
          ...footerStyle
        }}>
          {footer}
        </div> : undefined}
        <div style={styles.buttonscontainer}>{buttonsMap}</div>
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  containerStyle: PropTypes.object,
  titleContainerStyle: PropTypes.object,
  bodyContainerStyle: PropTypes.object,
  title: PropTypes.string || PropTypes.node,
  body: PropTypes.string || PropTypes.node,
  onBackdropClick: PropTypes.func,
  buttons: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
