import { Switch } from "antd";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiShieldQuarter } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import IconedInput from "../components/IconedInput";
import PageView from "../components/Page";
import SizeableButton from "../components/SizeableButton";
import * as apiCalls from "../redux/actions/apiCalls";

import { releaseCandidateVersion } from "../constants/ReduxStates";
import LoadingModal from "../components/LoadingModal";
import { useEffect } from "react";

const SignUpScreen = ({ auth, language, api, colorscheme }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showpassword, setShowpassword] = useState(false);
  const [read, setRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fpr, setFpr] = useState(undefined);

  useEffect(() => {
    let result = getCookie("_fprom_ref");
    console.log({ result });
    if (result) {
      setFpr(result);
    }
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const _checkPasswordStrength = (password) => {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    }
    return strength;
  };

  const _checkPasswordLength = (password) => {
    return password.length > 7;
  };

  const _checkEmailInForm = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const strength = _checkPasswordStrength(password);
  const isPasswordLong = _checkPasswordLength(password);
  const isEmailValid = email == "" || _checkEmailInForm(email);
  const strengthStr =
    strength > 3
      ? "Very Strong"
      : strength > 2
        ? "Strong"
        : strength > 1
          ? "Medium"
          : "Weak";
  const styles = {
    container: {},
    versionContainer: {
      float: "right",
    },
    version: {
      marginRight: "12px",
      marginTop: "12px",
      fontSize: "11px",
      color: colorscheme.v2.backgroundtext,
    },
    imagecontainer: {
      paddingLeft: "32px",
      paddingTop: "32px",
    },
    welcometextcontainer: {
      paddingLeft: "32px",
    },
    welcometextlinecontainer: {},
    headertext: {
      fontSize: "32px",
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    subheadertext: {
      fontSize: "14px",
      fontWeight: "700",
      color: colorscheme.v2.heavytext,
    },
    bluebar: {
      width: "48px",
      height: "8px",
      backgroundColor: colorscheme.v2.primary,
      marginTop: "12px",
      borderRadius: "12px",
    },
    inputscontainer: {
      padding: "18px 24px",
    },
    inputcontainer: {
      margin: "24px 0px",
    },
    completionlinescontainer: {
      width: "92%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
    },
    completionlineuncomplete: {
      backgroundColor: colorscheme.v2.unselected,
      height: "8px",
      width: "12%",
      margin: "0px 12px",
      borderRadius: "8px",
    },
    completionlinecomplete: {
      backgroundColor: colorscheme.v2.success,
      height: "8px",
      width: "12%",
      margin: "0px 12px",
      borderRadius: "8px",
    },
    warningtext: {
      margin: "12px 15% 0px 12%",
      fontSize: "11px",
      color: colorscheme.v2.backgroundtext,
      fontWeight: "400",
    },
    optionitemcontainer: {
      display: "flex",
      flexDirection: "row",
      margin: "24px 2%",
    },
    optiontextitemcontainer: {
      flex: 1,
      margin: "0px 8px 0px 24px",
    },
    optioncheckboxitemcontainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    registerlink: {
      margin: "0px 4px",
      color: colorscheme.v2.primary,
      fontSize: "13px",
      fontWeight: "700",
    },
    signupbuttoncontainer: {
      margin: "auto",
      marginTop: "24px",
    },
    alreadyhaveanaccountcontainer: {
      paddingLeft: "32px",
      margin: "24px auto",
    },
    alreadyhaveanaccount: {
      margin: "0px 4px",
      color: colorscheme.v2.heavytext,
      fontSize: "13px",
      fontWeight: "500",
    },
    signinlink: {
      margin: "0px 4px",
      color: colorscheme.v2.primary,
      fontSize: "13px",
      fontWeight: "700",
    },
  };
  return (
    <PageView>
      <div style={styles.container}>
        <div style={styles.versionContainer}>
          <div style={styles.version}>{releaseCandidateVersion}</div>
        </div>
        <div style={styles.imagecontainer}>
          <img
            width={96}
            height={96}
            src={
              colorscheme.v2.name === "dark"
                ? require("../assets/images/v2/logo-dark.png")
                : require("../assets/images/v2/logo-light.png")
            }
            alt={"MyPerformers"}
          />
        </div>
        <div style={styles.welcometextcontainer}>
          <div style={styles.welcometextlinecontainer}>
            <div style={styles.headertext}>Create New</div>
          </div>
          <div style={styles.welcometextlinecontainer}>
            <div style={styles.subheadertext}>Account</div>
          </div>
          <div style={styles.welcometextlinecontainer}></div>
          <div style={styles.bluebar}></div>
        </div>
        <div style={styles.inputscontainer}>
          <div style={styles.inputcontainer}>
            <IconedInput
              lefticon={
                <MdOutlineAlternateEmail
                  size={24}
                  color={colorscheme.v2.primary}
                />
              }
              leftseperator
              placeholder={"Email"}
              value={email}
              onChangeText={(text) => {
                let x = text.trimStart().trimEnd();
                setEmail(x);
              }}
            />
          </div>
          <div style={styles.inputcontainer}>
            <IconedInput
              lefticon={
                <BiShieldQuarter size={24} color={colorscheme.v2.primary} />
              }
              leftseperator
              placeholder={"Password"}
              rightseperator
              righticon={
                <AiOutlineEye size={24} color={colorscheme.v2.primary} />
              }
              onChangeText={(text) => {
                let result = text && text.replace(/ /g, "");
                setPassword(result);
              }}
              value={password}
              secure={!showpassword}
              onRightIconPressed={() => {
                setShowpassword(!showpassword);
              }}
            />
          </div>
        </div>
        <div style={styles.completionlinescontainer}>
          <div
            style={
              strength > 0
                ? styles.completionlinecomplete
                : styles.completionlineuncomplete
            }
          ></div>
          <div
            style={
              strength > 1
                ? styles.completionlinecomplete
                : styles.completionlineuncomplete
            }
          ></div>
          <div
            style={
              strength > 2
                ? styles.completionlinecomplete
                : styles.completionlineuncomplete
            }
          ></div>
          <div
            style={
              strength > 3
                ? styles.completionlinecomplete
                : styles.completionlineuncomplete
            }
          ></div>
          <div>
            <div
              style={{
                fontSize: "11px",
                width: "84px",
                color: colorscheme.v2.backgroundtext,
              }}
            >
              {strengthStr}
            </div>
          </div>
        </div>
        <div style={styles.warningtext}>
          At least 8 characters , numbers , digits , and special character.
        </div>
        <div style={styles.optionitemcontainer}>
          <div style={styles.optiontextitemcontainer}>
            I have carefully read and agree to the{" "}
            <Link to="/sign-up" style={styles.registerlink}>
              Terms and Conditions
            </Link>
          </div>
          <div style={styles.optioncheckboxitemcontainer}>
            <Switch
              checked={read}
              onChange={(read) => {
                setRead(read);
              }}
            />
          </div>
        </div>
        <div style={styles.signupbuttoncontainer}>
          <SizeableButton
            disabled={!read || !isPasswordLong || !isEmailValid}
            text={"Continue"}
            onClick={async () => {
              setLoading(true);
              try {
                let result = await api.registerAsStandardUserV2({
                  login: email,
                  password,
                  language: language._type,
                  fpr: fpr,
                });
                setLoading(false);
                navigate("/registration-verification", {
                  state: { userid: result.data.object },
                });
              } catch (e) {
                setLoading(false);
                console.error("Something wnet wrong during registration", e);
              }
            }}
          />
        </div>
        <div style={styles.alreadyhaveanaccountcontainer}>
          <div style={styles.alreadyhaveanaccount}>
            Already have an account?
            <Link to="/sign-in" style={styles.signinlink}>
              Sign In
            </Link>
          </div>
        </div>
      </div>
      <LoadingModal loading={loading} />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpScreen);
