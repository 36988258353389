import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";
import ActionButton from "../components/ActionButton";
import LoadingModal from "../components/LoadingModal";

import * as apiCalls from "../redux/actions/apiCalls";

import { toHumanReadableDate } from "../utils/timeservice";
import AlertModal from "../components/AlertModal";

const PaymentSummaryScreen = ({
  deviceconfigs,
  language,
  colorscheme,
  api,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [showpopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState(undefined);
  const [popupbody, setPopupBody] = useState(undefined);
  const [lastpayment, setLastPayment] = useState(undefined);

  const myState = location.state;
  const membership = myState.membership;
  const coin = myState.coin;

  const makePayment = () => {
    setLoading(true);
    api
      .createPaymentRequest(membership.type, coin.external)
      .then((res) => {
        const payment = res.data.object;
        payment.icon =
          payment.responseunit === "usdttrc20"
            ? "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
            : "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png";
        setLoading(false);
        setPopupTitle(language.success.paymentrequestreceived.header);
        setPopupBody(language.success.paymentrequestreceived.body);
        setLastPayment(payment);
        setShowPopup(true);
        // promptMessage(language.success.paymentrequestreceived.header, language.success.paymentrequestreceived.body, [{
        //     text: language.ok,
        //     onPress: () => {
        //         navigation.navigate("PaymentDetails", { payment: payment });
        //     }
        // }])
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    return () => {};
  }, []);

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {},
    summaryitemcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.xlargepadding}px 0px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxxlargepadding}px`,
    },
    summaryitemleft: {
      width: "44vw",
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
    },
    summaryitemright: {
      flex: 1,
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
    },
  };
  const expires =
    membership.type === "MONTHLY"
      ? Date.now() + 31 * 24 * 60 * 60 * 1000
      : membership.type == "SIXMONTHS"
      ? Date.now() + 182 * 24 * 60 * 60 * 1000
      : Date.now() + 365 * 24 * 60 * 60 * 1000;
  return (
    <PageView style={styles.mainframe}>
      <div style={styles.headercontainer}>
        <PageHeader
          onBackPressed={() => {
            window.history.back();
          }}
          header={language.paymentsummary}
        />
      </div>
      <div
        style={{
          flex: 1,
          width: "92vw",
          margin: "auto",
          marginTop: "32px",
        }}
      >
        <div
          style={{
            backgroundColor: colorscheme.v2.primary,
            padding: deviceconfigs.layout.xxlargepadding,
            borderRadius: deviceconfigs.layout.xxlargepadding,
          }}
        >
          <div
            style={{
              padding: deviceconfigs.layout.xlargepadding,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: colorscheme.v2.primarytext,
                fontSize: deviceconfigs.font.largetext,
                fontWeight: "500",
              }}
            >
              {language.paymentsummary}
            </div>
          </div>
          <div style={{}}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: deviceconfigs.layout.xxlargepadding,
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                (item) => {
                  return (
                    <div
                      key={item}
                      style={{
                        flex: 1,
                        backgroundColor: colorscheme.v2.background,
                        height: deviceconfigs.layout.smallpadding,
                        marginHorizontal: deviceconfigs.layout.mediumpadding,
                      }}
                    ></div>
                  );
                }
              )}
            </div>
          </div>
          <div style={styles.summarycontainer}>
            <div style={styles.summaryitemcontainer}>
              <div style={styles.summaryitemleft}>
                {language.subscriptiontype}
              </div>
              <div style={styles.summaryitemright}>
                {membership.name[language._denom]}
              </div>
            </div>
            <div style={styles.summaryitemcontainer}>
              <div style={styles.summaryitemleft}>{language.expires}</div>
              <div style={styles.summaryitemright}>
                {toHumanReadableDate(expires)}
              </div>
            </div>
            <div style={styles.summaryitemcontainer}>
              <div style={styles.summaryitemleft}>{language.paycurrency}</div>
              <div style={styles.summaryitemright}>{coin.symbol}</div>
            </div>
            <div style={styles.summaryitemcontainer}>
              <div style={styles.summaryitemleft}>
                {language.totalamountinusd}
              </div>
              <div style={styles.summaryitemright}>{membership.price}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            margin: `${deviceconfigs.layout.xxlargepadding}px 0px`,
          }}
        >
          <ActionButton
            text={language.cancel}
            buttonstyle={{
              margin: `0px ${deviceconfigs.layout.xxlargepaddding}px`,
              width: "36vw",
              backgroundColor: colorscheme.v2.error,
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <ActionButton
            text={language.confirm}
            buttonstyle={{
              margin: `0px ${deviceconfigs.layout.xxlargepaddding}px`,
              width: "36vw",
              backgroundColor: colorscheme.v2.primary,
            }}
            onClick={() => {
              makePayment();
            }}
          />
        </div>
      </div>
      <LoadingModal loading={loading} />
      <AlertModal
        open={showpopup}
        title={popuptitle}
        body={popupbody}
        onClose={() => {
          setShowPopup(false);
        }}
        onBackdropClick={() => {
          setShowPopup(false);
        }}
        buttons={[
          {
            text: "Go To Payment Details",
            onClick: () => {
              navigate("/my-payments/payment-details", {
                state: { payment: lastpayment },
              });
              setShowPopup(false);
            },
          },
        ]}
      />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    myexchanges: state.myexchanges,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSummaryScreen);
