import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';


const NavBarLink = ({ icon, name, selected, to, colorscheme }) => {
    const styles = {
        container: {
            padding: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        }
    };
    return (
        <Link style={styles.container} to={to}>
            {icon}
        </Link>
    );
}
NavBarLink.propTypes = {
    icon: PropTypes.node,

}
function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarLink);