import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

const MyAssetsListItem = ({
  id,
  deviceconfigs,
  colorscheme,
  language,
  balancedisplay,
  logo,
  name,
  total,
  free,
  locked,
}) => {
  const totalbalancehidden = balancedisplay.type === "BALANCEHIDE";
  return (
    <div
      key={id}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colorscheme.v2.cardbackground,
        margin: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.largepadding}px`,
        padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.largepadding}px`,
        borderRadius: deviceconfigs.layout.xlargepadding,
      }}
    >
      <div
        style={{
          width: "12vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={logo}
            style={{
              width: deviceconfigs.image.smallimagesize,
              height: deviceconfigs.image.smallimagesize,
              objectFit: "contain",
            }}
            alt={name}
          />
        </div>
        <div
          style={{
            marginTop: deviceconfigs.layout.mediumpadding,
          }}
        >
          <div
            style={{
              color: colorscheme.v2.backgroundtext,
              fontSize: deviceconfigs.font.mediumtext,
              fontWeight: "400",
            }}
          >
            {name}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "82vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: deviceconfigs.layout.smallpadding,
            paddingHorizontal: deviceconfigs.layout.xlargepadding,
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                color: colorscheme.v2.backgroundtext,
                fontSize: deviceconfigs.font.smalltext,
              }}
            >
              {language.totalinusdt}
            </div>
          </div>
          <div
            style={{
              marginRight: deviceconfigs.layout.xlargepadding,
            }}
          >
            <div
              style={{
                textAlign: "right",
                color: colorscheme.v2.backgroundtext,
                fontSize: deviceconfigs.font.mediumtext,
                fontWeight: "300",
              }}
            >
              {totalbalancehidden ? "********" : parseFloat(total).toFixed(8)}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: deviceconfigs.layout.smallpadding,
            paddingHorizontal: deviceconfigs.layout.xlargepadding,
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                color: colorscheme.v2.backgroundtext,
                fontSize: deviceconfigs.font.smalltext,
              }}
            >
              {language.totalfree}
            </div>
          </div>
          <div
            style={{
              marginRight: deviceconfigs.layout.xlargepadding,
            }}
          >
            <div
              style={{
                textAlign: "right",
                color: colorscheme.v2.backgroundtext,
                fontSize: deviceconfigs.font.mediumtext,
                fontWeight: "300",
              }}
            >
              {totalbalancehidden ? "********" : parseFloat(free).toFixed(8)}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: deviceconfigs.layout.smallpadding,
            paddingHorizontal: deviceconfigs.layout.xlargepadding,
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                color: colorscheme.v2.backgroundtext,
                fontSize: deviceconfigs.font.smalltext,
              }}
            >
              {language.totallocked}
            </div>
          </div>
          <div
            style={{
              marginRight: deviceconfigs.layout.xlargepadding,
            }}
          >
            <div
              style={{
                textAlign: "right",
                color: colorscheme.v2.backgroundtext,
                fontSize: deviceconfigs.font.mediumtext,
                fontWeight: "300",
              }}
            >
              {totalbalancehidden ? "********" : parseFloat(locked).toFixed(8)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
    balancedisplay: state.balancedisplay,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAssetsListItem);
