import { UNREADNOTIFICATIONS } from '../../constants/ReduxStates';
import * as apiCalls from '../actions/apiCalls';

export function unreadNotificationsSuccess(payload) {
    return {
        type: UNREADNOTIFICATIONS.UNREADNOTIFICATIONS_SUCCESS,
        payload,
    };
}

export function unreadNotificationsFail() {
    return {
        type: UNREADNOTIFICATIONS.UNREADNOTIFICATIONS_FAIL
    };
}

export function loadUnreadNotifiations() {
    return async function (dispatch, getState) {
        try {
            let result = await apiCalls.getUnreadCount()(dispatch, getState);
            // await Notifications.setBadgeCountAsync(result.data.object);
            dispatch(unreadNotificationsSuccess(result.data.object));
        } catch (e) {
            console.error(e);
        }

    }
}