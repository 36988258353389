import * as Constants from '../../constants/ReduxStates';

const initialAuthState = {
};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.EXCHANGES.EXCHANGES_LOAD_SUCCESS:
            return { ...action };
        case Constants.EXCHANGES.EXCHANGES_LOAD_FAILED:
            return { ...action };
        default:
            return state;
    }
}
