import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.XACTIONS.XACTIONS_LOADED:
            return { ...action };
        case Constants.XACTIONS.XACTIONS_FAILED:
            return { ...action };
        default:
            return state;
    }
}
