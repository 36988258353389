import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as apiCalls from "../redux/actions/apiCalls";

import { notification } from "antd";

import PageView from "../components/Page";
import IconedInput from "../components/IconedInput";
import SizeableButton from "../components/SizeableButton";

import { BiShieldQuarter } from "react-icons/bi";
import { AiOutlineWarning } from "react-icons/ai";

const openNotification = ({
  title = "Notification",
  description,
  duration,
  style,
}) => {
  const args = {
    message: title,
    description: description,
    duration: duration || 5,
    style: style || {},
  };
  notification.open(args);
};

const RegistrationVerificationScreen = ({
  api,
  auth,
  deviceconfigs,
  colorscheme,
  language,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationcode, setVerificationcode] = useState("");

  const verifyEmail = () => {
    const { state } = location;
    const { userid } = state;
    api
      .verifyRegistration(userid, verificationcode)
      .then((res) => {
        navigate("/sign-in");
      })
      .catch((err) => {
        console.log("Failed to Verify Registration");
        openNotification({
          title: "Error",
          description: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <AiOutlineWarning
                  style={{
                    color: colorscheme.v2.error,
                    fontSize: deviceconfigs.icon.largeicon,
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "12px",
                }}
              >
                Failed to Verify Registration
              </div>
            </div>
          ),
        });
      });
  };
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    secondframe: {
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
    },
    imagecontainer: {
      marginTop: deviceconfigs.custom.SignInPage.imagecontainermargintop,
      marginLeft: deviceconfigs.layout.xxlargepadding,
    },
    signintotext: {
      fontSize: deviceconfigs.font.xxxxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    myperformerstextcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    myperformerstext: {
      color: colorscheme.v2.heavytext,
      fontWeight: "700",
    },
    inputscontainer: {
      margin: `${deviceconfigs.layout.xxxlargepadding}px ${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.largepadding}px`,
      padding: deviceconfigs.layout.xlargepadding,
    },
    inputcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    line: {
      backgroundColor: colorscheme.v2.primary,
      height: deviceconfigs.layout.largepadding,
      width: "12vw",
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      borderRadius: deviceconfigs.layout.largepadding,
    },
    optionscontainer: {
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      padding: deviceconfigs.layout.xlargepadding,
    },
    optionitemcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    optiontextitemcontainer: {
      flex: 1,
    },
    optioncheckboxitemcontainer: {},
    optiontext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: "#727272",
    },
    signinbuttoncontainer: {
      marginTop: deviceconfigs.layout.xlargepadding,
    },
    bottomtextcontainer: {
      display: "flex",
      flexDirection: "row",
      marginVertical: deviceconfigs.layout.xxxxlargepadding,
      justifyContent: "center",
      alignItems: "center",
    },
    donthaveaccounttext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: "#727272",
    },
  };
  return (
    <PageView style={styles.mainframe}>
      <div style={styles.imagecontainer}>
        <img
          src={
            colorscheme.v2.name == "dark"
              ? require("../assets/images/v2/logo-dark.png")
              : require("../assets/images/v2/logo-light.png")
          }
          style={{
            width: "24vw",
            height: "24vw",
            objectFit: "contain",
          }}
        />
      </div>
      <div
        style={{
          margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
        }}
      >
        <div style={styles.signintotext}>{language.verifyyouremail}</div>
      </div>
      <div style={styles.myperformerstextcontainer}>
        <div style={styles.myperformerstext}>{language.myperformers}</div>
      </div>
      <div style={styles.line}></div>
      <div style={styles.inputscontainer}>
        <div style={styles.inputcontainer}>
          <IconedInput
            lefticon={
              <BiShieldQuarter size={24} color={colorscheme.v2.primary} />
            }
            leftseperator
            placeholder={"Verification Code..."}
            onChangeText={(verificationcode) => {
              setVerificationcode(verificationcode);
            }}
            value={verificationcode}
          />
        </div>
      </div>

      <div style={styles.signinbuttoncontainer}>
        <SizeableButton
          text={language.completeregistration}
          onClick={verifyEmail}
        />
      </div>
      <div
        style={{
          height: "20vh",
        }}
      ></div>
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationVerificationScreen);
