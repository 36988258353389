import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

const SignalInfoItem = ({ deviceconfigs, language, colorscheme, title, value, valuetwo }) => {
    const styles = {
        infoitemcontainer: {
            marginVertical: deviceconfigs.layout.largepadding,
        },
        infoitemline: {
            flexDirection: 'row',
            alignItems: "center",
            marginVertical: deviceconfigs.layout.mediumpadding,
        },
        infoitemtitle: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.unselected,
        },
        infoitemvalue: {
            flex: 1,
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        infoitemvaluetwo: {
            alignSelf: "flex-end",
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.success,
        },
    };
    return (
        <div style={styles.infoitemcontainer}>
            <div style={styles.infoitemline}>
                <div style={styles.infoitemtitle}>{title}</div>
            </div>
            <div style={styles.infoitemline}>
                <div style={styles.infoitemvalue}>{value}</div>
                {valuetwo && <div style={styles.infoitemvaluetwo}>{valuetwo}</div>}
            </div>
        </div>
    );
};

SignalInfoItem.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    valuetwo: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        language: state.language
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignalInfoItem);