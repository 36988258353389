import Colors from "../../constants/Colors";
import { GLOBALMETRICS } from "../../constants/ReduxStates";

import * as apiCalls from "./apiCalls";

export function loadGlobalMetricsSuccess(payload) {
  return {
    type: GLOBALMETRICS.GLOBALMETRICS_LOADED,
    payload,
  };
}

export function getGlobalMetrics() {
  return async function (dispatch, getState) {
    let result = await apiCalls.getGlobalMetrics()(dispatch, getState);
    console.log("Got Global Metrics");
    dispatch(loadGlobalMetricsSuccess(result.data.object));
  };
}
