import { DEVICECONFIGS } from "../../constants/ReduxStates";

export function loadDeviceConfigsSuccess(payload) {
  return {
    type: DEVICECONFIGS.LOAD_DEVICE_CONFIGS_SUCCESS,
    payload,
  };
}
const constants = {
  hdpi: {
    font: {
      xsmalltext: 8,
      smalltext: 10,
      mediumtext: 12,
      largetext: 14,
      xlargetext: 17,
      xxlargetext: 21,
      xxxlargetext: 27,
      xxxxlargetext: 35,
      xxxxxlargetext: 47,
    },
    image: {
      xxsmallimagesize: 12,
      xxsmallimageroundedradius: 4,
      xxsmallimageroundradius: 6,
      xsmallimagesize: 18,
      xsmallimageroundedradius: 6,
      xsmallimageroundradius: 9,
      smallimagesize: 24,
      smallimageroundedradius: 8,
      smallimageroundradius: 12,
      mediumimagesize: 36,
      mediumimageroundedradius: 12,
      mediumimageroundradius: 18,
      largeimagesize: 48,
      largeimageroundedradius: 16,
      largeimageroundradius: 24,
      xlargeimagesize: 60,
      xlargeimageroundedradius: 20,
      xlargeimageroundradius: 30,
      xxlargeimagesize: 72,
      xxlargeimageroundedradius: 24,
      xxlargeimageroundradius: 36,
      xxxlargeimagesize: 84,
      xxxlargeimageroundedradius: 28,
      xxxlargeimageroundradius: 42,
      xxxxlargeimagesize: 96,
      xxxxlargeimageroundedradius: 32,
      xxxxlargeimageroundradius: 48,
      categoriesimagewidth: "25vw",
      categoriesimageheight: "25vw",
      headerlogoimagewidth: "10vw",
      headerlogoimageheight: "10vw",
      headerlogoimageborderradius: "5vw",
      promotionimagewidth: "100vw",
      promotionimageheight: 152,
      productimagemapheight: 172,
    },
    layout: {
      smallpadding: 2,
      mediumpadding: 4,
      largepadding: 8,
      xlargepadding: 12,
      xxlargepadding: 16,
      xxxlargepadding: 24,
      xxxxlargepadding: 32,
      xxxxxlargepadding: 42,
      xxxxxxlargepadding: 54,
      topaddressbarheight: 66,
      categoriesitemmarginhorizontal: "3vw",
      productitemcontainermarginhorizontal: "2vw",
      productitemmarginhorizontal: "2vw",
      productitemimagesize: 72,
      categorynameminwidth: "16vw",
      addnoteheight: 48,
      logomargintop: 42,
      bottomtabbarheight: 90,
    },
    icon: {
      unselectedtabbaricon: 20,
      selectedtabbaricon: 24,
      xxsmallicon: 13,
      xsmallicon: 18,
      smallicon: 22,
      mediumicon: 30,
      largeicon: 38,
    },
    input: {
      smallheight: 32,
      mediumheight: 38,
      largeheight: 44,
    },
    button: {
      minisize: 12,
      minisizeroundedradius: 4,
      minisizeroundradius: 6,
      mediumsize: 18,
      mediumsizeroundedradius: 6,
      mediumsizeroundradius: 9,
      largesize: 24,
      largesizeroundedradius: 8,
      largesizeroundradius: 12,
      xlargesize: 30,
      xlargesizeroundedradius: 10,
      xlargesizeroundradius: 15,
      xxlargesize: 36,
      xxlargesizeroundedradius: 12,
      xxlargesizeroundradius: 18,
      xxxlargesize: 42,
      xxxlargesizeroundedradius: 14,
      xxxlargesizeroundradius: 21,
      xxxxlargesize: 48,
      xxxxlargesizeroundedradius: 16,
      xxxxlargesizeroundradius: 24,
      xxxxxlargesize: 64,
      xxxxxlargesizeroundedradius: 18,
      xxxxxlargesizeroundradius: 27,
    },
    custom: {
      SignInPage: {
        imagecontainermargintop: "72px",
      },
      AppInitPage: {
        slidingtextbottom: 120,
      },
    },
  },
  xxhdpi: {
    font: {
      xsmalltext: 8,
      smalltext: 10,
      mediumtext: 12,
      largetext: 14,
      xlargetext: 17,
      xxlargetext: 21,
      xxxlargetext: 27,
      xxxxlargetext: 35,
      xxxxxlargetext: 47,
    },
    image: {
      xxsmallimagesize: 12,
      xxsmallimageroundedradius: 4,
      xxsmallimageroundradius: 6,
      xsmallimagesize: 18,
      xsmallimageroundedradius: 6,
      xsmallimageroundradius: 9,
      smallimagesize: 24,
      smallimageroundedradius: 8,
      smallimageroundradius: 12,
      mediumimagesize: 36,
      mediumimageroundedradius: 12,
      mediumimageroundradius: 18,
      largeimagesize: 48,
      largeimageroundedradius: 16,
      largeimageroundradius: 24,
      xlargeimagesize: 60,
      xlargeimageroundedradius: 20,
      xlargeimageroundradius: 30,
      xxlargeimagesize: 72,
      xxlargeimageroundedradius: 24,
      xxlargeimageroundradius: 36,
      xxxlargeimagesize: 84,
      xxxlargeimageroundedradius: 28,
      xxxlargeimageroundradius: 42,
      xxxxlargeimagesize: 96,
      xxxxlargeimageroundedradius: 32,
      xxxxlargeimageroundradius: 48,
      categoriesimagewidth: "25vw",
      categoriesimageheight: "25vw",
      headerlogoimagewidth: "10vw",
      headerlogoimageheight: "10vw",
      headerlogoimageborderradius: "5vw",
      promotionimagewidth: "100vw",
      promotionimageheight: 152,
      productimagemapheight: 172,
    },
    layout: {
      smallpadding: 2,
      mediumpadding: 4,
      largepadding: 8,
      xlargepadding: 12,
      xxlargepadding: 16,
      xxxlargepadding: 24,
      xxxxlargepadding: 32,
      xxxxxlargepadding: 42,
      xxxxxxlargepadding: 54,
      topaddressbarheight: 66,
      categoriesitemmarginhorizontal: "3vw",
      productitemcontainermarginhorizontal: "2vw",
      productitemmarginhorizontal: "2vw",
      productitemimagesize: 72,
      categorynameminwidth: "16vw",
      addnoteheight: 48,
      logomargintop: 42,
      bottomtabbarheight: 90,
    },
    icon: {
      unselectedtabbaricon: 20,
      selectedtabbaricon: 24,
      xxsmallicon: 13,
      xsmallicon: 18,
      smallicon: 22,
      mediumicon: 30,
      largeicon: 38,
    },
    input: {
      smallheight: 32,
      mediumheight: 38,
      largeheight: 44,
    },
    button: {
      minisize: 12,
      minisizeroundedradius: 4,
      minisizeroundradius: 6,
      mediumsize: 18,
      mediumsizeroundedradius: 6,
      mediumsizeroundradius: 9,
      largesize: 24,
      largesizeroundedradius: 8,
      largesizeroundradius: 12,
      xlargesize: 30,
      xlargesizeroundedradius: 10,
      xlargesizeroundradius: 15,
      xxlargesize: 36,
      xxlargesizeroundedradius: 12,
      xxlargesizeroundradius: 18,
      xxxlargesize: 42,
      xxxlargesizeroundedradius: 14,
      xxxlargesizeroundradius: 21,
      xxxxlargesize: 48,
      xxxxlargesizeroundedradius: 16,
      xxxxlargesizeroundradius: 24,
      xxxxxlargesize: 64,
      xxxxxlargesizeroundedradius: 18,
      xxxxxlargesizeroundradius: 27,
    },
    custom: {
      SignInPage: {
        imagecontainermargintop: "72px",
      },
      AppInitPage: {
        slidingtextbottom: 120,
      },
    },
  },
  xxxhdpi: {},
};

export function loadDeviceConfigs() {
  return function (dispatch) {
    const { innerWidth, innerHeight } = window;
    console.log(
      innerWidth,
      innerHeight,
      ((innerWidth * 1.0) / innerHeight) * 1.0,
      innerHeight * innerWidth
    );
    dispatch(loadDeviceConfigsSuccess(constants.xxhdpi));
    return;
  };
}
