import { connect } from "react-redux";

import PageHeader from "components/PageHeader";
import { useNavigate } from "react-router-dom";
import SmartFlowPerformanceChart from "components/SmartFlowPerformanceChart";

const SmartFlowPerformanceChartsScreen = ({
  colorscheme,
  deviceconfigs,
  language,
}) => {
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    chartContainer: {
      paddingBottom: deviceconfigs.layout.xxlargepadding,
      padding: `72px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      flex: 1,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px`,
    },
  };
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          zIndex: 100,
          top: "0px",
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.automatic}
            onBackPressed={() => {
              window.history.back();
            }}
          />
        </div>
      </div>
      <div style={styles.chartContainer}>
        <div>
          <SmartFlowPerformanceChart
            options={{
              title: {
                text: language.automaticlast7days,
                subtext: language.startcapital,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (params) {
                  var tar = params[0];
                  return (
                    tar.name + "<br/>" + tar.seriesName + " : " + tar.value
                  );
                },
              },
              xAxis: {
                type: "category",
                data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  data: [120, 200, 150, 80, 70, 110, 130],
                  type: "bar",
                  showBackground: true,
                  backgroundStyle: {
                    color: "rgba(180, 180, 180, 0.2)",
                  },
                },
              ],
            }}
          />
        </div>
        <div>
          <SmartFlowPerformanceChart
            options={{
              title: {
                text:language.automaticlast30days,
                subtext: language.startcapital,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (params) {
                  var tar = params[0];
                  return (
                    tar.name + "<br/>" + tar.seriesName + " : " + tar.value
                  );
                },
              },
              xAxis: {
                type: "category",
                data: [
                  "Mon",
                  "Tue",
                  "Wed",
                  "Thu",
                  "Fri",
                  "Sat",
                  "Sun",
                  "Mon",
                  "Tue",
                  "Wed",
                  "Thu",
                  "Fri",
                  "Sat",
                  "Sun",
                  "Mon",
                  "Tue",
                  "Wed",
                  "Thu",
                  "Fri",
                  "Sat",
                  "Sun",
                  "Mon",
                  "Tue",
                  "Wed",
                  "Thu",
                  "Fri",
                  "Sat",
                  "Sun",
                  "Mon",
                  "Tue",
                ],
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  data: [
                    120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110,
                    130, 120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70,
                    110, 130, 132, 144,
                  ],
                  type: "bar",
                  showBackground: true,
                  backgroundStyle: {
                    color: "rgba(180, 180, 180, 0.2)",
                  },
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    myexchanges: state.myexchanges,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    balancedisplay: state.balancedisplay,
    membership: state.membership,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartFlowPerformanceChartsScreen);
