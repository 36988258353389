import ActionButton from "components/ActionButton";
import PageHeader from "components/PageHeader";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as apiCalls from "../../redux/actions/apiCalls";

const AboutMyPerformersAppScreen = ({ deviceconfigs, colorscheme, language }) => {
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
            padding: "18px",
            borderRadius: "18px",
        },
        headercontainer: {
            position: "fixed",
            top: "0px",
            width: "100vw",
            zIndex: 100,
            padding: `${deviceconfigs.layout.xxlargepadding}px 0px`,
            backgroundColor: colorscheme.v2.background,
        },
        header: {
            fontSize: "15px",
            fontWeight: "600",
            color: colorscheme.v2.backgroundtext,
        },
        text: {
            fontSize: "13px",
            fontWeight: "400",
            color: colorscheme.v2.backgroundtext,
        }
    };
    const [videoopen, setVideoopen] = useState(false);
    return (
        <div style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <PageHeader
                    header={language.aboutmyperformersapp}
                    onBackPressed={() => {
                        window.history.back();
                    }}
                />
            </div>
            <div style={{
                flex: 1,
                marginLeft: deviceconfigs.layout.xlargepadding,
                paddingTop: "64px",
            }}>
                <div>
                    <div style={styles.header}>{language.about.line1}</div>
                    <div style={styles.text}>{language.about.line2}</div>
                    <div style={styles.text}> {language.about.line3}</div>
                    <div style={styles.text}> {language.about.line4}</div>
                    <div style={styles.text}>{language.about.line5}</div>
                    <div style={styles.text}>{language.about.line6}</div>
                </div>
                <div style={{ marginTop: "18px" }}>
                    <div style={styles.text}>{language.about.line7}</div>
                </div>
                <div style={{ marginTop: "18px" }}>
                    <div style={styles.header}>{language.about.line8}</div>
                    <div style={styles.text}>{language.about.line9}</div>
                    <div style={styles.text}>{language.about.line10}</div>
                    <div style={styles.text}>{language.about.line11}</div>
                </div>
                <div style={{ marginTop: "18px" }}>
                    <div style={styles.header}>{language.about.line12}</div>
                    <div style={styles.text}>{language.about.line13}</div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "18px",
            }}>
                <ActionButton
                    text={language.videotutorial}
                    onClick={() => {
                        setVideoopen(true);
                    }}
                />
            </div>
            <ModalVideo channel='youtube' autoplay={1} isOpen={videoopen} videoId="02a1utc01l0" onClose={() => setVideoopen(false)} />
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        iconcache: state.iconcache,
        colorscheme: state.colorscheme,
        balances: state.balances,
        xactionssignals: state.xactionssignals,
        language: state.language,
        loading: state.loading,
        huobisymbols: state.huobisymbols,
        filters: state.filters,
        myexchanges: state.myexchanges,
        membership: state.membership,
        cmcidmap: state.cmcidmap,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutMyPerformersAppScreen);