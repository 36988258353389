import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as apiCalls from "../../redux/actions/apiCalls";

import { addCommas } from "../../utils/stringutils";

const MarketSummaryCard = ({
  api,
  key,
  deviceconfigs,
  language,
  colorscheme,
  activecurrencies,
  activeexchanges,
  activemarketpairs,
  totalmarketcap,
  totalmarketcap24h,
  btcdominance,
  ethdominance,
  onClick,
}) => {
  const styles = {
    maincontainer: {
      padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
      borderRadius: deviceconfigs.layout.xxlargepadding,
      backgroundColor: colorscheme.v2.cardbackground,
      width: "64vw",
      margin: `0px ${deviceconfigs.layout.largepadding}px`,
    },
    nameandiconcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    iconcontainer: {
      marginRight: deviceconfigs.layout.xlargepadding,
    },
    icon: {
      width: deviceconfigs.layout.xxxxlargepadding,
      height: deviceconfigs.layout.xxxxlargepadding,
      borderRadius: 999,
      objectFit: "center",
    },
    namecontainer: {
      flex: 1,
      width: "42%",
    },
    name: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.backgroundtext,
      height: "24px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    valuecontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
      marginLeft: deviceconfigs.layout.largepadding,
    },
    valueup: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.success,
    },
    valuedown: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.error,
    },
    itemscontainer: {},
    itemcontainer: {
      display: "flex",
      flexDirection: "row",
    },
    itemwrapper: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    itemtext: {
      width: "28vw",
      color: colorscheme.v2.backgroundtext,
      fontSize: "12px",
      fontWeight: "300",
    },
    itemvalue: {
      flex: 1,
      marginLeft: deviceconfigs.layout.largepadding,
      color: colorscheme.v2.backgroundtext,
      fontSize: "12px",
      fontWeight: "700",
    },
  };
  return (
    <div key={key} style={styles.maincontainer} onClick={onClick}>
      <div style={styles.nameandiconcontainer}>
        <div style={styles.namecontainer}>
          <div style={styles.name}>{"Market Overview"}</div>
        </div>
      </div>
      <div style={styles.itemscontainer}>
        <div style={styles.itemcontainer}>
          <div style={styles.itemtext}>{language.activecurrencies}</div>
          <div style={styles.itemvalue}>{activecurrencies}</div>
        </div>
        <div style={styles.itemcontainer}>
          <div style={styles.itemtext}>{language.activeexchanges}</div>
          <div style={styles.itemvalue}>{activeexchanges}</div>
        </div>
        <div style={styles.itemcontainer}>
          <div style={styles.itemtext}>{language.btcdominance}</div>
          <div style={styles.itemvalue}>{btcdominance?.toFixed(2) + "%"}</div>
        </div>
        <div style={styles.itemcontainer}>
          <div style={styles.itemtext}>{language.ethdominance}</div>
          <div style={styles.itemvalue}>{ethdominance?.toFixed(2) + "%"}</div>
        </div>
        <div style={{ marginTop: deviceconfigs.layout.largepadding }}>
          <div style={styles.itemtext}>{language.totalmarketcap}</div>
          <div
            style={{
              color: colorscheme.v2.backgroundtext,
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {"$" + addCommas(totalmarketcap)}
          </div>
        </div>
        <div style={{}}>
          <div style={styles.itemtext}>{language.totalmarketcap24h}</div>
          <div
            style={{
              color: colorscheme.v2.backgroundtext,
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {"$" + addCommas(totalmarketcap24h)}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
    balancedisplay: state.balancedisplay,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketSummaryCard);
