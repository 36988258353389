import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";

import {
    List
} from 'antd';

import * as apiCalls from '../redux/actions/apiCalls';

import { toHumanReadableDateTime } from "../utils/timeservice";

const MyPaymentsScreen = ({ deviceconfigs, language, colorscheme, api }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [mypaymentsdata, setMyPaymentsData] = useState([]);

    const stateToStr = (state, language) => {
        switch (state) {
            case "WAITING_FOR_RESPONSE":
                return language.waitingforconfirmation;
            case "CREATED":
                return language.created;
            case "ACCEPTED":
                return language.confirmed;
            case "ACCEPTED_PARTIALLY":
                return language.confirmedpartially;
            case "REJECTED":
                return language.rejected;
        }
    }

    const fetchMyPayments = async () => {
        try {
            let paymentsresult = await api.getMyPayments();
            const myPayments = paymentsresult?.data?.object?.content || [];
            for (var i = 0; i < myPayments?.length; i++) {
                myPayments[i].icon = myPayments[i].responseunit == "usdttrc20" ? "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" : "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png";
            }
            setMyPaymentsData(myPayments);
        } catch (e) {

        }
    }

    useEffect(() => {
        console.log("came Here");
        fetchMyPayments();
        return () => {

        }
    }, []);

    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        headercontainer: {
        },
        signalinfocontainer: {
            flex: 1,
        },
        pairscontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: deviceconfigs.layout.mediumpadding,
        },
        signalstatuscontainer: {

        },
        signalstatusopen: {

        },
        signalstatuscancelled: {

        },
        paymenttext: {
            fontSize: deviceconfigs.font.xxlargetext,
            fontWeight: "300",
            color: colorscheme.v2.backgroundtext,
        },
        amounttext: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        quotecointext: {
            fontSize: deviceconfigs.font.xxlargetext,
            fontWeight: "300",
            color: colorscheme.v2.unselected,
        },
        icon: {
            width: deviceconfigs.button.xxlargesize,
            height: deviceconfigs.button.xxlargesize,
            borderRadius: deviceconfigs.layout.xlargepadding,
            objectFit: "contain",
        },
        exchangecontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: deviceconfigs.layout.largepadding,
            marginBottom: deviceconfigs.layout.largepadding,
        },
        exchangetext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
            marginLeft: deviceconfigs.layout.largepadding,
        },
        timestampcontainer: {
            marginTop: deviceconfigs.layout.largepadding,
            marginBottom: deviceconfigs.layout.largepadding,
        },
        timestamptext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.heavytext,

        },
    }
    return (
        <PageView style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <PageHeader
                    onBackPressed={() => {
                        navigate("/settings");
                    }}
                    header={language.mypayments}
                />
            </div>
            <div style={{
                flex: 1,
            }}>
                <List
                    dataSource={mypaymentsdata}
                    renderItem={(item) => {
                        return (
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: deviceconfigs.layout.xlargepadding,
                                borderRadius: deviceconfigs.layout.xlargepadding,
                                backgroundColor: colorscheme.v2.cardbackground,
                                margin: deviceconfigs.layout.xlargepadding
                            }}
                                onClick={() => {
                                    navigate("/my-payments/payment-details", { state: { payment: item } });
                                }}
                            >
                                <div style={{
                                    marginLeft: deviceconfigs.layout.xlargepadding,
                                    marginRight: deviceconfigs.layout.xlargepadding,
                                }}>
                                    {item.icon ? <img
                                        src={item.icon}
                                        style={styles.icon}
                                    /> : null}
                                </div>
                                <div style={styles.signalinfocontainer}>
                                    <div style={styles.pairscontainer}>
                                        <div style={styles.quotecointext}>{item.externalpaymentid}</div>
                                    </div>
                                    <div style={styles.pairscontainer}>
                                        <div style={styles.timestamptext}>{stateToStr(item.state, language)}</div>
                                    </div>
                                    <div style={styles.timestampcontainer}>
                                        <div style={styles.timestamptext}>{toHumanReadableDateTime(item.creationdate)}</div>
                                    </div>
                                </div>
                                <div style={styles.pairscontainer}>
                                    <div style={styles.amounttext}>{"$" + item.requestamount}</div>
                                </div>
                            </div>
                        )
                    }}
                    rowKey={(item) => item.id}
                />
            </div>
        </PageView>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        myexchanges: state.myexchanges,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPaymentsScreen);