import SizeableButton from "components/SizeableButton";
import SmartFlowIcon from "components/SmartFlowIcon";
import SmartFlowPerformanceItem from "components/SmartFlowPerformanceItem";
import AlertModal from "components/AlertModal";
import { connect } from "react-redux";
import { useState } from "react";
import { bindActionCreators } from "redux";

import { AiOutlineInfoCircle, AiOutlineBarChart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AutomaticPerformanceItemContainer from "components/AutomaticPerformanceItemContainer";
import AvailableUsdtFundsComponent from "components/AvailableUsdtFundsComponent";
import PersonalAutomaticPerformanceItemContainer from "components/PersonalAutomaticPerformanceItemContainer";

const SmartFlowPerformanceCard = ({
  colorscheme,
  deviceconfigs,
  language,
  personalperformance,
  automaticperformances,
  balancedisplay,
}) => {
  const navigate = useNavigate();
  const [showpopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("");
  const [popupbody, setPopupBody] = useState("");
  const totalbalancehidden = balancedisplay.type === "BALANCEHIDE";
  const styles = {
    mainContainer: {
      backgroundColor: colorscheme.v2.cardbackground,
      padding: "18px",
      borderRadius: "18px",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "8px",
    },
    buttonscontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "12px",
    },
  };
  console.log({ personalperformance });
  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerContainer}>
        <SmartFlowIcon />
        <div
          style={{
            marginLeft: "8px",
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
          }}
        >
          {language.automaticperformance}
        </div>
      </div>
      <div style={{
        padding: "8px 12px",
      }}>
        <AvailableUsdtFundsComponent
        />
      </div>
      <div>
        <PersonalAutomaticPerformanceItemContainer
        />
      </div>
      <div style={styles.buttonscontainer}>
        <SizeableButton
          mainContainerStyle={{
            padding: `4px 12px`,
            margin: `${0}px ${8}px`,
            borderRadius: "12px",
            width: "84%",
          }}
          icon={
            <AiOutlineInfoCircle
              style={{
                fontSize: "20px",
                color: colorscheme.v2.primarytext,
              }}
            />
          }
          iconContainerStyle={{
            marginRight: "12px",
          }}
          text={language.information}
          textstyle={{
            fontSize: "13px",
          }}
          onClick={() => {
            navigate("/personal-automatic-performance");
          }}
        />
      </div>
      <AlertModal
        open={showpopup}
        title={popuptitle}
        body={popupbody}
        containerStyle={{
          width: "92vw",
          position: "absolute",
          top: "4vh",
          left: "4vw",
        }}
        bodyContainerStyle={{
          height: "82vh",
        }}
        onBackdropClick={() => {
          setShowPopup(false);
          setPopupTitle("");
          setPopupBody("");
        }}
        buttons={[
          {
            text: "OK",
            onClick: () => {
              setShowPopup(false);
              setPopupTitle("");
              setPopupBody("");
            },
            cancel: false,
          },
        ]}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    automaticperformances: state.automaticperformances,
    personalperformance: state.personalperformance,
    balancedisplay: state.balancedisplay
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartFlowPerformanceCard);