export function tsStringNow() {
    return Date.now();
}

export function toHumanReadableDateTime(timestamp) {
    var date = new Date(new Number(timestamp));
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const hours = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
    const minutes = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
    const seconds = (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
    return (
        day +
        "/" +
        month +
        "/" +
        date.getFullYear() +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
    );
}

export function toHumanReadableDate(timestamp) {
    var date = new Date(new Number(timestamp));
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    return (
        day +
        "/" +
        month +
        "/" +
        date.getFullYear()
    );
}

export function toHumanReadableTime(timestamp) {
    var date = new Date(new Number(timestamp));
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const hours = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
    const minutes = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
    const seconds = (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
    return (
        hours +
        ":" +
        minutes +
        ":" +
        seconds
    );
}