import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { MEMBERSHIP } from "../constants/ReduxStates";

import TopSummaryCard from "../components/TopSummaryCard";
import ActionButton from "../components/ActionButton";
import MyExchangeCard from "../components/MyExchangeCard";
import BottomNavBar from "../components/BottomNavBar";

import Modal from "@mui/material/Modal";

import * as authActions from "../redux/actions/authActions";
import * as apiCalls from "../redux/actions/apiCalls";
import * as balancesActions from "../redux/actions/balancesActions";
import * as myExchangesActions from "../redux/actions/myExchangesActions";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../components/LoadingModal";

import POPULAREXCHANGES from "../constants/Exchanges";

const isExchangeAccountPassive = (account) => account?.status == "PASSIVE";


const MyExchangesScreen = ({
  api,
  auth,
  deviceconfigs,
  colorscheme,
  language,
  myexchanges,
  cmcidmap,
  myexchangesactions,
  unreads,
}) => {
  const navigate = useNavigate();
  const [deleteconfirmationvisible, setDeleteConfirmationVisible] =
    useState(false);
  const [exchangetobedeleted, setExchangeToBeDeleted] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const myexchangesPayload = myexchanges?.payload;
  const myExchangeConntected = myexchangesPayload?.data?.length > 0;
  const binanceExchange = (myexchangesPayload?.data || []).filter((exchange) => {
    return exchange.type == "BINANCE";
  });
  const isBinancePassive = binanceExchange && binanceExchange.length > 0 && isExchangeAccountPassive(binanceExchange[0]);
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    topsummarycardcontainer: {
      backgroundColor: colorscheme.v2.primary,
      borderBottomLeftRadius: deviceconfigs.layout.xxxlargepadding,
      borderBottomRightRadius: deviceconfigs.layout.xxxlargepadding,
      padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    addexchangebuttoncontainer: {
      margin: `0px ${deviceconfigs.layout.largepadding}px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    exchangestextheader: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "500",
      color: colorscheme.v2.backgroundtext,
    },
    warningtext: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "500",
      color: colorscheme.v2.primarybackground,
      margin: `${deviceconfigs.layout.xxlargepadding}px 0px`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
    },
  };
  const unreadCount = unreads?.payload;
  const balancesMap = (
    (myexchangesPayload && myexchangesPayload.data) ||
    []
  ).map((exchange, index) => {
    for (const xChange of POPULAREXCHANGES) {
      if (xChange.type === exchange.type) {
        const { balances } = exchange;
        let balanceError = false;
        if (balances) {
          balanceError = isBinancePassive;
          for (const balance of balances) {
            if (!balance.logo) {
              try {
                balance.logo = `https://s2.coinmarketcap.com/static/img/coins/64x64/${cmcidmap[balance.asset.toUpperCase()].id
                  }.png`;
              } catch (e) {
                console.error("Could not get ID", balance.asset.toUpperCase());
              }
            }
          }
        } else {
          balanceError = true;
        }
        return (
          <div key={index}>
            <MyExchangeCard
              icon={xChange.icon}
              balanceInBTC={exchange.inbtc}
              balanceInUSDT={exchange.inusdt}
              balanceatstartinusdt={exchange.balanceatstartinusdt}
              creationdate={exchange.creationdate}
              invalid={balanceError}
              supported={xChange.supported}
              onClick={() => {
                navigate("/my-exchanges/assets", {
                  state: { exchange },
                });
              }}
              onDeletePressed={(e) => {
                setExchangeToBeDeleted(exchange);
                setDeleteConfirmationVisible(true);
                e.stopPropagation();
              }}
            />
          </div>
        );
      }
    }
  });
  return (
    <div style={styles.mainframe}>
      <TopSummaryCard
        onNotificationPress={() => {
          navigate("/notifications");
        }}
        unreadnotificationcount={unreadCount}
      />
      <div style={{}}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            padding: deviceconfigs.layout.xlargepadding,
            marginVertical: deviceconfigs.layout.xlargepadding,
          }}
        >
          {myExchangeConntected ? (
            <div style={styles.exchangestextheader}>
              {language.connectedexchanges}
            </div>
          ) : null}
        </div>
        <div
          style={{
            marginHorizontal: deviceconfigs.layout.xxxlargepadding,
            alignItems: "center",
          }}
        >
          {balancesMap}
        </div>
        {!myExchangeConntected ? (
          <div style={styles.addexchangebuttoncontainer}>
            <ActionButton
              text={language.connectyourbinance}
              buttonstyle={{
                width: "72vw",
                padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                backgroundColor: colorscheme.v2.primary,
                borderRadius: deviceconfigs.layout.xxlargepadding,
              }}
              onClick={() => {
                navigate("/add-exchange-select-exchange");
              }}
            />
          </div>
        ) : null}
        <div style={{ height: "24vh" }}></div>
      </div>
      <Modal
        open={deleteconfirmationvisible}
        onClose={() => setDeleteConfirmationVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <div
            style={{
              width: "84vw",
              height: "62vh",
              padding: deviceconfigs.layout.xxlargepadding,
              borderRadius: deviceconfigs.layout.xxlargepadding,
              backgroundColor: colorscheme.v2.primary,
              margin: "auto",
              marginTop: "32px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../assets/images/v2/danger.png")}
                style={{
                  width: deviceconfigs.button.xlargesize,
                  height: deviceconfigs.button.xlargesize,
                  objectFit: "contain",
                }}
                alt={"Danger"}
              />
              <div
                style={{
                  fontSize: deviceconfigs.font.xxlargetext,
                  fontWeight: "600",
                  color: colorscheme.v2.background,
                }}
              >
                {language.attention}
              </div>
            </div>
            <div style={{ alignItems: "center" }}>
              <div style={styles.warningtext}>
                {language.disconnectwarningone(
                  exchangetobedeleted && exchangetobedeleted.type
                )}
              </div>
              <div style={styles.warningtext}>
                {language.disconnectwarningtwo}
              </div>
              <div style={styles.warningtext}>
                {language.disconnectwarningthree}
              </div>
              <div style={styles.warningtext}>
                {language.disconnectwarningfour}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: `${deviceconfigs.layout.xxlargepadding}px 0px`,
            }}
          >
            <ActionButton
              text={language.cancel}
              buttonstyle={{
                marginHorizontal: deviceconfigs.layout.xxlargepaddding,
                width: "36vw",
                backgroundColor: colorscheme.v2.error,
              }}
              onClick={() => {
                setDeleteConfirmationVisible(false);
              }}
            />
            <ActionButton
              text={language.confirm}
              buttonstyle={{
                marginHorizontal: deviceconfigs.layout.xxlargepaddding,
                width: "36vw",
              }}
              onClick={async () => {
                if (exchangetobedeleted.id) {
                  setDeleteConfirmationVisible(false);
                  setLoading(true);

                  api
                    .deleteExchangeAccount(exchangetobedeleted.id)
                    .then(async (res) => {
                      await myexchangesactions.checkExchanges();
                      setLoading(false);
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                  return false;
                }
              }}
            />
          </div>
        </div>
      </Modal>
      <BottomNavBar />
      <LoadingModal loading={loading} />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(authActions, dispatch),
    api: bindActionCreators(apiCalls, dispatch),
    balancesactions: bindActionCreators(balancesActions, dispatch),
    myexchangesactions: bindActionCreators(myExchangesActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    myexchanges: state.myexchanges,
    cmcidmap: state.cmcidmap,
    membership: state.membership,
    unreads: state.unreads,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyExchangesScreen);
