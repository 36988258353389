import React from "react";

const R = {
  _name: "English",
  _language: "Language",
  _denom: "en",
  _type: "ENGLISH",
  myperformers: "My Performers.",
  signinto: "Sign in to",
  forgotpassword: "Forgot Password?",
  rememberme: "Remember me",
  activate: "Activate",
  faceid: "Face ID",
  fingerprint: "Fingerprint",
  signin: "Sign In",
  completeregistration: "Complete Registration",
  biometricnotfound: "Biometric record not found",
  verifybiometric: "Please verify your identity with your password",
  ok: "OK",
  yes: "Yes",
  no: "No",
  donthaveanaccount: "Don't have an account yet?",
  register: "Register",
  createanew: "Create a new",
  account: "Account",
  alreadyhaveanaccount: "Already have an account?",
  continue: "Continue",
  verystrong: "Very Strong",
  strong: "Strong",
  medium: "Medium",
  weak: "Weak",
  atleast8digits: "At least 8 characters , numbers or letters",
  signals: "Signals",
  alerts: "Alerts",
  orders: "My History",
  watchlist: "Watchlist",
  addyouraccount: "Add Your Account",
  connectyourexchange: "Connect Your Exchange",
  connectyourbinance: "Connect Your Binance",
  upgradetopremium: "Upgrade to Premium",
  taptoseehow: "Tap to See How",
  scanqrcode: "Scan QR Code",
  addyourkeys: "Add Your Keys",
  selectintegrationmethod: "Select Integration Method",
  selectmethod: "Select Method",
  noaccount: "No Account",
  manualkeycopy: "Manual Key Copy/Paste",
  apikey: "API Key",
  secretkey: "Secret Key",
  manualkeys: "Manual Keys",
  next: "Next",
  addyourkeyssummary: "Add Your Keys Summary",
  createaccount: "Create Account",
  profit: "Profit",
  balanceinusdt: "Balance (USDT)",
  balanceinbtc: "Balance (BTC)",
  connectedexchanges: "Connected Exchanges for Automatic - Binance",
  open: "Open",
  accepted: "Accepted",
  cancelled: "Cancelled",
  signaldetails: "Signal Details",
  manual: "Manual",
  automatic: "Automatic",
  buyrange: "Buy Range",
  buyrangefrom: "From",
  buyrangeto: "To",
  initialprice: "Initial Price",
  stoploss: "Stop Loss",
  targets: "Targets",
  targetsnotfull:
    "You have not selected 100% of target shares. Unselected target shares will be regulated by the Automatic Stop Loss.",
  target: "Target",
  addnewtarget: "Add New Target",
  orderdetails: "Order Details",
  currentstatus: "Current Status",
  currentresult: "Current Profit/Loss",
  active: "Active",
  waiting: "Waiting",
  closed: "Closed",
  closedactive: "Closed Active Order",
  closedwaiting: "Closed Waiting Order",
  closedbystoploss: "Stop Loss",
  closedbysystem: "Closed By System",
  completed: "Completed",
  finished: "Finished",
  exchange: "Exchange",
  type: "Type",
  quantity: "Quantity",
  amount: "Amount",
  purchasingprice: "Purchasing Price",
  targetedprofit: "Targeted Profit",
  realizedprofit: "Realized Profit/Loss",
  riskofinvestment: "Risk of Investment",
  minimumquantity: "Minimum Quantity",
  minimumvalue: "Minimum Value",
  minimumamount: "Minimum Amount",
  available: "Available",
  reached: "Reached",
  search: "Search",
  cancel: "Cancel",
  confirm: "Confirm",
  marketwatch: "Market Watch",
  name: "Name",
  price: "Price",
  changeIn24h: "24%",
  changeIn7d: "7d%",
  marketcap: "Market Cap.",
  dilutedmarketcap: "Fully Diluted Market Cap.",
  circulatingsupply: "Circulating Supply",
  totalsupply: "Total Supply",
  marketcapdominance: "Dominance",
  rank: "Rank",
  volume: "Volume",
  chart: "Chart",
  myfavoritecoins: "My Favorite Coins",
  markallasread: "Mark All Read",
  markasread: "Mark Read",
  marketprice: "Market Price",
  created: "Created",
  hit: "Hit Target",
  add: "Add",
  selectexchange: "Select Exchange",
  selectexchangeandpairing: "Select Exchange and Pairing",
  choose: "Choose",
  selectedexchange: "Selected Exchange",
  selectedcointoken: "Selected Coin/Token",
  targetvalue: "Target Value",
  selectanexchange: "Select an Exchange",
  selectcointoken: "Select a Coin/Token Pair",
  settings: "Settings",
  app: "App",
  abouttext: "About",
  support: "Support",
  accountsettings: "Account Settings",
  launchscreen: "Launch Screen",
  switchto: (currentmode) => {
    return currentmode == "light"
      ? "Switch to Night Mode"
      : "Switch to Day Mode";
  },
  language: "Language",
  termsandconditions: "Terms & Conditions",
  privacypolicy: "Privacy Policy",
  newsletter: "Newsletter",
  sharemyperformersapp: "Share MyPerformers App",
  contact: "Contact",
  contactsupport: "Contact Support",
  sendfeedback: "Send Feedback",
  howtoguides: "How to Guides",
  notificationsettings: "Notification Settings",
  notifications: "Notifications",
  securitysettings: "Security Settings",
  version: "Version",
  logout: "Logout",
  news: "News",
  neworderfeedback: "New Order Feedback",
  targethit: "Target Hit",
  targetthreepercent: "Target 3%",
  stoploss: "Stop Loss",
  stoplossthreepercent: "Stop Loss 3%",
  order: "Order",
  signal: "Signal",
  newsignal: "New Signal Published",
  signalchange: "Signal Changed",
  signalexpiration: "Signal Expired",
  newscreated: "News Published",
  orderactivated: "Order Activated",
  ordercompleted: "Order Completed",
  resetsettings: "Reset Settings",
  resetpassword: "Reset Password",
  verifylogin: "Verify Login",
  oneclickautomaticorder: "One-Click Automatic Order",
  requirephoneverificationfororders: "Require SMS Verification for Order",
  paymentsummary: "Payment Summary",
  mypayments: "My Payments",
  assetslist: "Assets List",
  password: "Password",
  changepassword: "Change Password",
  currentpassword: "Current Password",
  newpassword: "New Password",
  repeatnewpassword: "Repeat New Password",
  security: "Security",
  myorders: "My Orders",
  closeorder: "Close Order",
  noaccount: "No Account",
  changeavatar: "Change Avatar",
  clicktochange: "Click to Change",
  connectedat: "Connected at",
  overview: "Overview",
  markets: "Markets",
  statistics: "Statistics",
  disclaimerp1: "We are doing our best to prepare the content of this app.",
  disclaimerp2:
    "However, MyPerformers App cannot warranty the expressions and suggestions of the contents, as well as its accuracy.",
  disclaimerp3:
    "In addition, to the extent permitted by the law, MyPerformers App shall not be responsible for any losses and/or damages due to the usage of the information on our app.",
  disclaimerp4:
    "By using our app, you hereby consent to our disclaimer and agree to its terms.",
  acknowledge: "Acknowledge",
  lowhigh: "Low/High",
  volume24h: "Volume24h",
  maxsupply: "Max Supply",
  alltimehigh: "All Time High",
  alltimelow: "All Time Low",
  tags: "Tags",
  topnews: "Top News",
  bitcoin: "Bitcoin",
  altcoins: "Altcoins",
  nft: "NFT",
  subscriptiontype: "Subscription Type",
  verifyyouremail: "Verify Your E-Mail",
  selectsubscriptiontype: "Select Subscription Type",
  waitingforconfirmation: "Waiting for Confirmation",
  confirmed: "Confirmed",
  confirmedpartially: "Confirmed Partially",
  rejected: "Rejected",
  readandagree: (deviceconfigs, colorscheme) => {
    return (
      <span
        style={{
          fontSize: deviceconfigs.font.mediumtext,
          fontWeight: "700",
          color: colorscheme.v2.backgroundtext,
        }}
      >
        I have carefully read and agree to the{" "}
        <span
          style={{
            fontWeight: "700",
            fontSize: deviceconfigs.font.mediumtext,
            color: colorscheme.v2.primary,
          }}
        >
          Terms and Conditions
        </span>
      </span>
    );
  },
  arabic: "عربي",
  english: "English",
  french: "Français",
  german: "Deutsch",
  dutch: "Nederlands",
  turkish: "Türkçe",
  russian: "русский",
  spanish: "Español",
  italian: "Italiano",
  save: "Save",
  noaccountfirstpar: (exchange) => {
    return `We are currently in process of connecting ${exchange} via API.To do this, you need an active and verified account on ${exchange}.`;
  },
  noaccountsecondpar: (exchange) => {
    return `If you already have a ${exchange} account, choose one of the two other options.`;
  },
  noaccountthirdpar: (exchange) => {
    return `If you do not have a ${exchange} account yet, you can easily create one now by clicki on the button below.`;
  },
  createanaccount: "Create an Account",
  buyxinexchangefory: (x, y) => {
    return `Buy ${x} in exchange for ${y}`;
  },
  membership: (type) => {
    switch (type) {
      case "TRIAL":
        return "Basic";
      case "PREMIUM":
        return "Premium";
      case "PROMOTION30DAYS":
        return "30-Days Premium"
      default:
        return "N/A";
    }
  },
  membershiptext: "Membership",
  expiresat: "Expires At : ",
  expires: "Expires",
  paycurrency: "Pay Currency",
  totalamountinusd: "Total Amount in USDT",
  clicktoupgrade: "Click to Upgrade",
  upgradenow: "Upgrade",
  send: "Send",
  step: (x) => {
    return "Step " + x;
  },

  sureclose: "Are you sure to close the order?",
  selectpaymentcoin: "Select Payment Coin",
  apikeyexpired:
    "Api Key Expired. Try to delete this exchange and reconnect again.",

  paymentwarning:
    "Please make sure that you select the correct network for the transfer of the Payment Coin",
  paymentwarning2:
    "Please make sure that you pay the transfer costs, otherwise we will not receive the full amount and will not be able to activate the version.",
  requestedamount: "Requested Amount",
  copiedamounttoclipboard: "Amount copied to Clipboard",
  copiedaddresstoclipboard: "Payment Address copied to Clipboard",
  paymentaddress: "Payment Address",
  paymentnetwork: "Payment Network",
  addcoinpair: "Add Coin Pair",
  submit: "Submit",
  free: "Free",
  locked: "Locked",
  totalinusdt: "Total in USDT",
  totalinbtc: "Total in BTC",
  totalfree: "Total Free",
  totallocked: "Total Locked",
  myassets: "My Assets",
  email: "Email",
  uptotop: "Up to Top",
  stoplossnotvalidheader: "Stop-Loss Warning",
  stoplossnotvalidp1:
    "You have not selected a Stop-Loss for this order. If the price falls sharply before the targets are reached, it can lead to large losses.",
  stoplossnotvalidp2:
    "If you want to adjust the value , press on Adjust to go back.",
  stoplossnotvalidp3:
    "If you continue, you confirm that you are aware of the risk and that is your own decision and responsibility.",
  riskofinvestmentwarningheader: "High Level Risk of Investment",
  riskofinvestmentwarningxheader: "Very High Level Risk of Investment",
  riskofinvestmentwarningxp1:
    "The purchasing price and stop loss you have selected causes a Very High Level of Risk.",
  riskofinvestmentwarningp1:
    "The purchasing price and stop loss you have selected causes a High Level of Risk.",
  riskofinvestmentwarningp2:
    "Before proceeding you might want to reconsider your settings.",
  riskofinvestmentwarningp3:
    "To rearrange the values press Go Back , otherwise press Continue",
  targetnotfullheader: "Target Shares Below 100%",
  adjust: "Adjust",
  continue: "Continue",
  goback: "Go Back",
  appinitlineone: "Winning in Cryptomarket can be simple and controllable.",
  appinitlinetwo: "Easy, Fast and Simple Interface for Crypto lovers.",
  appinitlinethree: "Saving Assets...This takes a while.",
  appinitlinefour:
    "Start now to use the Cryptomarket efficiently, effectively and lucratively.",
  disconnectwarningone: (exchange) => {
    return `You are about to disconnect from ${exchange}.`;
  },
  disconnectwarningtwo:
    "In this case, all open orders will be stopped immediately.",
  disconnectwarningthree:
    "The remaining assets that were bought by accepting market information are sold immediately by market order.",
  disconnectwarningfour: "I am aware of this and agree to this procedure.",
  invoices: "Invoices",
  restarttheapp: "Restart",
  selectpaymentmethod: "Select Payment Method",
  activecurrencies: "Currencies",
  activeexchanges: "Exchanges",
  activemarketpairs: "Market Pairs",
  btcdominance: "BTC Dom.",
  ethdominance: "ETH Dom.",
  totalmarketcap: "Market Cap.",
  totalmarketcap24h: "Market Cap. 24h",
  sellingprice: "Selling Price",
  smartflow: "Automatic",
  smartflowheader:
    "The automatic mode is the automatic execution of market information. As long as you have USDT available on Binance, trades will be executed fully automated. ",
  smartflowsexpl:
    "The amount that is used for this is determined by you. There are two ways to do this.",
  smartflowsubheader1: "1) With a fixed USDT amount",
  smartflowsubheader2: "2) With a fixed percentage",
  smartflowp1:
    "To do this, specify the desired amount on the following page. This amount is then used for each market information.",
  smartflowp2:
    "To do this, select the desired percentage on the following page. This percentage of the balance available on Binance is then used per market information.",
  smartflowlastp:
    "Market information will be accepted as long as the USDT balance on Binance is above the selected amount. If the credit is insufficient, the current market information cannot be accepted. When sufficient USDT is available again, market information will be accepted again.",
  smartflowreadandagreed: "I have read and agreed to Automatic DIsclaimer",
  enablesmartflow: "Enable Automatic",
  amountofusdt: "Amount USDT per Trade",
  shareoffunds: "Share (%) of funds(USDT) per Trade",
  enjoyfullmyperformersexperience: "Enjoy full MyPerformers Experience.",
  install: "Install",
  donthaveanaccountyet: "Don't Have and Account Yet?",
  gotomyexchanges: "Go To My Exchanges",
  gotodashboard: "Go To Dashboard",
  retryapiandsecretkeys: "Retry API & Secret Keys",
  availableusdtfunds: "Available USDT for Automatic",
  automaticperformance: "Personal Automatic Overview",
  aboutmyperformersapp: "About MyPerformersApp",
  automaticmode: "Automatic Mode",
  automaticmodepopupheader1:
    "The automatic mode is the automatic execution of market information. As long as you have USDT available on Binance trades will be executed fully automated.",
  automaticmodepopupsubheader1: "There are two ways to do this.",
  automaticmodepopuptext1: "1.) With a fixed USDT amount",
  automaticmodepopuptext2: "2.) With a fixed percentage",
  automaticmodepopupheader2: "10% per Month Performance Rule",
  automaticmodepopuptext3:
    "We have made it our mission to give everyone the opportunity to participate in the crypto market as easily and transparently as possible. We have therefore decided not to charge any fees if we generate less than 10% per month. In other words, if the automatic mode generates less than 10%, you can use the application free of charge in the following month. The values of the general automatic mode apply to the calculation, as shown here in this field. A trade history for documentation can be found under the menu item Chart.",
  automaticinlinedesc:
    "Here you have to make a choice whether you want to use a fixed USDT amount per trade or use a certain percentage of your existing USDT balance on your Binance Exchange per trader. Only one selection can be made at a time.",
  minimumamount60USDT: "Minimum Amount 60 USDT",
  minimumshare: (share) => `Min. share ${share}%`,
  maximumshare: (share) => `Max. share ${share}%`,
  balanceofyourexchanges: "Balance Exchanges",
  automaticprofit: "Automatic Profit",
  information: "Detail Information",
  charts: "Charts",
  h24: "24h",
  d7: "7d",
  d30: "30d",
  y1: "1Y",
  automaticlast7days: "Automatic - Last 7 Days",
  automaticlast30days: "Automatic - Last 30 Days",
  startcapital: "(Start Capital 100 USDT)",
  automaticwarning: "Automatic Mode Warning",
  noexchangeconnected: "You must have Binance Exchange connected in order to use Automatic functionality.",
  membershipexpired: "In order to use Automatic Mode, you have to become a Premium User.",
  gopremium: "Go Premium",
  howtojointelegram: "Signal Channel",
  start: "Start",

  addingaccountsuccessful:
    "Adding account failed. Reason : Invalid API-Secret Key",
  youhavealreadyaccountintegrated: (text) =>
    `You have already ${text} account integrated`,
  checkyouremail: "Check you e-mail and login with new password",
  resetpasswordsuccess: "Reset Success",
  attention: "Attention",
  basic: "Basic",
  premium: "Premium",
  viewdetails: "View Details",
  youareabouttoleave: "Are you sure you want to leave MyPerformers?",
  welcometomyperformersapp: "Welcome to MyPerformers App",
  on: "On",
  off: "Off",

  about: {
    line1: "Welcome to MPA!",
    line2: "MPA is an application tool with focus on the crypto market and offers you the following possibilities.",
    line3: "- current , well-founded information",
    line4: "- an market overview, to be always up-to-date",
    line5: "Overview of your assets, simple and clear",
    line6: "Portfolio management at the highest level",
    line7: "Many areas are usable free of charge. Portfolio management is a paid area, it is offered under Automatic. You can use Automatic 30 days for free, if you activate within 7 days of downloading the App.",
    line8: "3 simple steps to are important to start with Automatic and participate in the crypto market :",
    line9: "(!) Register on Binance and created an API ",
    line10: "(!) Connect the APP to Binance via the API interface",
    line11: "(!) Activate the automatic mode",
    line12: "What does automatic mode offer?",
    line13: "The automatic mode is the automatic execution of market information directly on your exchange account(currently via Binance). As long as you have USDT available on your exchange account and the automatic mode is active, trades are executed fully automatically. The amount used for a trade is determined by you. The minimum bet per trade is 60.00 USDT",
  },

  videotutorial: "Video Tutorial",
  automaticmode: "The Automatic Mode",
  automaticmodedesc: {
    p1: "Automatic Mode is the automatic execution of market information, directly on your exchange account. As long as you have USDT available on your exchange account and the automatic mode is active, trades are executed fully automatically. There is always a chance that they themselves will intervene in an ongoing trade. The amount used for a trade is determined by you. The minumum bet per trade is 60.00 USDT.",
    p2: "10% per Month Performance Rule*",
    p3: "We have made it our mission to give everyone the oppotunity to participate in the crypto market as easily and transparently as possible with this function. As a sign if how seriously we take our self-imposed mission, we have chosen not to charge for using the app if we generate less than 10% usage in 30days.",
    p4: "What does that mean for you, how does it work?",
    p5: "If you load the APP, link Binance via API within 7 days and activate the automatic mode, you can use the APP with this function for 30 days free of charge.",
    p6: "Let the automatic mode run continuosly for 30 days without intervening in a trade manually, and it will not generate 10% if you have another 30 days of use for free.",
    p7: "If you have paid for 30 days and have not earned 10% during this period, provided you let the automatic mode run continuously for 30 days without intervening in a trade manually, you have free use for the next 30 days.",
    p8: "If the 10% is generated under the same conditions as described above, the usage fee must be paid for using APP.",
    p9: "In many cases, it will result in the user fee being able to be paid from the credit earned. That is the thinking behind this rule.",
    p10: "* applies until further notice",
  },

  firstregistration: {
    line1: "Test automatic mode now for 30 days - FREE!",
    line2: "If you connect your exchange(currently Binance) within the next 7 days and activate the Automatic Mode, you can test the Automatic Mode for 30 days free of charge. After 7 days, if you have not activated the automatic mode, this offer is no longer valid.",
    line3: "A detailed descripion of how to connect your exchange account and start the automatic mode can be found in the APP under the white ?, top right.",
    line4: "Use this unique opportunity",
    line5: "Your MPA TEAM",
  },

  binanceapikeypassive: "Binance API Key has Expired. Please go to your Binance account and re-activate your API options or create a new one",

  disablingautomaticwarning: {
    title: "Disabling Automatic Warning",
    body: {
      line1: "Dear MPA user!",
      line2: "As you probably know, the 10% rule currently applies. The prerequisite is that the automatic mode is ON for 30 days and that a trade is not intervened manually. By switching off the automatic mode, the 10% rule loses its validity in this period."
    }
  },
  proceed: "Proceed",

  success: {
    addexchangesuccess: {
      header: "Success",
      body: "Add Exchange Success",
    },
    scansuccess: {
      header: "Scan Success",
      body: "QR Scan Successful",
    },
    order: {
      header: "Order Successful",
      body: "Order received successfully.",
    },
    passwordchange: {
      header: "Success",
      body: "Password has changed successfully.",
    },
    verificationsuccess: {
      header: "Success",
      body: "E-Mail Verification Success",
    },
    paymentrequestreceived: {
      header: "Payment Request Success",
      body: "Proceed with your crypto payment. Once the payment approved your subscription will be active.",
    },
    purchasesuccess: {
      header: "Subscription Success",
      body: "You are now ready to use all features of MyPerformers.",
    },
    displaynamechangedsuccess: {
      header: "Success",
      body: "Display Name changed Successfully.",
    },
  },
  warning: {
    newversionavailable: {
      header: "New Version Available",
      body: "Please restart the app to get the latest version.",
    },
    alreadysubscribed: {
      header: "Subscription Warning",
      body: "Already Subscribed",
    },
    nobinanceforautomatic: {
      header: "Account Error",
      body: "Please integrate your Binance Account for Automatic Mode.",
    },
  },
  error: {
    generic: {
      header: "Error",
      body: "Something went wrong.",
    },
    addexchangefail: {
      header: "Error",
      body: "Adding account failed. Reason : Invalid API-Secret Key",
    },
    loginnotsuccessful: {
      header: "Sign In Error",
      body: "Credentials not correct.",
    },
    permission: {
      header: "Permission Error",
      body: "Make sure to give Camera permission.",
    },
    noexchangeaccount: {
      header: "Error",
      body: "You have no exchange accounts.",
    },
    exchangeaccountspecifiednotconnected: (exchange) => {
      return {
        header: "Error",
        body: `You have no exchange accounts connected for ${exchange}.`,
      };
    },
    passwordchange: {
      header: "Error",
      body: "Error occured during password change.",
    },
    faceidnotverified: {
      header: "Error",
      body: "FaceID/Fingerprint not verified",
    },
    verificationerror: {
      header: "Error",
      body: "Login Verification Failed",
    },
    faceid: {
      header: "Error",
      body: "Biometrics Not Supported",
    },
    biometricsnotenrolled: {
      header: "Biometrics Error",
      body: "Biometrics Not Enrolled.",
    },
    nobiometriclevel: {
      header: "Biometrics Error",
      body: "Level of Enrollment is not high.",
    },
    biometricsnotcompatible: {
      header: "Biometrics Error",
      body: "Biometrics Not Compatible.",
    },
    signuperror: {
      header: "Sign Up Error",
      body: "Username/Email already exists.",
    },
    expirederror: {
      header: "Membership Expired",
      body: "Please upgrade your membership for unlimited access.",
    },
    insufficientbalance: {
      header: "Insufficient Balance",
      body: "You don't have enough amount in your balance.",
    },
    purchasefailed: {
      header: "Subscription Failed",
      body: "Unknown error occurred during subscription",
    },
    forgotpassworderror: {
      header: "Reset Password Error",
      body: "Username/Email Address not found",
    },
  },
};

export default R;