import { BALANCES } from "../../constants/ReduxStates";

export function loadBalancesSuccess(payload) {
    return {
        type: BALANCES.BALANCES_LOADED,
        payload,
    };
}

export function loadBalancesFail() {
    return {
        type: BALANCES.BALANCES_LOAD_FAILED,
    };
}

export function checkMyBalances() {
    return function (dispatch) {
        const balances = JSON.parse(localStorage.getItem('balances'));
        dispatch(loadBalancesSuccess(balances));
    }
}

export function loadBalances() {
    return function (dispatch) {
        const balances = JSON.parse(localStorage.getItem('balances'));
        dispatch(loadBalancesSuccess(balances));
    }
}

export function saveBalances(balances) {
    return function (dispatch) {
        localStorage.setItem("balances", JSON.stringify(balances));
        dispatch(loadBalancesSuccess(balances));
    }
}

export function deleteBalance(exchangeid) {
    return function (dispatch) {
        const balances = JSON.parse(localStorage.getItem('balances'));
        let exchanges = balances.exchanges;
        if (exchanges) {
            for (var i = 0; i < exchanges.length; i++) {
                if (exchanges[i].id == exchangeid) {
                    exchanges.splice(i, 1);
                    localStorage.setItem("balances", JSON.stringify(balances));
                    dispatch(loadBalancesSuccess(balances));
                    return;
                }
            }
        }

    }
}

export function deleteBalances() {
    return function (dispatch) {
        localStorage.removeItem("balances");
        dispatch(loadBalancesFail());
    }
}