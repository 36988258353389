import MembershipStatusBadge from "components/MembershipStatusBadge";
import { AiOutlineEye } from "react-icons/ai";

import { FaQuestionCircle } from "react-icons/fa";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as balanceShowActions from "../../redux/actions/balanceShowActions";
import { addCommas } from "../../utils/stringutils";
import { toHumanReadableDate } from "../../utils/timeservice";

import StyledCompponents from "styled-components";
import AutomaticPersonalProfitTag from "components/AutomaticPersonalProfitTag";

const Button = StyledCompponents.button`
  outline : none;
  border: none;
  &:focus{
    outline : none;
  border: none;
  }
`;

const isNumber = (l) => typeof l == "number";

const TopSummaryCard = ({
  auth,
  myexchanges,
  balanceshowactions,
  balancedisplay,
  colorscheme,
  deviceconfigs,
  language,
  personalperformance,
}) => {
  const navigate = useNavigate();
  const totalbalancehidden = balancedisplay?.type === "BALANCEHIDE";
  const myexchangesPayload = myexchanges && myexchanges.payload;
  const totalBalanceInUSDT =
    (myexchangesPayload && myexchangesPayload.inusdt) || 0;
  const totalBalanceInBTC =
    (myexchangesPayload && myexchangesPayload.inbtc) || 0;
  const tBalance =
    (totalBalanceInUSDT &&
      isNumber(totalBalanceInUSDT) &&
      totalBalanceInUSDT.toFixed(2)) ||
    parseFloat(0).toFixed(8);
  const tBTCBalance =
    (totalBalanceInBTC &&
      isNumber(totalBalanceInBTC) &&
      totalBalanceInBTC.toFixed(8)) ||
    parseFloat(0).toFixed(2);
  const { user } = auth;
  const { membership } = user;
  const styles = {
    topsummarycardcontainer: {
      position: "relative",
      backgroundColor: colorscheme.v2.primary,
      borderBottomLeftRadius: "24px",
      borderBottomRightRadius: "24px",
      paddingBottom: deviceconfigs.layout.xxlargepadding,
      paddingTop: deviceconfigs.layout.xxlargepadding,
      lineHeight: 1.32,
    },
    profileandactioncontainer: {
      display: "flex",
      flexDirection: "row",
    },
    profilecontainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      marginLeft: "12px",
    },
    profileimagecontainer: {},
    profilenamecontainer: {
      marginLeft: "12px",
      display: "flex",
      flexDirection: "row",
    },
    profilename: {
      color: colorscheme.v2.primarytext,
      fontSize: "21px",
      fontWeight: "600",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "208px",
    },
    notificationbuttoncontainer: {
      marginRight: "18px",
      marginTop: "12px",
      backgroundColor: colorscheme.v2.primarybackground,
      borderRadius: "12px",
      width: "36px",
      height: "36px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    actionbuttonscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: "18px",
    },
    totalcontainer: {
      marginVertical: "12px",
      display: "flex",
      flexDirection: "row",
    },
    inbtctext: {
      fontSize: "13px",
      color: colorscheme.v2.primarytext,
      width: "72%",
    },
    inusdttext: {
      fontSize: "15px",
      color: colorscheme.v2.primarytext,
      flex: 1,
    },
    membershiptypetext: {
      fontSize: "18px",
      fontWeight: "400",
      color: colorscheme.v2.primarytext,
    },
    expirestext: {
      fontSize: "10px",
      fontWeight: "700",
      color: colorscheme.v2.primarytext,
      marginTop: "8px",
    },
    itemcontainer: {
      marginLeft: "24px",
      marginTop: deviceconfigs.layout.mediumpadding,
    },
  };
  const myMembership = user.membership;
  const isExpired = myMembership?.type != "PREMIUM" && myMembership?.status === "EXPIRED";
  return (
    <div>
      <div style={styles.topsummarycardcontainer}>
        <div style={styles.profileandactioncontainer}>
          <div style={styles.profilecontainer}>
            <div style={styles.profileimagecontainer}>
              <img
                src={
                  user.personalinfo.profilepic ||
                  "https://ih1.redbubble.net/image.2154337780.2319/poster,504x498,f8f8f8-pad,600x600,f8f8f8.jpg"
                }
                style={{
                  width: deviceconfigs.image.xxxxlargeimagesize,
                  height: deviceconfigs.image.xxxxlargeimagesize,
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
                alt={"MyPerformers"}
              />
            </div>
            <div style={styles.profilenamecontainer}>
              <div>
                <div style={styles.profilename}>
                  {user.personalinfo.displayname}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  {myMembership && (
                    <div
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      <MembershipStatusBadge />
                      {myMembership?.type != "TRIAL" ? <div style={styles.expirestext}>
                        {`${language.expires} : ` +
                          toHumanReadableDate(myMembership.expirationdate)}
                      </div> : undefined}
                    </div>
                  )}
                  <div>
                    {isExpired ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "8px",
                        }}
                      >
                        <Button
                          style={{
                            width: "92px",
                            fontSize: "13px",
                            fontWeight: "700",
                            color: colorscheme.v2.primarytext,
                            textAlign: "center",
                            padding: "4px 4px",
                            marginRight: "12px",
                            borderRadius: "12px",
                            backgroundColor: colorscheme.v2.attention,
                          }}
                          onClick={() => {
                            navigate("/memberships/select-membership");
                          }}
                        >
                          {language.upgradetopremium}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={styles.notificationbuttoncontainer}
            onClick={() => {
              navigate("/about-myperformersapp")
            }}
          >
            <FaQuestionCircle
              style={{
                fontSize: "22px",
                color: colorscheme.v2.primary,
              }}
            />
          </div>
        </div>

        <div style={styles.totalcontainer}>
          <div style={{ flex: 1 }}>
            <div
              style={{
                fontSize: "17px",
                color: colorscheme.v2.primarytext,
                flex: 1,
                marginLeft: "12px",
                marginTop: "12px",
              }}
            >
              {language.balanceofyourexchanges}
            </div>
            <div
              style={{
                marginLeft: "12px",
              }}
            >
              <div style={styles.inusdttext}>
                {"USDT " +
                  (totalbalancehidden ? "********" : addCommas(tBalance))}
              </div>
              <div style={styles.inbtctext}>
                {"" +
                  (totalbalancehidden
                    ? "********"
                    : "( BTC " + tBTCBalance + " )")}
              </div>
            </div>
          </div>
          <div style={{
            alignSelf: "center",
          }}>
            <div
              style={{
                margin: "0px 4px",
              }}
            >
              <button
                style={{
                  padding: "4px",
                  backgroundColor: !totalbalancehidden
                    ? colorscheme.v2.primarytext
                    : colorscheme.v2.primary,
                  borderRadius: "12px",
                  outline: "none",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => {
                  balanceshowactions.updateBalanceDisplay(totalbalancehidden);
                }}
              >
                <AiOutlineEye
                  size={22}
                  color={colorscheme.v2.primarybackground}
                />
              </button>
            </div>
          </div>
          <div style={{ flex: 1, marginRight: "4px" }}>
            <div
              style={{
                fontSize: "17px",
                color: colorscheme.v2.primarytext,
                flex: 1,
                marginLeft: "12px",
                marginTop: "12px",
              }}
            >
              {language.automaticprofit}
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: "12px",
              }}
            >
              <div style={styles.inusdttext}>
                {"USDT " +
                  (totalbalancehidden ? "********" : addCommas(parseFloat(personalperformance.totaldevelopmentusdt).toFixed(2)))}
              </div>
              <div style={styles.inbtctext}>
                <AutomaticPersonalProfitTag
                  value={parseFloat(personalperformance.totaldevelopmentpercentage)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    balanceshowactions: bindActionCreators(balanceShowActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    myexchanges: state.myexchanges,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    balancedisplay: state.balancedisplay,
    membership: state.membership,
    personalperformance: state.personalperformance
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSummaryCard);