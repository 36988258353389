import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";
import ActionButton from "../components/ActionButton";
import SettingsButton from "../components/SettingsButton";
import AlertModal from "../components/AlertModal";

import * as authActions from "../redux/actions/authActions";
import * as apiCalls from "../redux/actions/apiCalls";

import IconedInput from "../components/IconedInput";

import { toHumanReadableDateTime } from "../utils/timeservice";
import { useNavigate } from "react-router-dom";

import { message as MessageThrow, Upload } from "antd";

import {
  AiOutlineUser,
  AiOutlineSave,
  AiOutlineMail,
  AiOutlineKey,
  AiOutlineEye,
} from "react-icons/ai";
import LoadingModal from "components/LoadingModal";
import { useEffect } from "react";
import BinancePopupHeaderComponent from "components/BinancePopupHeaderComponent";

const openMessage = (message) => {
  MessageThrow.success(message);
};

const defaultPopup = {
  shown: false,
  title: undefined,
  header: undefined,
  footer: undefined,
  buttons: [],
}

const AccountSettingsScreen = ({
  auth,
  api,
  authactions,
  language,
  deviceconfigs,
  colorscheme,
}) => {
  const [oldpassword, setOldpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [rnewpassword, setRNewPassword] = useState("");
  const [showopassword, setShowOpassword] = useState(false);
  const [shownpassword, setShowNPassword] = useState(false);
  const [showrpassword, setShowRPassword] = useState(false);
  const [profilenamechanging, setProfileNameChanging] = useState(false);
  const [profilename, setProfileName] = useState("");
  const [selectedprofilepic, setSelectedProfilePic] = useState(undefined);
  const [selectedprofilepicfile, setSelectedProfilePicFile] =
    useState(undefined);
  const [settingstobechanged, setSettingsToBeChanged] = useState({});
  const [loading, setLoading] = useState(false);
  const [showpopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState("");
  const [popupbody, setPopupBody] = useState("");
  const [popup, setPopup] = useState({
    ...defaultPopup
  })

  const navigate = useNavigate();
  const styles = {
    mainframe: {
      flex: 1,
      padding: "12px 24px",
    },
    headercontainer: {
      marginBottom: deviceconfigs.layout.largepadding,
    },
    scrollcontainer: {
      flex: 1,
    },
    menuitemscontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
    menubuttoncontainertopradiusborder: {
      borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
      borderTopRightRadius: deviceconfigs.layout.xlargepadding,
    },
    menubuttoncontainerbottomradiusborder: {
      borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
      borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
    },
    menubuttoncontainerbottomborder: {
      borderBottomColor: colorscheme.v2.bordercolor,
      borderBottomWidth: 1,
    },
    menucontainer: {
      flex: 1,
    },
    subheadercontainer: {
      margin: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xxxlargepadding}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    subheader: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    password: {
      padding: deviceconfigs.layout.xlargepadding,
      borderWidth: 1,
      borderColor: colorscheme.v2.bordercolor,
      borderRadius: deviceconfigs.layout.xlargepadding,
      marginTop: deviceconfigs.layout.xlargepadding,
    },
    currentpasswordcontainer: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.background,
      padding: deviceconfigs.layout.xxlargepadding,
      borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
      borderTopRightRadius: deviceconfigs.layout.xlargepadding,
    },
    newpasswordcontainer: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.background,
      padding: deviceconfigs.layout.xxlargepadding,
    },
    repeatnewpasswordcontainer: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.background,
      padding: deviceconfigs.layout.xxlargepadding,
      borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
      borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
    },
    itemsubheader: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "400",
      color: colorscheme.v2.unselected,
      marginBottom: deviceconfigs.layout.largepadding,
    },
  };
  const currentprofilepic =
    auth.user.personalinfo &&
    (auth.user.personalinfo.profilepic ||
      "https://ih1.redbubble.net/image.2154337780.2319/poster,504x498,f8f8f8-pad,600x600,f8f8f8.jpg");
  const { membership } = auth.user;
  const membershipType = membership
    ? language.membership(membership.type)
    : "N/A";
  const expirationdate =
    membership && toHumanReadableDateTime(membership.expirationdate);

  const uploadProps = {
    beforeUpload: (file) => {
      setSelectedProfilePicFile(file);
      var reader = new FileReader();
      reader.onloadend = () => {
        setSelectedProfilePic(reader.result);
      };
      reader.readAsDataURL(file);

      return false;
    },
    showUploadList: false,
    multiple: false,
  };

  const handleUpload = async () => {
    try {
      await api.updateProfilePic(selectedprofilepicfile);
      await api.getMirror();
      openMessage("Profile Picture Changed Successfully.");
      setSelectedProfilePic(undefined);
      setSelectedProfilePicFile(undefined);
    } catch (e) {
      openMessage("Profile Picture Changed Error.", e.stack);
    }
  };

  useEffect(() => {

  }, []);

  return (
    <PageView style={styles.mainframe}>
      <div style={styles.headercontainer}>
        <PageHeader
          header={language.accountsettings}
          onBackPressed={() => {
            navigate("/settings");
          }}
        />
      </div>
      <div style={styles.scrollcontainer}>
        <div style={styles.menucontainer}>
          <div style={styles.subheadercontainer}>
            <div style={styles.subheader}>{language.changeavatar}</div>
          </div>
          <div style={styles.menuitemscontainer}>
            <div
              style={{
                backgroundColor: colorscheme.v2.background,
                borderTopRightRadius: deviceconfigs.layout.xlargepadding,
                borderTopLeftRadius: deviceconfigs.layout.xlargepadding,
                padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px 0px ${deviceconfigs.layout.xlargepadding}px`,
                margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Upload {...uploadProps}>
                    <img
                      src={selectedprofilepic || currentprofilepic}
                      style={{
                        width: deviceconfigs.button.xxxxxlargesize,
                        height: deviceconfigs.button.xxxxxlargesize,
                        borderRadius: "12px",
                        objectFit: "cover",
                      }}
                      alt="MyPerformers"
                    />
                  </Upload>
                </div>
              </div>
              <div
                onClick={async () => {
                  if (selectedprofilepicfile) {
                    await handleUpload();
                  } else {
                    openMessage("No Profile Pic File Selected");
                  }
                }}
                style={{
                  backgroundColor: colorscheme.v2.primary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "36vw",
                  padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                  margin: `0px ${deviceconfigs.layout.xxlargepadding}px`,
                  borderRadius: deviceconfigs.layout.xlargepadding,
                }}
              >
                {!selectedprofilepic ? (
                  <Upload {...uploadProps}>
                    <div
                      style={{
                        fontSize: deviceconfigs.font.mediumtext,
                        fontWeight: "600",
                        color: colorscheme.v2.primarytext,
                      }}
                    >
                      {language.clicktochange}
                    </div>
                  </Upload>
                ) : (
                  <div
                    style={{
                      fontSize: deviceconfigs.font.mediumtext,
                      fontWeight: "600",
                      color: colorscheme.v2.primarytext,
                    }}
                  >
                    {language.save}
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                backgroundColor: colorscheme.v2.background,
                borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
                borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
                padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                margin: `0px ${deviceconfigs.layout.xlargepadding}`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconedInput
                containerstyle={{
                  padding: deviceconfigs.layout.mediumpadding,
                }}
                lefticon={
                  <AiOutlineUser
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                leftseperator
                onChangeText={(profilename) => {
                  setProfileName(profilename);
                  setProfileNameChanging(true);
                }}
                value={
                  profilenamechanging
                    ? profilename
                    : auth.user.personalinfo.displayname
                }
                righticonstyle={{
                  padding: deviceconfigs.layout.largepadding,
                  borderRadius: deviceconfigs.layout.xlargepadding,
                }}
                righticon={
                  <AiOutlineSave
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                onRightIconPressed={() => {
                  api
                    .updateDisplayName({
                      displayname: profilename,
                    })
                    .then((res) => {
                      const payload = {
                        ...auth,
                        user: res.data.object,
                      };
                      authactions.loginPersist(payload);

                      setProfileNameChanging(false);
                      setLoading(false);
                      setPopup({
                        shown: true,
                        header: undefined,
                        title: language.success.displaynamechangedsuccess.header,
                        body: language.success.displaynamechangedsuccess.body,
                        buttons: [
                          {
                            id: "1",
                            text: language.ok,
                            onClick: () => {
                              setPopup({
                                shown: false
                              });
                              setLoading(false);
                            }
                          }
                        ]
                      });
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                }}
              />
            </div>

            <div
              style={{
                backgroundColor: colorscheme.v2.background,
                borderBottomRightRadius: deviceconfigs.layout.xlargepadding,
                borderBottomLeftRadius: deviceconfigs.layout.xlargepadding,
                padding: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                margin: `0px ${deviceconfigs.layout.xlargepadding}`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconedInput
                containerstyle={{
                  padding: deviceconfigs.layout.largepadding,
                }}
                lefticon={
                  <AiOutlineMail
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                leftseperator
                disabled={true}
                value={auth.user.personalinfo.email}
              />
            </div>
          </div>
          <div style={styles.subheadercontainer}>
            <div style={styles.subheader}>{language.changepassword}</div>
          </div>
          <div style={styles.menuitemscontainer}>
            <div style={styles.currentpasswordcontainer}>
              <div style={styles.itemsubheader}>{language.currentpassword}</div>
              <IconedInput
                containerstyle={{
                  padding: deviceconfigs.layout.xlargepadding,
                }}
                lefticon={
                  <AiOutlineKey
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                leftseperator
                placeholder={language.password + "..."}
                onChangeText={(oldpassword) => {
                  setOldpassword(oldpassword);
                }}
                value={oldpassword}
                secure={!showopassword}
                textContentType={"password"}
                righticon={
                  <AiOutlineEye
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                onRightIconPressed={() => {
                  setShowOpassword(!showopassword);
                }}
              />
            </div>
            <div style={styles.newpasswordcontainer}>
              <div style={styles.itemsubheader}>{language.newpassword}</div>
              <IconedInput
                containerstyle={{
                  padding: deviceconfigs.layout.xlargepadding,
                }}
                lefticon={
                  <AiOutlineKey
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                leftseperator
                placeholder={language.password + "..."}
                onChangeText={(newpassword) => {
                  setNewpassword(newpassword);
                }}
                value={newpassword}
                secure={!shownpassword}
                textContentType={"password"}
                righticon={
                  <AiOutlineKey
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                onRightIconPressed={() => {
                  setShowNPassword(!shownpassword);
                }}
              />
            </div>
            <div style={styles.repeatnewpasswordcontainer}>
              <div style={styles.itemsubheader}>
                {language.repeatnewpassword}
              </div>
              <IconedInput
                containerstyle={{
                  padding: deviceconfigs.layout.xlargepadding,
                }}
                lefticon={
                  <AiOutlineKey
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                leftseperator
                placeholder={language.password + "..."}
                onChangeText={(rnewpassword) => {
                  setRNewPassword(rnewpassword);
                }}
                value={rnewpassword}
                secure={!showrpassword}
                textContentType={"password"}
                righticon={
                  <AiOutlineEye
                    style={{
                      fontSize: deviceconfigs.font.xxlargetext,
                      color: colorscheme.v2.backgroundtext,
                    }}
                  />
                }
                onRightIconPressed={() => {
                  setShowRPassword(!showrpassword);
                }}
              />
              <ActionButton
                size={"small"}
                text={language.changepassword}
                buttonstyle={{
                  width: "44vw",
                  marginTop: deviceconfigs.layout.xxlargepadding,
                }}
                onClick={async () => {
                  const isSame = newpassword === rnewpassword;
                  if (isSame) {
                    setLoading(true);
                    api
                      .updatePassword(oldpassword, newpassword)
                      .then((res) => {
                        setNewpassword(undefined);
                        setOldpassword(undefined);
                        setRNewPassword(undefined);
                        setLoading(false);
                        setPopup({
                          shown: true,
                          header: undefined,
                          title: language.success.passwordchange.header,
                          body: language.success.passwordchange.body,
                          buttons: [
                            {
                              id: "1",
                              text: language.ok,
                              onClick: () => {
                                setPopup({
                                  shown: false,
                                })
                              }
                            }
                          ]
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                        setPopup({
                          shown: true,
                          header: undefined,
                          title: language.error.passwordchange.header,
                          body: language.error.passwordchange.body,
                          buttons: [
                            {
                              id: "1",
                              text: language.ok,
                              onClick: () => {
                                setPopup({
                                  shown: false,
                                })
                              }
                            }
                          ]
                        });
                      });
                  } else {
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingModal loading={loading} />
      <AlertModal
        open={popup.shown}
        title={popup.title}
        header={popup.header}
        body={popup.body}
        onBackdropClick={() => {
          setPopup({
            shown: false
          })
          setLoading(false);
        }}
        buttons={popup.buttons}
      />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    authactions: bindActionCreators(authActions, dispatch),
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsScreen);
