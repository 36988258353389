import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageHeader from "../components/PageHeader";

import TradingViewWidget from "react-tradingview-widget";

import * as apiCalls from "../redux/actions/apiCalls";

const MarketCapScreen = ({
  deviceconfigs,
  colorscheme,
  language,
  globalmetrics,
}) => {
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      display: "flex",
      flexDirection: "column",
    },
    headercontainer: {
      marginTop: deviceconfigs.layout.xlargepadding,
      marginBottom: deviceconfigs.layout.largepadding,
    },
    coinheadercontainer: {
      marginLeft: deviceconfigs.layout.xxxlargepadding,
    },
    coinheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "500",
      color: colorscheme.v2.primaryalternative,
    },
    subheadercontainer: {
      display: "flex",
      padding: `${12}px ${deviceconfigs.layout.xxxxlargepadding}px`,
      flexDirection: "row",
      alignItems: "center",
    },
    subheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    },
    flatlistcontainer: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      paddingTop: "112px",
    },
    chartcontainer: {
      backgroundColor: colorscheme.v2.background,
    },
  };
  return (
    <div>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          top: "0px",
          zIndex: 100,
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.marketwatch}
            onBackPressed={() => {
              window.history.back();
            }}
            headerstyle={{
              fontSize: deviceconfigs.font.xlargetext,
              fontWeight: "700",
            }}
          />
        </div>
      </div>
      <div
        style={{ height: "64px", backgroundColor: colorscheme.v2.background }}
      ></div>
      <div>
        <div
          style={{
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
          }}
        >
          <div
            style={{
              marginLeft: deviceconfigs.layout.xlargepadding,
              color: colorscheme.v2.backgroundtext,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Cryptocurrency Market Cap.
          </div>
        </div>
        <div style={styles.chartcontainer}>
          <TradingViewWidget
            symbol={"TOTAL"}
            width={"92vw"}
            height={"300px"}
            theme={colorscheme.v2.name}
            allow_symbol_change={false}
            hide_top_toolbar={true}
          />
        </div>
        <div
          style={{
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
          }}
        >
          <div
            style={{
              marginLeft: deviceconfigs.layout.xlargepadding,
              color: colorscheme.v2.backgroundtext,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            BTC Dominance
          </div>
        </div>
        <div style={styles.chartcontainer}>
          <TradingViewWidget
            symbol={"BTC.D"}
            width={"92vw"}
            height={"300px"}
            theme={colorscheme.v2.name}
            allow_symbol_change={false}
            hide_top_toolbar={true}
          />
        </div>
        <div
          style={{
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
          }}
        >
          <div
            style={{
              marginLeft: deviceconfigs.layout.xlargepadding,
              color: colorscheme.v2.backgroundtext,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            ETH Dominance
          </div>
        </div>
        <div style={styles.chartcontainer}>
          <TradingViewWidget
            symbol={"ETH.D"}
            width={"92vw"}
            height={"300px"}
            theme={colorscheme.v2.name}
            allow_symbol_change={false}
            hide_top_toolbar={true}
          />
        </div>
        <div
          style={{ height: "84px", backgroundColor: colorscheme.v2.background }}
        ></div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    balancedisplay: state.balancedisplay,
    myexchanges: state.myexchanges,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    unreads: state.unreads,
    cmcidmap: state.cmcidmap,
    installation: state.installation,
    globalmetrics: state.globalmetrics,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketCapScreen);
