import React, { useState } from "react";
import { connect } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import PageView from "../components/Page";
import AddExchangeButton from "../components/AddExchangeButton";
import AddExchangeStepCounter from "../components/AddExchangeStepCounter";
import IconicBadge from "../components/IconicBadge";

import { HiChevronLeft, HiOutlineQuestionMarkCircle } from "react-icons/hi";

const AddExchangeAccountSelectMethodScreen = ({
  deviceconfigs,
  auth,
  colorscheme,
  language,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const selectedexchange = state.selectedexchange;
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    topsummarycardcontainer: {
      backgroundColor: colorscheme.v2.primary,
      borderBottomLeftRadius: "12vw",
      borderBottomRightRadius: "12vw",
      padding: `0px ${deviceconfigs.layout.xlargepadding}px 32vw ${deviceconfigs.layout.xlargepadding}px`,
    },
    titleandquestioncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titlecontainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    backbuttoncontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.primaryalternative,
      borderRadius: deviceconfigs.layout.xlargepadding,
    },
    titletextcontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    profilename: {
      color: colorscheme.v2.primarytext,
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "600",
    },
    questioncontainer: {
      marginRight: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.primaryalternative,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: deviceconfigs.layout.xlargepadding,
    },
    stepscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      marginTop: deviceconfigs.layout.xxxxlargepadding,
    },
    stepstextcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginVertical: deviceconfigs.layout.xlargepadding,
    },
    stepstext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "600",
      color: colorscheme.v2.lighttext,
    },
    majorstepoff: {
      width: deviceconfigs.layout.xlargepadding,
      height: deviceconfigs.layout.xlargepadding,
      backgroundColor: colorscheme.v2.unselected,
      borderRadius: 999,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    majorstepon: {
      width: deviceconfigs.layout.xxlargepadding,
      height: deviceconfigs.layout.xxlargepadding,
      backgroundColor: colorscheme.v2.selected,
      borderRadius: 999,
      marginHorizontal: deviceconfigs.layout.xlargepadding,
    },
    minorstep: {
      marginHorizontal: deviceconfigs.layout.xlargepadding,
      width: deviceconfigs.layout.largepadding,
      height: deviceconfigs.layout.largepadding,
      backgroundColor: colorscheme.v2.unselected,
      borderRadius: 999,
    },
    selectorcontainer: {
      borderRadius: deviceconfigs.layout.xxxxlargepadding,
      width: "84vw",
      height: "62vh",
      position: "absolute",
      top: "28vh",
      left: "8vw",
      backgroundColor: colorscheme.v2.background,
    },
    selectexchangetextcontainer: {
      padding: deviceconfigs.layout.xxlargepadding,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    selectexchangetext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "500",
      color: colorscheme.v2.heavytext,
    },
    selectedexchangeiconcontainer: {
      display: "flex",
      justifyContent: "center",
    },
  };
  const { availablemethods } = selectedexchange;
  const { qrcode, manual, noaccount } = availablemethods;
  return (
    <PageView style={styles.mainframe}>
      <div style={styles.topsummarycardcontainer}>
        <div style={styles.titleandquestioncontainer}>
          <div style={styles.titlecontainer}>
            <div
              style={styles.backbuttoncontainer}
              onClick={() => {
                window.history.back();
              }}
            >
              <HiChevronLeft
                style={{
                  fontSize: "28px",
                  color: colorscheme.v2.primarytext,
                }}
              />
            </div>
            <div style={styles.titletextcontainer}>
              <div style={styles.profilename}>
                {language.selectintegrationmethod}
              </div>
            </div>
          </div>
        </div>
        <div style={{}}>
          <AddExchangeStepCounter step={2} />
        </div>
      </div>
      <div style={styles.selectorcontainer}>
        <div style={styles.selectexchangetextcontainer}>
          <div style={styles.selectexchangetext}>
            {language.selectintegrationmethod}
          </div>
        </div>
        <div style={styles.selectedexchangeiconcontainer}>
          <IconicBadge {...selectedexchange} />
        </div>
        {manual && (
          <AddExchangeButton
            text={language.manualkeycopy}
            onClick={() => {
              navigate("/add-exchange-manual-key", {
                state: { selectedexchange },
              });
            }}
          />
        )}
        {noaccount && (
          <AddExchangeButton
            text={language.noaccount}
            onClick={() => {
              navigate("/add-exchange-no-account", {
                state: { selectedexchange },
              });
            }}
          />
        )}
      </div>
    </PageView>
  );
};

AddExchangeAccountSelectMethodScreen.propTypes = {};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    language: state.language,
    loading: state.loading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExchangeAccountSelectMethodScreen);
