import { BINANCEPAIRPRICES } from "../../constants/ReduxStates";
import * as FirebaseService from "../../services/firebase";



export function binancePairPricesLoadSuccess(payload) {
  return {
    type: BINANCEPAIRPRICES.BINANCEPAIRPRICES_LOAD_SUCCESS,
    payload,
  };
}

export function binancePairPricesLoadFailed() {
  return {
    type: BINANCEPAIRPRICES.BINANCEPAIRPRICES_LOAD_FAILED,
  };
}

export function initiateBinancePairPrices() {
  return function (dispatch) {
    FirebaseService.getBinancePairPricesOn((snapshot) => {
      const prices = snapshot.val();
      dispatch(binancePairPricesLoadSuccess(prices));
    });
  };
}
