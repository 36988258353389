import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const LeaveMyPerformersHeaderComponent = ({ language, colorscheme, source }) => {
    const styles = {
        text: {
            fontSize: "13px",
            fontWeight: "300",
            color: colorscheme.v2.backgroundtext,
            flex: 1,
        }
    };
    return (
        <div style={{
            backgroundColor: colorscheme.v2.background,
            boxShadow: "#333 0px 0px 8px 0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "12px",
            margin: "0px 0px 8px 0px",
        }}>
            <div style={{

            }}>
                <img src={source}
                    style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "contain"
                    }}
                />
            </div>
            <div style={styles.text}>
                {language.youareabouttoleave}
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaveMyPerformersHeaderComponent);
