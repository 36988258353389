import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";

import { Switch, notification } from "antd";

import * as apiCalls from "../redux/actions/apiCalls";
import { useEffect, useState } from "react";
import Checkbox from "../components/Checkbox";
import IconedInput from "../components/IconedInput";
import ActionButton from "../components/ActionButton";
import LoadingModal from "../components/LoadingModal";

import { AiOutlineWarning } from "react-icons/ai";
import AlertModal from "components/AlertModal";

const defaultSmartFlow = {
  quotecoin: "USDT",
  type: "BINANCE",
  status: "DISABLED",
  value: "",
  valuetype: "",
};

const openNotification = ({
  title = "Notification",
  description,
  duration,
  style,
}) => {
  const args = {
    message: title,
    description: description,
    duration: duration || 5,
    style: style || {},
  };
  notification.open(args);
};

const defaultPopup = {
  shown: false,
  header: undefined,
  title: undefined,
  body: undefined,
  footer: undefined,
  buttons: []
}

const SmartFlowSettings = ({
  deviceconfigs,
  colorscheme,
  language,
  auth,
  api,
  smartflows,
  myexchanges,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState({
    ...defaultPopup
  });
  const smartFlowPayload = smartflows?.payload;
  const mySmartFlow =
    smartFlowPayload && smartFlowPayload.length > 0 ? smartFlowPayload[0] : {};
  const defSmartFlow = {
    ...defaultSmartFlow,
    ...mySmartFlow,
  };
  const [smartflowtobeadded, setSmartFlowToBeAdded] = useState({
    ...defSmartFlow,
  });
  const [amountincorrect, setAmountIncorrect] = useState(false);
  const [shareincorrect, setShareIncorrect] = useState(false);
  const [exchangeack, setExchangeAck] = useState(
    smartflowtobeadded.status === "ENABLED"
  );
  const myExchangesPayload = myexchanges?.payload;
  const myExchangesPayloadData = myExchangesPayload?.data;
  const mBinanceExchangeArray = (myExchangesPayloadData || []).filter((a) => {
    return a.type === "BINANCE";
  });
  const mBinanceExchange =
    mBinanceExchangeArray?.length === 1 ? mBinanceExchangeArray[0] : undefined;

  const usdtBalanceArray = mBinanceExchange?.balances?.filter((a) => {
    return a.asset === "USDT";
  });
  const usdtBalance =
    usdtBalanceArray?.length > 0
      ? usdtBalanceArray.reduce((item, arr) => {
        return (item += parseFloat(arr.free));
      }, 0)
      : 0;
  const minimumShare = (60.0 / usdtBalance).toFixed(6);
  useEffect(() => {
    if (smartflowtobeadded.status === "ENABLED" && exchangeack) {
      if (smartflowtobeadded.valuetype === "AMOUNT") {
        if (smartflowtobeadded.value !== "") {
          try {
            let val = parseFloat(smartflowtobeadded.value);
            if (val < 60 || val > usdtBalance * 0.95) {
              setAmountIncorrect(true);
            } else {
              setAmountIncorrect(false);
            }
            return;
          } catch (e) {
            setAmountIncorrect(false);
          }
        }
      } else {
        if (smartflowtobeadded.value !== "") {
          try {
            let val = parseFloat(smartflowtobeadded.value);
            if (val / 100.0 < minimumShare) {
              setShareIncorrect(true);
            } else if (val > 95.0) {
              setShareIncorrect(true);
            }
            else {
              setShareIncorrect(false);
            }
            return;
          } catch (e) {
            setShareIncorrect(false);
          }
        }
      }
    }
    setAmountIncorrect(false);
    setShareIncorrect(false);
  }, [smartflowtobeadded, exchangeack, usdtBalance]);

  const [loading, setLoading] = useState(false);
  const styles = {
    headercontainer: {
      marginBottom: deviceconfigs.layout.largepadding,
    },
    itemscontainer: {
      marginLeft: "24px",
      paddingTop: "64px",
    },
    itemcontainer: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "row",
    },
    leftitem: {
      flex: 1,
      fontSize: "15px",
      fontWeight: "400",
      color: colorscheme.v2.backgroundtext,
    },
    rightitem: {
      width: "42vw",
    },
    formcontainer: {
      flex: 4,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "8px",
      marginRight: "24px",
    },
  };

  const canSave =
    (smartflowtobeadded.status === "ENABLED" &&
      exchangeack &&
      smartflowtobeadded.value &&
      smartflowtobeadded.valuetype &&
      smartflowtobeadded.value !== "" &&
      smartflowtobeadded.value !== 0) ||
    smartflowtobeadded.status === "DISABLED";
  const canChange = smartflowtobeadded.status === "DISABLED" || !exchangeack;
  const didChange = !(
    defSmartFlow.status === smartflowtobeadded.status &&
    defSmartFlow.value === smartflowtobeadded.value &&
    defSmartFlow.valuetype === smartflowtobeadded.valuetype
  );
  return (
    <div>
      <div
        style={{
          position: "fixed",
          zIndex: 100,
          width: "100vw",
          top: -56,
          left: 0,
          right: 0,
          backgroundColor: colorscheme.v2.background,
          paddingTop: "56px",
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            header={language.smartflow}
            onBackPressed={() => {
              window.history.back();
            }}
            headerstyle={{
              fontSize: deviceconfigs.font.xxlargetext,
              fontWeight: "600",
              color: colorscheme.v2.backgroundtext,
              padding: `${12}px 0px`,
            }}
          />
        </div>
      </div>
      <div style={styles.itemscontainer}>
        <div style={styles.itemcontainer}>
          <div style={styles.leftitem}>{language.enablesmartflow}</div>
          <div style={styles.rightitem}>
            <Switch
              checked={smartflowtobeadded.status === "ENABLED"}
              onChange={(checked) => {
                if (checked) {
                  const myExchangePayload = myexchanges?.payload;
                  const data = myExchangePayload?.data;
                  const bExchange = data.filter((a) => {
                    return a.type === "BINANCE";
                  });
                  if (bExchange === undefined || bExchange.length === 0) {
                    openNotification({
                      title: language.warning.nobinanceforautomatic.header,
                      description: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <AiOutlineWarning
                              style={{
                                color: colorscheme.v2.error,
                                fontSize: deviceconfigs.icon.largeicon,
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            {language.warning.nobinanceforautomatic.body}
                          </div>
                        </div>
                      ),
                    });
                    return;
                  }
                }
                if (checked) {
                  setSmartFlowToBeAdded({
                    ...smartflowtobeadded,
                    status: "ENABLED",
                  });
                } else {
                  setPopup({
                    shown: true,
                    title: language.disablingautomaticwarning.title,
                    body: <div>
                      <div style={{ fontSize: "15px", fontWeight: "500", color: colorscheme.v2.backgroundtext }}>{language.disablingautomaticwarning.body.line1}</div>
                      <div style={{ fontSize: "13px", fontWeight: "300", color: colorscheme.v2.backgroundtext }}>{language.disablingautomaticwarning.body.line2}</div>
                    </div>,
                    buttons: [
                      {
                        id: "1",
                        text: language.cancel,
                        onClick: () => {
                          setSmartFlowToBeAdded({
                            ...smartflowtobeadded,
                            status: "ENABLED",
                          });
                          setPopup({
                            shown: false
                          })
                        },
                      },
                      {
                        id: "2",
                        text: language.proceed,
                        onClick: () => {
                          setSmartFlowToBeAdded({
                            ...smartflowtobeadded,
                            status: "DISABLED",
                          });
                          setExchangeAck(false);
                          setPopup({
                            shown: false
                          })
                        }
                      }
                    ]
                  })

                }
              }}
            />
          </div>
        </div>
        <div style={styles.itemcontainer}>
          <div style={styles.leftitem}>{language.exchange + " : Binance"}</div>
          <div style={styles.rightitem}>
            <Switch
              checked={exchangeack}
              onChange={(checked) => {
                if (smartflowtobeadded.status === "ENABLED") {
                  setExchangeAck(checked);
                }
              }}
            />
          </div>
        </div>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: colorscheme.v2.backgroundtext,
            marginRight: "24px",
            marginTop: "12px",
            padding: "12px 2px",
          }}
        >
          {language.automaticinlinedesc}
        </div>
        <div style={{ marginTop: "24px" }}>
          <div
            style={{
              flex: 1,
              fontWeight: "700",
              color: colorscheme.v2.backgroundtext,
            }}
          >
            {language.amountofusdt}
          </div>
          <div style={styles.formcontainer}>
            <Checkbox
              checked={smartflowtobeadded.valuetype === "AMOUNT"}
              disabled={canChange}
              maincontainer={{
                marginRight: "12px",
              }}
              onChange={() => {
                setSmartFlowToBeAdded({
                  ...smartflowtobeadded,
                  valuetype: "AMOUNT",
                  value: "",
                });
              }}
            />
            <IconedInput
              placeholder={language.minimumamount60USDT}
              error={amountincorrect}
              disabled={
                smartflowtobeadded.status === "DISABLED" || !exchangeack
              }
              type={"number"}
              value={
                smartflowtobeadded.valuetype === "AMOUNT"
                  ? smartflowtobeadded.value
                  : ""
              }
              onChangeText={(text) => {
                setSmartFlowToBeAdded({
                  ...smartflowtobeadded,
                  value: text,
                });
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <div
            style={{
              flex: 1,
              fontWeight: "700",
              color: colorscheme.v2.backgroundtext,
            }}
          >
            {language.shareoffunds}
          </div>
          <div style={styles.formcontainer}>
            <Checkbox
              checked={smartflowtobeadded.valuetype === "PERCENTAGE"}
              disabled={canChange}
              onChange={() => {
                setSmartFlowToBeAdded({
                  ...smartflowtobeadded,
                  valuetype: "PERCENTAGE",
                  value: "",
                });
              }}
              maincontainer={{
                marginRight: "12px",
              }}
            />
            <IconedInput
              placeholder={language.minimumshare(((minimumShare * 100.0) + 1).toFixed(0)) + " / " + language.maximumshare(95.0)}
              error={shareincorrect}
              disabled={
                smartflowtobeadded.status === "DISABLED" || !exchangeack
              }
              type={"number"}
              value={
                smartflowtobeadded.valuetype === "PERCENTAGE"
                  ? smartflowtobeadded.value
                  : ""
              }

              onChangeText={(text) => {
                if (text !== undefined && text !== "") {
                  try {
                    let val = parseFloat(text);

                  } catch (e) {

                  }
                }
                setSmartFlowToBeAdded({
                  ...smartflowtobeadded,
                  value: text,
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: deviceconfigs.layout.largepadding,
          }}
        >
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: colorscheme.v2.backgroundtext,
            }}
          >
            Binance : {usdtBalance.toFixed(2)} USDT {language.available}
          </div>
        </div>
        <div
          style={{
            marginTop: "24px",
          }}
        >
          <ActionButton
            text={language.save}
            disabled={
              !canSave || !didChange || amountincorrect || shareincorrect
            }
            onClick={async () => {
              setLoading(true);
              console.log("SmartFlow", smartflowtobeadded);
              if (smartflowtobeadded.status === "DISABLED") {
                smartflowtobeadded.value = null;
                smartflowtobeadded.valuetype = null;
              }
              let result = await api.createSmartFlow(smartflowtobeadded);
              console.log("SmartFlow", result.data);
              if (result.data) {
                console.log({ auth });
                if (!auth.user?.progress?.firstautomaticenabledts) {
                  await api.updateFirsAutomaticEnabled();
                }
              }
              setLoading(false);
              navigate("/dashboard");
            }}
            buttonstyle={{
              width: "64vw",
              margin: "auto",
            }}
          />
        </div>
      </div>
      <AlertModal
        open={popup.shown}
        header={popup.header}
        title={popup.title}
        body={popup.body}
        footer={popup.footer}
        divider={popup.divider}
        onBackdropClick={() => {
          setPopup({
            ...popup,
            shown: false,
          })
        }}
        buttons={popup.buttons}
      />
      <LoadingModal loading={loading} />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    balancedisplay: state.balancedisplay,
    myexchanges: state.myexchanges,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    unreads: state.unreads,
    cmcidmap: state.cmcidmap,
    installation: state.installation,
    globalmetrics: state.globalmetrics,
    smartflows: state.smartflows,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartFlowSettings);
