import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiShieldQuarter } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import images from "../../utils/images";

import { notification } from "antd";

import IconedInput from "../../components/IconedInput";
import PageView from "../../components/Page";
import SizeableButton from "../../components/SizeableButton";
import LoadingModal from "../../components/LoadingModal";

import { AiOutlineWarning } from "react-icons/ai";

import { releaseCandidateVersion } from "../../constants/ReduxStates";

import * as apiCalls from "../../redux/actions/apiCalls";

const openNotification = ({
  title = "Notification",
  description,
  duration,
  style,
}) => {
  const args = {
    message: title,
    description: description,
    duration: duration || 5,
    style: style || {},
  };
  notification.open(args);
};

const SignInScreen = ({ deviceconfigs, colorscheme, api }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  const _login = async () => {
    setLoading(true);

    try {
      let result = await api.login({ login: email, password });
      navigate("/email-verification", {
        state: { userid: result.data.user.id },
      });
    } catch (e) {
      console.error(e);
      if (e.response.data.responsecode === "403#1004") {
        navigate("/registration-verification", {
          state: { userid: e.response.data.object },
        });
        return;
      }
      openNotification({
        title: "Error",
        description: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <AiOutlineWarning
                style={{
                  color: colorscheme.v2.error,
                  fontSize: deviceconfigs.icon.largeicon,
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "12px",
              }}
            >
              Credentials Incorrect
            </div>
          </div>
        ),
      });
    } finally {
      setLoading(false);
    }

    // setTimeout(() => {
    //     const { email, password } = this.state;
    //     api.login({
    //         login: email,
    //         password,
    //     })
    //         .then((res) => {
    //             this.setState({
    //                 loading: false,
    //             })
    //         })
    //         .catch((err) => {
    //             if (err.response && err.response.data && err.response.data.responsecode == "403#1004") {
    //                 // Clearly Registration not verified.

    //                 return;
    //             }
    //             this.setState({
    //                 loading: false,
    //             })
    //             this._clearPassword();
    //         })
    // }, 800);
  };
  const styles = {
    container: {},
    versionContainer: {
      float: "right",
    },
    version: {
      marginRight: "12px",
      marginTop: "12px",
      fontSize: "11px",
      color: colorscheme.v2.backgroundtext,
    },
    imagecontainer: {
      paddingLeft: "32px",
      paddingTop: "32px",
    },
    welcometextcontainer: {
      paddingLeft: "32px",
    },
    welcometextlinecontainer: {},
    headertext: {
      fontSize: "32px",
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    subheadertext: {
      fontSize: "14px",
      fontWeight: "700",
      color: colorscheme.v2.heavytext,
    },
    bluebar: {
      width: "48px",
      height: "8px",
      backgroundColor: colorscheme.v2.primary,
      marginTop: "12px",
      borderRadius: "12px",
    },
    inputscontainer: {
      padding: "18px 24px",
    },
    inputcontainer: {
      margin: "24px 0px",
    },
    forgotpasswordtext: {
      fontSize: "13px",
      fontWeight: "600",
      color: "#929292",
    },
    signinbuttoncontainer: {
      marginTop: "24px",
    },
    donthaveaccountcontainer: {
      margin: "24px auto",
    },
    donthaveaccount: {
      margin: "0px 4px",
      color: colorscheme.v2.heavytext,
      fontSize: "13px",
      fontWeight: "500",
      display: "flex",
      justifyContent: "center",
    },
    registerlink: {
      margin: "0px 4px",
      color: colorscheme.v2.primary,
      fontSize: "13px",
      fontWeight: "700",
    },
  };
  return (
    <PageView>
      <div style={styles.container}>
        <div style={styles.versionContainer}>
          <div style={styles.version}>{releaseCandidateVersion}</div>
        </div>
        <div style={styles.imagecontainer}>
          <img
            width={96}
            height={96}
            src={
              colorscheme.v2.name === "dark"
                ? images.images.darklogo
                : images.images.lightlogo
            }
            alt={"MyPerformers"}
          />
        </div>
        <div style={styles.welcometextcontainer}>
          <div style={styles.welcometextlinecontainer}>
            <div style={styles.headertext}>Sign In to</div>
          </div>
          <div style={styles.welcometextlinecontainer}>
            <div style={styles.subheadertext}>My Performers</div>
          </div>
          <div style={styles.welcometextlinecontainer}></div>
          <div style={styles.bluebar}></div>
        </div>
        <div style={styles.inputscontainer}>
          <div style={styles.inputcontainer}>
            <IconedInput
              lefticon={
                <MdOutlineAlternateEmail
                  style={{
                    fontSize: "24px",
                    color: colorscheme.v2.primary,
                  }}
                />
              }
              leftseperator
              placeholder={"Email"}
              value={email}
              onChangeText={(text) => {
                setEmail(text);
              }}
            />
          </div>
          <div style={styles.inputcontainer}>
            <IconedInput
              lefticon={
                <BiShieldQuarter
                  style={{
                    fontSize: "24px",
                    color: colorscheme.v2.primary,
                  }}
                />
              }
              leftseperator
              placeholder={"Password"}
              rightseperator
              righticon={
                <AiOutlineEye
                  style={{
                    fontSize: "24px",
                    color: colorscheme.v2.primary,
                  }}
                />
              }
              value={password}
              secure={!showpassword}
              onRightIconPressed={() => {
                setShowPassword(!showpassword);
              }}
              onChangeText={(text) => {
                let result = text && text.replace(/ /g, "");
                setPassword(result);
              }}
            />
          </div>
          <div style={{ float: "right" }}>
            <div
              style={styles.forgotpasswordtext}
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password?
            </div>
          </div>
        </div>
        <div style={styles.signinbuttoncontainer}>
          <SizeableButton
            text={"Sign In"}
            onClick={() => {
              _login();
            }}
          />
        </div>
        <div style={styles.donthaveaccountcontainer}>
          <div style={styles.donthaveaccount}>
            Don't have an account yet?
            <Link to="/sign-up" style={styles.registerlink}>
              Register
            </Link>
          </div>
        </div>
      </div>
      <LoadingModal loading={loading} />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    huobisymbols: state.huobisymbols,
    filters: state.filters,
    myexchanges: state.myexchanges,
    membership: state.membership,
    cmcidmap: state.cmcidmap,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);
