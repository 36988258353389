import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import PageHeader from "../components/PageHeader";
import ActionButton from "../components/ActionButton";
import InfoItem from "../components/InfoItem";

import TradingViewWidget from "react-tradingview-widget";

import {
  toHumanReadableDate,
  toHumanReadableDateTime,
  toHumanReadableTime,
} from "../utils/timeservice";

import * as apiCalls from "../redux/actions/apiCalls";
import * as binanceTickerActions from "../redux/actions/binanceTIckerWebSocketActions";

import { IoSwapVertical } from "react-icons/io5";

import Modal from "@mui/material/Modal";
import IconicBadge from "../components/IconicBadge";
import SmartFlowIcon from "../components/SmartFlowIcon";

const POPULAREXCHANGES = [
  {
    id: "1",
    text: "Binance",
    type: "BINANCE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
    selected: false,
  },
  {
    id: "2",
    text: "Coinbase 2.0",
    type: "COINBASE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/89.png",
    selected: false,
  },
  {
    id: "3",
    text: "Huobi Global",
    type: "HUOBI",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
    selected: false,
  },
  {
    id: "4",
    text: "KuCoin",
    type: "KUCOIN",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png",
    selected: false,
  },
  {
    id: "5",
    text: "Bittrex",
    type: "BITTREX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/22.png",
    selected: false,
  },
];

const OrderDetailsScreen = ({
  deviceconfigs,
  language,
  colorscheme,
  api,
  binancetickeractions,
  binancetickers,
  binancepairprices,
}) => {
  const location = useLocation();

  let huobitimer = undefined;

  const [confirmationvisible, setConfirmationVisible] = useState(false);
  const [parentsignal, setParentSignal] = useState(undefined);

  const mystate = location.state;
  const myorder = mystate.myorder;
  const filters = mystate.filters;

  const {
    basecoin,
    quotecoin,
    closedprice,
    quantity,
    status,
    targets,
    timestamps,
    stoploss,
    stoplosstype,
    symbol,
    exchange,
    smartflow,
    purchaseprice,
  } = myorder;
  const symbolData = binancetickers[symbol];
  const currentprice = symbolData
    ? symbolData.currentprice
    : binancepairprices[symbol];
  const percent = symbolData?.percent;
  const margin = symbolData?.margin;

  const calculateRealizedProfit = () => {
    const investment = parseFloat(purchaseprice) * parseFloat(quantity);
    const realizedTargets = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status === "REALIZED"
          ? (parseFloat(curr.share) / 100.0) *
          parseFloat(quantity) *
          parseFloat(curr.value)
          : 0);
    }, 0.0);
    let unrealizedQuantity = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status !== "REALIZED"
          ? (parseFloat(curr.share) / 100.0) * quantity
          : 0);
    }, 0.0);
    if (targets == undefined || targets.length == 0) {
      unrealizedQuantity = parseFloat(quantity);
    }

    const unrealizedSell = closedprice
      ? parseFloat(closedprice) * unrealizedQuantity
      : 0;
    let result =
      ((realizedTargets + unrealizedSell - investment) / investment) * 100.0;
    return result.toFixed(5);
  };

  const calculateCurrentProfit = () => {
    const investment = parseFloat(purchaseprice) * parseFloat(quantity);
    const realizedTargets = targets.reduce((prev, curr) => {
      return (prev +=
        curr.status === "REALIZED"
          ? (parseFloat(curr.share) / 100.0) *
          parseFloat(quantity) *
          parseFloat(curr.value)
          : 0);
    }, 0.0);
    let unrealizedQuantity = targets.reduce((prev, curr) => {
      prev +=
        curr.status !== "REALIZED"
          ? (parseFloat(curr.share) / 100.0) * quantity
          : 0;
      return prev;
    }, 0.0);
    if (targets == undefined || targets.length == 0) {
      unrealizedQuantity = parseFloat(quantity);
    }

    const unrealizedSell = closedprice
      ? parseFloat(closedprice) * unrealizedQuantity
      : 0;
    const potential = unrealizedQuantity * currentprice;
    let result =
      ((realizedTargets + unrealizedSell + potential - investment) /
        investment) *
      100.0;
    return result.toFixed(5);
  };

  const getPrecision = (filters) => {
    for (var i = 0; i < filters.length; i++) {
      if (filters[i].filterType === "PRICE_FILTER") {
        let ss = filters[i].tickSize;
        let iindex = ss.indexOf(".");
        let oindex = ss.indexOf("1");
        let diff = oindex - iindex;
        if (diff < 1) {
          diff = 0;
        }
        return diff;
      }
    }
    return 8;
  };

  const priceprecision =
    myorder.exchange === "HUOBI"
      ? myorder.priceprecision
      : getPrecision(filters);
  const valueprecision =
    myorder.exchange === "HUOBI"
      ? myorder.valueprecision
      : getPrecision(filters);
  const amountprecision =
    myorder.exchange === "HUOBI"
      ? myorder.amountprecision
      : getPrecision(filters);

  useEffect(() => {
    if (myorder.exchange === "BINANCE") {
      binancetickeractions.subscribeToSymbol(symbol);
    } else if (myorder.exchange === "HUOBI") {
    }
    fetchSignalDetails(myorder.parentsignalid);
    return () => {
      binancetickeractions.unsubscribeToSymbol(symbol);
    };
  }, []);

  const fetchSignalDetails = async (signalid) => {
    let parentsignal = await api.getCPSignal(signalid);
    setParentSignal(parentsignal.data.object);
  };

  const currentresult = calculateCurrentProfit();
  const realizedProfit = calculateRealizedProfit();
  const orderstatus = orderStatusConverter(
    status,
    timestamps,
    language,
    colorscheme
  );
  const isClosed = status.indexOf("CLOSED") !== -1;
  const isWaiting = status === "WAITING";
  const isComplete = status === "COMPLETED";
  const isClosedWaiting = status === "CLOSEDWAITING";
  const { activedate, closedate, completedate } = myorder.timestamps;
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    headercontainer: {
      padding: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    marketwatchcontainer: {
      padding: deviceconfigs.layout.largepadding,
    },
    currentpriceandpercentcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pointcontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    currentpricecontainer: {
      flex: 1,
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    percentcontainer: {
      width: "24vw",
      padding: deviceconfigs.layout.largepadding,
      backgroundColor:
        margin < 0 ? colorscheme.v2.error : colorscheme.v2.success,
      borderRadius: deviceconfigs.layout.xlargepadding,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    currentprice: {
      fontSize: deviceconfigs.font.xxxlargetext,
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    percent: {
      fontSize: deviceconfigs.font.largetext,
      fontWeight: "700",
      color: colorscheme.v2.background,
    },
    margin: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    pointiconcontainer: {},
    chartanddetailscontainer: {},
    scrollcontainer: {
      flex: 1,
      paddingTop: "150px",
    },
    chartcontainer: {},
    detailscontainer: {
      flex: 1,
    },
    detailssubheadercontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: deviceconfigs.layout.xlargepadding,
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    infocontainer: {
      padding: deviceconfigs.layout.xxlargepadding,
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      borderTopColor: colorscheme.v2.bordercolor,
      borderTopWidth: 1,
    },
    infoleftitem: {
      flex: 6,
    },
    infoseperator: {
      width: 1,
      margin: `0px ${deviceconfigs.layout.xlargepadding}px`,
      backgroundColor: colorscheme.v2.bordercolor,
    },
    inforightitem: {
      flex: 7,
    },
    signalsubheader: {
      fontSize: deviceconfigs.font.xxlargetext,
      fontWeight: "400",
      color: colorscheme.v2.unselected,
    },
    actionbuttonscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    actionbutton: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colorscheme.v2.primarybackground,
      padding: deviceconfigs.layout.xxlargepadding,
      borderRadius: deviceconfigs.layout.xlargepadding,
      margin: `0px ${deviceconfigs.layout.xxlargepadding}`,
      width: "42vw",
    },
    actionbuttontext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "300",
      color: colorscheme.v2.primary,
    },
    currentstatusandresultcontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    coursehistorycontainer: {
      margin: `${deviceconfigs.layout.xxxxlargepadding}px 0px ${deviceconfigs.layout.xxxxlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    currentstatuscontainer: {
      marginBottom: deviceconfigs.layout.largepadding,
    },
    currentresultcontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    currentstatustextcontainer: {
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    currentstatusheadertext: {
      fontSize: deviceconfigs.font.xlargetext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    coursehistoryitem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: `${deviceconfigs.layout.mediumpadding}px 0px`,
    },
    courseitemtext: {
      width: "40vw",
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "500",
      color: colorscheme.v2.backgroundtext,
    },
    currentstatustext: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: orderstatus.color,
    },
    statusdatecontainer: {
      marginLeft: deviceconfigs.layout.xlargepadding,
    },
    statusdate: {
      fontSize: deviceconfigs.font.smalltext,
      fontWeight: "700",
      color: orderstatus.color,
    },
    currentresultheader: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: colorscheme.v2.unselected,
    },
    currentresult: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: currentresult > 0 ? colorscheme.v2.success : colorscheme.v2.error,
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    realizedprofit: {
      fontSize: deviceconfigs.font.mediumtext,
      fontWeight: "700",
      color: realizedProfit > 0 ? colorscheme.v2.success : colorscheme.v2.error,
      margin: `${deviceconfigs.layout.largepadding}px 0px`,
    },
    realizedprofitcontainer: {
      margin: `${deviceconfigs.layout.xlargepadding}px 0px`,
    },
  };
  const myOrderTargets = (targets || []).sort((a, b) => {
    if (parseFloat(a.value) < parseFloat(b.value)) {
      return -1;
    } else if (parseFloat(a.value) > parseFloat(b.value)) {
      return 1;
    }
    return 0;
  });
  const orderExchange = (POPULAREXCHANGES || []).filter((a) => {
    return a.type === exchange;
  })[0];
  const targetHits = (myOrderTargets || []).map((item, index) => {
    if (!item.realizationts) {
      return undefined;
    }
    return (
      <div style={styles.coursehistoryitem}>
        <div style={styles.courseitemtext}>{`Target #${index + 1} Hit`}</div>
        <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
          {toHumanReadableDateTime(item.realizationts)}
        </div>
      </div>
    )
  }).filter(a => a !== undefined);
  return (
    <div style={styles.mainframe}>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          top: "0px",
          zIndex: 100,
          backgroundColor: colorscheme.v2.background,
        }}
      >
        <div style={styles.headercontainer}>
          <PageHeader
            onBackPressed={() => {
              window.history.back();
            }}
            lefticon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                }}
              >
                <div style={{}}>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: colorscheme.v2.backgroundtext,
                    }}
                  >
                    {basecoin + quotecoin}
                  </div>
                </div>
              </div>
            }
            righticon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                  backgroundColor: colorscheme.v2.cardbackground,
                  padding: `${deviceconfigs.layout.largepadding}px ${deviceconfigs.layout.largepadding}px`,
                }}
              >
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <img
                    src={orderExchange.icon}
                    style={{
                      width: "18px",
                      height: "18px",
                      objectFit: "contain",
                    }}
                    alt={"MyPerformers"}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: colorscheme.v2.backgroundtext,
                    }}
                  >
                    {orderExchange.text}
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div style={styles.marketwatchcontainer}>
          <div style={styles.currentpriceandpercentcontainer}>
            <div style={styles.currentpricecontainer}>
              <div style={styles.currentprice}>{"$ " + currentprice}</div>
            </div>
            <div style={styles.percentcontainer}>
              <div style={styles.percent}>{percent + "%"}</div>
            </div>
          </div>
          <div style={styles.pointcontainer}>
            <div style={styles.pointiconcontainer}>
              <IoSwapVertical
                style={{
                  fontSize: deviceconfigs.icon.mediumicon,
                  color: colorscheme.v2.unselected,
                }}
              />
            </div>
            <div style={styles.margin}>{margin}</div>
          </div>
        </div>
      </div>
      <div style={styles.scrollcontainer}>
        <div style={styles.chartcontainer}>
          <TradingViewWidget
            symbol={basecoin + quotecoin}
            width={"92vw"}
            height={"300px"}
            theme={colorscheme.v2.name}
            allow_symbol_change={false}
          />
        </div>
        <div style={styles.detailscontainer}>
          <div style={styles.detailssubheadercontainer}>
            <div style={styles.signalsubheader}>{language.orderdetails}</div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                fontSize: deviceconfigs.font.largetext,
                fontWeight: "500",
                color: colorscheme.v2.unselected,
                marginRight: "12px",
              }}
            >
              {smartflow === "ENABLED" ? <SmartFlowIcon /> : null}
              {smartflow === "DISABLED"
                ? stoplosstype === "AUTOMATIC"
                  ? language.automatic
                  : language.manual
                : null}
            </div>
          </div>
          <div style={styles.infocontainer}>
            <div style={styles.infoleftitem}>
              <InfoItem
                title={language.purchasingprice}
                value={parseFloat(myorder.purchaseprice).toFixed(
                  priceprecision
                )}
              />
              <InfoItem
                title={language.quantity}
                value={parseFloat(quantity)}
              />
              {!isWaiting ? (
                <InfoItem
                  title={language.amount}
                  value={
                    (
                      parseFloat(quantity) * parseFloat(myorder.purchaseprice)
                    ).toFixed(priceprecision) +
                    " " +
                    quotecoin
                  }
                />
              ) : null}
              <InfoItem
                title={language.stoploss}
                value={parseFloat(myorder.stoploss)}
              />
              {myorder.closedprice ? (
                <InfoItem
                  title={language.sellingprice}
                  value={parseFloat(myorder.closedprice)}
                />
              ) : null}
              {(isClosed || isComplete) && !isClosedWaiting && (
                <div style={styles.realizedprofitcontainer}>
                  <div style={styles.currentresultheader}>
                    {language.realizedprofit}
                  </div>
                  <div style={styles.realizedprofit}>{realizedProfit}%</div>
                </div>
              )}
              {!isClosed && !isWaiting && !isComplete && (
                <div style={styles.currentresultcontainer}>
                  <div style={styles.currentresultheader}>
                    {language.currentresult}
                  </div>
                  <div style={styles.currentresult}>{currentresult}%</div>
                </div>
              )}
            </div>
            <div style={styles.infoseperator} />
            <div style={styles.inforightitem}>
              {(myOrderTargets || []).map((target, index) => {
                return (
                  <div key={index}>
                    <InfoItem
                      title={"Target # " + (index + 1)}
                      value={parseFloat(target.value).toFixed(priceprecision)}
                      valuetwo={target.share + "%"}
                      targethit={target.realizationts}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div style={styles.currentstatusandresultcontainer}>
          <div style={styles.currentstatuscontainer}>
            <div>
              <div style={styles.currentstatusheadertext}>
                {language.currentstatus}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingVertical: deviceconfigs.layout.smallpadding,
                borderRadius: deviceconfigs.layout.largepadding,
                marginTop: deviceconfigs.layout.largepadding,
                alignSelf: "flex-start",
              }}
            >
              <div style={styles.currentstatustextcontainer}>
                <div style={styles.currentstatustext}>{orderstatus.text}</div>
              </div>
              <div style={styles.statusdatecontainer}>
                <div style={styles.statusdate}>
                  {toHumanReadableDateTime(orderstatus.ts)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.coursehistorycontainer}>
          <div style={styles.currentstatusheadertext}>Course History</div>
          {parentsignal ? (
            <div style={styles.coursehistoryitem}>
              <div style={styles.courseitemtext}>Signal Created</div>
              <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
                {parentsignal &&
                  toHumanReadableDateTime(parentsignal.creationdate)}
              </div>
            </div>
          ) : null}
          {parentsignal ? (
            <div style={styles.coursehistoryitem}>
              <div style={styles.courseitemtext}>Signal Published</div>
              <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
                {parentsignal &&
                  toHumanReadableDateTime(parentsignal.timestamps.publishdate)}
              </div>
            </div>
          ) : null}
          <div style={styles.coursehistoryitem}>
            <div style={styles.courseitemtext}>Signal Accepted</div>
            <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
              {toHumanReadableDateTime(myorder.creationdate)}
            </div>
          </div>
          {activedate ? (
            <div style={styles.coursehistoryitem}>
              <div style={styles.courseitemtext}>Order Activated</div>
              <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
                {toHumanReadableDateTime(activedate)}
              </div>
            </div>
          ) : null}
          {targetHits}
          {completedate ? (
            <div style={styles.coursehistoryitem}>
              <div style={styles.courseitemtext}>Order Finished</div>
              <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
                {toHumanReadableDateTime(completedate)}
              </div>
            </div>
          ) : null}
          {closedate ? (
            <div style={styles.coursehistoryitem}>
              <div style={styles.courseitemtext}>Order Closed/Cancelled</div>
              <div style={{ flex: 1, color: colorscheme.v2.backgroundtext }}>
                {toHumanReadableDateTime(closedate)}
              </div>
            </div>
          ) : null}
        </div>
        {orderstatus.pressable && (
          <div style={styles.actionbuttonscontainer}>
            <ActionButton
              text={language.closeorder}
              buttonstyle={{
                width: "82vw",
                backgroundColor: colorscheme.v2.errorbackground,
              }}
              textstyle={{
                color: colorscheme.v2.error,
              }}
              onClick={() => {
                setConfirmationVisible(true);
              }}
            />
          </div>
        )}
        <div style={{ height: "12vh" }} />
      </div>
      <Modal
        open={confirmationvisible}
        onClose={() => setConfirmationVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            width: "84vw",
            height: "32vh",
            padding: deviceconfigs.layout.xxlargepadding,
            margin: "auto",
            marginTop: "18vh",
          }}
        >
          <div
            style={{
              flex: 1,
              backgroundColor: colorscheme.v2.primary,
              padding: `${deviceconfigs.layout.xxxlargepadding}px 8px`,
              borderRadius: deviceconfigs.layout.xxlargepadding,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../assets/images/v2/danger.png")}
                style={{
                  width: deviceconfigs.button.xlargesize,
                  height: deviceconfigs.button.xlargesize,
                  objectFit: "contain",
                }}
                alt={"Danger"}
              />
            </div>
            <div style={{}}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: deviceconfigs.layout.xxlargepadding,
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                  (item) => {
                    return (
                      <div
                        key={item}
                        style={{
                          flex: 1,
                          backgroundColor: colorscheme.v2.primarytext,
                          height: deviceconfigs.layout.smallpadding,
                          marginHorizontal: deviceconfigs.layout.mediumpadding,
                        }}
                      ></div>
                    );
                  }
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: deviceconfigs.layout.xlargepadding,
              }}
            >
              <div
                style={{
                  fontSize: deviceconfigs.font.largetext,
                  fontWeight: "500",
                  color: colorscheme.v2.primarybackground,
                }}
              >
                {language.sureclose}
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "24px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginVertical: deviceconfigs.layout.xxlargepadding,
            }}
          >
            <ActionButton
              text={language.cancel}
              buttonstyle={{
                marginHorizontal: deviceconfigs.layout.xxlargepaddding,
                width: "36vw",
                backgroundColor: colorscheme.v2.error,
              }}
              onClick={() => {
                setConfirmationVisible(false);
              }}
            />
            <ActionButton
              text={language.confirm}
              buttonstyle={{
                marginHorizontal: deviceconfigs.layout.xxlargepaddding,
                width: "36vw",
              }}
              onClick={() => {
                const closePromise =
                  exchange === "BINANCE"
                    ? api.closeManually(myorder.id)
                    : api.closeHuobiManually(myorder.id);
                closePromise
                  .then((res) => {
                    setConfirmationVisible(false);
                    window.history.back();
                  })
                  .catch((err) => {
                    setConfirmationVisible(false);
                  });
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

function orderStatusConverter(status, timestamps, language, colorscheme) {
  switch (status) {
    case "WAITING":
      return {
        color: colorscheme.v2.success,
        bgcolor: colorscheme.v2.successbackground,
        text: language.waiting,
        summarytext: language.accepted,
        ts: timestamps.creationdate,
        pressable: true,
        status,
      };
    case "ACTIVE":
      return {
        color: colorscheme.v2.success,
        bgcolor: colorscheme.v2.successbackground,
        text: language.active,
        summarytext: language.accepted,
        ts: timestamps.activedate,
        pressable: true,
        status,
      };
    case "CLOSEDWAITING":
      return {
        color: colorscheme.v2.error,
        bgcolor: colorscheme.v2.errorbackground,
        text: language.closedwaiting,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "CLOSEDACTIVE":
      return {
        color: colorscheme.v2.error,
        bgcolor: colorscheme.v2.errorbackground,
        text: language.closedactive,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "CLOSEDBYSYSTEM":
      return {
        color: colorscheme.v2.error,
        bgcolor: colorscheme.v2.errorbackground,
        text: language.closedbysystem,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "CLOSEDBYSTOPLOSS":
      return {
        color: colorscheme.v2.error,
        bgcolor: colorscheme.v2.errorbackground,
        text: language.closedbystoploss,
        summarytext: language.cancelled,
        ts: timestamps.closedate,
        pressable: false,
        status,
      };
    case "COMPLETED":
      return {
        color: colorscheme.v2.primary,
        bgcolor: colorscheme.v2.primarybackground,
        text: language.completed,
        summarytext: language.finished,
        ts: timestamps.completedate || timestamps.closedate,
        pressable: false,
        status,
      };
    default:
      return;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    api: bindActionCreators(apiCalls, dispatch),
    binancetickeractions: bindActionCreators(binanceTickerActions, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    iconcache: state.iconcache,
    colorscheme: state.colorscheme,
    balances: state.balances,
    xactionssignals: state.xactionssignals,
    language: state.language,
    loading: state.loading,
    binancetickers: state.binancetickers,
    binancepairprices: state.binancepairprices,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsScreen);
