import { connect } from "react-redux";

const AvailableUsdtFundsComponents = ({
  myexchanges,
  colorscheme,
  language,
  balancedisplay,
  personalperformance
}) => {
  const totalbalancehidden = balancedisplay.type === "BALANCEHIDE";
  const myExchangesPayload = myexchanges?.payload;
  const myExchangesPayloadData = myExchangesPayload?.data;
  const mBinanceExchangeArray = (myExchangesPayloadData || []).filter((a) => {
    return a.type === "BINANCE";
  });
  const mBinanceExchange =
    mBinanceExchangeArray?.length === 1 ? mBinanceExchangeArray[0] : undefined;
  const usdtBalanceArray = (mBinanceExchange?.balances || []).filter((a) => {
    return a.asset === "USDT";
  });
  const usdtBalance =
    usdtBalanceArray?.length > 0
      ? usdtBalanceArray.reduce((item, arr) => {
        return (item += parseFloat(arr.free));
      }, 0)
      : 0;
  const styles = {
    left: {

    },
    right: {
      marginLeft: "12px",
      fontSize: "17px",
      fontWeight: "600",
      color: colorscheme.v2.backgroundtext,
    }
  }
  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}>
        <div style={{ widht: "128px", fontSize: "13px", fontWeight: "600", color: colorscheme.v2.backgroundtext, }}>Available USDT : </div>
        <div style={styles.right}>{totalbalancehidden ? "*********" : usdtBalance.toFixed(2)}</div>
      </div>
      <div style={{
        marginTop: "12px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}>
        <div style={{ widht: "128px", fontSize: "13px", fontWeight: "600", color: colorscheme.v2.backgroundtext, }}>Invested USDT : </div>
        <div style={styles.right}>{
          totalbalancehidden ? "*********" : parseFloat(personalperformance.totalinvested).toFixed(2)
        }</div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    myexchanges: state.myexchanges,
    balancedisplay: state.balancedisplay,
    personalperformance: state.personalperformance
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableUsdtFundsComponents);