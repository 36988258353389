import { connect } from "react-redux";
import { useState } from "react";
import { bindActionCreators } from "redux";
import SmartFlowPerformanceItem from "components/SmartFlowPerformanceItem";

const PersonalAutomaticPerformanceItemContainer = ({
    colorscheme,
    deviceconfigs,
    language,
    personalperformance,
}) => {
    const styles = {
        mainContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "12px",
        },
    };
    return (
        <div>
            <div
                style={styles.mainContainer}
            >
                <SmartFlowPerformanceItem
                    title={language.h24}
                    value={personalperformance?.h24?.toFixed(5)}
                />
                <SmartFlowPerformanceItem
                    title={language.d7}
                    value={personalperformance?.d7?.toFixed(5)}
                />

            </div>
            <div
                style={styles.mainContainer}
            >
                <SmartFlowPerformanceItem
                    title={language.d30}
                    value={personalperformance?.d30?.toFixed(5)}
                />
                <SmartFlowPerformanceItem
                    title={language.y1}
                    value={personalperformance?.y1?.toFixed(5)}
                />
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {};
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        deviceconfigs: state.deviceconfigs,
        colorscheme: state.colorscheme,
        personalperformance: state.personalperformance,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalAutomaticPerformanceItemContainer);