import TopSummaryCard from "../components/TopSummaryCard";
import BottomNavBar from "../components/BottomNavBar";
import CoinSummaryCard from "../components/CoinSummaryCard";
import HomePageActionButton from "../components/HomePageActionButton";
import AlertModal from "components/AlertModal";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getMessagingObj } from "../services/firebase";
import { getToken, onMessage } from "firebase/messaging";

import * as myExchangesActions from "../redux/actions/myExchangesActions";
import * as unreadNotificationsActions from "../redux/actions/unreadNotificationsActions";
import * as apiCalls from "../redux/actions/apiCalls";
import * as smartFlowActions from "../redux/actions/smartFlowsCheckerActions";
import * as binanceTickerActions from "../redux/actions/binanceTIckerWebSocketActions";
import * as signalsActions from "../redux/actions/signalsActions";
import * as globalMetricsActions from "../redux/actions/globalMetricsActions";
import * as userCommChannelActions from "../redux/actions/userCommChannelActions";
import * as performancesActions from "../redux/actions/performancesActions";
import * as personalPerformanceActions from '../redux/actions/personalPerformanceActions';
import * as showErrorProgressActions from '../redux/actions/showErrorProgressActions';

import 'react-modal-video/css/modal-video.css';

import { BsCardList } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { FaTelegramPlane, FaQuestion } from 'react-icons/fa';

import ActionButton from "../components/ActionButton";
import LoadingModal from "../components/LoadingModal";
import MarketSummaryCard from "../components/MarketSummaryCard";
import SmartFlowIcon from "../components/SmartFlowIcon";
import { COMMCHANNEL, SIGNALS } from "../constants/ReduxStates";
import SmartFlowPerformanceCard from "components/SmartFlowPerformanceCard";
import AvailableUsdtFundsComponent from "components/AvailableUsdtFundsComponent";

import { Row, Col } from 'antd';

import ModalVideo from 'react-modal-video'
import LeaveMyPerformersHeaderComponent from "components/LeaveMyPerformersHeaderComponent";
import WelcomeToMyPerformersAppPopupBodyComponent from "components/WelcomeToMyPerformersAppPopupBodyComponent";
import GenericErrorPopupHeader from "components/GenericErrorPopupHeader";

const isLoggedIn = (auth) => auth && auth.user && auth.user.role == "STANDARD";

const isExchangeAccountPassive = (account) => account?.status == "PASSIVE";

let shownthissession = false;

const defaultPopup = {
  shown: false,
  header: undefined,
  title: undefined,
  body: undefined,
  footer: undefined,
  divider: undefined,
  buttons: [],
}

const DashboardScreen = ({
  auth,
  myexchanges,
  myexchangesactions,
  smartflowactions,
  language,
  colorscheme,
  deviceconfigs,
  unreads,
  unreadnotificationsactions,
  api,
  installation,
  smartflows,
  binancetickeractions,
  signals,
  signalsactions,
  globalmetricsactions,
  usercommchannel,
  usercommchanelactions,
  performancesactions,
  personalperformanceactions,
  showerrors,
  showerroractions
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const [popup, setPopup] = useState({
    ...defaultPopup
  })

  const myexchangesPayload = myexchanges?.payload;
  const myExchangeConntected = myexchangesPayload?.data?.length > 0;
  const binanceExchange = (myexchangesPayload?.data || []).filter((exchange) => {
    return exchange.type == "BINANCE";
  });
  const isBinancePassive = binanceExchange && binanceExchange.length > 0 && isExchangeAccountPassive(binanceExchange[0]);

  const preload = useCallback(async () => {
    console.log("Getting Here");
    api.getMirror()
      .then((res) => {

      })
      .catch((er) => {

      })
    setLoading(true);
    const { progress } = auth.user;
    console.log({ progress });
    const isAutomaticEnabled = auth?.user?.progress?.firstautomaticenabledts;
    if (!isAutomaticEnabled) {
      setPopup({
        shown: true,
        title: language.welcometomyperformersapp,
        body: <WelcomeToMyPerformersAppPopupBodyComponent />,
        buttons: [
          {
            id: "1",
            text: language.ok,
            onClick: async () => {
              setPopup({
                shown: false
              })
            },

          }
        ]
      })
    }

    globalmetricsactions.getGlobalMetrics();
    unreadnotificationsactions.loadUnreadNotifiations();
    performancesactions.getAutomaticPerformances();
    smartflowactions.checkSmartFlows();
    personalperformanceactions.cratePersonalPerformance();

    try {
      setTimeout(() => {
        setLoading(false);
      }, 1200);
      await myexchangesactions.checkExchanges();
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);

    }
    const ucPayload =
      usercommchannel.type === COMMCHANNEL.COMM_CHANNEL_LOAD_SUCCESS
        ? usercommchannel.payload
        : undefined;
    if (ucPayload) {
    } else {
      const messaging = getMessagingObj();
      getToken(messaging, {
        vapidKey:
          "BFzxuA-XRwDKycukCtM7siDPoLv2oMkHT6J5tv8i4KY1C4jVvsQMlHUM5MYZsfUVkbs1VvYyR9Mle94vc9xxBe4",
      })
        .then(async (value) => {
          console.log("messaging toke", value);
          const uCommChannel = {
            type: "GCM",
            token: value,
          };
          try {
            let ucResult = await api.createCommChannel(value);
            usercommchanelactions.saveCommChannel(uCommChannel);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      onMessage(messaging, (message) => {
        console.log("Message", message);
      });
    }
  }, [
    myexchanges,
    myexchangesactions,
    unreadnotificationsactions,
    smartflowactions,
    signalsactions,
    signals,
    globalmetricsactions,
    usercommchannel,
    usercommchanelactions,
    api,
  ]);


  useEffect(() => {
    if (!isLoggedIn(auth)) {
      navigate("/");
      return;
    }

    document.addEventListener("visibilitychange", () => {
      if (
        document.hidden ||
        document.mozHidden ||
        document.msHidden ||
        document.webkitHidden
      ) {
        console.log("Hiding");
      } else {
        console.log("Coming Visible");
      }
    });
    binancetickeractions.initiateBinanceWebSocket();
    preload();

    return () => {

    };
  }, [document.hidden]);

  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
      margin: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
    },
    actionbuttonscontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: `${deviceconfigs.layout.xxlargepadding}px ${deviceconfigs.layout.xxxxlargepadding}px`,
      flexWrap: "wrap",
    },
    addexchangebuttoncontainer: {
      margin: `0px ${deviceconfigs.layout.largepadding}px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    homeactionbuttonicon: {
      fontSize: "36px",
      color: colorscheme.v2.backgroundtext,
    },
    homeactionbuttoniconsmall: {
      fontSize: "32px",
      color: colorscheme.v2.backgroundtext,
    }
  };
  const unreadCount = unreads?.payload;
  const smartflowPayload = smartflows?.payload;
  const smartFlowsExist = smartflowPayload && smartflowPayload.length > 0;
  const smartFlowsEnabled =
    smartFlowsExist && smartflowPayload[0].status === "ENABLED";
  const membershipType = auth?.user?.membership?.type;
  const membershipExpired = auth?.user?.membership?.status === "EXPIRED";
  const installPrompt = !dismissed && installation.type;
  console.log({ showerrors });
  return (
    <div>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          zIndex: 100,
        }}
      >
        {installPrompt ? (
          <div
            style={{
              width: "100vw",
              backgroundColor: colorscheme.v2.cardbackground,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: `${deviceconfigs.layout.largepadding}px 8px`,
            }}
          >
            <div
              style={{
                marginLeft: "12px",
                padding: "8px",
                borderRadius: "12px",
                border: `1px solid ${colorscheme.v2.backgroundtext}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setDismissed(true);
              }}
            >
              <AiOutlineClose
                style={{
                  fontSize: "18px",
                  color: colorscheme.v2.backgroundtext,
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "8px",
                flex: 3,
                fontSize: "13px",
                fontWeight: "700",
                color: colorscheme.v2.backgroundtext,
                textAlign: "center",
              }}
            >
              {language.enjoyfullmyperformersexperience}
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: "8px",
                padding: "8px",
                borderRadius: "12px",
                backgroundColor: colorscheme.v2.cardbackground,
                color: colorscheme.v2.backgroundtext,
                fontSize: "13px",
                fontWeight: "700",
                border: `2px solid ${colorscheme.v2.backgroundtext}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                installation.prompt();
              }}
            >
              {language.install}
            </div>
          </div>
        ) : null}
        <div style={{}}>
          <TopSummaryCard
            onNotificationPress={() => {
              navigate("/notifications");
            }}
            unreadnotificationcount={unreadCount}
            onUpgradePress={() => {
              navigate("/memberships/select-membership");
            }}
          />
        </div>
      </div>
      <div
        style={{
          paddingTop: installPrompt ? "300px" : "240px",
        }}
      >
        <div style={styles.actionbuttonscontainer}>
          <Row gutter={32}>
            <Col span={8}>
              <HomePageActionButton
                buttoncontainer={{
                  padding: deviceconfigs.layout.xxxlargepadding,
                }}
                icon={
                  <SmartFlowIcon
                    style={{
                      width: "44px",
                      height: "44px",
                    }}
                  />
                }
                text={language.smartflow}
                onClick={() => {
                  if (membershipType === "TRIAL" && membershipExpired) {
                    setPopup({
                      shown: true,
                      header: "",
                      title: language.automaticwarning,
                      body: language.membershipexpired,
                      buttons: [
                        {
                          id: "1",
                          text: language.goPremium,
                          onClick: () => {
                            setPopup({
                              shown: false,
                            });
                            navigate("/memberships/select-membership");
                          }
                        },
                        {
                          id: "2",
                          text: language.cancel,
                          onClick: () => {
                            setPopup({
                              shown: false,
                            });
                          }
                        }
                      ]
                    })
                    return;
                  }
                  if (membershipExpired) {
                    setPopup({
                      shown: true,
                      header: "",
                      title: language.automaticwarning,
                      body: language.membershipexpired,
                      buttons: [
                        {
                          id: "1",
                          text: language.goPremium,
                          onClick: () => {
                            setPopup({
                              shown: false,
                            });
                            navigate("/memberships/select-membership");
                          }
                        },
                        {
                          id: "2",
                          text: language.cancel,
                          onClick: () => {
                            setPopup({
                              shown: false,
                            });
                          }
                        }
                      ]
                    });
                    return;
                  }
                  if (!myExchangeConntected || isBinancePassive) {
                    setPopup({
                      shown: true,
                      header: "",
                      title: language.automaticwarning,
                      body: language.noexchangeconnected,
                      buttons: [
                        {
                          text: language.connectyourbinance,
                          onClick: () => {
                            setPopup({
                              shown: false,
                            })
                            navigate("/add-exchange-select-exchange");
                          },
                          cancel: false,
                        },
                        {
                          text: language.ok,
                          onClick: () => {
                            setPopup({
                              ...popup,
                              shown: false
                            })
                          },
                          cancel: false,
                        },
                      ]
                    })
                    return;
                  }
                  if (smartFlowsEnabled) {
                    navigate("/settings/smart-flows");
                    return;
                  }
                  navigate("/settings/smart-flows/description");
                }}
                badge={
                  <div style={{
                    marginTop: "8px",
                    padding: "2px 18px",
                    backgroundColor: smartFlowsEnabled ? colorscheme.v2.successbackground : colorscheme.v2.errorbackground,
                    borderRadius: "12px",
                    border: "1px solid #929292",
                  }}>
                    <div style={{ fontSize: "11px", color: colorscheme.v2.primary, fontWeight: "600" }}>{smartFlowsEnabled ? language.on : language.off}</div>
                  </div>
                }
              />
            </Col>
            <Col span={8}>
              <HomePageActionButton
                icon={
                  <FaTelegramPlane
                    style={styles.homeactionbuttoniconsmall}
                  />
                }
                text={language.howtojointelegram}
                onClick={() => {
                  setPopup({
                    shown: true,
                    header: <LeaveMyPerformersHeaderComponent
                      source={require("../assets/images/v2/telegram-logo.png")}
                    />,
                    buttons: [
                      {
                        id: "1",
                        text: language.cancel,
                        onClick: () => {
                          setPopup({
                            ...popup,
                            shown: false
                          })
                        },
                        cancel: true,
                      },
                      {
                        id: "2",
                        text: language.confirm,
                        onClick: () => {
                          window.location.href = "https://t.me/+ZsAdlZb6Zd81ZWE0";
                          setPopup({
                            ...popup,
                            shown: false
                          })
                        },
                      },
                    ]
                  })
                }}
              />
            </Col>
            <Col span={8}>
              <HomePageActionButton
                icon={
                  <BsCardList
                    style={styles.homeactionbuttonicon}
                  />
                }
                text={language.orders}
                onClick={() => {
                  navigate("/orders");
                }}
              />
            </Col>
          </Row>
        </div>
        <div
          style={{
            margin: "6%",
          }}
        >
          <SmartFlowPerformanceCard />
        </div>
        {myexchanges?.type === "EXCHANGES_LOAD_SUCCESS" &&
          !myExchangeConntected ? (
          <div style={styles.addexchangebuttoncontainer}>
            <ActionButton
              text={language.connectyourbinance}
              buttonstyle={{
                width: "72vw",
                padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xlargepadding}px`,
                backgroundColor: colorscheme.v2.primary,
                borderRadius: deviceconfigs.layout.xxlargepadding,
              }}
              onClick={() => {
                navigate("/add-exchange-select-exchange");
              }}
            />
          </div>
        ) : null}
        <div style={{ height: "84px" }}></div>
      </div>

      <LoadingModal loading={loading} />
      <AlertModal
        open={popup.shown}
        header={popup.header}
        title={popup.title}
        body={popup.body}
        footer={popup.footer}
        divider={popup.divider}
        onBackdropClick={() => {
          setPopup({
            ...popup,
            shown: false,
          })
        }}
        buttons={popup.buttons}
      />

      <AlertModal
        open={isBinancePassive && showerrors?.dashboard?.apiexpired}
        header={<GenericErrorPopupHeader />}
        title={language.error.generic.header}
        body={language.binanceapikeypassive}
        onBackdropClick={() => {
          showerroractions.updateShowErrorProgress(false, "dashboard.apiexpired")
        }}
        buttons={[
          {
            id: "1",
            text: language.ok,
            onClick: () => {
              showerroractions.updateShowErrorProgress(false, "dashboard.apiexpired")
            }
          }
        ]}
      />
      <BottomNavBar />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    myexchangesactions: bindActionCreators(myExchangesActions, dispatch),
    unreadnotificationsactions: bindActionCreators(
      unreadNotificationsActions,
      dispatch
    ),
    smartflowactions: bindActionCreators(smartFlowActions, dispatch),
    api: bindActionCreators(apiCalls, dispatch),
    binancetickeractions: bindActionCreators(binanceTickerActions, dispatch),
    signalsactions: bindActionCreators(signalsActions, dispatch),
    globalmetricsactions: bindActionCreators(globalMetricsActions, dispatch),
    usercommchanelactions: bindActionCreators(userCommChannelActions, dispatch),
    performancesactions: bindActionCreators(performancesActions, dispatch),
    personalperformanceactions: bindActionCreators(personalPerformanceActions, dispatch),
    showerroractions: bindActionCreators(showErrorProgressActions, dispatch)
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    balancedisplay: state.balancedisplay,
    myexchanges: state.myexchanges,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    unreads: state.unreads,
    cmcidmap: state.cmcidmap,
    installation: state.installation,
    globalmetrics: state.globalmetrics,
    smartflows: state.smartflows,
    cmclisting: state.cmclisting,
    binancetickers: state.binancetickers,
    signals: state.signals,
    usercommchannel: state.usercommchannel,
    automaticperformances: state.automaticperformances,
    showerrors: state.showerrors
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);