import { connect } from "react-redux";
import images from "../../utils/images";

const SmartFlowIcon = ({ colorscheme, style }) => {
  if (colorscheme.v2.name === "light") {
    return (
      <img
        src={images.images.smartflowlight}
        style={{
          width: "32x",
          height: "32px",
          objectFit: "contain",
          ...style,
        }}
        alt={"Smart-Flow"}
      />
    );
  }
  return (
    <img
      src={images.images.smartflowdark}
      style={{
        width: "32x",
        height: "32px",
        objectFit: "contain",
        ...style,
      }}
      alt={"Smart-Flow"}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
    language: state.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartFlowIcon);
