import axios from "axios";
import React, { useState } from "react";
import { BiShieldQuarter } from "react-icons/bi";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import IconedInput from "../components/IconedInput";
import PageView from "../components/Page";
import SizeableButton from "../components/SizeableButton";
import LoadingModal from "../components/LoadingModal";

import * as authActions from "../redux/actions/authActions";
import * as apiCalls from "../redux/actions/apiCalls";

const EmailVerificationScreen = ({ colorscheme, language, api }) => {
  const [verificationcode, setVerificationCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const styles = {
    mainframe: {
      flex: 1,
      backgroundColor: colorscheme.v2.background,
    },
    secondframe: {
      margin: "0px 12px",
    },
    imagecontainer: {
      marginTop: "32px",
      marginLeft: "24px",
    },
    signintotext: {
      fontSize: "28px",
      fontWeight: "700",
      color: colorscheme.v2.backgroundtext,
    },
    myperformerstextcontainer: {
      margin: "12px",
    },
    myperformerstext: {
      color: colorscheme.v2.heavytext,
      fontWeight: "700",
    },
    inputscontainer: {
      margin: "24px 8px 12px 8px",
      padding: "12px",
    },
    inputcontainer: {
      margin: "12px 0px",
    },
    line: {
      backgroundColor: colorscheme.v2.primary,
      height: "8px",
      width: "12%",
      margin: "0px 12px",
      borderRadius: "8px",
    },
    optionscontainer: {
      margin: "0px 12px",
      padding: "12px",
    },
    optionitemcontainer: {
      flexDirection: "row",
      alignItems: "center",
      margin: "12px 0px",
    },
    optiontextitemcontainer: {
      flex: 1,
    },
    optioncheckboxitemcontainer: {},
    optiontext: {
      fontSize: "13px",
      fontWeight: "500",
      color: "#727272",
    },
    signinbuttoncontainer: {
      margin: "12px 12%",
    },
    bottomtextcontainer: {
      display: "flex",
      flexDirection: "row",
      margin: "32px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
    donthaveaccounttext: {
      fontSize: "13px",
      fontWeight: "500",
      color: "#727272",
    },
  };

  const verifyEmail = async () => {
    const { state } = location;
    const { userid } = state;
    setLoading(true);
    try {
      await api.verifyEmail(userid, verificationcode);
      navigate("/dashboard");
      setLoading(false);
    } catch (e) {
      console.error("Error during Verification", e);
      setLoading(false);
    }
  };

  return (
    <PageView>
      <div style={styles.imagecontainer}>
        <img
          src={
            colorscheme.v2.name === "dark"
              ? require("../assets/images/v2/logo-dark.png")
              : require("../assets/images/v2/logo-light.png")
          }
          style={{
            resize: "both",
            objectFit: "contain",
            width: "24%",
            height: "24%",
          }}
          alt={"MyPerformers"}
        />
      </div>
      <div
        style={{
          margin: "0px 12px",
        }}
      >
        <div style={styles.signintotext}>{"Verify Login"}</div>
      </div>
      <div style={styles.myperformerstextcontainer}>
        <div style={styles.myperformerstext}>{"My Performers"}</div>
      </div>
      <div style={styles.line}></div>
      <div style={styles.inputscontainer}>
        <div style={styles.inputcontainer}>
          <IconedInput
            lefticon={
              <BiShieldQuarter
                style={{
                  fontSize: "24px",
                  color: colorscheme.v2.backgroundtext,
                }}
              />
            }
            keyboardType={"number-pad"}
            textContentType={"telephoneNumber"}
            leftseperator
            placeholder={"Verification Code..."}
            onChangeText={(verificationcode) => {
              setVerificationCode(verificationcode);
            }}
            value={verificationcode}
          />
        </div>
      </div>

      <div style={styles.signinbuttoncontainer}>
        <SizeableButton text={"Verify Code"} onClick={verifyEmail} disabled={verificationcode.length !== 6}/>
      </div>
      <div style={styles.bottomtextcontainer}>
        <div style={styles.donthaveaccounttext}>
          {language.donthaveanaccountyet}
          <Link
            style={{
              marginLeft: "8px",
              color: colorscheme.v2.primary,
              fontWeight: "700",
            }}
            to={"/sign-up"}
          >
            {language.signup}
          </Link>
        </div>
      </div>
      <LoadingModal loading={loading} />
    </PageView>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    authactions: bindActionCreators(authActions, dispatch),
    api: bindActionCreators(apiCalls, dispatch),
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    colorscheme: state.colorscheme,
    language: state.language,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerificationScreen);
