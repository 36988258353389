
import { BALANCESDISPLAY } from "../../constants/ReduxStates";

export function balanceShow() {
    return {
        type: BALANCESDISPLAY.BALANCESHOW
    };
}

export function balanceHide() {
    return {
        type: BALANCESDISPLAY.BALANCEHIDE,
    };
}

export function updateBalanceDisplay(display) {
    return function (dispatch) {
        try {
            localStorage.setItem("balancedisplay", JSON.stringify(display));
            if (display) {
                dispatch(balanceShow());
            } else {
                dispatch(balanceHide());
            }

        } catch (e) {
            console.error(e);
        }
    }
}

export function loadBalanceDisplay() {
    return function (dispatch) {
        try {
            const balancedisplay = JSON.parse(localStorage.getItem("balancedisplay"));
            if (balancedisplay == undefined) {
                localStorage.setItem("balancedisplay", JSON.stringify(false));
                dispatch(balanceHide());
                return;
            }
            if (balancedisplay) {
                dispatch(balanceShow());
            } else {
                dispatch(balanceHide());
            }

        } catch (e) {
            console.error(e);
            console.log("Error filters");
        }

    }
}