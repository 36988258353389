import ActionButton from "components/ActionButton";
import AutomaticPerformanceItemContainer from "components/AutomaticPerformanceItemContainer";
import AvailableUsdtFundsComponent from "components/AvailableUsdtFundsComponent";
import PageHeader from "components/PageHeader";
import PersonalAutomaticPerformanceItemContainer from "components/PersonalAutomaticPerformanceItemContainer";
import React, { useState } from "react";

import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as apiCalls from "../../redux/actions/apiCalls";

const PersonalAutomaticPerformanceScreen = ({ deviceconfigs, colorscheme, language, automaticperformances, personalperformance }) => {
    let navigate = useNavigate();
    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
            padding: "8px",
            borderRadius: "18px",
        },
        headercontainer: {
            position: "fixed",
            top: "0px",
            width: "100vw",
            zIndex: 100,
            padding: `${deviceconfigs.layout.xxlargepadding}px 0px`,
            backgroundColor: colorscheme.v2.background,
        },
        statsRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "8px 4px",
        },
        statItemColumn: {
            flex: 1,
            margin: "4px 8px",
        },
        statItemContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        statleft: {
            width: "132px",
            fontSize: "11px",
            color: colorscheme.v2.backgroundtext,
        },
        statleftlong: {
            width: "168px",
            fontSize: "11px",
            color: colorscheme.v2.backgroundtext,
        },
        statrightlong: {
            flex: 1,
            margin: "0px 4px",
            fontSize: "11px",
            color: colorscheme.v2.backgroundtext,
        },
        statrightmostlong: {
            flex: 3,
            fontSize: "11px",
            color: colorscheme.v2.backgroundtext,
        },
        totalTradeStyle: {
            fontSize: "13px",
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
        },
        positiveTradeStyle: {
            fontSize: "13px",
            fontWeight: "700",
            color: colorscheme.v2.success,
        },
        negativeTradeStyle: {
            fontSize: "13px",
            fontWeight: "700",
            color: colorscheme.v2.error,
        }
    };
    const totalDevelopmentPercent = parseFloat(personalperformance.totaldevelopmentpercentage);
    const totalDevelopmentUSDT = parseFloat(personalperformance.totaldevelopmentusdt);
    const totalOrderCount = parseInt(personalperformance.totalordercount);
    const totalDevelopmentPercentPerTrade = totalDevelopmentPercent / totalOrderCount;
    const totalDevelopmentUSDTPerTrade = totalDevelopmentUSDT / totalOrderCount;
    return (
        <div style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <PageHeader
                    header={language.aboutmyperformersapp}
                    onBackPressed={() => {
                        window.history.back();
                    }}
                />
            </div>
            <div style={{
                flex: 1,
                paddingTop: "64px",
            }}>
                <div style={{
                    padding: "8px 12px",
                }}>
                    <AvailableUsdtFundsComponent />
                </div>
                <div style={{ height: "1px", backgroundColor: "#929292", margin: "12px 0px" }}></div>
                <div style={styles.statsRow}>
                    <div style={styles.statItemColumn}>
                        <div style={styles.statItemContainer}>
                            <div style={styles.statleft}>Total Trades</div>
                            <div style={styles.statright}><span style={styles.totalTradeStyle}>{personalperformance.totalordercount}</span></div>
                        </div>
                        <div style={styles.statItemContainer}>
                            <div style={styles.statleft}>Positive Trades</div>
                            <div style={styles.statright}><span style={styles.positiveTradeStyle}>{personalperformance.positiveordercount}</span></div>
                        </div>
                    </div>
                    <div style={styles.statItemColumn}>
                        <div style={styles.statItemContainer}>
                            <div style={styles.statleft}>Active Trades </div>
                            <div style={styles.statright}><span style={styles.totalTradeStyle}>{personalperformance.activeordercount}</span></div>
                        </div>
                        <div style={styles.statItemContainer}>
                            <div style={styles.statleft}>Negative Trades</div>
                            <div style={styles.statright}><span style={styles.negativeTradeStyle}>{personalperformance.negativeordercount}</span></div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "1px", backgroundColor: "#929292" }}></div>
                <div style={styles.statsRow}>
                    <div style={styles.statItemColumn}>
                        <div style={styles.statItemContainer}>
                            <div style={styles.statleftlong}>Total Development %</div>
                            <div style={styles.statrightlong}><span style={totalDevelopmentPercent > 0 ? styles.positiveTradeStyle : styles.negativeTradeStyle}>{totalDevelopmentPercent.toFixed(2)}</span></div>
                            <div style={styles.statrightmostlong}>( <span style={totalDevelopmentPercentPerTrade > 0 ? styles.positiveTradeStyle : styles.negativeTradeStyle}>{totalDevelopmentPercentPerTrade.toFixed(2)}</span> per Trade)</div>
                        </div>
                        <div style={styles.statItemContainer}>
                            <div style={styles.statleftlong}>Total Development USDT</div>
                            <div style={styles.statrightlong}><span style={totalDevelopmentUSDT > 0 ? styles.positiveTradeStyle : styles.negativeTradeStyle}>{totalDevelopmentUSDT.toFixed(2)}</span></div>
                            <div style={styles.statrightmostlong}>( <span style={totalDevelopmentUSDTPerTrade > 0 ? styles.positiveTradeStyle : styles.negativeTradeStyle}>{totalDevelopmentUSDTPerTrade.toFixed(2)}</span> per Trade)</div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "1px", backgroundColor: "#929292", margin: "12px 0px" }}></div>
                <div>
                    <PersonalAutomaticPerformanceItemContainer
                    />
                </div>
                
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    marginTop: "18px",
                }}>
                    <ActionButton
                        text={"Information"}
                        onClick={() => {
                            navigate("/settings/automatic/description");
                        }}
                        buttonstyle={{
                            padding: "4px 12px",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        iconcache: state.iconcache,
        colorscheme: state.colorscheme,
        balances: state.balances,
        xactionssignals: state.xactionssignals,
        language: state.language,
        loading: state.loading,
        huobisymbols: state.huobisymbols,
        filters: state.filters,
        myexchanges: state.myexchanges,
        membership: state.membership,
        cmcidmap: state.cmcidmap,
        personalperformance: state.personalperformance,
        automaticperformances: state.automaticperformances
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAutomaticPerformanceScreen);