import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
  switch (action.type) {
    case Constants.BINANCETICKERS.BINANCETICKERS_SUCCESS:
      return { ...action.payload };
    case Constants.BINANCETICKERS.BINANCETICKERS_FAIL:
      return { ...action };
    default:
      return state;
  }
}
