import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toHumanReadableDateTime } from "../../utils/timeservice";

const NewsSummary = ({ deviceconfigs, colorscheme, language, title, imagelink, link, creationdate, source, onClick }) => {
    const styles = {
        maincontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: `${deviceconfigs.layout.largepadding}px 0px`,
        },
        titlecontainer: {

        },
        title: {
            fontSize: deviceconfigs.font.xlargetext,
            fontWeight: "600",
            color: colorscheme.v2.backgroundtext,
        },
        imagecontainer: {
            padding: deviceconfigs.layout.xlargepadding,

        },
        image: {
            width: "18vw",
            height: "18vw",
            borderRadius: deviceconfigs.layout.xxlargepadding,
            objectFit: "cover"
        },
        sourceandtextcontainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        sourcetext: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "500",
            color: colorscheme.v2.heavytext,
        },
        datetext: {
            fontSize: deviceconfigs.font.smalltext,
            fontWeight: "500",
            color: colorscheme.v2.backgroundtext,
        },
        contentcontainer: {
            flex: 1,
            padding: `0px ${deviceconfigs.layout.largepadding}px`,
        },

    };

    return (
        <div
            style={styles.maincontainer}
            onClick={onClick}
        >
            <div style={styles.imagecontainer}>
                <img
                    src={imagelink}
                    style={styles.image}
                />
            </div>
            <div style={styles.contentcontainer}>
                <div style={styles.titlecontainer}>
                    <div style={styles.title}>{title}</div>
                </div>
                <div style={styles.sourceandtextcontainer}>
                    <div style={styles.sourcetext}>{source}</div>
                    <div style={{ width: deviceconfigs.layout.mediumpadding, height: deviceconfigs.layout.mediumpadding, borderRadius: 999, margin: deviceconfigs.layout.xlargepadding, }}></div>
                    <div style={styles.datetext}>{toHumanReadableDateTime(creationdate)}</div>
                </div>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        colorscheme: state.colorscheme,
        deviceconfigs: state.deviceconfigs,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsSummary);