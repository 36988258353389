import * as Constants from "../../constants/ReduxStates";

const initialAuthState = {};

export default function reducer(state = initialAuthState, action) {
    switch (action.type) {
        case Constants.FILTERS.FILTERS_LOAD_SUCCESS:
            return { ...action };
        case Constants.FILTERS.FILTERS_LOAD_FAILED:
            return { ...action };
        default:
            return state;
    }
}
