export const getBinanceQuantityPrecision = (item) => {
    const { info } = item;
    if (info) {
        for (var i = 0; i < info.filters.length; i++) {
            if (info.filters[i].filterType == "LOT_SIZE") {
                let ss = info.filters[i].stepSize;
                let iindex = ss.indexOf(".");
                let oindex = ss.indexOf("1");
                let diff = oindex - iindex;
                if (diff < 1) {
                    diff = 0;
                }
                return diff;
            }
        }
    }
    return 8;
}

export const getBinancePricePrecision = (item) => {
    const { info } = item;
    if (info) {
        for (var i = 0; i < info.filters.length; i++) {
            if (info.filters[i].filterType == "PRICE_FILTER") {
                let ss = info.filters[i].tickSize;
                let iindex = ss.indexOf(".");
                let oindex = ss.indexOf("1");
                let diff = oindex - iindex;
                if (diff < 1) {
                    diff = 0;
                }
                return diff;
            }
        }
    }
    return 8;
}