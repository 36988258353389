import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import auth from "./redux/reducers/authReducer";
import balancedisplay from "./redux/reducers/balanceShowReducer";
import language from "./redux/reducers/languageReducer";
import deviceconfigs from "./redux/reducers/deviceConfigsReducer";
import colorscheme from "./redux/reducers/colorsReducer";
import myexchanges from "./redux/reducers/myExchangesReducer";
import cmcidmap from "./redux/reducers/cmcIdMapReducer";
import filters from "./redux/reducers/filtersReducer";
import huobisymbols from "./redux/reducers/huobiSymbolsReducer";
import unreads from "./redux/reducers/unreadNotificationsReducer";
import installation from "./redux/reducers/installationEventReducer";
import globalmetrics from "./redux/reducers/globalMetricsReducer";
import smartflows from "./redux/reducers/smartFlowReducer";
import cmclisting from "./redux/reducers/cmcListingReducer";
import binancetickers from "./redux/reducers/binanceTickerWebSocketReducer";
import signals from "./redux/reducers/signalsReducer";
import xactionssignals from "./redux/reducers/myXActionsReducer";
import usercommchannel from "./redux/reducers/userCommChannelReducer";
import newsdata from "./redux/reducers/newsReducer";
import automaticperformances from "./redux/reducers/performancesReducer";
import binancepairprices from "./redux/reducers/binancePricesReducer";
import personalperformance from './redux/reducers/personalPerformanceReducer';
import showerrors from './redux/reducers/showErrorProgressReducer';

import { checkLoginState } from "./redux/actions/authActions";
import { loadBalanceDisplay } from "./redux/actions/balanceShowActions";
import { getSelectedLanguage } from "./redux/actions/languageActions";
import { loadDeviceConfigs } from "./redux/actions/deviceConfigsActions";
import { loadColorMode } from "./redux/actions/colorsActions";
import { loadIDMap } from "./redux/actions/cmcIdMapActions";
import { loadFilters } from "./redux/actions/filtersActions";
import { checkCMCListing } from "./redux/actions/cmcListingActions";
import { startNewsUpdateService } from "./redux/actions/newsActions";
import { initiateBinancePairPrices } from "redux/actions/binancePairPricesActions";
import { loadPriceTickers } from "redux/actions/priceTickerActions";

import "antd/dist/antd.css";
import "./App.css";

import AppContainer from "./components/AppContainer.js";

import * as FirebaseService from "./services/firebase";
import { getSignals } from "redux/actions/signalsActions";
import { loadShowErrorsProgress } from "redux/actions/showErrorProgressActions";

FirebaseService.initializeFirebase();

const reducers = {
  auth,
  balancedisplay,
  language,
  deviceconfigs,
  colorscheme,
  myexchanges,
  cmcidmap,
  filters,
  huobisymbols,
  unreads,
  installation,
  globalmetrics,
  smartflows,
  cmclisting,
  binancetickers,
  signals,
  xactionssignals,
  usercommchannel,
  newsdata,
  automaticperformances,
  binancepairprices,
  personalperformance,
  showerrors
};

const store = createStore(combineReducers(reducers), applyMiddleware(thunk));

store.dispatch(checkLoginState());
store.dispatch(loadBalanceDisplay());
store.dispatch(getSelectedLanguage());
store.dispatch(loadDeviceConfigs());
store.dispatch(loadColorMode());
store.dispatch(loadIDMap());
store.dispatch(loadFilters());
store.dispatch(checkCMCListing());
store.dispatch(startNewsUpdateService());
store.dispatch(initiateBinancePairPrices());
store.dispatch(getSignals());
store.dispatch(loadPriceTickers());
store.dispatch(loadShowErrorsProgress());

function App() {
  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}

export default App;
