import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageView from "../components/Page";
import PageHeader from "../components/PageHeader";

import {
    AiOutlineCopy,
    AiFillCheckCircle,
    AiOutlineClose
} from 'react-icons/ai';

import {
    message as MessageThrow,
    notification
} from 'antd'

import * as apiCalls from '../redux/actions/apiCalls';

import { toHumanReadableDateTime } from "../utils/timeservice";




const PaymentDetailsScreen = ({ deviceconfigs, language, colorscheme }) => {


    const navigate = useNavigate();
    const location = useLocation();

    const stateToStr = (state, language) => {
        switch (state) {
            case "WAITING_FOR_RESPONSE":
                return language.waitingforconfirmation;
            case "CREATED":
                return language.created;
            case "ACCEPTED":
                return language.confirmed;
            case "ACCEPTED_PARTIALLY":
                return language.confirmedpartially;
            case "REJECTED":
                return language.rejected;
        }
    }

    const openNotification = ({ title = "Notification", description, duration, style, onClickClose }) => {
        const args = {
            message: <div style={{ color: colorscheme.v2.backgroundtext, }}>{title}</div>,
            description: <div style={{ color: colorscheme.v2.backgroundtext, }}>{description}</div>,
            duration: duration || 3,
            closeIcon: <div onClick={onClickClose}><AiOutlineClose style={{ fontSize: "28px", color: colorscheme.v2.backgroundtext }} /></div>,
            style: style || { backgroundColor: colorscheme.v2.background, color: colorscheme.v2.backgroundtext },
        };
        notification.open(args);
    };

    const openMessage = (message) => {
        MessageThrow.success(message);
    }

    const myState = location.state;
    const payment = myState.payment;

    const styles = {
        mainframe: {
            flex: 1,
            backgroundColor: colorscheme.v2.background,
        },
        headercontainer: {
        },
        paymentidcontainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colorscheme.v2.primarybackground,
            marginTop: deviceconfigs.layout.xxlargepadding,
            marginBottom: deviceconfigs.layout.xxlargepadding,
            marginLeft: deviceconfigs.layout.xlargepadding,
            marginRight: deviceconfigs.layout.xlargepadding,
            paddingTop: deviceconfigs.layout.largepadding,
            paddingBottom: deviceconfigs.layout.largepadding,
            borderRadius: deviceconfigs.layout.xlargepadding,
        },
        paymentidheadertext: {
            fontSize: deviceconfigs.font.largetext,
            fontWeight: "300",
            color: colorscheme.v2.heavytext,
        },
        paymentidtext: {
            fontSize: deviceconfigs.font.xxxlargetext,
            fontWeight: "700",
            color: colorscheme.v2.primary,
            textAlign: "center",
        },
        itemcontainer: {
            marginTop: deviceconfigs.layout.xlargepadding,
            marginBottom: deviceconfigs.layout.xlargepadding,
        },
        itemleft: {
            color: colorscheme.v2.backgroundtext,
        },
        itemright: {
            fontSize: deviceconfigs.font.mediumtext,
            fontWeight: "700",
            color: colorscheme.v2.backgroundtext,
            marginTop: deviceconfigs.layout.mediumpadding,
            marginBottom: deviceconfigs.layout.mediumpadding,
        },
        warningscontainer: {
            padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
            backgroundColor: colorscheme.v2.errorbackground,
            marginTop: deviceconfigs.layout.xlargepadding,
            marginBottom: deviceconfigs.layout.xlargepadding,
        }
    }
    return (
        <PageView style={styles.mainframe}>
            <div style={styles.headercontainer}>
                <div style={styles.headercontainer}>
                    <PageHeader
                        onBackPressed={() => {
                            navigate("/my-payments")
                        }}
                        header={language.paymentsummary}
                    />
                </div>
                <div
                    style={{
                        margin: "auto",
                        marginTop: "12px",
                        width: "92vw",
                        borderRadius: deviceconfigs.layout.xlargepadding,
                        backgroundColor: colorscheme.v2.cardbackground,
                        color: colorscheme.v2.unselected,
                        padding: `${deviceconfigs.layout.xlargepadding}px ${deviceconfigs.layout.xxlargepadding}px`,
                    }}
                >
                    <div style={styles.paymentidcontainer}>
                        <div style={styles.paymentidtext}>{stateToStr(payment.state, language)}</div>
                    </div>
                    <div style={{
                        padding: deviceconfigs.layout.xlargepadding,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img
                            src={payment.icon}
                            style={{
                                width: "21vw",
                                height: "21vw",
                                borderRadius: 999,
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                    <div style={{
                    }}>
                        <div style={styles.itemcontainer}>
                            <div style={styles.itemleft}>{language.paymentnetwork} : </div>
                            <div style={styles.itemright}>TRC20</div>
                        </div>
                        <div style={{
                            ...styles.itemcontainer,

                        }}>
                            <div style={styles.itemleft}>{language.paymentaddress} : </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                                <div
                                    style={{
                                        ...styles.itemright,
                                        textDecorationLine: "underline",
                                    }}
                                    onClick={() => {
                                        if (navigator.clipboard.writeText) {
                                            navigator.clipboard.writeText(payment.payaddress);
                                            openMessage("Copied Address to clipboard");
                                        }
                                    }}
                                >{payment.payaddress}</div>
                                <div
                                    style={{
                                        marginLeft: deviceconfigs.layout.xlargepadding,
                                        padding: deviceconfigs.layout.largepadding,
                                        borderRadius: deviceconfigs.layout.largepadding,
                                        backgroundColor: colorscheme.v2.primary,
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        if (navigator.clipboard.writeText) {
                                            navigator.clipboard.writeText(payment.payaddress);
                                            openMessage("Copied Address to clipboard");
                                        }
                                    }}
                                >
                                    <AiOutlineCopy
                                        style={{
                                            fontSize: "18px",
                                            color: colorscheme.v2.primarytext
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={styles.itemcontainer}>
                            <div style={styles.itemleft}>{language.requestedamount} : </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                                <div style={styles.itemright}>{payment.responseamount + (payment.responseunit == "usdttrc20" ? " USDT" : " TRX")}</div>
                                <div
                                    style={{
                                        marginLeft: deviceconfigs.layout.xlargepadding,
                                        padding: deviceconfigs.layout.largepadding,
                                        borderRadius: deviceconfigs.layout.largepadding,
                                        backgroundColor: colorscheme.v2.primary,
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        if (navigator.clipboard.writeText) {
                                            navigator.clipboard.writeText(payment.responseamount);
                                            openMessage("Copied Amount to Clipboard");
                                        }

                                    }}
                                >
                                    <AiOutlineCopy
                                        style={{
                                            fontSize: "18px",
                                            color: colorscheme.v2.primarytext
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={styles.warningscontainer}>
                            <div style={{
                                fontSize: deviceconfigs.font.mediumtext,
                                fontWeight: "600",
                                color: colorscheme.v2.error,
                            }}>{language.paymentwarning}</div>
                        </div>
                        <div style={styles.warningscontainer}>
                            <div style={{
                                fontSize: deviceconfigs.font.mediumtext,
                                fontWeight: "600",
                                color: colorscheme.v2.error,
                            }}>{language.paymentwarning2}</div>
                        </div>
                    </div>
                </div>
            </div>
        </PageView>
    );
}


function mapDispatchToProps(dispatch) {
    return {
        api: bindActionCreators(apiCalls, dispatch),
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        deviceconfigs: state.deviceconfigs,
        iconcache: state.iconcache,
        colorscheme: state.colorscheme,
        balances: state.balances,
        xactionssignals: state.xactionssignals,
        language: state.language,
        loading: state.loading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsScreen);
