import PropTypes from "prop-types";
import { connect } from "react-redux";
import StyledComponents from "styled-components";

const _INPUT = StyledComponents.input`
    &:focus {
        outline: none;
    }
`;

const IconedInput = ({
  colorscheme,
  lefticon,
  righticon,
  leftseperator,
  rightseperator,
  placeholder,
  onChangeText,
  value,
  secure,
  onRightIconPressed,
  disabled,
  type,
  error,
}) => {
  const styles = {
    noerrorinputcontainer: {
      padding: "4px 4px",
      backgroundColor: colorscheme.v2.cardbackground,
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    errorinputcontainer: {
      padding: "4px 4px",
      backgroundColor: colorscheme.v2.cardbackground,
      border: `1px solid ${colorscheme.v2.error}`,
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    input: {
      backgroundColor: "transparent",
      border: "none",
      padding: "8px",
      flex: 1,
      color: colorscheme.v2.backgroundtext,
    },
    lefticoncontainer: {
      marginLeft: "8px",
      display: "flex",
      flexDirection: "row",
      alignItem: "center",
      justifyContent: "center",
    },
    righticoncontainer: {
      marginRight: "8px",
      display: "flex",
      flexDirection: "row",
      alignItem: "center",
      justifyContent: "center",
    },
    verticalseperator: {
      height: "24px",
      width: "2px",
      backgroundColor: "#929292",
      margin: "0px 4px",
    },
  };
  const containerStyle = error
    ? styles.errorinputcontainer
    : styles.noerrorinputcontainer;
  return (
    <div style={containerStyle}>
      {lefticon ? <div style={styles.lefticoncontainer}>{lefticon}</div> : null}
      {leftseperator ? <div style={styles.verticalseperator}></div> : null}
      <_INPUT
        disabled={disabled}
        style={styles.input}
        placeholder={placeholder}
        type={secure ? "password" : type || "text"}
        value={value}
        onChange={(e) => {
          if (onChangeText) {
            onChangeText(e.target.value);
          }
        }}
      />
      {righticon ? <div></div> : null}
      {rightseperator ? <div style={styles.verticalseperator}></div> : null}
      {righticon ? (
        <div
          style={styles.righticoncontainer}
          onClick={(e) => {
            if (onRightIconPressed) {
              onRightIconPressed();
            }
          }}
        >
          {righticon}
        </div>
      ) : null}
    </div>
  );
};

IconedInput.propTypes = {
  lefticon: PropTypes.node,
  righticon: PropTypes.node,
  onChangeText: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    deviceconfigs: state.deviceconfigs,
    colorscheme: state.colorscheme,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconedInput);
