import { CMCLISTING } from "../../constants/ReduxStates";
import { getListingsDBOnce } from "../../services/firebase";

export function coinsLoadSuccess(payload) {
  return {
    type: CMCLISTING.CMCLISTING_SUCCESS,
    payload,
  };
}

export function coinsLoadFail() {
  return {
    type: CMCLISTING.CMCLISTING_FAIL,
  };
}

export function checkCMCListing() {
  return async function (dispatch) {
    try {
      getListingsDBOnce(async (snapshot) => {
        const data = snapshot.val();
        const list = data.data;
        console.log("MarketWatchListing" , list);
        for (var i = 0; i < list.length - 1; i++) {
          list[
            i
          ].icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/${list[i].id}.png`;
        }
        console.log("Updating Listing");
        dispatch(coinsLoadSuccess(list));
      });
    } catch (e) {
      dispatch(coinsLoadFail());
    }
  };
}
