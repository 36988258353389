import axios from "axios";
import * as authActions from "./authActions";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const path = REACT_APP_API_URL;

const configureAxios = (authState) => {
  if (authState.token) {
    axios.defaults.headers.common.Authorization = `Bearer ${authState.token}`;
  }
};

export function login({ login, password }) {
  return function (dispatch) {
    return axios.post(`${path}/api/v1/oauth/login`, {
      phonenumber: login,
      password,
      role: "STANDARD",
    });
  };
}

export function registerAsStandardUser(regObject) {
  return function (dispatch) {
    return axios.post(
      `${path}/api/v1/user-registrations/standard-users`,
      regObject,
      {}
    );
  };
}

export function acknowledgetDisclaimer(accepted, readfor) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/app-users/standard-users/update-disclaimer`,
      { accepted: accepted, readfor: readfor },
      {}
    );
  };
}

export function registerAsStandardUserV2(regObject) {
  return function (dispatch) {
    return axios.post(
      `${path}/api/v2/user-registrations/standard-users`,
      regObject,
      {}
    );
  };
}

export function verifyRegistration(userid, verificationcode) {
  return function (dispatch) {
    return axios.post(
      `${path}/api/v2/user-registrations/standard-users/registration-verification`,
      { userid, verificationcode },
      {}
    );
  };
}

export function updatePassword(oldpassword, newpassword) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/app-users/standard-users/passwords`,
      { oldpassword, newpassword },
      {}
    );
  };
}

export function forgotPassword(email) {
  return function (dispatch) {
    return axios.put(
      `${path}/api/v1/app-users/standard-users/forgot-password`,
      { login: email }
    );
  };
}

export function verifyEmail(userid, verificationcode) {
  return function (dispatch, getState) {
    return axios
      .post(
        `${path}/api/v1/verification-pair-controller/email-verification`,
        {
          userid,
          verificationcode,
        },
        {}
      )
      .then((res) => {
        const tokenizeduser = {
          isLoggedIn: true,
          user: res.data.object.user,
          token: res.data.object.token,
          logindate: Date.now(),
        };
        axios.defaults.headers.common.Authorization = `Bearer ${tokenizeduser.token}`;
        dispatch(authActions.loginPersist(tokenizeduser));
        return res;
      })
      .catch((err) => {
        console.log("Error during verification");
      });
  };
}

export function getMirror() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios
      .get(`${path}/api/v1/app-users/standard-users/get-mirror`)
      .then((res) => {
        const tokenizeduser = {
          isLoggedIn: true,
          user: res.data.object.user,
          token: res.data.object.token,
          logindate: getState().auth.logindate,
        };
        axios.defaults.headers.common.Authorization = `Bearer ${tokenizeduser.token}`;
        dispatch(authActions.loginPersist(tokenizeduser));
        return res;
      });
  };
}

export function updateProfilePic(image) {
  return function (dispatch, getState) {
    var data = new FormData();
    data.append("file", image);
    configureAxios(getState().auth);
    return fetch(`${path}/api/v1/app-users/standard-users/profile-pic`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      body: data,
    });
  };
}

export function createCommChannel(token) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/app-users/standard-users/comm-channels`, {
      token,
      type: "GCM",
    });
  };
}

export function deleteCommChannel(id) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.delete(
      `${path}/api/v1/app-users/standard-users/comm-channels/${id}`
    );
  };
}

export function updateUserSettings(settings) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.put(
      `${path}/api/v1/app-users/standard-users/user-settings`,
      settings
    );
  };
}

export function updateDisplayName(profile) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/app-users/standard-users/personal-info`,
      profile
    );
  };
}

export function getUnreadCount() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/user-notifications/unreads`);
  };
}

export function getUserNotifications() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(
      `${path}/api/v1/user-notifications?page=0&size=10000&sort=creationdate,desc`
    );
  };
}


export function createExchangeAccount(account) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/user-exchange-accounts`, account);
  };
}

export function getMyExchangeAccounts() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/user-exchange-accounts/my-accounts`);
  };
}

export function deleteExchangeAccount(userexchangeid) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.delete(
      `${path}/api/v1/user-exchange-accounts/${userexchangeid}`
    );
  };
}

export function getBinanceBalances() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/binance-controller/my-balances`);
  };
}

export function getHuobiBalances() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/huobi-controller/my-balances`);
  };
}

export function getBittrexBalances() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/bittrex-controller/my-balances`);
  };
}

export function getCPOrders() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/cp-orders?page=0&size=1000&sort=creationdate,desc`);
  };
}

export function getCPOrderDetails(orderid) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/cp-orders/${orderid}`);
  };
}

export function showRateAndReview() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/show-rate-and-review`);
  };
}

export function getAutomaticPerformance() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/performances/automatic/generate-general-performance`);
  };
}

export function createPersonalAutomaticPerformances() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/performances/automatic/generate-personal-performance`);
  };
}

export function getCPSignals() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/cp-signals?sort=creationdate,desc`);
  };
}

export function getCPSignal(signalid) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/cp-signals/${signalid}`);
  };
}

export function getCPSignalIdsOfMyOrders() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(
      `${path}/api/v1/cp-signals?my-order-signals=true&sort=creationdate,desc`
    );
  };
}

export function likeDislikeSignal(id, likedislike) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/cp-signals/${id}/likes-dislikes`, {
      likedislike,
    });
  };
}

export function saveSignal(id) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/cp-signals/${id}/saves`, {});
  };
}

export function createBinanceMarketTrade(sender) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/binance-controller/market-trades`,
      sender
    );
  };
}

export function createHuobiMarketOrder(sender) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/huobi-controller/market-trades`, sender);
  };
}

export function closeManually(id) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/binance-controller/market-trades/close-trades`,
      {
        cporderid: id,
      }
    );
  };
}

export function closeHuobiManually(id) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/huobi-controller/market-trades/close-trades`,
      {
        cporderid: id,
      }
    );
  };
}

export function createPaymentRequest(subscriptiontype, paycurrency) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(`${path}/api/v1/payment-controller`, {
      subscriptiontype,
      paycurrency,
      orderdescription: "r991kkfa",
    });
  };
}

export function getMyPaymentById(paymentid) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/payment-controller/${paymentid}`);
  };
}

export function getMyPayments() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(
      `${path}/api/v1/payment-controller/my-payments?sort=creationdate,desc`
    );
  };
}

export function getBinancePrices() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/binance-controller/ticker/price`);
  };
}

export function getHuobiPrices() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/huobi-controller/market/tickers`);
  };
}

export function getMySmartFlows() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(`${path}/api/v1/smart-flow-controller/smart-flows`);
  };
}

export function createSmartFlow(smartflow) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/smart-flow-controller/smart-flows`,
      smartflow
    );
  };
}

export function getSymbolsCoinMarketCap(symbol) {
  return function (dispatch) {
    return axios.get(
      `https://pro-api.coinmarketcap.com/v1/cryptocurrency/info?symbol=${symbol}`,
      {
        headers: {
          "X-CMC_PRO_API_KEY": "754014fb-f1b2-4e78-8767-e49d7d6c026b",
        },
      }
    );
  };
}

export function getSymbolsQuote(symbol) {
  return function (dispatch) {
    return axios.get(
      `https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=${symbol}`,
      {
        headers: {
          "X-CMC_PRO_API_KEY": "754014fb-f1b2-4e78-8767-e49d7d6c026b",
        },
      }
    );
  };
}

export function queryMarketWatch(query) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(
      `${path}/api/v1/coin-market-cap-controller/listings/latests?query=${query}`
    );
  };
}

export function getGlobalMetrics() {
  return function (dispatch, getState) {
    return axios.get(
      `${path}/api/v1/coin-market-cap-controller/global-metrics/quotes/latest`
    );
  };
}

export function getMarketWatch(page = 1, size = 50, convert = "USD") {
  return function (dispatch) {
    return axios.get(
      `https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?start=${page}&limit=${size}&convert=${convert}`,
      {
        headers: {
          "X-CMC_PRO_API_KEY": "754014fb-f1b2-4e78-8767-e49d7d6c026b",
        },
      }
    );
  };
}

export function getExchangesForSymbol(symbol) {
  return function (dispatch) {
    return axios.get(
      `https://pro-api.coinmarketcap.com/v1/exchange/market-pairs/latest?matched_symbol=${symbol}`,
      {
        headers: {
          "X-CMC_PRO_API_KEY": "754014fb-f1b2-4e78-8767-e49d7d6c026b",
        },
      }
    );
  };
}

export function getPerformance(symbol) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.get(
      `${path}/api/v1/coin-market-cap-controller/cryptocurrencies/performance?symbol=${symbol}`
    );
  };
}

export function getMarketCap() {
  return function (dispatch) {
    return axios.get(
      `https://pro-api.coinmarketcap.com/v1/global-metrics/quotes/latest`,
      {
        headers: {
          "X-CMC_PRO_API_KEY": "754014fb-f1b2-4e78-8767-e49d7d6c026b",
        },
      }
    );
  };
}

export function getExchangeInfoForSymbol(symbol) {
  return function (dispatch) {
    return axios.get(
      `${path}/api/v1/binance-controller/exchangeInfo?symbol=${symbol}`
    );
  };
}

export function getBinanceExchangeSymbolInfoForAllSymbol() {
  return function (dispatch) {
    return axios.get(`https://api.binance.com/api/v3/exchangeInfo`);
  };
}

export function getAllHuobiSymbols() {
  return function (dispatch) {
    return axios.get(`/huobi-api/v1/common/symbols`);
  };
}

export function getHuobiSymbol(pair) {
  return function (dispatch) {
    return axios.get(`/huobi-api/market/detail/merged?symbol=${pair}`);
  };
}

export function getAllSymbols() {
  return function (dispatch) {
    return axios.get(`https://api.binance.com/api/v3/ticker/price`, { headers: [] });
  };
}

export function getSymbolsTicker(symbol) {
  return function (dispatch) {
    return axios.get(
      `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`, { headers: [] }
    );
  };
}

export function updateFirstLoginPrompted() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/user-progress-controller/login-prompted`
    );
  };
}

export function updateFirstExchangeConnected() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/user-progress-controller/first-exchange-connect`
    );
  };
}

export function updateFirsAutomaticEnabled() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.post(
      `${path}/api/v1/user-progress-controller/first-automatic-enabled`
    );
  };
}

export function markUserNotificationsRead(notificationid) {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/user-notifications/${notificationid}/mark-read`
    );
  };
}

export function markAllSignalNotificationsRead() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/user-notifications/mark-read?type=SIGNAL`
    );
  };
}

export function markAllOrderNotificationsRead() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/user-notifications/mark-read?type=ORDER`
    );
  };
}

export function markAllWatchlistNotificationsRead() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(
      `${path}/api/v1/user-notifications/mark-read?type=WATCHLIST`
    );
  };
}

export function markAllUserNotificationsAsRead() {
  return function (dispatch, getState) {
    configureAxios(getState().auth);
    return axios.patch(`${path}/api/v1/user-notifications/mark-read`);
  };
}

export function getBitcoinNews() {
  return function (dispatch, getState) {
    return axios.get(
      `https://cryptonews-api.com/api/v1?tickers=BTC&items=50&token=3w6vkp8zuvkv3t6q1mmvk4tnhqniig4uv7dfng4i`
    );
  };
}

export function getAltcoinsNews() {
  return function (dispatch, getState) {
    return axios.get(
      `https://cryptonews-api.com/api/v1?tickers=ETH,SOL,ADA,XRP,DOGE,SHIB,AVAX,CAKE,BNB,DOT,LUNA,LTC,UNI,XLM,VET&items=50&token=3w6vkp8zuvkv3t6q1mmvk4tnhqniig4uv7dfng4i`
    );
  };
}

export function getTopNews() {
  return function (dispatch, getState) {
    return axios.get(
      `https://cryptonews-api.com/api/v1/category?section=alltickers&items=50&token=3w6vkp8zuvkv3t6q1mmvk4tnhqniig4uv7dfng4i`
    );
  };
}

export function getNFTNews() {
  return function (dispatch, getState) {
    return axios.get(
      `https://cryptonews-api.com/api/v1/category?section=alltickers&search=NFT&items=50&token=3w6vkp8zuvkv3t6q1mmvk4tnhqniig4uv7dfng4i`
    );
  };
}